import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import DropdownItemWithPill from 'components/DropdownItemWithPill';
import InputWithLabel from 'components/InputWithLabel';
import InputWithTags from 'components/InputWithTags';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/Select';

import useConstants from './constants';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';
import isFunction from 'utilities/isFunction';

const DropdownWithTags = forwardRef((props, ref) => {
    const { SELECT } = useConstants();

    const {
        className = '',
        hasError = false,
        isRequired = false,
        items = [],
        label = '',
        onChange,
        onSubmit,
        placeholder = SELECT
    } = props;

    

    const errorMessage = useRef('');

    const [error, setError] = useState();
    // const [selectedIndexes, setSelectedIndexes] = useState({});
    // const [tagToItemMap, setTagToItemMap] = useState([]);
    const [tags, setTags] = useState([]);

    const selectedIndexes = useRef({});
    const tagToItemMap = useRef([]);

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    const selectItem = useCallback(
        index => {
            if (!(index in selectedIndexes.current)) {
                // setSelectedIndexes(indexes => ({
                //     ...indexes,
                //     [index]: true
                // }));
                selectedIndexes.current = {
                    ...selectedIndexes.current,
                    [index]: true
                };

                setTags(tags => {
                    return [...tags, items?.[index]];
                });

                // setTagToItemMap(map => [...map, index]);
                tagToItemMap.current = [...tagToItemMap.current, index];
            }
        },
        [items, selectedIndexes]
    );

    const handleError = useCallback(message => {
        setError(message);

        errorMessage.current = message;
    }, []);

    const handleChange = useCallback(
        event => {
            const dropdownValue = event.target.value;

            if (isRequired && !dropdownValue.length) {
                handleError?.('This field is required');
            } else {
                setError(undefined);

                errorMessage.current = '';
            }
        },
        [isRequired, handleError]
    );

    // const handleBlur = useCallback(
    //     event => {
    //         if (
    //             !event?.relatedTarget?.classList.contains(styles.dropdownMenu)
    //         ) {
    //             // onClose();
    //         }
    //     },
    //     [onClose]
    // );

    const handleDropdownItemClick = useCallback(
        index => () => {
            selectItem(index);

            onClose();
        },

        [onClose, selectItem]
    );

    const handleTagClose = useCallback(
        removedTagIndex => {
            const correspondingItemIndex =
                tagToItemMap.current[removedTagIndex];

            setTags(tags => {
                const mutatedTags = [...tags];

                mutatedTags.splice(removedTagIndex, 1);

                return mutatedTags;
            });

            // setTagToItemMap(map => {
            //     const mutatedMap = [...map];

            //     mutatedMap.splice(removedTagIndex, 1);

            //     return mutatedMap;
            // });

            const mutatedMap = [...tagToItemMap.current];

            mutatedMap.splice(removedTagIndex, 1);

            tagToItemMap.current = mutatedMap;

            // setSelectedIndexes(indexes => {
            //     const mutatedIndexes = { ...indexes };

            //     delete mutatedIndexes[correspondingItemIndex];

            //     return mutatedIndexes;
            // });

            const mutatedIndexes = { ...selectedIndexes.current };

            delete mutatedIndexes[correspondingItemIndex];

            selectedIndexes.current = mutatedIndexes;
        },
        [tagToItemMap]
    );

    useEffect(() => {
        items.forEach((item, index) => {
            

            if (!(index in selectedIndexes.current) && item.selected === true) {
                selectItem(index);
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentReference = ref?.current;

        const handleSubmit = () => {
            const inputValue = currentReference?.value;

            handleChange({ target: { value: inputValue } });
            onSubmit?.(inputValue, errorMessage.current);
        };

        currentReference?.addEventListener('submit', handleSubmit);

        return () => {
            currentReference?.removeEventListener('submit', handleSubmit);
        };
    }, [errorMessage, handleChange, onSubmit, ref]);

    useEffect(() => {
        if (isFunction(onChange)) {
            onChange(tags, handleError);
        }
    }, [handleError, onChange, tags]);

    const errorClassName = hasError || error ? styles.hasError : '';
    

    return (
        <InputWithLabel
            className={`${className} ${styles.dropdownWithTags}`}
            isRequired={isRequired}
            text={label}
        >
            <Select
                items={items}
                // onBlur={handleBlur}
                onChange={handleChange}
                onClick={handleClick}
                ref={ref}
            />

            <InputWithTags
                className={`${className} ${errorClassName} ${styles.input}`}
                items={tags}
                // onBlur={handleBlur}
                onClick={handleClick}
                onTagClose={handleTagClose}
                placeholder={placeholder}
            />

            <DropdownMenu
                isOpen={isOpen}
                onClose={onClose}
                referenceElement={referenceElement}
            >
                {items.reduce((accumulator, { label, type, value }, index) => {
                    if (index in selectedIndexes.current) {
                        return accumulator;
                    }

                    accumulator.push(
                        <DropdownItemWithPill
                            isSelected={false}
                            key={`${label}.${index}`}
                            onClick={handleDropdownItemClick(index)}
                            text={label}
                            type={type}
                            value={value}
                        />
                    );

                    return accumulator;
                }, [])}
            </DropdownMenu>
        </InputWithLabel>
    );
});

export default DropdownWithTags;
