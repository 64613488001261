import { atom } from 'recoil';

const requestUpdateModalAtom = atom({
    default: [],
    key: 'requestUpdateModal'
});

const state = {
    atoms: {
        requestUpdateModalAtom
    }
};

export default state;
