import { useCallback } from 'react';

import Buttonize from 'components/Buttonize';
import ChevronIcon from 'components/icons/ChevronIcon';

import styles from './styles.module.scss';

const PreviousButton = ({ className = '', isDisabled = false, onClick }) => {
    const handleClick = useCallback(onClick, [onClick]);

    const disabledClass = isDisabled ? styles.isDisabled : '';

    return (
        <Buttonize
            className={`${className} ${disabledClass}`}
            onClick={handleClick}
        >
            <ChevronIcon />
        </Buttonize>
    );
};

export default PreviousButton;
