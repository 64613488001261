import { atom } from 'recoil';

const logsAtom = atom({
    default: [],
    key: 'logs'
});

const state = {
    atoms: {
        logsAtom
    }
};

export default state;
