import BodyText from 'components/BodyText';

import openInNewTab from 'utilities/openInNewTab';

import styles from './styles.module.scss';

const ImportantLinkInNewTab = ({
    className = '',
    openNewTab = false, 
    text, 
    to 
}) => {
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    const handleOpenInNewTab = () => {
        return openInNewTab(linkTo)
    }

    return (
        <>
        {openNewTab 
        ? 
        <div
            className={`${styles.importantLink} ${className}`}
            onClick={handleOpenInNewTab}
        >
            {text}
        </div>
        : 
        <BodyText 
            className={`${className} ${styles.bodyText}`}
            text={text}
        />
        }
        </>
    )
};

export default ImportantLinkInNewTab;