import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import CancelDeleteSaveButtons from 'components/CancelDeleteSaveButtons';
import ContentPanel from 'components/ContentPanel';
import EditActivityDetailsPanel from 'components/EditActivityDetailsPanel';
import EditActivityMetaPanel from 'components/EditActivityMetaPanel';
import FileUpload from 'components/FileUpload';
import PanelBody from 'components/PanelBody';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import WithPageTitle from 'components/WithPageTitle';

import useForm from 'hooks/useForm';

import useData from './hooks/useData';

import styles from './styles.module.scss';

const EditActivity = () => {
    const { HOME } = useConfig();
    const { title } = useData();

    

    const form = useForm({
        activity: '',
        'activity-type': '',
        'assignee-1': '',
        'assignee-2': '',
        'due-date': '',
        entity: '',
        instructions: '',
        'overdue-frequency': '',
        policy: '',
        recurrence: '',
        'reminder-frequency': '',
        reviewer: '',
        'start-date': ''
    });

    // const { handleForm } = form;

    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        navigate(HOME);
    }, [HOME, navigate]);

    const handleDelete = useCallback(() => {
        
    }, []);

    const handleSave = useCallback(() => {
        
    }, []);

    return (
        <div className={styles.editActivity}>
            <WithPageTitle title={title}>
                <CancelDeleteSaveButtons
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                    onSave={handleSave}
                />
            </WithPageTitle>

            <PanelWithAsidePanel>
                <EditActivityDetailsPanel form={form} />

                <EditActivityMetaPanel form={form} />
            </PanelWithAsidePanel>

            <ContentPanel>
                <PanelBody>
                    <FileUpload
                        className={styles.fileUpload}
                        uploadFile={file =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve({ url: URL.createObjectURL(file) });
                                }, 1000);
                            })
                        }
                    />
                </PanelBody>
            </ContentPanel>
        </div>
    );
};

export default memo(EditActivity);
