import { useCallback, useEffect, useState } from 'react';

import useVariableForm from 'hooks/useVariableForm';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';
import SaveButton from 'components/SaveButton';

import useTranslation from './hooks/useTranslation';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useData from './hooks/useData';
import useStatus from 'pages/Activity/hooks/useStatus';
import ActivityFormComponent from 'components/ActivityFormComponent';

const AddActivityQuestions = props => {
    const { activityId, className = '', formId, inactive=false, linkSchema, questions={} } = props;
    
    const {
        // NEXT,
        QUESTIONS,
        // VIEW_FULL_FORM
    } = useTranslation();

    const [_errorMessage, setErrorMessage] = useState();
    const [activityStatus, /*setActivityStatus*/] = useStatus();
    const [createdFormSubmission, createFormSubmission, reset] = useData(linkSchema);
    const [closeAfterSubmit, /*setCloseAfterSubmit*/] = useState(false);
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const form = useVariableForm();

    const { handleForm } = form;
    const { handleError, handleSubmit, handleValid, handleValidPreBulk } = form;

    // const navigate = useNavigate();

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};
        const answers = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                if(key.includes("_")){
                    const checkboxKey = parseInt(key.split('_')[0]);
                    const checkboxIndex = parseInt(key.split('_')[1]);
                    if(value){
                        accumulator.push({
                            answer: checkboxIndex,
                            questionId: checkboxKey,
                            value
                        });
                    }
                }else{
                    accumulator.push({
                        answer: value,
                        questionId: parseInt(key)
                    });
                }

                return accumulator;
            },[]
        );
        const finalizedAnswerQuestions =  questions.filter(question => question.type !== "checkBoxInput");
        const finalizedAnswerQuestionsIds = finalizedAnswerQuestions.reduce((accumulator, question) => {
            accumulator.push(question?.id)
            return accumulator;
        }, []);
        const finalizedAnswers = answers.filter(answer => finalizedAnswerQuestionsIds.includes(answer?.questionId));
        const checkboxAnswers = answers.filter(answer => !finalizedAnswerQuestionsIds.includes(answer?.questionId));
        const checkboxArrayAnswers = checkboxAnswers.reduce((accumulator, answer) => {
            const answerArray = Array.isArray(accumulator[answer?.questionId]) ? [...accumulator[answer?.questionId]] : [];
            if(answer?.value === 'true'){
                answerArray.push(answer?.answer);
            }
            accumulator[answer?.questionId] = answerArray;
            return accumulator;
        }, {})
        debugger;
        const finalizedCheckboxAnswerArray = {};
        for(const [key, _value] of Object.entries(checkboxArrayAnswers)){
            const currentAnswer = questions.filter(question => question.id === parseInt(key))[0]?.answer;
            const currentAnswerArray = (typeof currentAnswer === 'string' || currentAnswer instanceof String) && currentAnswer.length > 0 ? currentAnswer.split(',').map(x => parseInt(x)) : [];
            for(const ans of checkboxAnswers){
                if(ans?.questionId===parseInt(key)){
                    if(ans?.value === 'true'){
                        currentAnswerArray.push(ans?.answer)
                    }else if(ans?.value === 'false'){
                        const answerIndex = currentAnswerArray.indexOf(ans?.answer);
                        if(answerIndex >= 0){
                            currentAnswerArray.splice(answerIndex, 1)
                        }
                    }
                }
            }
            finalizedCheckboxAnswerArray[key] = [...new Set(currentAnswerArray)]
        }

        const accumulatedAnswers = Object.entries(finalizedCheckboxAnswerArray).reduce((accumulator, answer) => {
            accumulator.push({answer: answer[1].toString(), questionId:answer[0]});
            return accumulator;
        }, []);

        formObject['answers'] = [...finalizedAnswers, ...accumulatedAnswers];
        formObject['activityId'] = activityId;
        formObject['formId'] = formId;
        formObject['userId'] = parseInt(userId);
        createFormSubmission(formObject);
        return formObject;
    }, [handleForm, setErrorMessage, createFormSubmission, activityId, formId, userId, questions]);


    // const handleClose = useCallback(() => {
    //     setCloseAfterSubmit(true);
    //     const formObject = handleSave();
    //     setActivityStatus({
    //         id: activityId,
    //         status: 'complete'
    //     });
    // }, [activityId, handleSave, setActivityStatus, setCloseAfterSubmit]);


    useEffect(() => {
        if (!createdFormSubmission) {
            return;
        }

        if(createdFormSubmission) {
            if(!closeAfterSubmit){
                window.location.reload();
            }
        }

        if (activityStatus && activityStatus?.status === 'complete'){
            window.location.reload();
        }

        // navigate(ACTIVITY_ROUTE.replace(':activityId', createdFormSubmission));

        reset();
    }, [/*navigate,*/ reset, createdFormSubmission, closeAfterSubmit, activityStatus]);

    useEffect(() => {
        const filteredQuestions = questions.filter(question => question?.type === 'checkBoxInput');
        for(const question of filteredQuestions){
            const answerArray = question?.answer ? question?.answer.split(',').map(x => parseInt(x)): [];
            const answerDict = answerArray.reduce((a, v) => ({ ...a, [`${question?.id}_${v}`]: true}), {});
            handleValidPreBulk(answerDict)
        }
    //eslint-disable-next-line
    }, [questions])

    return (
        <SectionWithBorder
            className={`${className} ${styles.addActivityQuestions}`}
        >
            <Title className={styles.title} text={QUESTIONS} />
        
            {questions.map(({answer, choices, formComponent, id, index, questionId, name, type}) => {
                if(formComponent === "newForm"){
                    return(
                        <ActivityFormComponent
                            answer={answer}
                            choices={choices}
                            formComponent={formComponent}
                            handleError={handleError}
                            handleForm={handleForm}
                            handleSubmit={handleSubmit}
                            handleValid={handleValid}
                            id={id}
                            inactive={inactive}
                            index={index}
                            name={name}
                            questionId={questionId}
                            questions={questions}
                            type={type}
                        />
                    );
                }else{
                    return <></>;
                }
            })}

            {/* <FullFormDrawer
                handleError={handleError}
                handleSave={handleSave}
                handleSaveClose={handleClose}
                handleSubmit={handleSubmit}
                handleValid={handleValid}
                questions={questions}
            >
                {({ toggle }) => (
                    <InfoHollowButton
                        className={styles.infoHollowButton}
                        onClick={toggle}
                        text={VIEW_FULL_FORM}
                    />
                )}
            </FullFormDrawer> */}

            {!inactive &&
                <SaveButton
                    className={styles.saveButton} 
                    onClick={handleSave}
                    saveText={"Save"}
                />
            }

            {/* <SaveButton
                className={styles.saveAndCloseButton} 
                onClick={handleClose}
                saveText={"Save and Complete"}
            /> */}
        </SectionWithBorder>
    );
};

export default AddActivityQuestions;
