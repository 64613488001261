import Button from 'components/Button';

import EditIcon from 'components/icons/EditIcon';

import styles from './styles.module.scss';

const EditButton = props => {
    const { className = '', onClick } = props;

    return (
        <Button
            className={`${className} ${styles.editButton}`}
            onClick={onClick}
        >
            <EditIcon />
        </Button>
    );
};

export default EditButton;
