import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formsManagement');

    return {
        FORMS_MANAGEMENT: t('formsManagement')
    };
};

export default useTranslation;
