import CheckIcon from 'components/icons/CheckIcon';
import IconWithTitle from 'components/IconWithTitle';

import styles from './styles.module.scss';

const AssigneeStatus = ({
    isCompleted = false,
    text = ''
}) => (
    <div className={styles.assigneeStatus} >
        <IconWithTitle
            icon={isCompleted ? <CheckIcon /> : null} 
            text={text} 
        />
    </div>
);

export default AssigneeStatus;
