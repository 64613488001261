const translations = {
    activityTypeName: 'Activity Type Name',
    addExistingForm: '+ Add Existing Form',
    addNewForm: '+ Add New Form',
    category: 'Category',
    createNewForm: 'Create New Form',
    daysToCompletion: 'Days To Completion',
    dueDate: 'Due Date',
    enterFormName: 'Enter Form Name',
    formName: 'Form Name',
    formOption: 'Form Option',
    nextStepsResolution: 'Next Steps / Resolution',
    policy: 'Policy Reference',
    recipient: 'Recipients',
    regulatoryReference: 'Regulatory Reference',
    riskLabel: 'Risk Level',
    visibleBy: 'Visible By'
};

export default translations;
