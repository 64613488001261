import { useCallback } from 'react';

import CloseButton from 'components/CloseButton';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

const FileUploadThumbnail = ({
    children,
    filename,
    isUploadError = false,
    isUploading = false,
    onRemove,
    validationErrors = []
}) => {
    const hasError = isUploadError || validationErrors.length > 0;
    const errorClass = hasError ? styles.withError : '';
    const hasSuccess = !isUploadError && !isUploading;
    const successClass = hasSuccess ? styles.withSuccess : '';

    const handleClose = useCallback(
        () => onRemove(filename),
        [filename, onRemove]
    );

    return (
        <div
            className={`${errorClass} ${successClass} ${styles.fileUploadThumbnail}`}
            title={filename}
        >
            {children}

            {isUploading ? (
                <Spinner className={styles.spinner} />
            ) : (
                <CloseButton
                    className={styles.closeButton}
                    onClick={handleClose}
                />
            )}
        </div>
    );
};

export default FileUploadThumbnail;
