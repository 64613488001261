import { useCallback, useState } from 'react';

import ActionMenuItem from 'components/ActionMenuItem';
import FileIcon from 'components/icons/FileIcon';
import CaseCreationReasonModal from 'components/CaseCreationReasonModal';

import isFunction from 'utilities/isFunction';

import useActivityId from './hooks/useActivityId';
import useTranslation from './hooks/useTranslation';

const CloseAndCreateCaseMenuListItem = ({ activityRecord, linkSchema, onSave, riskLabel }) => {
    if (!activityRecord) {
        throw new Error(
            'No valid activityRecord was passed to CloseAndCreateCaseMenuListItem'
        );
    }
    const [_, setActivityLinkId] = useActivityId();
    const { CLOSE_AND_CREATE_CASE } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = useCallback(() => {
        setActivityLinkId({
            activityId: activityRecord?.id,
            activityName: activityRecord?.name
        });
        setIsModalOpen(!isModalOpen);
    }, [activityRecord, isModalOpen, setIsModalOpen, setActivityLinkId]);

    const handleModalClose = useCallback(() => {
        setActivityLinkId('');
        setIsModalOpen(false);
    }, [setActivityLinkId, setIsModalOpen]);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave();
        }

        setIsModalOpen(false);
    }, [onSave, setIsModalOpen]);

    return (
        <>
            <ActionMenuItem
                icon={<FileIcon />}
                onClick={handleClick}
                text={CLOSE_AND_CREATE_CASE}
            />

            {isModalOpen && (
                <CaseCreationReasonModal
                    activityRecord={activityRecord}
                    onClose={handleModalClose}
                    onSave={handleSave}
                    redirect={false}
                    riskLabel={riskLabel}
                    linkSchema={linkSchema}
                />
            )}
        </>
    );
};

export default CloseAndCreateCaseMenuListItem;
