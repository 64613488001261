const translations = {
    next: 'Next',
    question: 'Question',
    questions: 'Questions',
    save: 'Save',
    saveAndMarkAsReviewed: 'Save and mark as reviewed',
    viewFullForm: 'View full form'
};

export default translations;
