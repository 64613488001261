import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateDescription } = state;

const { updateDescriptionAtom } = updateDescription.atoms;

const MUTATE_LOG_DESCRIPTION = tenantSchema => gql`
mutation mutateLogDescription($text: String!, $policyId: Int!) {
    update_${tenantSchema}_log(where: {id: {_eq: $policyId}}, _set: {description: $text}) {
      returning {
        description
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_LOG_DESCRIPTION, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    // TODO we need to normalize this signature to work like all other useData
    const saveDescription = useRecoilCallback(({ set }) => variables => {
        
        const runMutation = async () => {
            const res = await mutation(variables);

            const newDescription = res?.data[`update_${usedSchema}_log`].returning[0]?.description;

            set(updateDescriptionAtom, newDescription);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateDescriptionAtom, '');
    });

    return [useRecoilValue(updateDescriptionAtom), saveDescription, reset];
};

export default useData;
