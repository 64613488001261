import { forwardRef } from 'react';

import HollowButton from 'components/HollowButton';

import styles from './styles.module.scss';

const NegativeHollowButton = forwardRef(
    ({ className = '', isDisabled = false, onClick, text }, ref) => (
        <HollowButton
            className={`${className} ${styles.negativeHollowButton}`}
            disabled={isDisabled}
            onClick={onClick}
            ref={ref}
            text={text}
        />
    )
);

export default NegativeHollowButton;
