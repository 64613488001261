import AsideText from 'components/AsideText';
import ToggleWithLabel from 'components/ToggleWithLabel';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdminRiskMonitoring = ({ isActive = false }) => {
    const { ACTIVE, INACTIVE, RISK_BASED_MONITORING } = useTranslation();

    return (
        <div className={styles.adminRiskMonitoring}>
            <WithLabel text={RISK_BASED_MONITORING}>
                <ToggleWithLabel
                    isActive={isActive}
                    label={isActive ? ACTIVE : INACTIVE}
                />
            </WithLabel>

            <AsideText text="Lorem ipsum dorem sit amet lorem ipsum dorem sit amet" />
        </div>
    );
};

export default AdminRiskMonitoring;
