import AddActivityButton from 'components/AddActivityButton';
import AddActivitiesPanel from 'components/AddActivitiesPanel';
import WithDownloadButton from 'components/WithDownloadButton';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import useData from './hooks/useData';

import styles from './styles.module.scss';
import CheckPermissions from 'components/CheckPermissions';
import CheckMissingPermissions from 'components/CheckMissingPermissions';

const Activities = () => {
    const { ACTIVITIES } = translations();

    const { loading, error, data } = useData();

    if(loading){}
    if(error){}

    return (
        <div className={styles.activities}>
            <WithPageTitle title={ACTIVITIES}>
                    <WithDownloadButton data={data} hasPDFexport={false} name={'Activities'}>
                        <CheckPermissions permission={'activity_add'}>
                            <AddActivityButton />
                        </CheckPermissions>
                    </WithDownloadButton>
            </WithPageTitle>

            <CheckPermissions permission={'activity_view'}>
                <AddActivitiesPanel className={styles.activitiesPanel} detailedData={data} />
            </CheckPermissions>

            <CheckMissingPermissions
                permission={
                    [
                        'activity_add',
                        'activity_delete',
                        'activity_edit',
                        'activity_view'
                    ]
                }
                text={'activity'}
            />
        </div>
    );
};

export default Activities;
