import titleCase from "utilities/titleCase";

const normalizeData = ({ data, schema, tenants }) => {
    var schemasForUse = [schema];
    if(tenants){
        for(const s of tenants){
            schemasForUse.push(s);
        }
    }
    var resDict = {
        selectedColumns: [
            'name',
            'username',
            'email',
            'registration',
            'status',
            'roles',
            'groups'
        ]
    };
    var resPeople = [];
    if(schemasForUse){
        for(const s of [...new Set(schemasForUse)]){
            const schemaRes = data[`${s}_user`].reduce(
                (accumulator, user) => {
                    const {
                        tenant_user: tenantUser,
                        first_name: firstName,
                        last_name: lastName,
                        registration_status: registration,
                        employee_status: status
                    } = user;

                    const {
                        email,
                        id,
                        user_roles: userRoles,
                        username
                    } = tenantUser;

                    const userGroups = tenantUser[`group_users_${s}`]
                    const groups = userGroups.reduce((accumulator, group) => {
                        accumulator.push(group?.group?.name);
                        return accumulator;
                    }, [])
                    
                    const usersRoles = userRoles.reduce((accumulator, role) => {
                        const roleToLoad ={description: role?.role?.description, name:titleCase(role?.role?.name)}
                        accumulator.push(roleToLoad)
                        return accumulator;
                    }, [])

                    accumulator.push({
                        email,
                        firstName,
                        groups,
                        id,
                        lastName,
                        registration,
                        role: usersRoles,
                        status,
                        tenant: s,
                        username
                    })

                    return accumulator;
                },[]
            );

            resPeople = [...resPeople, ...schemaRes] 
        }
    }

    resDict['people'] = resPeople;
    if(tenants && tenants.length > 1){
        resDict.selectedColumns.push('tenant')
    }
    return resDict;
}

export default normalizeData;
