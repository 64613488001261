import useGlobalState from './useGlobalState';

let globalStateHooks = {};

const useGlobalStateHooks = registerHooks => {
    // const [globalStateHooks, setGlobalStateHooks] = useGlobalState(
    //     'app.globalStateHooks'
    // );

    const globalState = useGlobalState;

    if (registerHooks) {
        const registeredGlobalStateHooks = Object.keys(registerHooks).reduce(
            (accumulator, hookName) => ({
                ...accumulator,
                [hookName]: () => {
                    return globalState(registerHooks[hookName]);
                }
            }),
            globalStateHooks || {}
        );

        globalStateHooks = registeredGlobalStateHooks;

        return registeredGlobalStateHooks;
    }
    
    return globalStateHooks;
};

export default useGlobalStateHooks;
