const normalizeData = ({ data, schema }) =>
    data[`${schema}_log_types`].reduce((accumulator, logTypes) => {
        const {
            id,
            name,
            risk_label: riskLevel
        } = logTypes;
        const visibleTo = ['Test User 1', 'Test User 2']

        // let visibleTo = [];
        // if (dropdown !== null) { visibleTo = dropdown };

        accumulator.push({
            id,
            name,
            riskLevel,
            visibleTo
        });

        return accumulator;
    }, []);

export default normalizeData;


// const normalizeData = ({ data, schema }) =>
//     data[`${schema}_log`].reduce((accumulator, logTypes) => {
//         const {
//             id,
//             log_type: logType,
//             log_visible_tos: users,
//             risk_label: riskLevel
//         } = logTypes;

//         const visibleTo = users.reduce((visibilityAccumulator, userInfo) => {
//             const { tenant_user: tenantUser } = userInfo;
//             const { username } = tenantUser;

//             visibilityAccumulator.push(username);
//             return visibilityAccumulator;
//         }, []);            

//         accumulator.push({
//             id,
//             logType,
//             riskLevel,
//             visibleTo
//         });

//         return accumulator;
//     }, []);

// export default normalizeData;
