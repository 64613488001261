import sendEmail from './sendEmail';
import { client } from 'graphql-client';
import { gql } from '@apollo/client';

async function sendActivityAssignedEmail(activityId, activityName, assigneeType, _dueDate, groupId, schema, schemaDescription, userId, key){
  const GET_EMAIL = gql`query getEmail {tenant_user_by_pk(id: ${userId}) {email}}`;
  const GET_GROUP = gql`query getGroup {${schema}_group_user(where: {group_id: {_eq: ${groupId}}}) {tenant_user_id}}`;
  var emails = [];
  if (userId) {
    const emailObject = await client.query({query: GET_EMAIL});
    emails = [...emails, emailObject.data.tenant_user_by_pk.email];
  }

  if (groupId) {
    const groupEmailObject = await client.query({query: GET_GROUP});
    const groupEmails = await groupEmailObject.data.skematic_group_user.reduce(async (accumulator, tenant_user_id) => {
          const GET_EMAIL_IN_LOOP = gql`query getEmail {tenant_user_by_pk(id: ${tenant_user_id}) {email}}`;
          const emailObject = await client.query({query: GET_EMAIL_IN_LOOP});
          accumulator.push(emailObject.data.tenant_user_by_pk.email);

          return accumulator;
      }, Promise.resolve([]));
    emails = [...emails, ...groupEmails];
  }

  const assigneeTypeText = assigneeType.replace('_', ' ')
                                        .replace('-', ' ')
                                        .toLowerCase()
                                        .split(' ')
                                        .map(val =>
                                            (val.length > 0) ? val.replace(val[0], val[0].toUpperCase()) : ""
                                        )
                                        .join(' ')

  const subject = `You have been assigned a new activity: ${activityName} - ${schemaDescription}`;
  const headerIn = `You have been assigned a new activity.`;
  const openingTextIn = `You have been added to the ${activityName} activity as ${assigneeTypeText}. Head over to follow up, view remaining tasks, and close the activity.`;
  const explanatoryTextIn = '';
  const buttonUrl = `${window.location.origin}/activity/${activityId}/${schema}`
  const buttons = [{'link': buttonUrl, 'style': 'solid', 'text': 'Complete the Activity Here'}]
  for (const email of emails){
    sendEmail(email, subject, headerIn, openingTextIn, explanatoryTextIn, buttons, key);
  }
}

export default sendActivityAssignedEmail;