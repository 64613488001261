import BodyText from 'components/BodyText';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const IconWithText = ({
    className = '',
    icon: Icon,
    isActive = false,
    onClick,
    text = ''
}) => {
    const activeStyles = isActive ? styles.isActive : '';

    const handleClick = event => {
        if (isFunction(onClick)) {
            onClick(event);
        }
    };

    return (
        <div
            className={`${className} ${activeStyles} ${styles.iconWithText}`}
            onClick={handleClick}
        >
            <Icon />

            <BodyText text={text} />
        </div>
    );
};

export default IconWithText;
