// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { ApolloProvider } from '@apollo/client';

import 'app/i18n';

import App from 'components/App';
import { client } from 'graphql-client';
import { initSentry } from 'libs/sentry';

initSentry()

const container = document.getElementById('root');
const root = createRoot(container);

// const SUPPRESSED_WARNINGS = ['Cannot update a component',
//                             'You provided a `value` prop to a form field w',
//                             'Select elements must be either controlled or uncontrolled (',
//                             'Each child in a list should have a unique "key" prop.',
//                             'Duplicate atom',
//                             'countered two children with the same key',
//                             'FATAL ERROR',];

// const consoleWarn = console.warn;
// const consoleInfo = console.info;
// const consoleError = console.error;
// console.warn = function filterWarnings(msg, ...args) {
//     if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
//         consoleWarn(msg, ...args);
//     }
// };
// console.info = function filterWarnings(msg, ...args) {
//     if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
//         consoleInfo(msg, ...args);
//     }
// };
// console.error = function filterWarnings(msg, ...args) {
//     if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
//         consoleError(msg, ...args);
//     }
// };

root.render(
    // <StrictMode>
    <RecoilRoot>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </RecoilRoot>
    // </StrictMode>
);
