import { atom } from 'recoil';

const caseTypeCountsAtom = atom({
    default: [],
    key: 'caseTypeCounts'
});

const state = {
    atoms: {
        caseTypeCountsAtom,
    }
};

export default state;
