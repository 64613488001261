import { useCallback, useEffect, useState } from 'react';

import ActiveIndicator from 'components/ActiveIndicator';
import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import EditableEditor from 'components/EditableEditor';
import EditableTextWithLabel from 'components/EditableTextWithLabel';
import EditableDatePickerWithLabel from 'components/EditableDatePickerWithLabel';
import ImportantLink from 'components/ImportantLink';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import RiskPill from 'components/RiskPill';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import WithLabel from 'components/WithLabel';

import useData from './hooks/useData';
import useAddCategory from './hooks/useAddCategory';
import useRemoveCategory from './hooks/useRemoveCategory';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';
import useSectionName from './hooks/useSectionName';
import useSectionEffectiveDate from './hooks/useSectionEffectiveDate';
import useSectionDescription from './hooks/useSectionDescription';

import normalizeData from './data';

const EditSectionModal = ({
    assignedDocuments,
    description,
    effectiveDate,
    id,
    name,
    onClose,
    published,
    riskLabel,
    selectedCategories: defaultSelectedCategories,
    status,
    version
}) => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { POLICY_ROUTE } = useConfig();

    const {
        DESCRIPTION,
        DOCUMENTS,
        EDIT_SECTION,
        EFFECTIVE_DATE,
        PUBLISHED,
        RISK_LABEL,
        SECTION_NAME,
        SELECTED_CATEGORIES,
        STATUS,
        VERSION
    } = useTranslation();

    const [addCategory] = useAddCategory();
    const [removeCategory] = useRemoveCategory();

    const { loading, _error, data } = useData();
    const [saveSectionName] = useSectionName();
    const [saveSectionEffectiveDate] = useSectionEffectiveDate();
    const [saveSectionDescription] = useSectionDescription();
    
    const [displayedSectionName, setSectionName] = useState(name);
    const [displayedSectionEffectiveDate, setSectionEffectiveDate] = useState(effectiveDate);
    const [displayedSectionDescription, setSectionDescription] = useState(description);
    const [updatedSectionName, setUpdatedSectionName] = useState();
    const [updatedSectionEffectiveDate, setUpdatedSectionEffectiveDate] = useState();
    const [updatedSectionDescription, setUpdatedSectionDescription] = useState();
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(defaultSelectedCategories);
    const [updatedCategories, updateSelectedCategories] = useState([]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            setCategories(normalizedData?.categories);
        }
    }, [data, schema, setCategories]);

    useEffect(() => {
        if(updatedCategories.length > 0){
            const isSameCategory = (a, b) => a.value === b.value && a.label === b.label;
            const onlyInLeft = (left, right, compareFunction) => 
                left.filter(leftValue =>
                    !right.some(rightValue => 
                        compareFunction(leftValue, rightValue)));
            if(selectedCategories.length > updatedCategories.length){
                const removedCategory = onlyInLeft(selectedCategories, updatedCategories, isSameCategory);
                removeCategory({removedCategory, sectionId:id})
            }else if(selectedCategories.length < updatedCategories.length){
                const addedCategory = onlyInLeft(updatedCategories, selectedCategories, isSameCategory);
                var newCategories = [];
                var existingCategories = [];
                for(const category of addedCategory){
                    if(category?.__isNew__){
                        newCategories.push(category?.value);
                    }else{
                        existingCategories.push(category)
                    }
                }
                addCategory({existingCategories, newCategories, sectionId:id})
            }
            setSelectedCategories(updatedCategories);
        }
    // eslint-disable-next-line
    }, [updatedCategories])

    useEffect(() => {
        if(!updatedSectionName){
            return;
        }
        setSectionName(updatedSectionName);
        setUpdatedSectionName(null);
    }, [updatedSectionName])

    useEffect(() => {
        if(!updatedSectionEffectiveDate){
            return;
        }
        setSectionEffectiveDate(updatedSectionEffectiveDate);
        setUpdatedSectionEffectiveDate(null);
    }, [updatedSectionEffectiveDate])

    useEffect(() => {
        if(!updatedSectionDescription){
            return;
        }
        setSectionDescription(updatedSectionDescription);
        setUpdatedSectionDescription(null);
    }, [updatedSectionDescription])

    const handleSectionNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                }, {}
            );

            saveSectionName({id, name:formObject?.text});
            setUpdatedSectionName(formObject?.text);
        },[id, saveSectionName]
    );

    const handleSectionEffectiveDateSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                }, {}
            );

            saveSectionEffectiveDate({effectiveDate:new Date(formObject?.text), id});
            setUpdatedSectionEffectiveDate(formObject?.text);
        },[id, saveSectionEffectiveDate]
    );

    const handleSectionDescriptionSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                }, {}
            );

            saveSectionDescription({description:formObject?.text, id});
            setUpdatedSectionDescription(formObject?.text);
        },[id, saveSectionDescription]
    );

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const isActive = status.toLowerCase() === 'active';
    const isPublished = published.toLowerCase() === 'published';

    return (
        <Modal className={styles.modal} isOpen={true} onClose={handleClose}>
            <PanelBody className={styles.modalGrid} loading={loading}>
                <PanelTitle className={styles.panelTitle} text={EDIT_SECTION} />

                <EditableTextWithLabel
                    label={SECTION_NAME}
                    onSave={handleSectionNameSave}
                    text={displayedSectionName}
                />

                <EditableDatePickerWithLabel
                    date={displayedSectionEffectiveDate}
                    onSave={handleSectionEffectiveDateSave}
                    text={EFFECTIVE_DATE}
                />

                <WithLabel text={STATUS}>
                    <ActiveIndicator
                        className={styles.statusIcon}
                        isActive={isActive}
                    />
                </WithLabel>

                <WithLabel text={PUBLISHED}>
                    <ActiveIndicator
                        className={styles.statusIcon}
                        isActive={isPublished}
                    />
                </WithLabel>

                <DropdownMultiSelectWithLabel
                    change={updateSelectedCategories}
                    className={styles.wideDrop}
                    label={SELECTED_CATEGORIES}
                    options={categories}
                    selectedOptions={selectedCategories}
                />

                <WithLabel text={RISK_LABEL}>
                    <RiskPill
                        riskLevel={riskLabel}
                    />
                </WithLabel>

                <WithLabel text={VERSION}>
                    <div>{version}</div>
                </WithLabel>

                <EditableEditor
                    className={styles.fullWidth}
                    key={`${id}.description`}
                    onSave={handleSectionDescriptionSave}
                    text={displayedSectionDescription}
                    title={DESCRIPTION}
                />

                <WithLabel text={DOCUMENTS}>
                    {assignedDocuments.map(
                        (
                            {
                                id,
                                name
                            }
                        ) => (
                            <ImportantLink
                                className={styles.link}
                                text={name}
                                to={`${POLICY_ROUTE.replace(':policyId', id)}`}
                            />
                        )
                    )}
                </WithLabel>

            </PanelBody>
        </Modal>
    );
};

export default EditSectionModal;
