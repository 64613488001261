import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('summarizedListItems');

    return {
        ACTIONS: t('actions'),
        ASSIGNED: t('assigned'),
        DUE_DATE: t('dueDate'),
        TYPE: t('type')
    };
};

export default useTranslation;
