import DownloadButton from 'components/DownloadButton';

import { useExportDocumentManagementRepository } from 'hooks/useExportDocumentManagementRepository';
import styles from './styles.module.scss';

import {useGetFilteredRepositoriesData} from 'components/DocumentManagementRepositoryTab/hooks/useFilteredData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const WithDownloadButtonDocumentManagement = props => {
    const { className = '', children, data, isDisabled=false, name } = props;
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();


    const {exportRepositoryDocuments} = useExportDocumentManagementRepository()

    const filteredRepositoriesData = useGetFilteredRepositoriesData();

    const onAllDataExport = () => {
      exportRepositoryDocuments({ documents: data, name, schema });
    }

    const onFilteredDataExport = () => {
      exportRepositoryDocuments({ documents: filteredRepositoriesData, name, schema });
    }

    return (
        <div className={`${className} ${styles.withDownloadButton}`}>
            <DownloadButton
                className={styles.downloadButton}
								isDisabled={isDisabled}
                onAllDataExport={onAllDataExport}
                onFilteredDataExport={onFilteredDataExport}
            />

            {children}
        </div>
    );
};

export default WithDownloadButtonDocumentManagement;
