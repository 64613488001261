import OverdueItems from 'components/OverdueItems';
import OverdueSummarizedListItems from 'components/OverdueSummarizedListItems';

const OverdueSummarizedItems = ({ className = '', items, onDownloadItem }) =>  (
    <OverdueItems className={className}>
        <OverdueSummarizedListItems
            items={items}
						onDownloadItem={onDownloadItem}
        />
    </OverdueItems>
);

export default OverdueSummarizedItems;
