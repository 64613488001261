import { atom } from 'recoil';

const caseCreationReasonModalAtom = atom({
    default: '',
    key: 'caseCreationReasonModal'
});

const state = {
    atoms: {
        caseCreationReasonModalAtom
    }
};

export default state;
