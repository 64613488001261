import BodyText from 'components/BodyText';
import Buttons from 'components/Buttons';
import TrashIcon from 'components/icons/TrashIcon';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ConfirmationModal = ({ children, icon, onClose, text }) => {
    const { ARE_YOU_SURE } = useTranslation();

    return (
        <Modal isOpen onClose={onClose}>
            <PanelBody className={styles.panelBody}>
                {icon || <TrashIcon />}

                <PanelTitle text={ARE_YOU_SURE} />

                <BodyText className={styles.bodyText} text={text} />

                <Buttons>{children}</Buttons>
            </PanelBody>
        </Modal>
    );
};

export default ConfirmationModal;
