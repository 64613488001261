import styles from './styles.module.scss';

const statusClasses = {
    active: styles.active,
    archived: styles.archived,
    draft: styles.draft
};

const VersionTag = ({ className = '', status, text }) => {
    return (
        <div
            className={`${className} ${styles.versionTag} ${statusClasses[status]}`}
        >
            {text}
        </div>
    );
};

export default VersionTag;
