import { atom } from 'recoil';

const logoAtom = atom({
    default: {},
    key: 'logo'
});

const state = {
    atoms: {
        logoAtom
    }
};

export default state;
