import { useCallback } from 'react';

import CheckboxMenuItem from 'components/CheckboxMenuItem';

import styles from './styles.module.scss';
import isFunction from 'utilities/isFunction';

const CheckboxMenu = props => {
    const { className = '', items, onChange, onClick } = props;

    const handleChange = useCallback(
        ({ index, isSelected, text }) => {
            if (isFunction(onChange)) {
                onChange({ index, isSelected, text });
            }
        },
        [onChange]
    );

    const handleClick = useCallback(
        ({ index, isSelected, text }) => {
            if (isFunction(onClick)) {
                onClick({ index, isSelected, text });
            }
        },
        [onClick]
    );

    return (
        <div className={`${className} ${styles.checkboxMenu}`}>
            {items.map(({ isSelected, text }, index) => (
                <CheckboxMenuItem
                    isChecked={isSelected}
                    key={`${text}.${index}`}
                    onChange={() => handleChange({ index, isSelected, text })}
                    onClick={() => handleClick({ index, isSelected, text })}
                    text={text}
                />
            ))}
        </div>
    );
};

export default CheckboxMenu;
