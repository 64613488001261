import formatDate from 'utilities/formatDate';
import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, querySchemas, schema }) =>
    data[`${schema}_document`].reduce((accumulator, policies) => {
        const {
            id, 
            name,
            is_active: active,
            risk_label: riskLabel,
            document_categories: documentCategories,
            document_versions: documentVersions,
            applied_tos: versionAppliedTo
        } = policies;

        const selectedCategories = documentCategories.reduce(
            (accumulator, category) => {
                const id = category?.category?.id;
                const label = category?.category?.label;
                if (label !== null){
                    accumulator.push({
                        label,
                        value: id
                    })
                }
                return accumulator
            }, []
        );

        const versions = documentVersions.reduce(
            (accumulator, version) => {
                const {
                    effective_date: effectiveDate,
                    is_active_version: activeVersion,
                    is_draft: draftVersion,
                    is_published: publishedVersion,
                    version_number: versionNumber
                } = version;

                const status = (activeVersion) ? 'Active' : (draftVersion) ? 'Draft' : 'Inactive';
                const published = (publishedVersion) ? 'Published' : 'Unpublished';

                accumulator.push({
                    activeVersion,
                    draftVersion,
                    effectiveDate: new Date(effectiveDate),
                    published,
                    status,
                    versionNumber
                });

                return accumulator;
            },
            []
        );

        const groups = versionAppliedTo.reduce((accumulator, appliedTo) => {
            const { group, tenant_user: tenantUser } = appliedTo;
            if (group !== null){
                accumulator.push({label: group?.name, type: 'group'});
            } else if (tenantUser !== null){
                if(querySchemas){
                    for(const s of querySchemas){
                        const user = tenantUser[`user_${s.value}`];
                        const firstName = user?.first_name;
                        const lastName = user?.last_name;
                        const userName = `${firstName} ${lastName}`

                        if(firstName){
                            accumulator.push({label: userName, type: 'user'});
                        }
                    }
                }else{
                    const user = tenantUser[`user_${schema}`];
                    const firstName = user?.first_name;
                    const lastName = user?.last_name;
                    const userName = `${firstName} ${lastName}`

                    if(firstName){
                        accumulator.push({label: userName, type: 'user'});
                    }
                }
            }
            return accumulator;
        },[])

        const activeVersions = versions.filter(version => {
            return version.activeVersion
        })

        const draftVersions = versions.filter(version => {
            return version.draftVersion
        })

        const activeVersion = (activeVersions.length > 0) ? activeVersions[0] :
                                (draftVersions.length > 0) ? draftVersions[0] : {}

        accumulator.push({
            active,
            effectiveDate: formatDate(new Date(activeVersion?.effectiveDate)) || '',
            id,
            name,
            published: activeVersion?.published || '',
            riskLabel,
            schema,
            selectedCategories,
            sortDate: new Date(activeVersion?.effectiveDate),
            status: activeVersion?.status || '',
            version: activeVersion?.versionNumber || '',
            visibleTo: groups || []
        });
        
        accumulator = accumulator.sort(
            (objA, objB) => (objA.sortDate > objB.sortDate ? 1 : -1)
        );

        return accumulator;
    }, []);

const normalizeData = ({ data, querySchemas, schema, tenants }) => normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizeData;
