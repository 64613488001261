import CaseHistoryPanel from 'components/CaseHistoryPanel';
import SplitContainer from 'components/SplitContainer';

const CaseHistoryTab = ({ caseSchema, history }) => (
    <SplitContainer>
        <CaseHistoryPanel caseSchema={caseSchema} history={history} />
    </SplitContainer>
);

export default CaseHistoryTab;
