import { useQuery } from '@apollo/client';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = tenantSchema => `
  ${tenantSchema}_admin_settings(
    where: {
      setting_name: { _eq: "side_letters_upsell" }
    }
  ) {
    setting
  }
`;

const usePermissions = () => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema
        ? userSetSchema
        : tenants && tenants.length === 1
        ? tenants[0]
        : schema;

    return useQuery(
        generateQueryFromDict({ query, schema: usedSchema, tenants }),
        { fetchPolicy: 'no-cache' }
    );
};

export default usePermissions;
