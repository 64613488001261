const normalizeDefaultCaseTypeData = ({ data, schema }) =>
    data[`${schema}_admin_settings`].reduce(
        (accumulator, {id, setting, setting_name}) => {
            accumulator.push({
                id: parseInt(id),
                setting,
                settingName: setting_name,
            });

            return accumulator;
        }, []);

export default normalizeDefaultCaseTypeData;