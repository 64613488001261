import { useCallback, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CheckIcon from 'components/icons/CheckIcon';
import CloseIcon from 'components/icons/CloseIcon';
import DatePicker from 'components/DatePicker';
import DateWithLabel from 'components/DateWithLabel';
import PenIcon from 'components/icons/PenIcon';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const EditableTextWithLabel = ({ className = '', date, hasEdit = true, onSave, text }) => {
    const [isEditing, setIsEditing] = useState(false);

    const form = useForm({
        text: ''
    });

    const { handleSubmit, handleValid } = form;

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave({ form });
        }

        setIsEditing(false);
    }, [form, onSave, setIsEditing]);

    return (
        <div className={`${className} ${styles.editableContentBox}`}>
            {!isEditing && (
                <>
                    <DateWithLabel
                        className={styles.contentBox}
                        date={date}
                        text={text}
                    />

                    {hasEdit && (
                        <Buttonize
                            className={styles.buttonize}
                            onClick={handleEdit}
                        >
                            <PenIcon />
                        </Buttonize>
                    )}
                </>
            )}

            {isEditing && (
                <>
                    <DatePicker
                        className={styles.textBox}
                        label={text}
                        onChange={handleValid('text')}
                        onSubmit={handleSubmit('text')}
                        placeholder={date}
                    />

                    <Buttonize
                        className={styles.closeIcon}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </Buttonize>

                    <Buttonize
                        className={styles.checkIcon}
                        onClick={handleSave}
                    >
                        <CheckIcon />
                    </Buttonize>
                </>
            )}
        </div>
    );
};

export default EditableTextWithLabel;
