import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addPolicy } = state;

const { addPolicyAtom } = addPolicy.atoms;

const MUTATE_ADD_POLICY = tenantSchema => gql`
mutation addPolicy($createdBy: Int!, $description: String!, $documentType: String!, $isActive: Boolean!, $name: String!) {
    insert_${tenantSchema}_document(objects: {
        created_by: $createdBy,
        description: $description,
        document_type: $documentType,
        is_active: $isActive,
        name: $name}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ADD_POLICY_VERSION = tenantSchema => gql`
mutation addPolicyVersion(
    $documentId: Int!,
    $effectiveDate: timestamp!,
    $versionNumber: Int!,
    $modifiedByUserId: Int!,
    $file: String,
    $fileName: String,
    $isActive: Boolean!,
    $isDraft: Boolean!,
    $isPublished: Boolean!,
    $description: String!) {
    insert_${tenantSchema}_document_version(objects: {
        document_id: $documentId,
        effective_date: $effectiveDate,
        version_number: $versionNumber,
        modified_by_user_id: $modifiedByUserId,
        file: $file,
        file_name: $fileName,
        is_active_version: $isActive,
        is_draft: $isDraft,
        is_published: $isPublished,
        description: $description}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ADD_POLICY_APPLIED_TO = tenantSchema => gql`
mutation addPolicyVersion($documentId: Int!, $groupId: Int, $userId: Int) {
    insert_${tenantSchema}_applied_to(objects: {document_id: $documentId, group_id: $groupId, user_id: $userId}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_NEW_CATEGORY = tenantSchema => gql`
mutation addCategory($label: String!) {
    insert_${tenantSchema}_categories_one(object: {label: $label}) {
      id
    }
  }
`;

const MUTATE_INSERT_DOCUMENT_CATEGORY = tenantSchema => gql`
mutation addSectionCategory($categoryId: Int!, $documentId: Int!) {
    insert_${tenantSchema}_document_category_one(object: {category_id: $categoryId, document_id: $documentId}) {
      id
    }
  }
`;

const MUTATE_INSERT_SECTION = tenantSchema => gql`
mutation addSection($description: String!, $file: String!, $name: String!) {
    insert_${tenantSchema}_sections_one(object: {description: $description, file: $file, name: $name}) {
      id
    }
  }
`;

const MUTATE_INSERT_DOCUMENT_SECTION = tenantSchema => gql`
mutation addDocumentSection($policyId: Int!, $sectionId: Int!) {
    insert_${tenantSchema}_policy_sections_one(object: {policy_id: $policyId, section_id: $sectionId}) {
      id
    }
  }
`;

const MUTATE_INSERT_SECTION_CATEGORY = tenantSchema => gql`
mutation addSectionCategory($categoryId: Int!, $sectionId: Int!) {
    insert_${tenantSchema}_section_category_one(object: {category_id: $categoryId, section_id: $sectionId}) {
      id
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_ADD_POLICY, providedSchema);
    const mutationVersion = useGraphQL(MUTATE_ADD_POLICY_VERSION, providedSchema);
    const mutationAppliedTo = useGraphQL(MUTATE_ADD_POLICY_APPLIED_TO, providedSchema);
    const mutationNewCategory = useGraphQL(MUTATE_INSERT_NEW_CATEGORY, providedSchema);
    const mutationNewSection = useGraphQL(MUTATE_INSERT_SECTION, providedSchema);
    const mutationNewDocumentCategory = useGraphQL(MUTATE_INSERT_DOCUMENT_CATEGORY, providedSchema);
    const mutationNewDocumentSection = useGraphQL(MUTATE_INSERT_DOCUMENT_SECTION, providedSchema);
    const mutationNewSectionCategory = useGraphQL(MUTATE_INSERT_SECTION_CATEGORY, providedSchema);


    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();

    const usedSchema = providedSchema ? providedSchema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

    const addPolicy = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const { existingCategories, existingPolicyCategories, newCategories, newPolicyCategories, sections } = variables;
            const consolidatedCategories = [...existingCategories]
            const consolidatedPolicyCategories = [...existingPolicyCategories]
            for(const nc of newCategories){
              const ncidRes = await mutationNewCategory({label:nc});
              const ncid = ncidRes?.data[`insert_${usedSchema}_categories_one`]?.id
              consolidatedCategories.push({label:nc, value:ncid});
            }
            const selectedCategoryIds = consolidatedCategories.map((obj) => obj.value);

            for(const nc of newPolicyCategories){
              const ncidRes = await mutationNewCategory({label:nc});
              const ncid = ncidRes?.data[`insert_${usedSchema}_categories_one`]?.id
              consolidatedPolicyCategories.push({label:nc, value:ncid});
            }
            const selectedPolicyCategoryIds = consolidatedPolicyCategories.map((obj) => obj.value);
            
            const res = await mutation(variables);
            const documentId =
                res?.data[`insert_${usedSchema}_document`]?.returning[0]
                    ?.id;
            
            variables['documentId'] = documentId;
            const filenameFromFileIndex = variables['file'].lastIndexOf('_');
            const filenameExtensionIndex = variables['file'].lastIndexOf('.');
            variables['fileName'] = variables['file'].substr(0, filenameFromFileIndex) + variables['file'].substr(filenameExtensionIndex)
            
            await mutationVersion(variables);
            
            const visibleToVariables = JSON.parse(variables['visibleTo']);
            
            for (let i = 0; i < visibleToVariables.length; i++) {
                const visibleTo = visibleToVariables[i];
                
                if (visibleTo?.type === 'group'){
                  await mutationAppliedTo({documentId, groupId: visibleTo?.id});
                } else if (visibleTo?.type === 'user'){
                  await mutationAppliedTo({documentId, userId: visibleTo?.id});
                }
            }

            for(const scid of selectedPolicyCategoryIds){
              await mutationNewDocumentCategory({categoryId:scid, documentId});
            }

            var sectionIds = [];
            if(sections && Array.isArray(sections)){
              for(const section of sections){
                const {sectionName, sectionDescription} = section;
                const sectionRes = await mutationNewSection({description:sectionDescription, file:variables?.file, name:sectionName});
                const sid = sectionRes?.data[`insert_${usedSchema}_sections_one`]?.id
                sectionIds.push(sid);
              }
            }

            for(const sid of sectionIds){
              await mutationNewDocumentSection({policyId:documentId, sectionId:sid});
              for(const scid of selectedCategoryIds){
                await mutationNewSectionCategory({categoryId:scid, sectionId:sid});
              }
            }

            set(addPolicyAtom, documentId);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addPolicyAtom, '');
    });

    return [useRecoilValue(addPolicyAtom), addPolicy, reset];
};

export default useData;
