import { useCallback, useEffect, useState } from 'react';
import AvatarWithTextLink from 'components/AvatarWithTextLink'
import BodyLink from 'components/BodyLink';
import ImportantLink from 'components/ImportantLink';
import InfoBarAlternativeListItem from 'components/InfoBarAlternativeListItem';
import ListItemDetailsButton from 'components/ListItemDetailsButton';

import useConfig from 'hooks/useConfig';
import useComplete from './hooks/useComplete';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { usePermissions } from 'hooks/usePermissions';

import styles from './styles.module.scss';
import { safeGetLocalStorage } from 'utilities/localStorage';
import titleCase from 'utilities/titleCase';
import WithTooltipAvatar from 'components/WithTooltipAvatar';
import WithTooltip from 'components/WithTooltip';
import DocumentIcon from 'components/icons/DocumentIcon';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const InProgressPageListItem = ({
    active,
    assigned,
    className = '',
    completable,
    dueDate,
    entities,
    flagged,
    id,
		item,
		onDownloadItem,
    policyReference,
    recurrence,
    reviewer = [],
    schema,
    startDate,
    tenantCount=1,
    text,
    type
}) => {
    const { ACTIVITY_ROUTE } = useConfig();

    const [complete, setComplete] = useState(false);
    const [completeStyle, setCompleteStyle] = useState(false);
    const { useActivities, useUserId } = useGlobalStateHooks();
    const [activities, setActivities] = useActivities();
    const [userId] = useUserId();

    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const [completedActivity, completeActivity, resetCompleteActivity] = useComplete(schema);

    const handleComplete = useCallback(() => {
        setCompleteStyle(true);
        const formObject = {activityId:id, completable, groupId:null, status:'complete', userId:parseInt(userId)};
        completeActivity(formObject);
        }, [completable, completeActivity, id, setCompleteStyle, userId]
    );

    useEffect(() => {
    }, [completeStyle]);

    useEffect(() => {
        if (!completedActivity) {
            return;
        }
        if (id===completedActivity){
            resetCompleteActivity();
            const completed = [...activities?.completed];
            const overdue = [...activities?.overdue];
            const inProgress = [...activities?.inProgress];
            const upcomingOriginal = [...activities?.upcoming];
            const upcomingUnfiltered = upcomingOriginal.map(activity => {
                if(activity?.id !== completedActivity){
                    return activity;
                }else{
                    completed.push(activity);
                    return false;
                }
            })
            const upcoming = upcomingUnfiltered.filter(el => el)
            setActivities({completed, inProgress, overdue, upcoming});
            setComplete(true);
        }
    }, [activities, completedActivity, complete, setActivities, setComplete, id, resetCompleteActivity]);

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    const policyReferenceTooltip =
        policyReference?.length > 0
            ? policyReference.map(reference => reference.name).join('\n')
            : undefined;

    if(tenantCount > 1){
        return (
            <>{!complete &&
            <InfoBarAlternativeListItem
                className={`${className} ${completeStyle ? styles.completing : ''} ${styles.upcomingDetailedListItemMultiTenant}`}
            >

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={text}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={startDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={dueDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.avatar}
                    newTab={newTab}
                    text={type}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <WithTooltipAvatar assigned={assigned}>
                    <AvatarWithTextLink
                        assigned={assigned}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <WithTooltipAvatar assigned={reviewer}>
                    <AvatarWithTextLink
                        assigned={reviewer}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={schema ? titleCase(schema) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {policyReferenceTooltip ? (
                    <WithTooltip className="policyReference" text={policyReferenceTooltip}>
                        <DocumentIcon className={styles.icon} />
                    </WithTooltip>
                ):(
                    <BodyLink
                            className={styles.assigned}
                            newTab={newTab}
                            text={'-'}
                            to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                        />
                )}

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={recurrence ? titleCase(recurrence) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={entities.join(", ")}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {activitiesActionsPermission && (
                    <ListItemDetailsButton active={active} activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema} />
                )}
            </InfoBarAlternativeListItem>
            }</>
        );
    }else{
        return (
            <>{!complete &&
            <InfoBarAlternativeListItem
                className={`${className} ${completeStyle ? styles.completing : ''} ${styles.upcomingListItem}`}
            >

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={text}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={startDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={dueDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.avatar}
                    newTab={newTab}
                    text={type}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <WithTooltipAvatar assigned={assigned}>
                    <AvatarWithTextLink
                        assigned={assigned}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <WithTooltipAvatar assigned={reviewer}>
                    <AvatarWithTextLink
                        assigned={reviewer}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                {policyReferenceTooltip ? (
                    <WithTooltip className="policyReference" text={policyReferenceTooltip}>
                        <DocumentIcon className={styles.icon} />
                    </WithTooltip>
                ):(
                    <BodyLink
                            className={styles.assigned}
                            newTab={newTab}
                            text={'-'}
                            to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                        />
                )}

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={recurrence ? titleCase(recurrence) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={entities.join(", ")}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {activitiesActionsPermission && (
                    <ListItemDetailsButton active={active} activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema} />
                )}
            </InfoBarAlternativeListItem>
            }</>
        );
    }
};

export default InProgressPageListItem;