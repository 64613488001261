const translations = {
    addDescription: 'Add description',
    categories: 'Categories',
    description: 'Description',
    enterSectionName: 'Enter section name',
    riskLabel: 'Risk Label - Policy Section',
    sectionName: 'Section Name'
};

export default translations;
