import { useExport } from './useExport';
import { getFileName } from 'utilities/files';
import generateXLSX from 'utilities/generateXLSX';
import parseDocumentRepositoryDataForReport from 'utilities/parseDocumentRepositoryDataForReport';

export function useExportDocumentManagementRepository() {
    const { exportToExcel } = useExport();

    async function exportRepositoryDocuments({ documents, name, schema }) {
        if(documents){
            const parsedData = await parseDocumentRepositoryDataForReport(documents, schema);
            const buffer = await generateXLSX(parsedData, name);
            exportToExcel({
                buffer,
                filename: getFileName(name)
            });
        }
    }

    return {
        exportRepositoryDocuments,
    };
}
