import AdminGroupFormDetailsPanel from 'components/AdminGroupFormDetailsPanel';
import AdminGroupFormMembersPanel from 'components/AdminGroupFormMembersPanel';

const AdminGroupForm = ({ form, selectedMembers, users = [], usersAndGroups = [] }) => {
    return (
        <>
            <AdminGroupFormDetailsPanel
                form={form}
                supervisorOptions={usersAndGroups}
            />

        <AdminGroupFormMembersPanel form={form} memberOptions={users} selectedMembers={selectedMembers} />
        </>
    );
};

export default AdminGroupForm;
