import ActivityDetailsPanel from 'components/ActivityDetailsPanel';
import ActivityHistoryPanel from 'components/ActivityHistoryPanel';
import ActivityMetaPanel from 'components/ActivityMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import SplitContainer from 'components/SplitContainer';
import TabToggle from 'components/TabToggle';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ActivityTabs = props => {
    const {
        activityEntityData,        
        activityLinkages,
        activityName,
        activityRecurrence,
        activitySchema,
        activityUsersForRecurrence,
        addAssignee,
        addReviewer,
        allAnswers,
        allEntityData,
        allForms,
        allPreviouslyAssignedUsers,
        answers,
        attachments,
        attachmentsOnDelete,
        attachmentsOnUpdate,
        caseLinkages,
        closed,
        comments,
        dropdowns,
        dueDate,
        entities,
        forms,
        handleFileUpload,
        hierarchical,
        history,
        id,
        instructions,
        isCreatingPDF,
        logLinkages,
        otherAnswers,
        otherForms,
        policyReference,
        questions,
        recurrenceType,
        recurrences,
        regulatoryReferences,
        riskLabel,
        sendEmail,
        startDate,
        type,
        userGroup,
        userStatus,
        users
    } = props;

    const { DETAILS, HISTORY, META } = useTranslation();

    const tabMap = {
        details: () => (
            <div id="activity-view">
                <PanelWithAsidePanel>
                    <ActivityDetailsPanel
                        activitySchema={activitySchema}
                        allAnswers={allAnswers}
                        allForms={allForms}
                        answers={answers}
                        attachments={attachments}
                        attachmentsOnDelete={attachmentsOnDelete}
                        attachmentsOnUpdate={attachmentsOnUpdate}
                        closed={closed}
                        comments={comments}
                        dropdowns={dropdowns}
                        forms={forms}
                        handleFileUpload={handleFileUpload}
                        id={id}
                        instructions={instructions}
                        isCreatingPDF={isCreatingPDF}
                        name={activityName}
                        otherAnswers={otherAnswers}
                        otherForms={otherForms}
                        policyReference={policyReference}
                        questions={questions}
                        regulatoryReferences={regulatoryReferences}
                        riskLabel={riskLabel}
                        type={type}
                        userGroup={userGroup}
                        userStatus={userStatus}
                    />

                    <ActivityMetaPanel
                        activityEntityData={activityEntityData}     
                        activityForms={allForms}           
                        activityLinkages={activityLinkages}
                        activityName={activityName}
                        activityPolicy={policyReference}
                        activityRecurrence={activityRecurrence}
                        activitySchema={activitySchema}
                        activityUsersForRecurrence={activityUsersForRecurrence}
                        addAssignee={addAssignee}
                        addReviewerGroup={addReviewer}
                        allEntityData={allEntityData}
                        allPreviouslyAssignedUsers={allPreviouslyAssignedUsers}               
                        caseLinkages={caseLinkages}
                        className={styles.activityMetaPanel}
                        closed={closed}
                        dueDate={dueDate}
                        entities={entities}
                        hierarchical={hierarchical}
                        id={id}
                        instructions={instructions}
                        logLinkages={logLinkages}
                        recurrenceType={recurrenceType}
                        recurrences={recurrences}
                        sendEmail={sendEmail}
                        startDate={startDate}
                        type={type}
                        users={users}
                    />
                </PanelWithAsidePanel>
            </div>
        ),

        history: () => (
            <SplitContainer>
                <ActivityHistoryPanel history={history} />
            </SplitContainer>
        ),

        meta: () => (
            <PanelWithAsidePanel>
                <ActivityDetailsPanel
                    activitySchema={activitySchema}
                    allAnswers={allAnswers}
                    allForms={allForms}
                    answers={answers}
                    attachments={attachments}
                    attachmentsOnDelete={attachmentsOnDelete}
                    attachmentsOnUpdate={attachmentsOnUpdate}
                    className={styles.activityDetailsPanel}
                    closed={closed}
                    comments={comments}
                    dropdowns={dropdowns}
                    forms={forms}
                    id={id}
                    instructions={instructions}
                    name={activityName}
                    otherAnswers={otherAnswers}
                    otherForms={otherForms}
                    policyReference={policyReference}
                    questions={questions}
                    type={type}
                    userGroup={userGroup}
                    userStatus={userStatus}
                />

                <ActivityMetaPanel
                    activityEntityData={activityEntityData}
                    activityForms={allForms}
                    activityLinkages={activityLinkages}
                    activityName={activityName}
                    activityPolicy={policyReference}
                    activityRecurrence={activityRecurrence}
                    activitySchema={activitySchema}
                    activityUsersForRecurrence={activityUsersForRecurrence}
                    addAssignee={addAssignee}
                    addReviewerGroup={addReviewer}
                    allEntityData={allEntityData}
                    allPreviouslyAssignedUsers={allPreviouslyAssignedUsers}                   
                    caseLinkages={caseLinkages}
                    className={styles.activityMetaPanel}
                    dueDate={dueDate}
                    entities={entities}
                    hierarchical={hierarchical}
                    id={id}
                    instructions={instructions}
                    logLinkages={logLinkages}
                    recurrenceType={recurrenceType}
                    recurrences={recurrences}
                    sendEmail={sendEmail}
                    startDate={startDate}
                    users={users}
                />
            </PanelWithAsidePanel>
        )
    };

    return (
        <TabToggle
            className={styles.activityTabs}
            items={[
                { label: DETAILS, value: 'details' },
                { label: HISTORY, value: 'history' },
                {
                    className: styles.meta,
                    label: META,
                    value: 'meta'
                }
            ]}
            selectedTab="details"
        >
            {selectedView => {
                const TabComponent = tabMap[selectedView];

                return <TabComponent />;
            }}
        </TabToggle>
    );
};

export default ActivityTabs;
