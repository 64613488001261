const normalizeData = ({ data, schema }) =>
    {
        const policy = data[`${schema}_document`].reduce(
            (accumulator, policy) => {
                const {
                    id,
                    description,
                    name,
                    document_categories: documentCategories,
                    document_versions: documentVersions,
                    policy_sections: policySections,
                    tenant_user: policyCreatedBy,
                    applied_tos: versionAppliedTo,
                } = policy;

                const selectedCategories = documentCategories.reduce(
                    (accumulator, category) => {
                        const id = category?.category?.id;
                        const label = category?.category?.label;
                        if (label !== null){
                            accumulator.push({
                                label,
                                value: id
                            })
                        }
                        return accumulator
                    }, []
                );

                const createdById = policyCreatedBy?.id
                const createdByDetails = policyCreatedBy[`user_${localStorage.schema}`]
                const createdBy = (createdByDetails) ? {id: createdById, name: `${createdByDetails?.first_name} ${createdByDetails?.last_name}`} : {};
                const sections = policySections.reduce((accumulator, section) => {
                    const {
                        id: sectionId,
                        name: sectionName,
                        description: sectionDescription,
                        file,
                        riskLevel,
                        section_categories:selectedSectionCategories
                    } = section.section;

                    const parsedSelectedSectionCategories = selectedSectionCategories.reduce(
                        (accumulator, category) => {
                            const id = category?.category?.id;
                            const label = category?.category?.label;
                            if (label !== null){
                                accumulator.push({
                                    label,
                                    value: id
                                })
                            }
                            return accumulator
                        }, []
                    );

                    accumulator.push({
                        description: sectionDescription,
                        file,
                        id: sectionId,
                        name: sectionName,
                        riskLevel,
                        selectedSections: parsedSelectedSectionCategories,
                        sortFactor: sectionName
                    });
                    return accumulator
                }, []);

                const versions = documentVersions.reduce((accumulator, version) => {
                    const {
                        id,
                        deleted,
                        file,
                        file_name: fileName,
                        effective_date: effectiveDate,
                        document_version_histories: versionHistory,
                        uploaded_timestamp: uploadedTimestamp,
                        version_number: label,
                        is_active_version: active,
                        is_draft: draft
                    } = version;

                    const history = versionHistory.reduce((accumulator, historyArray) => {
                        const {
                            color,
                            created_at: createdAt,
                            message,
                            tenant_user: historyUser
                        } = historyArray;

                        const userDetails = historyUser[`user_${localStorage.schema}`];
                        const user = `${userDetails?.first_name} ${userDetails?.last_name}`

                        accumulator.push({
                            color,
                            date: new Date(createdAt),
                            text: `${user} ${message}`
                        })
                        return accumulator
                    }, []);

                    accumulator.push({
                        deleted,
                        description,
                        document: deleted ? {} : {id: file, name: fileName, size: '', uploadedTimestamp, url: file},
                        effectiveDate: new Date(effectiveDate),
                        history,
                        id,
                        label,
                        name,
                        status: (active) ? 'active' : (draft) ? 'draft' : 'inactive'
                    });
                    return accumulator
                }, [])

                const visibleTo = versionAppliedTo.reduce((accumulator, appliedTo) => {
                    const { group, tenant_user: tenantUser } = appliedTo;
                    if (group !== null){
                        accumulator.push({
                            id: group?.id,
                            label: group?.name,
                            type: 'group'
                        })
                    } else if (tenantUser !== null){
                        const id = tenantUser?.id;
                        const user = tenantUser[`user_${localStorage.schema}`]
                        const userName = `${user?.first_name} ${user?.last_name}`
                        accumulator.push({
                            id,
                            label: userName,
                            type: 'user'
                        });
                    }
                    return accumulator
                }, []);

                accumulator = {
                    createdBy,
                    id,
                    sections: sections.sort((a, b) => (
                        a.name.localeCompare(b.name, undefined, {
                          numeric: true,
                          sensitivity: 'base'
                        })
                    )),
                    selectedCategories,
                    versions: versions.sort((objA, objB) => (objA.label < objB.label) ? 1 : -1),
                    visibleTo
                };

                return accumulator;
            },{}
        );
        const groups = data[`${schema}_group`].reduce(
            (accumulator, group) => {
                accumulator.push({id: group?.id, label: group?.name, type: 'group'})
                return accumulator
            }, []
        );
        const users = data[`tenant_user`].reduce(
            (accumulator, tenantUser) => {
                const id = tenantUser?.id;
                const user = tenantUser[`user_${schema}`];
                if (user !== null){
                    accumulator.push({
                        id,
                        label: `${user?.first_name} ${user?.last_name}`,
                        type: 'user'
                    })
                }
                return accumulator
            }, []
        );
        const appliedToOptions = groups.concat(users);

        for (const option of appliedToOptions){
            const isFound = policy.visibleTo.some(element => {
                if (element.id === option?.id && element.type === option?.type) {
                  return true;
                }
                return false;
            });
            option['selected'] = isFound;
        }

        policy.visibleTo = appliedToOptions;

        const categories = data[`${schema}_categories`].reduce(
            (accumulator, category) => {
                const id = category?.id;
                const label = category?.label;
                if (label !== null){
                    accumulator.push({
                        label,
                        value: id
                    })
                }
                return accumulator
            }, []
        );

        const selectedCategories = policy?.selectedCategories;

        return {categories, policy, selectedCategories}
    }

export default normalizeData;
