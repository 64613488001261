import styles from './styles.module.scss';

const CollapsiblePanelWithChildren = ({ children, className = '', id, label }) => (
    <div className={`${className} ${styles.tabs}`}>

        <div className={`${className} ${styles.tab}`}>

            <input id={id} type="checkbox" />

            <label className={`${className} ${styles.tabLabel}`} htmlFor={id}>

                {label}

            </label>

            <div className={`${className} ${styles.tabContent}`}>

                {children}

            </div>

        </div>

    </div>
);

export default CollapsiblePanelWithChildren;
