const translations = {
    addDescription: 'Add description',
    categories: 'Section Categories',
    description: 'Description',
    documentType: 'Document Type',
    effectiveDate: 'Effective Date',
    lastReviewDate: 'Last Review Date',
    parseContents: 'Would you like the contents of each section to be added to the section repository?',
    parseFile: 'Would you like the file to be parsed into sections?',
    sectionPrefix: 'Section Title Prefix',
    sectionSuffix: 'Section Title Suffix',
    selectDocument: 'Select document to upload',
    upload: 'Upload'
};

export default translations;
