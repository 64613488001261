import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateSendEmail } = state;

const { updateSendEmailAtom } = updateSendEmail.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateActivityInstructions($sendEmail: Boolean!, $activityId: Int!) {
    update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {send_email: $sendEmail}) {
      returning {
        send_email
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveSendEmail = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const newSendEmail = res?.data[`update_${usedSchema}_activity`].returning[0]?.send_email;

            set(updateSendEmailAtom, newSendEmail);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateSendEmailAtom, '');
    });

    return [useRecoilValue(updateSendEmailAtom), saveSendEmail, reset];
};

export default useData;
