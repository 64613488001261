import { forwardRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';
import useData from 'components/CloseCaseMenuListItem/hooks/useData';
import useConfig from 'hooks/useConfig';

const CloseCaseDirectButton = forwardRef(
    (
        { caseId, className = '', isDisabled = false },
        ref
    ) => {

        const [closedCase, closeCase, reset] = useData();

        const { CASE_MANAGEMENT_ROUTE } = useConfig();

        const navigate = useNavigate();

        
        const { CLOSE_CASE, CLOSED } = useTranslation();

        const handleClose = useCallback(() => {
            closeCase({
                caseId
            });
        }, [caseId, closeCase]);

        useEffect(() => {
            if (!closedCase) {
                return;
            }
    
            navigate(CASE_MANAGEMENT_ROUTE);
    
            reset();
        }, [CASE_MANAGEMENT_ROUTE, closedCase, navigate, reset]);

        return (
            <SubmitButton
                className={className}
                isDisabled={isDisabled}
                onClick={handleClose}
                ref={ref}
                text={isDisabled ? CLOSED : CLOSE_CASE}
            />
        );
    }
);

export default CloseCaseDirectButton;
