import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryAllGroups = groupSchema => gql`
    query getGroups {
        ${groupSchema}_form {
            form_visibilities {
                group {
                    name
                }
            }            
            id
            is_active            
            name
        }
    }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryAllGroups(schema), { fetchPolicy: 'no-cache' });
};

export default useData;