import { forwardRef, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import useData from 'components/CloseActivityMenuListItem/hooks/useData';
import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';

const MarkDirectCloseButton = forwardRef(
    ({ activityId, className = '', isDisabled = false, onClick }, ref) => {
        
        const [closedActivity, closeActivity, reset] = useData();
        const { CLOSE } = useTranslation();
        const { useUserId } = useGlobalStateHooks();
        
        const [userId] = useUserId();
        const { HOME } = useConfig();

        const navigate = useNavigate();

        const handleClose = useCallback(() => {
            closeActivity({
                activityId,
                userId
            });
        }, [activityId, closeActivity, userId]);

        useEffect(() => {
            if (!closedActivity) {
                return;
            }
    
            if (isFunction(onClick)) {
                onClick(closedActivity);
            }
    
            navigate(HOME);
    
            reset();
        }, [HOME, closedActivity, navigate, onClick, reset]);

        return (
            <SubmitButton
                className={`${className} ${styles.closeButton}`}
                disabled={isDisabled}
                onClick={handleClose}
                ref={ref}
                text={CLOSE}
            />
        );
    }
);

export default MarkDirectCloseButton;
