import { useEffect } from 'react';

import AddRequestButton from 'components/AddRequestButton';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import RequestStatus from 'components/RequestStatus';
import ViewAllLink from 'components/ViewAllLink';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import useRequestCounts from './hooks/useRequestCounts';

import styles from './styles.module.scss';

import normalizeData from './data';

const MarketingPanel = ({ className = '' }) => {
    const { MARKETING_REQUESTS_ROUTE } = useConfig();
    const { MARKETING } = useTranslation();

    const { useSchema, useTenants, useUserId } = useGlobalStateHooks();

    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [schema] = useSchema();
    const [requests, setRequests] = useRequestCounts();

    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ data, schema, tenants, userId });

            setRequests(normalizedData);
        }
    }, [data, schema, setRequests, userId]);

    if (error) {}

    const loadedPanelBodyClass = !loading ? styles.panelBody : '';

    return (
        <ContentPanel className={`${className} ${styles.marketingPanel}`}>
            <PanelBody className={loadedPanelBodyClass} isLoading={loading}>
                <PanelTitle className={styles.panelTitle} text={MARKETING} />

                <ViewAllLink
                    className={styles.viewAllLink}
                    text="View All"
                    to={MARKETING_REQUESTS_ROUTE}
                />

                <AddRequestButton className={styles.addRequestButton} />

                <RequestStatus
                    assignedCount={requests?.assigned}
                    className={styles.requestStatus}
                    openCount={requests?.open}
                    reviewCount={requests?.review}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default MarketingPanel;
