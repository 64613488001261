import { useCallback, useMemo } from 'react';

import MenuListItems from 'components/MenuListItems';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdministrationAdditionalSettingsSidebar = ({
    className = '',
    onClick,
    activeIndex = 0
}) => {
    const {
        // CASE_MANAGEMENT,
        EMAIL_ADDRESS,
        // LOG_CATEGORY_CONFIGURATION,
        RISK_BASED_MONITORING
    } = useTranslation();

    const items = useMemo(
        () => [
            {
                text: EMAIL_ADDRESS
            },
            {
                text: RISK_BASED_MONITORING
            },
            // {
            //     text: LOG_CATEGORY_CONFIGURATION
            // },
            // {
            //     text: CASE_MANAGEMENT
            // }
        ],
        [
            // CASE_MANAGEMENT,
            EMAIL_ADDRESS,
            // LOG_CATEGORY_CONFIGURATION,
            RISK_BASED_MONITORING
        ]
    );

    const handleClick = useCallback(
        (_, index) => {
            onClick(index);
        },
        [onClick]
    );

    return (
        <MenuListItems
            activeItem={activeIndex}
            className={`${className} ${styles.administrationAdditionalSettingsSidebar}`}
            itemClassName={styles.item}
            items={items}
            onClick={handleClick}
        />
    );
};

export default AdministrationAdditionalSettingsSidebar;
