import { atom } from 'recoil';

const activityTypeDetailsPanelAtom = atom({
    default: '',
    key: 'activityTypeDetailsPanel'
});

const addExistingFormAtom = atom({
    default: '',
    key: 'addExistingForm'
});

const updateActivityTypeNameAtom = atom({
    default: '',
    key: 'updateActivityTypeName'
});

const updateActivityTypeCategoryAtom = atom({
    default: '',
    key: 'updateActivityTypeCategory'
});

const updateActivityTypePoliciesAtom = atom({
    default: '',
    key: 'updateActivityTypePolicies'
});

const updateActivityTypeReferencesAtom = atom({
    default: '',
    key: 'updateActivityTypeReferences'
});

const updateActivityTypeRiskLevelAtom = atom({
    default: '',
    key: 'updateActivityTypeRiskLevel'
});

const updateActivityTypeVisibleToAtom = atom({
    default: '',
    key: 'updateActivityTypeVisibleTo'
});

const updateExistingFormAtom = atom({
    default: '',
    key: 'updateExistingForm'
});

const state = {
    atoms: {
        activityTypeDetailsPanelAtom,
        addExistingFormAtom,
        updateActivityTypeCategoryAtom,
        updateActivityTypeNameAtom,
        updateActivityTypePoliciesAtom,
        updateActivityTypeReferencesAtom,
        updateActivityTypeRiskLevelAtom,
        updateActivityTypeVisibleToAtom,
        updateExistingFormAtom
    }
};

export default state;
