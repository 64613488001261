import HeaderText from 'components/HeaderText';
import TableRow from 'components/TableRow';

import styles from './styles.module.scss';

const FormTableHeader = ({ className = '', items = [], headerClassName }) => (
    <TableRow className={`${className} ${styles.formTableHeader} ${headerClassName}`}>
        {items.map((item, index) => (
            <HeaderText
                className={`${className} ${styles.headerText}`}
                key={`${item}.${index}`}
                text={item}
            />
        ))}
    </TableRow>
);

export default FormTableHeader;
