import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

const AdministrationPanel = props => {
    console.debug('[AdministrationPanel]');

    const { children, className = '', loading = false } = props;

    return (
        <ContentPanel>
            <PanelBody
                className={`${className} ${styles.panelBody}`}
                loading={loading}
            >
                {children}
            </PanelBody>
        </ContentPanel>
    );
};

export default AdministrationPanel;
