import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

const ModalWithIcon = ({ children, onClose }) => (
    <Modal isOpen onClose={onClose}>
        <PanelBody className={styles.panelBody}>{children}</PanelBody>
    </Modal>
);

export default ModalWithIcon;
