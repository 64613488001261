const normalizeData = ({ data, schema }) =>
    {
        const categories = data[`${schema}_categories`].reduce(
            (accumulator, category) => {
                const id = category?.id;
                const label = category?.label;
                if (label !== null){
                    accumulator.push({
                        label,
                        value: id
                    })
                }
                return accumulator
            }, []
        );

        return {categories}
    }

export default normalizeData;
