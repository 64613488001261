import { atom } from 'recoil';

const markReviewedButtonAtom = atom({
    default: '',
    key: 'markReviewedButton'
});

const state = {
    atoms: {
        markReviewedButtonAtom
    }
};

export default state;
