import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

// query cases and log categories
const queryAllGroups = caseSchema => gql`
    query getCases {
        ${caseSchema}_case {
            id
            user_cases {
                id
                tenant_user {
                    username
                    role {
                        name
                    }
                }
            }
        }
    }
`;

const useData = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(queryAllGroups(schema), { fetchPolicy: 'no-cache' });
};

export default useData;