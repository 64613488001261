const filterDataByName = (data, searchFilter) => {
    if (!searchFilter) return data;
    searchFilter = searchFilter.toLowerCase();

    return data.filter(item => {
        return Object.values(item).some(value => {
            return typeof value === 'string' && value.toLowerCase().includes(searchFilter);
        });
    });
};

export default filterDataByName;