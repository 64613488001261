import { useCallback, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminGroupForm from 'components/AdminGroupForm';
import CancelSaveButtons from 'components/CancelSaveButtons';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useData from './hooks/useData';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useTranslation from './hooks/useTranslations';
import useUsers from './hooks/useUsers';

import normalizeData from './data';

import styles from './styles.module.scss';

const AddGroup = () => {
    const { useSchema, useUserId } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [userId] = useUserId();

    const { ADMIN, GROUP_ROUTE } = useConfig();

    const navigate = useNavigate();

    const { ADD_NEW_GROUP } = useTranslation();

    const [group, saveGroup, reset] = useData();
    const { loading, error, data } = useUsers();

    const selectedMembers = useRef([]);
    const [_errorMessage, setErrorMessage] = useState();
    const [users, setUsers] = useState();

    const userMembers = (users ?? []).filter(user => user.type === 'user');

    const form = useForm({
        description: '',
        members: selectedMembers?.current.value || [],
        name: '',
        supervisors: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(ADMIN);
    }, [ADMIN, navigate]);

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);
            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;
                return accumulator;
            },
            {
                description: '',
                members: '',
                name: '',
                supervisors: ''
            }
        );

        formObject.createdBy = userId;
        formObject.supervisors = JSON.parse(JSON.stringify(formObject.supervisors));
        formObject.members = JSON.stringify([...selectedMembers?.current.value]);
        saveGroup(formObject);
    }, [
        handleForm,
        saveGroup,
        userId
    ]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });
            setUsers(normalizedData);
        }
    }, [data, schema, setUsers]);

    useEffect(() => {
        if (!group) {
            return;
        }

        navigate(GROUP_ROUTE.replace(':groupId', group));

        reset();
    }, [group, GROUP_ROUTE, navigate, reset]);

    if (loading){
        
    }
    if (error){
        
    }

    return (
        <div className={styles.addGroup}>
            <WithPageTitle title={ADD_NEW_GROUP}>
                <CancelSaveButtons onCancel={handleCancel} onSave={handleSave} />
            </WithPageTitle>

            <AdminGroupForm form={form} selectedMembers={selectedMembers} users={userMembers} usersAndGroups={users} />
        </div>
    );
};

export default AddGroup;
