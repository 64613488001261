import styles from './styles.module.scss';

const ClockIcon = ({ className = '' }) => (
    <svg
        className={`${className} ${styles.clockIcon}`}
        fill="none"
        height="14"
        viewBox="0 0 13 14"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6.49375 0.75C3.04375 0.75 0.25 3.55 0.25 7C0.25 10.45 3.04375 13.25 6.49375 13.25C9.95 13.25 12.75 10.45 12.75 7C12.75 3.55 9.95 0.75 6.49375 0.75ZM6.5 12C3.7375 12 1.5 9.7625 1.5 7C1.5 4.2375 3.7375 2 6.5 2C9.2625 2 11.5 4.2375 11.5 7C11.5 9.7625 9.2625 12 6.5 12ZM6.3625 3.875H6.325C6.075 3.875 5.875 4.075 5.875 4.325V7.275C5.875 7.49375 5.9875 7.7 6.18125 7.8125L8.775 9.36875C8.9875 9.49375 9.2625 9.43125 9.3875 9.21875C9.51875 9.00625 9.45 8.725 9.23125 8.6L6.8125 7.1625V4.325C6.8125 4.075 6.6125 3.875 6.3625 3.875Z" />
    </svg>
);

export default ClockIcon;
