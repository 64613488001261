import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PolarAreaChart = ({ className = '', data = {} }) => {
    return (
        <Doughnut className={className} data={data} />
    );
};

export default PolarAreaChart;