import CriticalRiskPill from 'components/CriticalRiskPill';
import LowRiskPill from 'components/LowRiskPill';
import MediumRiskPill from 'components/MediumRiskPill';
import HighRiskPill from 'components/HighRiskPill';
import NeutralRiskPill from 'components/NeutralRiskPill';

const riskMap = {
    critical: CriticalRiskPill,
    high: HighRiskPill,
    low: LowRiskPill,
    medium: MediumRiskPill,
    neutral: NeutralRiskPill
};

const RiskPill = ({ className = '', riskLevel }) => {
    if (riskLevel) riskLevel = riskLevel.toLowerCase();
    const RiskPillComponent = riskMap[riskLevel || 'neutral'];

    return RiskPillComponent ? (
        <RiskPillComponent className={className} />
    ) : (
        <></>
    );
};

export default RiskPill;
