import HtmlContent from 'components/HtmlContent';
import ImportantText from 'components/ImportantText';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';

import styles from './styles.module.scss';

const InfoContentBox = ({ className = '', createdAt, text, type, user, version, versionNumber}) => {
    if (version > versionNumber) {
        return <></>
    }

    const title = (type==='request') ? "Requested Updates" : "Response";
    const contentClass = (type==='request') ? styles.reqestContentBox : styles.responseContentBox;
    const firstName = user?.firstName;
    const lastName = user?.lastName;

    return (
    <SectionWithBorder className={`${className} ${contentClass}`}>
        <Title className={styles.title} text={title} />

        <ImportantText className={styles.date} text={createdAt} />

        <ImportantText className={styles.user} text={`${firstName} ${lastName}`} />

        <HtmlContent className={styles.text} html={text} />
    </SectionWithBorder>
    );
};

export default InfoContentBox;
