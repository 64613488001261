import titleCase from "utilities/titleCase";

const parseDataForReport = async (complete, inprogress, overdue, upcoming) => {
    const headers = ['Activity Name', 'Status', 'Start Date', 'Due Date', 'Close Date', 'Activity Type', 'Entities', 'Recurrence', 'Risk Label', 'Reviewer', 'Assigned'];
    const rows = [];
    function addRow(data, status){
        const row = [];
        row.push(data?.text ? data?.text : "");
        row.push(status);
        row.push(data?.startDate ? data?.startDate : "");
        row.push(data?.dueDate ? data?.dueDate : "");
        row.push(data?.closedDate && data?.closedDate !== 'Dec 31, 1969' ? data?.closedDate : "");
        row.push(data?.type ? data?.type : "");
        row.push(data?.entities && Array.isArray(data?.entities) && data?.entities.length > 0 ? data?.entities.join(', ') : "");
        row.push(data?.recurrence ? data?.recurrence : "");
        row.push(data?.riskLabel ? data?.riskLabel : "");

        if(data?.reviewer.length > 0){
            for(const reviewer of data?.reviewer){
                row.push(`${reviewer?.firstName} ${reviewer?.lastName}`)
            }
        } else{
            row.push("-")
        }

        for(const user of data?.assigned){
            row.push(user?.status === 'complete' ? `${user?.firstName} ${user?.lastName}: ${titleCase(user?.status)}` : `${user?.firstName} ${user?.lastName}`)
        }
        rows.push(row);
    }
    for(const o of overdue){
        addRow(o, 'Overdue');
    }
    for(const i of inprogress){
        addRow(i, 'In Progress');
    }
    for(const u of upcoming){
        addRow(u, 'Upcoming');
    }
    for(const c of complete){
        addRow(c, 'Complete');
    }
    const morphedData = {headers, rows}
    return morphedData
}

export default parseDataForReport