const translations = {
    date: 'Date',
    name: 'Name',
    referenceName: 'Attached To',
    referenceType: 'Classification',
    search: 'Search',
    type: 'Type',
    uploadBy: 'Upload By',
    viewAll: 'View All',
};

export default translations;
