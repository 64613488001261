import { useCallback } from 'react';

import HollowButton from 'components/HollowButton';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeleteButton = props => {
    const { DELETE } = useTranslation();

    const {
        className = '',
        isDisabled = false,
        onClick,
        text = DELETE
    } = props;

    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <HollowButton
            className={`${className} ${styles.deleteButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={text}
        />
    );
};

export default DeleteButton;
