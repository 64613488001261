// import image from './image.png';

const items = [
    // {
    //     assigned: 'Mark Johnson',
    //     completed: 12,
    //     dueDate: new Date('7/10/2022'),
    //     image,
    //     isActive: false,
    //     name: 'Ethics Training',
    //     total: 232
    // },
    // {
    //     assigned: 'Mark Johnson',
    //     completed: 12,
    //     dueDate: new Date('7/8/2022'),
    //     image,
    //     isActive: true,
    //     name: 'Ethics Training',
    //     total: 232
    // },
    // {
    //     assigned: 'Mark Johnson',
    //     completed: 12,
    //     dueDate: new Date('8/31/2022'),
    //     image,
    //     isActive: false,
    //     name: 'Ethics Training',
    //     total: 232
    // }
];

const useData = () => items;

export default useData;
