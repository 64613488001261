import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DocumentManagementSectionsTableHeader = ({ onSort, tenantCount }) => {
    const { CATEGORIES, COMPANY, _CURRENT_VERSION, DOCUMENT_TAGS, DOCUMENTS, _LAST_REVIEWED_DATE, PUBLISHED, _RISK_LEVEL, SECTION_NAME, STATUS } =
        useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: SECTION_NAME
            },
            // {
            //     isSortable: false,
            //     key: 'version',
            //     text: CURRENT_VERSION
            // },
            // {
            //     isSortable: true,
            //     key: 'lastReviewedDate',
            //     text: LAST_REVIEWED_DATE
            // },
            // {
            //     isSortable: false,
            //     key: 'riskLevel',
            //     text: RISK_LEVEL
            // },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: false,
                key: 'documents',
                text: DOCUMENTS
            },
            {
                isSortable: false,
                key: 'documentTags',
                text: DOCUMENT_TAGS
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            },
            {
                isSortable: true,
                key: 'published',
                text: PUBLISHED
            }
        ],
        [CATEGORIES, DOCUMENT_TAGS, DOCUMENTS, PUBLISHED, SECTION_NAME, STATUS]
    );

    const headerItemsMulti = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: SECTION_NAME
            },
            // {
            //     isSortable: false,
            //     key: 'version',
            //     text: CURRENT_VERSION
            // },
            // {
            //     isSortable: true,
            //     key: 'lastReviewedDate',
            //     text: LAST_REVIEWED_DATE
            // },
            // {
            //     isSortable: false,
            //     key: 'riskLevel',
            //     text: RISK_LEVEL
            // },
            {
                isSortable: true,
                key: 'company',
                text: COMPANY
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: false,
                key: 'documents',
                text: DOCUMENTS
            },
            {
                isSortable: false,
                key: 'documentTags',
                text: DOCUMENT_TAGS
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            },
            {
                isSortable: true,
                key: 'published',
                text: PUBLISHED
            }
        ],
        [CATEGORIES, DOCUMENT_TAGS, DOCUMENTS, PUBLISHED, SECTION_NAME, STATUS]
    );

    return (
        <TableHeader
            className={tenantCount>1 ? styles.documentManagementTableHeaderMulti : styles.documentManagementTableHeader}
            items={tenantCount>1 ? headerItemsMulti : headerItems}
            onSort={onSort}
        />
    );
};

export default DocumentManagementSectionsTableHeader;
