import AddFormsButton from 'components/AddFormsButton';
import FormsManagementPanel from 'components/FormsManagementPanel';
import WithPageTitle from 'components/WithPageTitle';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const FormsManagement = () => {
    const { FORMS_MANAGEMENT } = useTranslation();
    return(
        <div className={styles.formsManagement}>
            <WithPageTitle title={FORMS_MANAGEMENT}>
                <AddFormsButton className={styles.addFormsButton} />
            </WithPageTitle>

            <FormsManagementPanel className={styles.formsManagementPanel} />
        </div>
    );
}

export default FormsManagement;
