import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('publishPolicyVersionButton');

    return {
        PUBLISH_VERSION: t('publishVersion')
    };
};

export default useTranslation;
