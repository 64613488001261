import WorkflowSettingsTableHeader from 'components/WorkflowSettingsTableHeader';
import WorkflowSettingsTableRow from 'components/WorkflowSettingsTableRow';
import Table from 'components/Table';

const WorkflowSettingsTable = ({ items = [] }) => {
    return (
        <Table>
            <WorkflowSettingsTableHeader />

            {items.map(({ approvingGroup, id, status, submittingGroup }) => (
                <WorkflowSettingsTableRow
                    approvingGroup={approvingGroup}
                    key={id}
                    status={status}
                    submittingGroup={submittingGroup}
                />
            ))}
        </Table>
    );
};

export default WorkflowSettingsTable;
