import formatDate from 'utilities/formatDate';
import normalizeListData from 'utilities/normalizeListData';

const schemaData = ({ data, querySchemas, schema }) =>
    data[`${schema}_marketing_request`].reduce((accumulator, requests) => {
        const {
            request_name: requestName,
            due_date: requestDueDate,
            expected_use_date: expectedUseDate,
            marketing_request_assigned_tos: requestAssignedTo,
            marketing_request_versions: requestVersions,
            tenant_user: requestedBy,
            status,
            id: requestId
        } = requests;

        const assignedTo = requestAssignedTo.reduce((accumulator, assigned) => {
            const { group, tenant_user: tenantUser } = assigned;
            if (group) {
                accumulator.push({
                    firstName: group?.name,
                    id: group?.id,
                    lastName: 'Group'
                });
            } else {
                if (querySchemas) {
                    for (const s of querySchemas) {
                        const user = tenantUser[`user_${s.value}`];
                        const id = tenantUser.id;

                        if (user) {
                            const {
                                first_name: firstName,
                                last_name: lastName
                            } = user;

                            accumulator.push({
                                firstName,
                                id,
                                lastName
                            });
                        }
                    }
                } else {
                    const user = tenantUser[`user_${schema}`];
                    const id = tenantUser.id;

                    const { first_name: firstName, last_name: lastName } = user;

                    accumulator.push({
                        firstName,
                        id,
                        lastName
                    });
                }
            }

            return accumulator;
        }, []);

        const approvedVersions = requestVersions
            .filter(x => x?.status === 'approved')
            .map(({ file: fileName, version_number: versionNumber, created_date: dateApproved, marketing_request_version_histories: histories  }) => ({
                fileName,
                versionNumber,
                dateApproved,
                histories
            }));
        
        // Extract Date Approved
        let approvedDate = null
        approvedVersions.length > 0 && approvedVersions.map((version) => {
            if(version && version.histories.length > 0){
                version.histories.map((history) => {
                    if(history.message.includes("approved")){
                        approvedDate =  history.created_at
                    }
                }
                )
            }
        })

        const requestedByDetailsArray = [];
        if (querySchemas) {
            for (const s of querySchemas) {
                if (requestedBy[`user_${s.value}`]) {
                    requestedByDetailsArray.push(
                        requestedBy[`user_${s.value}`]
                    );
                }
            }
        } else {
            requestedByDetailsArray.push(requestedBy[`user_${schema}`]);
        }
        const requestedByDetails = requestedByDetailsArray[0];
        const formatedRequestedBy = {
            firstName: requestedByDetails?.first_name,
            id: requestedBy?.id,
            lastName: requestedByDetails?.last_name
        };

        // Extract Date Requested
        const dateRequested = requestVersions.length > 0 ? requestVersions[0].created_date : null;

        accumulator.push({
            approvedVersions,
            assignedTo,
            dateApproved: approvedDate ? formatDate(new Date(approvedDate)) : null,
            dateRequested: dateRequested ? formatDate(new Date(dateRequested)) : null,
            dueDate: formatDate(new Date(requestDueDate)),
            expectedUseDate: formatDate(new Date(expectedUseDate)),
            id: requestId,
            requestName,
            requestSchema: schema,
            requestedBy: formatedRequestedBy,
            sortDate: dateRequested,
            status
        });

        accumulator = accumulator.sort((objA, objB) => {
            if (!objA.dateRequested && !objB.dateRequested) return 0;
            if (!objA.dateRequested) return 1;
            if (!objB.dateRequested) return -1;
            return new Date(objB.dateRequested) - new Date(objA.dateRequested);
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, querySchemas, schema, tenants }) =>
    normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizeData;
