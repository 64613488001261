import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('formSettingsTableHeader');

    return {
        FIELD: t('field'),
        STATUS: t('status'),
        VISIBILITY: t('visibility')
    };
};

export default useTranslation;
