import { atom } from 'recoil';

const completedActivityInProgressDetailedAtom = atom({
    default: '',
    key: 'completedActivityInProgressDetailed'
});

const state = {
    atoms: {
        completedActivityInProgressDetailedAtom
    }
};

export default state;
