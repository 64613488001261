const { default: prefixSingleDigitDateString } = require("./prefixSingleDigitDateString");

function dateToTimestampString(date) {
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    if(Object.prototype.toString.call(date) === '[object Date]'){
        return `${month[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${prefixSingleDigitDateString(date.getHours())}:${prefixSingleDigitDateString(date.getMinutes())}`;
    }
    return '';
}

export default dateToTimestampString