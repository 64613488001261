import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('authenticatePanel');

    return {
        AUTHENTICATION: t('authentication'),
        EMAIL_SENT: t('emailSent'),
        LOGIN_TIMEOUT: t('loginTimeout'),
        SUCCESSFUL_LOGIN: t('successfulLogin')
    };
};

export const MAXIMUM_TOKEN_POLL_ATTEMPTS = 500;

export const POLL_INTERVAL = 1000;

export default useTranslation;
