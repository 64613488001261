import { Droppable, Draggable } from "react-beautiful-dnd";
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import CheckBoxWithTextInput from "components/CheckBoxWithTextInput";
import EditableTextInput from 'components/EditableTextInput';
import TextInput from 'components/TextInput';
import TextBox from 'components/TextBox';
import RadioButtonWithTextInput from "components/RadioButtonWithTextInput";

import styles from './styles.module.scss';
import PanelTitle from "components/PageTitle";
import AddRadioButtonOption from 'components/AddRadioButtonOption';
import AddDropDownOption from "components/AddDropDownOption";
import AddCheckBoxOption from "components/AddCheckBoxOption";
import Dropdown from "components/Dropdown";
import MoveIcon from "components/icons/MoveIcon";
import CloseIcon from "components/icons/CloseIcon";
import Buttonize from "components/Buttonize";
import { useEffect } from "react";

const AddFormMetaPanel = ({
    className = '',
    formElements,
    pendingCheckBoxInputElementOptions,
    pendingDropDownInputElementOptions,
    pendingRadioInputElementOptions,
    resetElement,
    setPendingCheckBoxInputElement,
    setPendingCheckBoxInputElementOptions,
    setPendingConditionalElement,
    setPendingDescriptionElement,
    setPendingDropDownInputElement,
    setPendingDropDownInputElementOptions,
    setPendingRadioInputElement,
    setPendingRadioInputElementOptions,
    setPendingTextAreaInputElement,
    setPendingTextInputElement,
    setPendingTitleElement,
    setResetElements
}) => {

    const handleTitleChange = titleField => {
        setPendingTitleElement(titleField);
    };

    const handleCheckBoxInputChange = checkBoxField => {
        setPendingCheckBoxInputElement(checkBoxField);
    };

    const handleDescriptionChange = descriptionField => {
        setPendingDescriptionElement(descriptionField);
    };

    const handleTextBoxChange = textInputField => {
        setPendingTextInputElement(textInputField);
    };

    const handleTextAreaBoxChange = textAreaInputField => {
        setPendingTextAreaInputElement(textAreaInputField);
    };

    const handleRadioButtonTitleChange = radioButtonInputTitle => {
        setPendingRadioInputElement(radioButtonInputTitle);
    };

    const handleRadioButtonOptionChange = (radioButtonOptions, index) => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions[index].name = radioButtonOptions;
        mutatedOptions[index].label = radioButtonOptions;
        setPendingRadioInputElementOptions(mutatedOptions);
    }

    const handleAddRadioButtonOption = () => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions.push({label: '', name: ''});
        setPendingRadioInputElementOptions(mutatedOptions);
    };

    const handleRemoveRadioButtonOption = (_, index) => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingRadioInputElementOptions(setOptions);
    };

    const handleDropDownTitleChange = dropDownInputTitle => {
        setPendingDropDownInputElement(dropDownInputTitle);
    };

    const handleDropDownOptionChange = (dropDownOptions, index) => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions[index].label = dropDownOptions;
        mutatedOptions[index].value = dropDownOptions;
        setPendingDropDownInputElementOptions(mutatedOptions);
    }

    const handleRemoveDropDownButtonOption = (_, index) => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingDropDownInputElementOptions(setOptions);
    };

    const handleAddDropDownOption = () => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions.push({label: '', value: ''});
        setPendingDropDownInputElementOptions(mutatedOptions);
    };

    const handleAddCheckBoxOption = () => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions.push({label: '', value: ''});
        setPendingCheckBoxInputElementOptions(mutatedOptions);
    };

    const handleRemoveCheckBoxButtonOption = (_, index) => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingCheckBoxInputElementOptions(setOptions);
    };

    const handleCheckBoxOptionChange = (checkBoxOptions, index) => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions[index].name = checkBoxOptions;
        mutatedOptions[index].label = checkBoxOptions;
        setPendingCheckBoxInputElementOptions(mutatedOptions);
    }

    useEffect(() => {
        if(resetElement){
            setResetElements(!resetElement);
            if(document.getElementById('pendingTitle')){document.getElementById('pendingTitle').value = '';}
            if(document.getElementById('pendingDescription')){document.getElementById('pendingDescription').value = '';}
            if(document.getElementById('pendingTextBox')){document.getElementById('pendingTextBox').value = '';}
            if(document.getElementById('pendingRadioTitle')){document.getElementById('pendingRadioTitle').value = '';}
            if(document.getElementById('pendingRadioOne')){document.getElementById('pendingRadioOne').value = '';}
            if(document.getElementById('pendingDropDownTitle')){document.getElementById('pendingDropDownTitle').value = '';}
            if(document.getElementById('pendingDropDownOne')){document.getElementById('pendingDropDownOne').value = '';}
            if(document.getElementById('pendingCheckBoxTitle')){document.getElementById('pendingCheckBoxTitle').value = '';}
            if(document.getElementById('pendingCheckBoxOne')){document.getElementById('pendingCheckBoxOne').value = '';}
            if(document.getElementById('pendingConditional')){document.getElementById('pendingConditional').value = '';}
        }
    }, [resetElement, setResetElements])

    return (
        <ContentPanel className={`${className} ${styles.addFormMetaPanel}`}>
            <PanelBody className={styles.panelBody}>
                <Droppable droppableId={'formElements'} key={'formElements'}>
                    {(provided, _snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <PanelTitle text={'Text Components'} />
                                
                                <Draggable
                                    draggableId={'titleText'}
                                    index={1}
                                    key={'titleText'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>

                                                    <TextInput
                                                        id={'pendingTitle'}
                                                        label={'Title'}
                                                        onChange={handleTitleChange}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'normalText'}
                                    index={2}
                                    key={'normalText'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <TextBox
                                                        id={'pendingDescription'}
                                                        label={'Description or Instructions'}
                                                        onChange={handleDescriptionChange}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <PanelTitle text={'Form Components'} />

                                <Draggable
                                    draggableId={'textInput'}
                                    index={3}
                                    key={'textInput'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>

                                                    <EditableTextInput
                                                        id={'pendingTextBox'}
                                                        label={'Replace text input title'}
                                                        onChange={handleTextBoxChange}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'textAreaInput'}
                                    index={4}
                                    key={'textAreaInput'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>

                                                    <EditableTextInput
                                                        id={'pendingTextAreaBox'}
                                                        label={'Replace text area title'}
                                                        onChange={handleTextAreaBoxChange}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'radioInput'}
                                    index={5}
                                    key={'radioInput'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <div className={styles.radioButtonContainer} >
                                                        <TextInput
                                                            className={styles.radioButtonLabel}
                                                            id={'pendingRadioTitle'}
                                                            label={'Radio Button Label'}
                                                            onChange={handleRadioButtonTitleChange}
                                                        />

                                                        {pendingRadioInputElementOptions && pendingRadioInputElementOptions.map(({label}, index) => {
                                                            return(
                                                                <div className={styles.deleteContainer}>
                                                                    {index === 0 &&
                                                                        <RadioButtonWithTextInput handleTextChange={res => handleRadioButtonOptionChange(res, index)} id={'pendingRadioOne'} value={label}/>
                                                                    }

                                                                    {index !== 0 &&
                                                                        <RadioButtonWithTextInput handleTextChange={res => handleRadioButtonOptionChange(res, index)} value={label}/>
                                                                    }

                                                                    {index === pendingRadioInputElementOptions.length-1 && index !== 0 &&
                                                                        <Buttonize onClick={res => handleRemoveRadioButtonOption(res, index)}>
                                                                            <CloseIcon />
                                                                        </Buttonize>
                                                                    }
                                                                </div>
                                                            )
                                                        })}

                                                        <AddRadioButtonOption onClick={handleAddRadioButtonOption} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'dropDownInput'}
                                    index={6}
                                    key={'dropDownInput'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <div className={styles.dropDownContainer} >
                                                        <TextInput
                                                            className={styles.dropDownLabel}
                                                            id={'pendingDropDownTitle'}
                                                            label={'Dropdown Label'}
                                                            onChange={handleDropDownTitleChange}
                                                        />

                                                        {pendingDropDownInputElementOptions && pendingDropDownInputElementOptions.map((_, index) => {
                                                            return(
                                                                <div className={styles.deleteContainer}>
                                                                    {index === 0 &&
                                                                        <TextInput
                                                                            className={styles.dropDownOption}
                                                                            id={'pendingDropDownOne'}
                                                                            onChange={res => handleDropDownOptionChange(res, index)}
                                                                            placeholder={"Option"}
                                                                        />
                                                                    }

                                                                    {index !== 0 &&
                                                                        <TextInput
                                                                            className={styles.dropDownOption}
                                                                            onChange={res => handleDropDownOptionChange(res, index)}
                                                                            placeholder={"Option"}
                                                                        />
                                                                    }
                                                                    
                                                                    {index === pendingDropDownInputElementOptions.length-1 && index !== 0 &&
                                                                        <Buttonize onClick={res => handleRemoveDropDownButtonOption(res, index)}>
                                                                            <CloseIcon />
                                                                        </Buttonize>
                                                                    }
                                                                </div>
                                                            )
                                                        })}

                                                        <AddDropDownOption onClick={handleAddDropDownOption} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'checkBoxInput'}
                                    index={7}
                                    key={'checkBoxInput'}
                                >
                                    {(provided, _snapshot) => {
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <div className={styles.radioButtonContainer} >
                                                        <TextInput
                                                            className={styles.radioButtonLabel}
                                                            id={'pendingCheckBoxTitle'}
                                                            label={'CheckBox Question'}
                                                            onChange={handleCheckBoxInputChange}
                                                        />

                                                        {pendingCheckBoxInputElementOptions && pendingCheckBoxInputElementOptions.map((_, index) => {
                                                            return(
                                                                <div className={styles.deleteContainer}>
                                                                    {index === 0 &&
                                                                        <CheckBoxWithTextInput handleTextChange={res => handleCheckBoxOptionChange(res, index)} id={'pendingCheckBoxOne'} />
                                                                    }

                                                                    {index !== 0 &&
                                                                        <CheckBoxWithTextInput handleTextChange={res => handleCheckBoxOptionChange(res, index)}/>
                                                                    }
                                                                    
                                                                    {index === pendingCheckBoxInputElementOptions.length-1 && index !== 0 &&
                                                                        <Buttonize onClick={res => handleRemoveCheckBoxButtonOption(res, index)}>
                                                                            <CloseIcon />
                                                                        </Buttonize>
                                                                    }
                                                                </div>
                                                            )
                                                        })}

                                                        <AddCheckBoxOption onClick={handleAddCheckBoxOption} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                <Draggable
                                    draggableId={'conditional'}
                                    index={8}
                                    key={'conditional'}
                                >
                                    {(provided, _snapshot) => {
                                        const items = formElements.reduce((accumulator, element) => {
                                            if(element?.options && element?.options.length > 0){
                                                accumulator.push({label: element.text, value: element.id})
                                            }
                                            return accumulator;
                                        }, []);
                                        return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={{
                                                    paddingBottom: 10,
                                                    paddingTop: 10,
                                                    userSelect: "none",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                    <span
                                                        className={styles.icon}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MoveIcon className={styles.iconComponent}/>
                                                    </span>
                                                    
                                                    <Dropdown id={'pendingConditional'} items={items} label={"Conditional Question"} onChange={setPendingConditionalElement} />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </Draggable>

                                {provided.placeholder}
                            </div>
                        )
                    }}
                </Droppable> 
            </PanelBody>
        </ContentPanel>
    );
};

export default AddFormMetaPanel;
