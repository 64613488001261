import BodyText from 'components/BodyText';
import DetailsButton from 'components/DetailsButton';
import EyeIcon from 'components/icons/EyeIcon';
import ImportantLink from 'components/ImportantLink';
import TableRow from 'components/TableRow';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const FormTableRow = ({
    activities,
    answers,
    formId,
    formSchema,
    isActive,
    isMultiTenant,
    name,
    questions,
    rowStyle
}) => {
    const { EDIT_FORM_ROUTE } = useConfig();
    const isActiveText = (isActive) ? '✓' : '';
    return (
        <TableRow className={rowStyle}>
            <ImportantLink
                className={styles.titleLink}
                text={name}
                to={`${EDIT_FORM_ROUTE.replace(':formId', formId).replace(':formSchema', formSchema)}`}
            />


            {isMultiTenant ? <BodyText className={styles.formCell} text={titleCase(formSchema)} /> : []}

            <BodyText className={styles.formCell} text={isActiveText} />

            <BodyText className={styles.formCell} text={questions} />

            <BodyText className={styles.formCell} text={activities} />

            <BodyText className={styles.formCell} text={answers} />

            <DetailsButton
                className={styles.detailsButton}
                items={[
                    {
                        icon: <EyeIcon />,
                        onClick: () => {
                            
                        },
                        text: 'View'
                    }
                ]}
            />
        </TableRow>
    );
};

export default FormTableRow;
