import AttachmentListItem from 'components/AttachmentListItem';

import styles from './styles.module.scss';

const AttachmentList = props => {
    const { className = '', hasEdit = true, hasEditPermission, items = [], onDelete, onUpdate, providedSchema } = props;

    return (
        <div className={`${className} ${styles.attachmentList}`}>
            {items.map(({ id, name, size, uploaded_timestamp, url }) => (
                <AttachmentListItem
                    hasEdit={hasEdit}
                    hasEditPermission={hasEditPermission}
                    id={id}
                    key={url}
                    name={name}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    providedSchema={providedSchema}
                    size={size}
                    uploaded_timestamp={uploaded_timestamp}
                    url={url}
                />
            ))}
        </div>
    );
};

export default AttachmentList;
