import Title from 'components/Title';

import styles from './styles.module.scss';

const WithTitle = props => {
    const { className = '', children, text = '' } = props;

    return (
        <div className={`${className} ${styles.withTitle}`}>
            <Title text={text} />

            {children}
        </div>
    );
};

export default WithTitle;
