import Buttonize from 'components/Buttonize';
import DetailsIcon from 'components/DetailsIcon';
import ActionMenu from 'components/ActionMenu';

import styles from './styles.module.scss';

const DetailsButton = ({ className = '', items = [] }) => (
    <ActionMenu
        items={items}
        renderReferenceElement={({ handleClick }) => (
            <Buttonize
                className={`${styles.buttonize} ${className}`}
                onClick={handleClick}
            >
                <DetailsIcon />
            </Buttonize>
        )}
    />
);

export default DetailsButton;
