import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import nameFile from 'utilities/nameFile';
import pushFile from 'utilities/pushFile';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

import {
    getRejectedFilesState,
    getStateFromLocalFiles,
    getStateFromUrls
} from './helpers';

const DEFAULT_MAX_SIZE = 100 * 1024 * 1024;

const FileInput = props => {
    const {
        accept = { 'image/*': ['.png', '.jpeg', '.doc', '.docx', '.pdf', '.xls', '.xlsx', '.ppt', '.pptx', '.eml', '.msg'] },
        children,
        className = '',
        disabled = false,
        filesList,
        maxFiles,
        maxSize = DEFAULT_MAX_SIZE,
        persist = true,
        providedSchema,
        renderPreview,
        value = []
    } = props;

    const { API_KEY, PUSH_FILE_URL } = useConfig();
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [files, setFiles] = useState(() => getStateFromUrls(value));

    const usedSchema = providedSchema ? providedSchema : schema;

    const removeFileHandler = useCallback(filename => {
        setFiles(prevState => {
            delete prevState[filename];
            return { ...prevState };
        });
    }, []);

    const fileUploadHandler = useCallback(
        async file => {
            document.body.style.cursor = 'progress';
            const fileName = nameFile(file);
            file.newName = fileName;
            setFiles(prevState => ({
                ...prevState,
                [fileName]: {
                    ...prevState[fileName],
                    file,
                    isUploadError: false,
                    isUploading: true
                }
            }));

            try {
                const response = await pushFile(usedSchema, file, fileName, API_KEY, PUSH_FILE_URL);

                if(!persist){
                    removeFileHandler(fileName);
                }else{
                    setFiles(prevState => ({
                        ...prevState,
                        [fileName]: {
                            ...prevState[fileName],
                            isUploadError: false,
                            isUploading: false,
                            url: response.url
                        }
                    }));
                }
            } catch (error) {
                setFiles(prevState => ({
                    ...prevState,
                    [fileName]: {
                        ...prevState[fileName],
                        isUploadError: error,
                        isUploading: false
                    }
                }));
            }
            document.body.style.cursor = 'default';

        },
        [schema, persist, removeFileHandler]
    );

    const { getInputProps, getRootProps } = useDropzone({
        accept,
        disabled,
        maxFiles,
        maxSize,

        onDropAccepted: async acceptedFiles => {
            setFiles(prevState => ({
                ...prevState,
                ...getStateFromLocalFiles(acceptedFiles)
            }));

            await Promise.all(acceptedFiles.map(fileUploadHandler));
        },

        onDropRejected: rejections => {
            setFiles(prevState => ({
                ...prevState,
                ...getRejectedFilesState(rejections)
            }));
        }
    });

    useEffect(()=>{
        filesList(files);
        // eslint-disable-next-line
    },[files])

    return (
        <div className={className}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />

                {children}
            </div>

            {renderPreview({ files, removeFileHandler })}
        </div>
    );
};

export default FileInput;
