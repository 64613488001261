import ActionMenu from 'components/ActionMenu2';
import CreateActivityMenuListItemFromCase from 'components/CreateActivityMenuListItemFromCase';
import CreateCaseMenuListItemFromCase from 'components/CreateCaseMenuListItemFromCase';
import CreateLogMenuListItemFromCase from 'components/CreateLogMenuListItemFromCase';

const AddFromCaseMenu = ({
    caseId,
    caseRecord,
    children,
    onClick,
    riskLabel
}) => (
    <ActionMenu
        renderReferenceElement={({ handleClick }) => children(handleClick)}
    >
        {onPopperClose => (
            <>
                <CreateActivityMenuListItemFromCase
                    caseId={caseId}
                    caseRecord={caseRecord?.name}
                    // onClick={onPopperClose}
                />

                <CreateCaseMenuListItemFromCase
                    caseId={caseId}
                    caseRecord={caseRecord}
                    // onClick={onPopperClose}
                    onSave={onClick}
                    riskLabel={riskLabel}
                />

                <CreateLogMenuListItemFromCase
                    caseId={caseId}
                    caseRecord={caseRecord}
                    onClick={onPopperClose}
                    onSave={onClick}
                />
            </>
        )}
    </ActionMenu>
);

export default AddFromCaseMenu;
