import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const MenuItem = props => {
    const { children, className = '', onClick } = props;

    return (
        <Buttonize
            className={`${className} ${styles.menuItem}`}
            onClick={onClick}
        >
            {children}
        </Buttonize>
    );
};

export default MenuItem;
