const parseDocumentRepositoryDataForReport = async (documents, schema) => {
    const headers = ['Document Name', 'Type', 'Attached To', 'Classification', 'Date Introduced', 'Upload By'];
    const rows = [];

    function addRow(data) {
        const row = [];
        const urlType = 
            schema && window.location.origin
                ? `${window.location.origin}/${(data?.type).toLowerCase()}/${data?.id}/${schema}`
                : undefined;
        
        if (data?.name && urlType) {
            row.push({
                hyperlink: urlType,
                text: data.name,
            });
        } else {
            row.push(data.name ? data.name : "");
        }

        row.push(data?.type ? data?.type : "");
        row.push(data?.referenceName ? data?.referenceName : "");
        row.push(data?.referenceType ? data?.referenceType : "");
        row.push(data?.dateIntroduced ? data?.dateIntroduced : "");
        row.push(data?.uploadBy ? data?.uploadBy : "");
        rows.push(row);
    }

    documents?.forEach((document) => {
        addRow(document);
    });

    const morphedData = { headers, rows };
    return morphedData;
};

export default parseDocumentRepositoryDataForReport;
