import { useCallback, useRef, useState } from 'react';

import CarouselSlides from 'components/CarouselSlides';
import Dots from 'components/Dots';
import NextButton from 'components/NextButton';
import PreviousButton from 'components/PreviousButton';

import styles from './styles.module.scss';

const Carousel = ({ className = '', items = [] }) => {
    const [activeSlide, setActiveSlide] = useState(0);

    const slideCount = useRef(items.length);

    const handleLeftClick = useCallback(() => {
        const nextSlide = activeSlide - 1;

        if (nextSlide > -1) {
            setActiveSlide(nextSlide);
        }
    }, [activeSlide]);

    const handleRightClick = useCallback(() => {
        const nextSlide = activeSlide + 1;

        if (nextSlide < slideCount.current) {
            setActiveSlide(nextSlide);
        }
    }, [activeSlide]);

    return (
        <div className={`${className} ${styles.carousel}`}>
            <PreviousButton
                className={styles.previousButton}
                isDisabled={activeSlide === 0}
                onClick={handleLeftClick}
            />

            <CarouselSlides
                activeSlide={activeSlide}
                className={styles.carouselSlides}
                items={items}
            />

            <NextButton
                className={styles.nextButton}
                isDisabled={activeSlide === slideCount.current - 1}
                onClick={handleRightClick}
            />

            <Dots
                activeDot={activeSlide}
                className={styles.dots}
                count={slideCount.current}
            />
        </div>
    );
};

export default Carousel;
