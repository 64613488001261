import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PolicyMappingTableHeader = ({ onSort }) => {
    const { CATEGORIES, DESCRIPTION, SECTION_NAME } = useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: SECTION_NAME
            },
            {
                isSortable: false,
                key: 'description',
                text: DESCRIPTION
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            }
        ],
        [CATEGORIES, DESCRIPTION, SECTION_NAME]
    );

    return (
        <TableHeader
            className={styles.policyMappingTableHeader}
            items={headerItems}
            onSort={onSort}
        />
    );
};

export default PolicyMappingTableHeader;
