import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const ListItemLink = ({ children, className = '', onClick }) => {
    const handleClick = event => {
        if (isFunction(onClick)) {
            onClick(event.target.textContent);
        }
    };

    return (
        <li className={`${className} ${styles.listItemLink}`} onClick={handleClick}>
            {children}
        </li>
    );
};

export default ListItemLink;
