import { atom } from 'recoil';

const sideLettersListAtom = atom({
    default: [],
    key: 'sideLettersList'
});

const state = {
    atoms: {
        sideLettersListAtom
    }
};

export default state;
