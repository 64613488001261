import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { activityDetailsPanel } = state;

const { updateRegulatoryReferenceAtom } = activityDetailsPanel.atoms;

const MUTATE_DELETE_OLD_REFERENCES = tenantSchema => gql`
  mutation deleteActivityRegulatoryReferences($activityId: Int!) {
    delete_${tenantSchema}_activity_regulatory_reference(where: {activity_id: {_eq: $activityId}}) {
      returning {
          id
      }
    }
  }
`;

const MUTATE_INSERT_NEW_REFERENCES = tenantSchema => gql`
  mutation insertActivityRegulatoryReference(
    $activityId: Int!, 
    $regulatoryReferenceId: Int!,
  ) {
    insert_${tenantSchema}_activity_regulatory_reference(objects: {
        activity_id: $activityId, 
        regulatory_reference_id: $regulatoryReferenceId
    }) {
      returning {
          regulatory_reference_id
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_dropdown($activityId: Int!, $color: String = "yellow", $message: String = "changed the regulatory references", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutationDeleteOldRegulatoryReferences = useGraphQL(MUTATE_DELETE_OLD_REFERENCES, providedSchema);
    const mutationInsertNewRegulatoryReference = useGraphQL(MUTATE_INSERT_NEW_REFERENCES, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveRegulatoryReferences = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
          
            await mutationDeleteOldRegulatoryReferences(variables);

            const updatedReferences = [];
            for (const ref of variables.references) {
              variables.regulatoryReferenceId = ref;

              const res = await mutationInsertNewRegulatoryReference(variables);
              updatedReferences.push(res?.data[`insert_${usedSchema}_activity_regulatory_reference`].returning[0]?.regulatory_reference_id)
            }

            set(updateRegulatoryReferenceAtom, updatedReferences);
            
            await mutationActivityHistory(variables);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateRegulatoryReferenceAtom, '');
    });

    return [useRecoilValue(updateRegulatoryReferenceAtom), saveRegulatoryReferences, reset];
};

export default useData;
