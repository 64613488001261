import BarListItem from 'components/BarListItem';

import styles from './styles.module.scss';

const InfoBarListItem = ({
    children,
    className = '',
    isActive = false
}) => {
    const activeClass = isActive ? styles.isActive : '';

    return (
        <BarListItem
            className={`${activeClass} ${className} ${styles.infoBarListItem}`}
            isActive={isActive}
        >
            {children}
        </BarListItem>
    );
};

export default InfoBarListItem;
