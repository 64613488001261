// import { useCallback } from 'react';

// import mime from 'mime/lite';

import ImageUploadThumbnail from 'components/ImageUploadThumbnail';
import UnknownFileUploadThumbnail from 'components/UnknownFileUploadThumbnail';

import styles from './styles.module.scss';

const thumbnailMap = {
    image: ImageUploadThumbnail,
    unknown: UnknownFileUploadThumbnail
};

const FileUploadPreview = ({ files = {}, onRemove }) => {
    // const resolveFileType = useCallback(filename => {
    //     const mimeType = mime.getType(filename);

    //     return mimeType?.includes('image') ? 'image' : 'unknown';
    // }, []);

    return (
        <div className={styles.fileUploadPreview}>
            {Object.keys(files).map(filename => {
                const { isUploadError, isUploading, url, validationErrors } =
                    files[filename];

                const fileType = 'unknown';

                const ThumbnailComponent = thumbnailMap[fileType];

                return (
                    <ThumbnailComponent
                        filename={filename}
                        isUploadError={isUploadError}
                        isUploading={isUploading}
                        key={url}
                        onRemove={onRemove}
                        url={url}
                        validationErrors={validationErrors}
                    />
                );
            })}
        </div>
    );
};

export default FileUploadPreview;
