import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddButton from 'components/AddButton';

import useTranslation from './hooks/useTranslation';

const AddFormButton = ({ className = '' }) => {
    const { ADD_FORM } = useTranslation();

    const { ADD_FORM_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(ADD_FORM_ROUTE);
    }, [ADD_FORM_ROUTE, navigate]);

    return (
        <AddButton
            className={className}
            onClick={handleClick}
            text={ADD_FORM}
        />
    );
};

export default AddFormButton;
