import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddCaseButton from 'components/AddCaseButton';
import ContentPanel from 'components/ContentPanel';
import DonutPanel from 'components/DonutPanel';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import ViewAllLink from 'components/ViewAllLink';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import useCaseTypeCounts from './hooks/useCaseTypeCounts';

import styles from './styles.module.scss';

import normalizeData from './data';
import normalizeCaseData from './caseData';
import useFilter from './hooks/useFilter';
import useCaseData from './hooks/useCaseData';
import CaseTable from 'components/CaseTable';
import { useExportCases } from 'hooks/useExportCases';

const CasePanel = ({ className = '' }) => {
    const { CASE_MANAGEMENT_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();
    const { CASE } = useTranslation();

    const navigate = useNavigate();
		const {exportCases} = useExportCases()

    const { useIsAllFilter, useSchema, useQuerySchemas, useTenants } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [isAll] = useIsAllFilter();
    const [querySchemas] = useQuerySchemas();
    const [types, setTypes] = useCaseTypeCounts();
    const [_, setTypeFilter] = useFilter();

    const { loading, error, data } = useData();

    const [overdueCases, setOverdueCases] = useState([]);
    const [pendingCases, setPendingCases] = useState([]);
    const [closedCases, setClosedCases] = useState([]);


    const { loading:_caseLoading, error:caseError, data:caseData } = useCaseData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ data, schema, tenants });

            setTypes(normalizedData);
        }
    }, [data, schema, setTypes, tenants]);

    useEffect(() => {
        if (caseData) {
            const normalizedData = normalizeCaseData({
                data:caseData,
                querySchemas,
                schema,
                tenants
            });

            const sortedCases = normalizedData.sort(function(a,b){
                return new Date(a.dueDate) - new Date(b.dueDate);
            });

            const closedCases = sortedCases.filter(
                ({ closed }) => closed
            );

            const notClosedCases = sortedCases.filter(
                ({ closed }) => !closed
            );

            const overdueCases = notClosedCases.filter(
                ({ dueDate }) => new Date(dueDate) < new Date()
            );

            const pendingCases = notClosedCases.filter(
                ({ dueDate }) => new Date(dueDate) >= new Date()
            );

            const isAllFilteredClosedCases = !isAll
                ? closedCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : closedCases

            const isAllFilteredPendingCases = !isAll
                ? pendingCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : pendingCases

            const isAllFilteredOverdueCases = !isAll
                ? overdueCases.filter(
                    ({ assignedTo }) => assignedTo.some(arrVal => arrVal?.id === Number(localStorage.userID))
                )
                : overdueCases

            setOverdueCases(isAllFilteredOverdueCases);
            setPendingCases(isAllFilteredPendingCases)
            setClosedCases(isAllFilteredClosedCases);
        }
    }, [caseData, isAll, schema, setClosedCases, setOverdueCases, setPendingCases, tenants, querySchemas]);

    if (error || caseError) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    const onClick = (eventProp) => {
        setTypeFilter(eventProp);
        navigate(CASE_MANAGEMENT_ROUTE);
    }

		const onDownloadItem = async (item)=> {
			exportCases({cases: [item], name: 'Case'})
		}

    // const loadedPanelBodyClass = !loading && !caseLoading ? styles.panelBody : '';

    return (
        <ContentPanel className={`${className} ${styles.casePanel}`}>
            <PanelBody className={styles.panelBody}>
                <PanelTitle className={styles.panelTitle} text={CASE} />

                <ViewAllLink
                    className={styles.viewAllLink}
                    onClick={onClick}
                    text="View All"
                    to={CASE_MANAGEMENT_ROUTE}
                />

                <AddCaseButton className={styles.addCaseButton} />

                <PanelBody
                    className={styles.subPanel}
                    isLoading={loading || (!isAll && [...closedCases, ...overdueCases, ...pendingCases].length === 0)}
                    isLoadingWithText={!isAll && [...closedCases, ...overdueCases, ...pendingCases].length === 0 ? 'You have not been assigned any cases.' : null}
                >

                    {isAll && <DonutPanel className={styles.donutPanel} items={types} onClick={onClick} />}

                    {!isAll && <CaseTable className={styles.table} closedCases={closedCases} dashboard={true} onDownloadItem={onDownloadItem} overdueCases={overdueCases} pendingCases={pendingCases} />}

                </PanelBody>
            </PanelBody>
        </ContentPanel>
    );
};

export default CasePanel;
