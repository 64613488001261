import TextWithLabel from 'components/TextWithLabel';

import useDateFormat from './hooks/useDateFormat';

const DateWithLabel = ({ className = '', date, text = '' }) => {
    const formattedDate = useDateFormat(date);

    return (
        <TextWithLabel
            className={className}
            label={text}
            text={formattedDate}
        />
    );
};

export default DateWithLabel;
