import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateGroupSupervisors } = state;

const { updateGroupSupervisorsAtom } = updateGroupSupervisors.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateAddGroupSupervisor(
  $groupId: Int!,
  $groupSupervisoryId: Int,
  $userId: Int
  ) {
    insert_${tenantSchema}_group_supervisor(
      objects: {
        group_id: $groupId
        supervisory_group_id: $groupSupervisoryId,
        user_id: $userId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const addGroupSupervisor = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const addedGroupSupervisor = res?.data[`insert_${usedSchema}_group_supervisor`].returning[0]?.name;

            set(updateGroupSupervisorsAtom, addedGroupSupervisor);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateGroupSupervisorsAtom, '');
    });

    return [useRecoilValue(updateGroupSupervisorsAtom), addGroupSupervisor, reset];
};

export default useData;
