import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

const { caseDetailsPanel } = state;

const { updateCreatedByAtom } = caseDetailsPanel.atoms;

const MUTATE = tenantSchema => gql`
mutation deleteLogUsersInvolved($logId: Int!) {
    delete_${tenantSchema}_log_user_involved(where: {log_id: {_eq: $logId}}) {
      returning {
        id
      }
    }
  }
`;


const MUTATE_UPDATE = tenantSchema => gql`
mutation mutateLogUsersInvolved($userId: Int!, $logId: Int!) {
    insert_${tenantSchema}_log_user_involved(objects: {log_id: $logId, user_id: $userId}) {
        returning {
          id
        }
      }
    }
`;

const useData = (providedSchema) => {
    const mutationDelete = useMutation(MUTATE(providedSchema));
    const mutationInsert = useMutation(MUTATE_UPDATE(providedSchema));

    const saveCreatedBy = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
              await mutationDelete(variables);

              if(variables?.users.length > 0){
                var res = {};
                for(const userId of variables?.users){
                    res = await mutationInsert({...variables, userId});
                }
                const newUsersInvolved = res?.data[`insert_${providedSchema}_log_user_involved`].returning[0]?.id;

                set(updateCreatedByAtom, newUsersInvolved);
            }else{
                set(updateCreatedByAtom, variables?.changeUserId);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateCreatedByAtom, '');
    });

    return [useRecoilValue(updateCreatedByAtom), saveCreatedBy, reset];
};

export default useData;
