import { useEffect, useState } from 'react';

import PanelBody from 'components/PanelBody';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import arraysEqual from 'utilities/arraysEqual';

const CheckMissingPermissions = ({ permission }) => {
    const { usePermissions } = useGlobalStateHooks();

    const [permissions] = usePermissions();
    const [updatedPermissions, setUpdatedPermissions] = useState(permissions);

    useEffect(() => {
        if(!arraysEqual(updatedPermissions, permissions)){
            setUpdatedPermissions(permissions)
        }
    // eslint-disable-next-line
    }, [permissions, setUpdatedPermissions]);

    const testPermission = Array.isArray(permission) ? permission : [permission]
    const permissionIntersection = testPermission.filter(element => updatedPermissions.includes(element));
    const notPermitted = permissionIntersection.length === 0;
    
    if(notPermitted){
        return (
            <PanelBody isLoadingWithText={`Loading Data ...  If you require additional access, please contact your administrator.`} />
        );
    }else{
        return (
            <></>
        );
    }
};

export default CheckMissingPermissions;
