import { atom, /*atomFamily*/ } from 'recoil';

const groupDetailsPanelAtom = atom({
    default: '',
    key: 'groupDetailsPanel'
});

const updateGroupDescriptionAtom = atom({
    default: '',
    key: 'updateGroupDescription'
});

// const updateGroupMembersAtom = atom({
//     default: '',
//     key: 'updateGroupMembers'
// });

const updateGroupNameAtom = atom({
    default: '',
    key: 'updateGroupName'
});

const updateGroupSupervisorsAtom = atom({
    default: '',
    key: 'updateGroupSupervisors'
})

// const groupDetailsPanelDropdownsAtomFamily = atomFamily({
//     default: {
//         id: undefined,
//         options: [],
//         selectedIndex: undefined,
//         title: ''
//     },
//     key: ''
// });

const state = {
    // atomFamilies: {
    //     groupDetailsPanelDropdownsAtomFamily
    // },

    atoms: {
        groupDetailsPanelAtom,
        updateGroupDescriptionAtom,
        // updateGroupMembersAtom,
        updateGroupNameAtom,
        updateGroupSupervisorsAtom
    }
};

export default state;
