import LogsReportPanel from 'components/LogsReportPanel';
import WithPageTitle from 'components/WithPageTitle';

import styles from './styles.module.scss';

const Reports = () => (
    <div className={styles.reports}>
        <WithPageTitle className={styles.head} title="Log Report" />

        <LogsReportPanel className={styles.reportsPanel} />
    </div>
);

export default Reports;
