import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateLogEntryForm from 'components/CreateLogEntryForm';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AddLogEntryModal = ({ onClose, onSave }) => {
    const { CREATE_LOG_ENTRY } = useTranslation();

    const navigate = useNavigate();

    const { COMPLIANCE_LOG_ENTRY_ROUTE } = useConfig();
    const { useSchema, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [userId] = useUserId();

    const [_errorMessage, setErrorMessage] = useState();
    const [redirectSchema, setRedirectSchema] = useState(schema);

    const [createdLogEntry, createLogEntry, reset] = useData();

    const handleModalClose = useCallback(
        event => isFunction(onClose) && onClose(event),
        [onClose]
    );

    const handleSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                handleModalClose();

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    createdBy: Number(userId)
                }
            );

            formObject.dateOfOccurrence = new Date(formObject.dateOfOccurrence);
            formObject.logCategory = Number(formObject.logCategory);
            formObject.selectedTenant = formObject.selectedTenant ? formObject.selectedTenant : schema;

            setRedirectSchema(formObject.selectedTenant)

            createLogEntry(formObject);
        },
        [createLogEntry, handleModalClose, schema, userId]
    );

    useEffect(() => {
        if (!createdLogEntry) {
            return;
        }
        if (isFunction(onSave)) {
            onSave(createdLogEntry);
        }
        navigate(
            COMPLIANCE_LOG_ENTRY_ROUTE.replace(':entryId', createdLogEntry).replace(':logSchema', redirectSchema)
        );

        reset();

        handleModalClose();
    }, [
        COMPLIANCE_LOG_ENTRY_ROUTE,
        createdLogEntry,
        handleModalClose,
        navigate,
        onSave,
        redirectSchema,
        reset
    ]);

    return (
        <Modal isOpen onClose={handleModalClose}>
            <PanelBody>
                <PanelTitle
                    className={styles.panelTitle}
                    text={CREATE_LOG_ENTRY}
                />

                <CreateLogEntryForm
                    onCancel={handleModalClose}
                    onSave={handleSave}
                />
            </PanelBody>
        </Modal>
    );
};

export default AddLogEntryModal;
