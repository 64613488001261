import { useEffect, useState } from 'react';

import AdministrationCaseTypesTable from 'components/AdministrationCaseTypesTable';
import AdministrationPanel from 'components/AdministrationPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';

import normalizeData from './data';

const AdministrationActivityTypesTab = ({ className = '' }) => {
    console.debug('[AdministrationCasesTypesTab]');

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { loading, error, data } = useData();
    
    const [caseTypes, setCaseTypes] = useState();

    const [sortBy, setSortBy] = useState(null);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });
            if (normalizedData) {
                setCaseTypes(normalizedData);
            };
        };
    }, [data, schema, setCaseTypes])

    if (loading) {
        return `Loading...`;
    }
    if (error) {
        return `Error! ${error}`;
    };

    return (
        <AdministrationPanel className={className}>
            <AdministrationCaseTypesTable
                items={caseTypes}
                onSort={setSortBy}
                sortBy={sortBy}
            />
        </AdministrationPanel>
    );
};

export default AdministrationActivityTypesTab;
