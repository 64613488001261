import AsideLink from 'components/AsideLink';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const AddFormRow = ({ className = '', onClick = '', text = '+ Add Form' }) => (
    <Buttonize className={styles.addForm} onClick={onClick}>
        <AsideLink
            className={className}
            text={text}
        />
    </Buttonize>
);

export default AddFormRow;
