import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';
import sendMarketingDeniedEmail from 'utilities/sendMarketingDeniedEmail';

const { requestRejectModal } = state;

const { requestRejectModalAtom } = requestRejectModal.atoms;

const MUTATE_VERSION = tenantSchema => gql`
mutation updateVersion(
  $requestId: Int!,
  $status: String = "rejected"
  ) {
    update_${tenantSchema}_marketing_request(_set: {status: $status}, where: {id: {_eq:$requestId}}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_REQUEST = tenantSchema => gql`
mutation updateRequest(
  $versionId: Int!,
  $status: String = "rejected"
  ) {
    update_${tenantSchema}_marketing_request_version(_set: {status: $status}, where: {id: {_eq:$versionId}}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT_VERSION_HISTORY = tenantSchema => gql`
mutation insertVersionHistory(
        $color: String = "red",
        $message: String = "rejected the request",
        $userId: Int!,
        $versionId: Int!
    ) {
    insert_${tenantSchema}_marketing_request_version_history(
        objects: {
            color: $color,
            message: $message,
            user_id: $userId,
            version_id: $versionId
        }) {
      returning {
        id
      }
    }
  }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_VERSION, providedSchema);
    const mutationUpdateRequest = useGraphQL(MUTATE_REQUEST, providedSchema);
    const mutationInsertVersionHistory = useGraphQL(MUTATE_INSERT_VERSION_HISTORY, providedSchema);

    const { API_KEY } = useConfig();
    const { useSchema, useSchemaDescription } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [schemaDescription] = useSchemaDescription();
    const usedSchema = providedSchema ? providedSchema : schema;
    const usedSchemaDescription = providedSchema ? providedSchema : schemaDescription;

    const requestUpdate = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            await mutation(variables);

            await mutationUpdateRequest(variables)

            await mutationInsertVersionHistory(variables);

            sendMarketingDeniedEmail(variables?.requestId, variables?.requestName, null, usedSchema, usedSchemaDescription, variables?.createdById, API_KEY ? API_KEY : '')

            set(requestRejectModalAtom, 'complete');
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(requestRejectModalAtom, '');
    });

    return [useRecoilValue(requestRejectModalAtom), requestUpdate, reset];
};

export default useData;
