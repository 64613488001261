import AsideText from 'components/AsideText';
import Input from 'components/Input';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdminMasterEmailAddress = ({ email = '' }) => {
    const { MASTER_EMAIL_ADDRESS } = useTranslation();

    return (
        <div className={styles.adminMasterEmailAddress}>
            <WithLabel text={MASTER_EMAIL_ADDRESS}>
                <Input className={styles.input} value={email} />
            </WithLabel>

            <AsideText text="Lorem ipsum dorem sit amet lorem ipsum dorem sit amet" />
        </div>
    );
};

export default AdminMasterEmailAddress;
