import ActionMenuItem from 'components/ActionMenuItem';
import CloseIcon from 'components/icons/CloseIcon';

import useTranslation from './hooks/useTranslation';

const CloseMenuListItem = ({ onClick }) => {
    const { CLOSE } = useTranslation();

    return (
        <ActionMenuItem icon={<CloseIcon />} onClick={onClick} text={CLOSE} />
    );
};

export default CloseMenuListItem;
