import { atom } from 'recoil';

const updateActivityEntityAtom = atom({
    default: "",
    key: 'updateActivityEntity'
});

const updateActivityUserAtom = atom({
    default: "",
    key: 'updateActivityUser'
});

const updateEndDateAtom = atom({
    default: "",
    key: 'updateEndDate'
});

const updateHierarchyAtom = atom({
    default: "",
    key: 'updateHierarchy'
});

const updateSendEmailAtom = atom({
    default: "",
    key: 'updateSendEmail'
});

const updateStartDateAtom = atom({
    default: "",
    key: 'updateStartDate'
});

const state = {
    atoms: {
        updateActivityEntityAtom,
        updateActivityUserAtom,
        updateEndDateAtom,
        updateHierarchyAtom,
        updateSendEmailAtom,
        updateStartDateAtom
    }
};

export default state;
