import { memo } from 'react';

import ActivityForm from 'components/ActivityForm';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

const EditActivityDetailsPanel = ({ className = '', form }) => (
    <ContentPanel className={`${className} ${styles.editActivityDetailsPanel}`}>
        <PanelBody className={styles.panelBody}>
            <ActivityForm form={form} />
        </PanelBody>
    </ContentPanel>
);

export default memo(EditActivityDetailsPanel);
