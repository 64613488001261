import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AddAssignee from 'components/AddAssignee';
import AddReviewer from 'components/AddReviewer';
import ContentPanel from 'components/ContentPanel';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import DropdownWithTags from 'components/DropdownWithTags';
import ImportantLinkInNewTab from 'components/ImportantLinkInNewTab';
import PanelBody from 'components/PanelBody';
// import TextInput from 'components/TextInput';
import ToggleWithLabel from 'components/ToggleWithLabel';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useActivityId from './hooks/useActivityId';
// import useCaseId from './hooks/useCaseId';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
// import useUsers from './hooks/useUsers';
import useEntities from './hooks/useEntities';

import styles from './styles.module.scss';

// import normalizeUserData from './dataUsers';
import normalizeEntityData from './dataEntities';
import isFunction from 'utilities/isFunction';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import CollapsiblePanelWithChildren from 'components/CollapsiblePanelWithChildren';

const AddActivityMetaPanel = ({ 
    addReviewer, 
    assignees, 
    className = '', 
    form, 
    setAddReviewer, 
    setAssignees,
    setSelectedEntities
}) => {
    const { ACTIVITY_ROUTE, CASE_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();

    const navigate = useNavigate();

    const { useConsultancyUsers, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [consultancyUsers] = useConsultancyUsers();
    const [activityLink, setActivityId] = useActivityId();
    // const [caseLink, setCaseId] = useCaseId();
    const [entities, setEntities] = useEntities();
    const [userSetSchema] = useUserSetSchema();

    const { DUE_DATE, FIRST_ASSIGNEE, REVIEWER, START_DATE } = useTranslation();

    const { handleError, handleSubmit, handleValid } = form;

    const handleActivity = () => {
        if (isFunction(setActivityId)) {
        }
    };

    const handleCase = () => {
        if (isFunction(setActivityId)) {
        }
    };

    const handleAssignee = useCallback(() => {
        const mutatedAssignees = [...assignees];

        mutatedAssignees.push({});

        setAssignees(mutatedAssignees);
    }, [assignees, setAssignees]);

    const handleClick = useCallback(() => {
        setAddReviewer(true);
    }, [setAddReviewer]);

    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            // const normalizedUserData = normalizeUserData({ data, schema, tenants });
            const normalizedEntityData = normalizeEntityData({ data, schema, tenants });

            // setUsers(normalizedUserData);
            setEntities(normalizedEntityData);
        }
    }, [data, schema, setEntities, tenants]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    if (!consultancyUsers.filter(item => item.tenants.includes(userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema))) {
        return null;
    }

    return (
        <ContentPanel className={`${className} ${styles.addActivityMetaPanel}`}>
            <PanelBody className={styles.panelBody} loading={loading}>
                <DatePicker
                    isRequired={true}
                    label={START_DATE}
                    onChange={handleValid('start-date')}
                />

                <DatePicker
                    isRequired={true}
                    label={DUE_DATE}
                    onChange={handleValid('due-date')}
                />

                <DropdownWithTags
                    className={styles.dropdownWithTags}
                    items={consultancyUsers.filter(item => item.tenants.includes(userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema))}
                    label={FIRST_ASSIGNEE}
                    onChange={handleValid('assignee-1')}
                    tags={[]}
                />

                <DropdownWithTags
                    className={styles.dropdownWithTags}
                    items={consultancyUsers.filter(item => item.tenants.includes(userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema))}
                    label="Assignee 2"
                    onChange={handleValid('assignee-2')}
                    tags={[]}
                />

                {assignees.map((assignee, index) => (
                    <DropdownWithTags
                        className={styles.dropdownWithTags}
                        items={consultancyUsers.filter(item => item.tenants.includes(userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema))}
                        key={`assignee-${index}`}
                        label={`Assignee ${index + 3}`}
                        onChange={handleValid(`assignee-${index + 3}`)}
                        tags={[]}
                    />
                ))}

                <AddAssignee onClick={handleAssignee} />

                {!addReviewer && <AddReviewer onClick={handleClick} />}

                {addReviewer && (
                    <DropdownWithTags
                        className={styles.dropdownWithTags}
                        items={consultancyUsers.filter(item => item.tenants.includes(userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema))}
                        label={REVIEWER}
                        onChange={handleValid('reviewer')}
                    />
                )}

                <DropdownMultiSelect
                    className={styles.dropdown}
                    items={entities}
                    label="Entity"
                    onChange={setSelectedEntities}
                />

                {/* <TextInput
                    className={styles.textInput}
                    label="Reminder Frequency"
                    onChange={handleValid('reminder-frequency')}
                    placeholder="Numeric"
                    type="number"
                />

                <TextInput
                    className={styles.textInput}
                    label="Overdue Frequency"
                    onChange={handleValid('overdue-frequency')}
                    placeholder="Numeric"
                    type="number"
                /> */}

                <Dropdown
                    className={styles.dropdown}
                    items={[
                        {label: "None", selected: true, value: "none"},
                        {label: "Annually", value: "annually"},
                        {label: "Semi-Annual", value: "semi_annual"},
                        {label: "Quarterly", value: "quarterly"},
                        {label: "Monthly", value: "monthly"},
                        {label: "Bi-Weekly", value: "biweekly"},
                        {label: "Weekly", value: "weekly"},
                        {label: "Daily", value: "daily"},
                    ]}
                    label="Recurrence"
                    onError={handleError('recurrence')}
                    onSubmit={handleSubmit('recurrence')}
                    onValid={handleValid('recurrence')}
                />

                <CollapsiblePanelWithChildren className={styles.advancedSettings} id={'advanced_settings'} label={'Advanced Settings'}>
                    <ToggleWithLabel
                        className={styles.toggleWithLabel}
                        label="Hierarchical"
                        onToggle={handleValid('hierarchical')}
                        sublabel="Should Assignee Groups Complete this Activity in Order?"
                    />

                    <ToggleWithLabel
                        className={styles.toggleWithLabel}
                        label="Send Email Updates on Activity"
                        onToggle={handleValid('initial-email')}
                    />
                </CollapsiblePanelWithChildren>

                {activityLink.activityId &&
                <div onClick={handleActivity}>
                    <ImportantLinkInNewTab 
                        className={activityLink?.activityId ? styles.linkInNewTab : ''}
                        openNewTab={activityLink?.activityId} 
                        text={`${activityLink?.activityName}`}
                        to={ACTIVITY_ROUTE.replace(':activityId', activityLink?.activityId).replace(':activitySchema', schema)}
                    />
                </div>
                }

                {activityLink.caseId &&
                <div onClick={handleCase}>
                    <ImportantLinkInNewTab 
                        className={activityLink?.caseId ? styles.linkInNewTab : ''}
                        openNewTab={activityLink?.caseId} 
                        text={`${activityLink?.caseName}`}
                        to={CASE_ROUTE.replace(':caseId', activityLink?.caseId).replace(':activitySchema', schema)}
                    />
                </div>
                }

            </PanelBody>
        </ContentPanel>
    );
};

export default AddActivityMetaPanel;
