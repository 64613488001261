import { useEffect } from 'react';

import ContentPanel from 'components/ContentPanel';
import FormTable from 'components/FormTable';
import PanelBody from 'components/PanelBody';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useForms from './hooks/useForms';
import useData from './hooks/useData';

import normalizeData from './data';
import Loader from 'components/Loader';

const FormsManagementPanel = ({ className = '' }) => {
    const { useTenants, useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const [forms, setForms] = useForms();
    const { loading, error, data } = useData();

    useEffect(() => {
        const selectedTenant = tenants && tenants.length === 1 ? tenants[0] : schema;
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema: selectedTenant
            });

            setForms(normalizedData);
        }
    }, [data, schema, setForms]);

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <ContentPanel className={`${className} ${styles.formsManagementPanel}`}>
            <PanelBody className={styles.panelBody} isLoading={loading}>
                {tenants && tenants.length===1 && <FormTable className={styles.table} items={forms} />}

                {tenants && tenants.length!==1 && <Loader text={'Please select only one tenant to view a list of forms.'} />}
            </PanelBody>
        </ContentPanel>
    );
};

export default FormsManagementPanel;
