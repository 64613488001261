import DonutChart from 'components/DonutChart';
import Legend from 'components/Legend';
import Loader from 'components/Loader';

import styles from './styles.module.scss';

const DonutPanel = ({ className = '', items, onClick }) => {
    return (
        <>
            {(Array.isArray(items) && items.length > 0) && (
                <div className={`${className} ${styles.donutPanel}`}>
                    <DonutChart className={styles.donutChart} items={items} onClick={onClick} />

                    <Legend className={styles.legend} items={items} onClick={onClick} />
                </div>
            )}

            {!(Array.isArray(items) && items.length > 0) && (
                <Loader className={`${styles.donutLoading}`} text={'There are no cases to display at this time'} />
            )}
        </>
    );
};

export default DonutPanel;
