import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router-dom';

export function initSentry() {
    if (process.env.REACT_APP_SENTRY_DSN) {
        const environment =
            process.env.REACT_APP_RUNTIME_ENVIRONMENT ?? 'local';

        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: environment,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    createRoutesFromChildren,
                    matchRoutes,
                    useEffect,
                    useLocation,
                    useNavigationType
                }),
                Sentry.replayIntegration()
            ],
            replaysOnErrorSampleRate: 1.0,
            replaysSessionSampleRate: 0
        });
    }
}
