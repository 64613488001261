import { createRef, useEffect, useState } from 'react';
import { Link as OriginalLink } from 'react-router-dom';
import WithTooltip from 'components/WithTooltip';

import styles from './styles.module.scss';

const ImportantLink = ({ className = '', newTab, text, to }) => {
    const ref = createRef();
    const [overflow, setOverflow] = useState(false);
    const linkTo = /^\//.test(to) ? to : { pathname: to };

    useEffect(() => {
        if (ref?.current?.clientWidth < ref?.current?.scrollWidth) {
            setOverflow(true);
        }
    }, [ref]);
    return (
        <OriginalLink
            className={`${className} ${styles.importantLink}`}
            ref={ref}
            target={newTab ? '_blank' : undefined}
            to={linkTo}
        >
            {overflow ? <WithTooltip
                className={`${className} ${styles.importantLink}`}
                text={text}
            >
                {text}
            </WithTooltip>
            : text
            }
        </OriginalLink>
    );
};

export default ImportantLink;