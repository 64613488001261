import Pill from 'components/Pill';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

const HighRiskPill = ({ className = '' }) => {
    const { HIGH } = useTranslation();

    return (
        <Pill
            className={`${className} ${styles.highRiskPill}`}
            label={HIGH}
        />
    );
};

export default HighRiskPill;
