import { useCallback, useEffect, useState } from 'react';

import Attachments from 'components/Attachments';
// import ActivityQuestions from 'components/ActivityQuestions';
import AddActivityQuestions from 'components/AddActivityQuestions';
import Comments from 'components/Comments';
import ContentPanel from 'components/ContentPanel';
import Dropdowns from 'components/Dropdowns';
// import EditableContentBox from 'components/EditableContentBox';
import EditableMultiSelectWithLabel from 'components/EditableMultiSelectWithLabel';
import FileUpload from 'components/FileUpload';
import ImportantLink from 'components/ImportantLink';
import ImportantLinkInNewTab from 'components/ImportantLinkInNewTab';
// import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import RiskPill from 'components/RiskPill';
import PanelBody from 'components/PanelBody';
// import SectionWithBorder from 'components/SectionWithBorder';
import StandoutText from 'components/StandoutText';
import WithLabel from 'components/WithLabel';
import WithTooltip from 'components/WithTooltip';

import useConfig from 'hooks/useConfig';
// import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import {useComment} from './hooks/useComment';
import useData from './hooks/useData';
import useDropdowns from './hooks/useDropdowns';
import useTranslation from './hooks/useTranslation';
import usePolicies from './hooks/usePolicies';
import useInstructions from './hooks/useInstructions';
import useActivityName from './hooks/useActivityName';
import usePolicyReference from './hooks/usePolicyReference';
// import usePolicies from './hooks/usePolicies';
import useRegulatoryReferences from './hooks/useRegulatoryReferences';
import useUpdatedRegulatoryReferences from './hooks/useUpdatedRegulatoryReferences';

import styles from './styles.module.scss';
import EditableTextWithLabel from 'components/EditableTextWithLabel';
import normalizePolicyData from './dataPolicies';
import normalizeRegulatoryReferencesData from './dataRegulatoryReferences';
import CheckPermissionsAlternateElement from 'components/CheckPermissionsAlternateElement';
import TextWithLabel from 'components/TextWithLabel';
import ContentBox from 'components/ContentBox';
import CheckPermissions from 'components/CheckPermissions';
import EditableEditor from 'components/EditableEditor';
import WithTooltipHtml from 'components/WithTooltipHtml';
import truncateString from 'utilities/truncateString';
import updateActivities from 'utilities/updateActivities';

const ActivityDetailsPanel = ({
    activitySchema,
    // allAnswers,
    // allForms,
    answers,
    attachments,
    attachmentsOnDelete,
    attachmentsOnUpdate,
    className = '',
    closed,
    comments = [],
    dropdownSelections,
    dropdowns,
    forms = {},
    handleFileUpload,
    id,
    instructions,
    isCreatingPDF,
    name,
    otherAnswers,
    otherForms,
    policyReference,
    riskLabel,
    type,
    // userGroup
}) => {
    const { POLICY_ROUTE } = useConfig();

    const { useActivities, usePermissions, useTenants, useUserId } = useGlobalStateHooks();

    const [activities, setActivities] = useActivities();
    const [permissions] = usePermissions();
    const [userId] = useUserId();
    const [tenants] = useTenants();

    const [policies, setPolicies] = usePolicies();
    const [regulatoryReferencesOptions, setRegulatoryReferencesOptions] = useRegulatoryReferences();


    const {
        ACTIVITY_NAME,
        ACTIVITY_TYPE,
        INSTRUCTIONS,
        POLICY_REFERENCE,
        // REGULATORY_REFERENCE,
        // RISK_LEVEL_ACTIVITY
    } = useTranslation();

    const { ___, data } = useData();

    const [savedInstructions, saveInstructions, reset] = useInstructions(activitySchema);
    const [savedActivityName, saveActivityName, resetActivityName] = useActivityName(activitySchema);
    const [savedPolicyReference, savePolicyReference, resetPolicyReference] = usePolicyReference(activitySchema);
    const [savedRegulatoryReferences, /*saveRegulatoryReferences*/, resetRegulatoryReferences] = useUpdatedRegulatoryReferences(activitySchema);
    const [activityComments, setActivityComments] = useState(comments);

    const [_errorMessage, setErrorMessage] = useState();
    const [displayedInstructions, setInstructions] = useState();
    const [displayedActivityName, setActivityName] = useState();
    const [displayedPolicyReference, setPolicyReference] = useState(policyReference);

    const [_, createComment, deleteComment, updateComment] = useComment(
        id,
        activitySchema
    );
    const [__, updateDropdown] = useDropdowns(dropdowns);

    if(!displayedInstructions){
        setInstructions(instructions);
    }

    if(!displayedActivityName){
        setActivityName(name);
    }

    useEffect(() => {
        if (data) {
            const normalizedPolicyData = normalizePolicyData({ data, schema:activitySchema, tenants });
            const normalizedRegulatoryReferencesData = normalizeRegulatoryReferencesData({ data, schema:activitySchema, tenants });

            setPolicies([].concat.apply([], normalizedPolicyData));
            setRegulatoryReferencesOptions(normalizedRegulatoryReferencesData);
        }
    }, [data, activitySchema, setPolicies, setRegulatoryReferencesOptions, tenants]);

    // const form = useForm({
    //     riskLevel: riskLabel,
    // });
    // const { handleValid } = form;

    const handleCommentSubmit = useCallback(
        async formData => {
            const addedComment = formData.get('comment');

            const commentId = await createComment(id, addedComment);

            const commentDate = new Date();

            const newComment = {
                content: addedComment,
                createdAt: commentDate.toISOString().slice(0, -1),
                id: commentId,
                user: {
                    id: userId,
                    name: `${localStorage.firstName} ${localStorage.lastName}`
                }
            };

            const newComments = [...activityComments, newComment].sort(
                (objA, objB) => (objA.createdAt < objB.createdAt ? 1 : -1)
            );

            setActivityComments(newComments);
        },
        [activityComments, createComment, id, userId]
    );

    const handleDeleteComment = async commentId => {
        await deleteComment(commentId);
        setActivityComments(
            activityComments.map(comment =>
                comment.id === commentId
                    ? { ...comment, deleted: true }
                    : comment
            )
        );
    };

    const handleUpdateComment = async params => {
        const { commentId, newText } = params;
        await updateComment(commentId, newText);
        setActivityComments(
            activityComments.map(comment =>
                comment.id === commentId
                    ? { ...comment, content: newText }
                    : comment
            )
        );
    };

    const handleDropdownsChange = useCallback(
        ({
            changedDropdownId,
            changedDropdownIndex,
            dropdownSelection,
            formData,
            selectedIndex
        }) => {
            updateDropdown({
                activityId: id,
                changedDropdownId,
                changedDropdownIndex,
                dropdownSelection,
                formData,
                selectedIndex
            });
        },
        [id, updateDropdown]
    );

    const handleInstructionsSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: id,
                    userId: userId
                }
            );

            saveInstructions(formObject);
        },
        [id, saveInstructions, userId]
    );

    useEffect(() => {
        if (!savedInstructions) {
            return;
        }
        setInstructions(savedInstructions);
        reset();
        //eslint-disable-next-line
    }, [reset, savedInstructions, setInstructions, setActivities]);

    const handleActivityNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: id,
                    userId: userId
                }
            );

            saveActivityName(formObject);
        },
        [id, saveActivityName, userId]
    );

    useEffect(() => {
        if (!savedActivityName) {
            return;
        }
        updateActivities(activities, setActivities, id, 'name', savedActivityName);
        setActivityName(savedActivityName);
        resetActivityName();
        window.location.reload();
        //eslint-disable-next-line
    }, [resetActivityName, savedActivityName, setActivityName, setActivities]);

    const handlePolicyReferenceSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: id,
                    userId: userId
                }
            );
            formObject['policies'] = JSON.parse(formObject.selected)

            savePolicyReference(formObject);
        },
        [id, savePolicyReference, userId]
    );

    useEffect(() => {
        if (!savedPolicyReference) {
            return;
        }

        setPolicyReference(savedPolicyReference);
        resetPolicyReference();
    }, [resetPolicyReference, savedPolicyReference, setPolicyReference]);

    // const handleRegulatoryReferenceSave = useCallback(
    //     ({ form }) => {
    //         const { handleForm } = form;

    //         const output = handleForm();

    //         if (typeof output === 'string') {
    //             setErrorMessage(output);

    //             return;
    //         }

    //         const formObject = Array.from(output.entries()).reduce(
    //             (accumulator, [key, value]) => {
    //                 accumulator[key] = value;

    //                 return accumulator;
    //             },
    //             {
    //                 activityId: id,
    //                 userId: userId
    //             }
    //         );
    //         formObject['references'] = JSON.parse(formObject.selected)

    //         saveRegulatoryReferences(formObject);
    //     },
    //     [id, saveRegulatoryReferences, userId]
    // );

    useEffect(() => {
        if (!savedRegulatoryReferences) {
            return;
        }

        const filteredSelectedRegulatoryReferences = [];
        for (const selected of (savedRegulatoryReferences ?? [])) {
            filteredSelectedRegulatoryReferences.push(regulatoryReferencesOptions?.filter(reference => reference.value === selected)[0]);
        };

        resetRegulatoryReferences();
    }, [regulatoryReferencesOptions, resetRegulatoryReferences, savedRegulatoryReferences]);

    useEffect(() => {console.log(displayedInstructions)},[displayedInstructions])

    useEffect(() => {console.log(displayedActivityName)},[displayedActivityName])

    const otherFormsWithAnswers = (Array.isArray(otherForms) && otherForms.length > 0)
        ? otherForms.reduce((accumulator, form) => {
        var {assignment, assignment_type, formId, formName, questions:questionsWithoutAnswers} = form;
        const questions = questionsWithoutAnswers.reduce((accumulator, question) => {
            const {choices, formComponent, id, index, name, questionId, required, type} = question;
            const answerQuestions = otherAnswers['questions'].filter(function (e){return e.id === id;})[0]?.answers;
            const answer = answerQuestions[answerQuestions.length - 1]?.questionAnswer;
            const answerUser = answerQuestions[answerQuestions.length - 1]?.answerUser;
            accumulator.push({
                answer,
                answerUser,
                choices,
                formComponent,
                id,
                index,
                name,
                questionId,
                required,
                type
            })
            return accumulator;
        },[])
        accumulator.push({
            assignment,
            assignment_type,
            formId,
            formName,
            questions
        })
        return accumulator;
    },[]) : [];

    const questionsWithAnswers = (Array.isArray(forms?.questions) && forms?.questions.length > 0) ? forms?.questions.reduce((accumulator, question) => {
        const {choices, formComponent, id, index, name, questionId, required, type} = question;
        const answerQuestions = answers?.questions.filter(function (e){return e.id === id;})[0]?.answers;
        const answer = answerQuestions[answerQuestions.length - 1]?.questionAnswer;
        const answerUser = answerQuestions[answerQuestions.length - 1]?.answerUser;
        accumulator.push({
            answer,
            answerUser,
            choices,
            formComponent,
            id,
            index,
            name,
            questionId,
            required,
            type
        })
        return accumulator
    },[]) : [];

    const canEditAttachments = (permissions ?? []).includes('activity_update');

    return (
        <ContentPanel className={`${className} ${styles.activityDetailsPanel}`}>
            <PanelBody className={styles.panelBody}>
                <CheckPermissionsAlternateElement permission={['activity_update']}>
                    <EditableTextWithLabel
                        hasEdit={!closed}
                        label={ACTIVITY_NAME}
                        onSave={handleActivityNameSave}
                        text={displayedActivityName}
                    />

                    <TextWithLabel
                        label={ACTIVITY_NAME}
                        text={displayedActivityName}
                    />
                </CheckPermissionsAlternateElement>

                <CheckPermissionsAlternateElement permission={['activity_update']}>
                    <EditableMultiSelectWithLabel
                        className={styles.multipleWithLabel}
                        hasEdit={!closed}
                        items={policies}
                        label={POLICY_REFERENCE}
                        onSave={handlePolicyReferenceSave}
                        selected={(displayedPolicyReference ?? []).map(({ id }) =>(id))}
                        wrapperButtonize={styles.wrapperButtonize}
                    >

                        {displayedPolicyReference.length > 0
                        ?
                        displayedPolicyReference.map(policy => (
                            <>
                                <WithTooltipHtml text={policy?.description}>
                                    <ImportantLinkInNewTab
                                        className={policy?.id ? styles.policyReferenceWithLink : styles.policyReferenceWithoutLink}
                                        openNewTab={policy?.id}
                                        text={truncateString(policy.name)}
                                        to={POLICY_ROUTE.replace(':policyId', String(policy.id).split('.')[0]).replace(':policySchema', activitySchema)}
                                    />
                                </WithTooltipHtml>

                                <RiskPill
                                    className={styles.policyReferenceRiskPill}
                                    riskLevel={policy?.riskLevel}
                                />
                            </>
                        ))
                        :
                        <ImportantLink
                            className={styles.policyReferenceWithoutLink}
                            text={'-'}
                        />
                        }
                    </EditableMultiSelectWithLabel>

                    <WithLabel text={POLICY_REFERENCE}>
                        <div className={styles.multipleWithLabel}>
                            {displayedPolicyReference.length > 0
                            ?
                            displayedPolicyReference.map(policy => (
                                <>
                                    <WithTooltip text={policy?.description}>
                                        <ImportantLinkInNewTab
                                            className={policy?.id ? styles.policyReferenceWithLink : styles.policyReferenceWithoutLink}
                                            openNewTab={policy?.id}
                                            text={truncateString(policy.name)}
                                            to={POLICY_ROUTE.replace(':policyId', String(policy.id).split('.')[0]).replace(':policySchema', activitySchema)}
                                        />
                                    </WithTooltip>

                                    <RiskPill
                                        className={styles.policyReferenceRiskPill}
                                        riskLevel={policy?.riskLevel}
                                    />
                                </>
                            ))
                            :
                            <ImportantLink
                                className={styles.policyReferenceWithoutLink}
                                text={'-'}
                            />
                            }
                        </div>
                    </WithLabel>
                </CheckPermissionsAlternateElement>

                {/* <EditableMultiSelectWithLabel
                    // className={styles.multipleWithLabel}
                    items={regulatoryReferencesOptions}
                    label={REGULATORY_REFERENCE}
                    onSave={handleRegulatoryReferenceSave}
                    selected={(displayedRegulatoryReferences ?? [])?.map(({ value }) => value)}
                    // selected={displayedRegulatoryReferences}
                    wrapperButtonize={styles.wrapperButtonize}
                >
                {displayedRegulatoryReferences?.length > 0
                ?
                displayedRegulatoryReferences?.map(reference =>  (
                    <>
                        <WithTooltip text={reference?.label}>
                            <ImportantLinkInNewTab
                                // className={policy?.id ? styles.policyReferenceWithLink : styles.policyReferenceWithoutLink}
                                // openNewTab={policy?.id}
                                text={reference?.label}
                                // to={POLICY_ROUTE.replace(':policyId', policy.id.split('.')[0])}
                            />



                        </WithTooltip>

                        <RiskPill
                            className={styles.policyReferenceRiskPill}
                            riskLevel={policy?.riskLevel}
                        />
                    </>
                ))
                :
                <ImportantLink
                    className={styles.policyReferenceWithoutLink}
                    text={'-'}
                />
                }
                </EditableMultiSelectWithLabel> */}

                <WithLabel className={styles.withLabel} text={ACTIVITY_TYPE}>
                    <StandoutText text={type} />

                    <RiskPill
                        className={styles.policyReferenceRiskPill}
                        riskLevel={riskLabel}
                    />
                </WithLabel>

                <CheckPermissionsAlternateElement  permission={['activity_update']}>
                    <EditableEditor
                        className={styles.instructions}
                        hasEdit={!closed}
                        key={`${id}.description`}
                        onSave={handleInstructionsSave}
                        text={displayedInstructions}
                        title={INSTRUCTIONS}
                    />

                    <ContentBox
                        className={styles.instructions}
                        html={displayedInstructions}
                        key={`${id}.description`}
                        title={INSTRUCTIONS}
                    />
                </CheckPermissionsAlternateElement>

                <Dropdowns
                    className={styles.dropdowns}
                    hasEdit={!closed}
                    items={dropdowns}
                    onChange={handleDropdownsChange}
                    selections={dropdownSelections}
                />

                {forms && Object.keys(forms).length > 0 && (
                    <AddActivityQuestions
                        inactive={closed}
                        activityId={id}
                        assignmentType={forms.assignment_type}
                        className={styles.addQuestions}
                        formId={forms?.formId}
                        linkSchema={activitySchema}
                        questions={questionsWithAnswers}
                    />
                )}

                {otherFormsWithAnswers.map(
                    ({ assignment_type, formId, questions }) => {

                        return (
                        <AddActivityQuestions
                            activityId={id}
                            assignmentType={assignment_type}
                            className={styles.addQuestions}
                            formId={formId}
                            inactive={true}
                            linkSchema={activitySchema}
                            questions={questions}
                        />
                        )
                    }
                )}

                {/* {allForms && Object.keys(allForms).length > 0 &&
                    allAnswers?.questions.some(({answers}) => (answers.length > 0)) &&
                    (allAnswers?.assignment_type !== "1" || userGroup !== allAnswers?.assignment) &&
                    <ActivityQuestions
                        answers={allAnswers}
                        className={styles.activityQuestions}
                    />
                } */}

                {/* <SectionWithBorder className={styles.sectionWithBorder}>

                    <RiskLevelRadioButtons
                        className={styles.riskLevelRadioButtons}
                        label={RISK_LEVEL_ACTIVITY}
                        onChange={handleValid('riskLevel')}
                    />

                </SectionWithBorder> */}

                <Comments
                    className={styles.comments}
                    closed={closed}
                    commentType={'activity'}
                    commentTypeId={id}
                    commentTypeName={name}
                    comments={activityComments}
                    isCreatingPDF={isCreatingPDF}
                    onDelete={handleDeleteComment}
                    onSubmit={handleCommentSubmit}
                    onUpdate={handleUpdateComment}
                    usedSchema={activitySchema}
                    user={{
                        id: userId,
                        name: `${localStorage.firstName} ${localStorage.lastName}`
                    }}
                />

                {attachments && (
                    <Attachments
                        className={styles.attachments}
                        hasEdit={!closed}
                        hasEditPermission={canEditAttachments}
                        items={attachments}
                        providedSchema={activitySchema}
                        onDelete={attachmentsOnDelete}
                        onUpdate={attachmentsOnUpdate}
                    />
                )}


                <CheckPermissions permission={['activity_view']}>
                    <FileUpload
                        className={styles.fileUpload}
                        filesList={handleFileUpload}
                        persist={false}
                        providedSchema={activitySchema}
                    />
                </CheckPermissions>
            </PanelBody>
        </ContentPanel>
    );
};

export default ActivityDetailsPanel;
