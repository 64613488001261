import { atom } from 'recoil';

const flaggedActivityAtom = atom({
    default: '',
    key: 'flaggedActivity'
});

const unflaggedActivityAtom = atom({
    default: '',
    key: 'unflaggedActivity'
});

const state = {
    atoms: {
        flaggedActivityAtom,
        unflaggedActivityAtom
    }
};

export default state;
