import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionMenuItem from 'components/ActionMenuItem';
import CalendarIcon from 'components/icons/CalendarIcon';

import useConfig from 'hooks/useConfig';

import useActivityId from './hooks/useActivityId';
import useTranslation from './hooks/useTranslation';

const CreateActivityMenuListItemFromActivity = ({ activityId, activityName }) => {
    const [_, setActivityLinkId] = useActivityId();

    const { ADD_ACTIVITY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const { CREATE_AN_ACTIVITY } = useTranslation();

    const handleClick = useCallback(() => {
        setActivityLinkId({
            activityId: activityId,
            activityName: activityName
        });

        navigate(ADD_ACTIVITY_ROUTE);
    }, [activityId, activityName, ADD_ACTIVITY_ROUTE, navigate, setActivityLinkId]);

    return (
        <ActionMenuItem
            icon={<CalendarIcon />}
            onClick={handleClick}
            text={CREATE_AN_ACTIVITY}
        />
    );
};

export default CreateActivityMenuListItemFromActivity;