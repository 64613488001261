import { atom } from 'recoil';

const closeCaseMenuListItemAtom = atom({
    default: '',
    key: 'closeCaseMenuListItem'
});

const state = {
    atoms: {
        closeCaseMenuListItemAtom
    }
};

export default state;
