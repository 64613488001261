import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

export const useGetSchema = (providedSchema) => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();

    return providedSchema
        ? providedSchema
        : userSetSchema
        ? userSetSchema
        : tenants && tenants.length === 1
        ? tenants[0]
        : schema;
};
