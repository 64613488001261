import { useCallback, useState } from 'react';

import AddButton from 'components/AddButton';
import AddSectionModal from 'components/AddSectionModal';

import useTranslation from './hooks/useTranslation';

const AddSectionButton = ({ className = '' }) => {
    const { ADD_SECTION } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <AddButton
                className={className}
                onClick={handleClick}
                text={ADD_SECTION}
            />

            {isOpen && <AddSectionModal onClose={handleClose} />}
        </>
    );
};

export default AddSectionButton;
