import { atom } from 'recoil';

const sectionsListAtom = atom({
    default: [],
    key: 'sectionsList'
});

const state = {
    atoms: {
        sectionsListAtom
    }
};

export default state;
