import CreatableSelect from 'react-select/creatable';
import styles from './styles.module.scss';
import InputWithLabel from 'components/InputWithLabel';
import makeAnimated from 'react-select/animated';
import { useCallback } from 'react';
import isFunction from 'utilities/isFunction';

const DropdownMultiSelectWithLabel = ({ change, className, label, options=[], selectedOptions=[] }) => {
    const animatedComponents = makeAnimated();

    const dropdownStyle = {
        container: (base) => ({
            ...base,
            height: '100%'
        }),
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            height: '100%'
        })
    };

    const handleChange = useCallback(
        clickEvent => {
            if (isFunction(change)) {
                change(clickEvent);
            }
        },
        [change]
    );

    return (
        <InputWithLabel
            className={`${className} ${styles.textInput}`}
            isDisabled={false}
            text={label}
        >
            <CreatableSelect
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={selectedOptions}
                isClearable
                isMulti
                onChange={handleChange}
                options={options}
                styles={dropdownStyle}
            />
        </InputWithLabel>
    );
};

export default DropdownMultiSelectWithLabel;
