const translations = {
    activityName: 'Activity Name',
    activityType: 'Activity Type',
    instructions: 'Instructions',
    policyReference: 'Reference',
    regulatoryReference: 'Regulatory Reference',
    riskLevelActivity: 'Risk Level - Activity'
};

export default translations;
