import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const queryUsersAndEntities = tenantSchema => gql`
    query getUsersAndEntities {
        ${tenantSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
            tenant_user_id
            first_name
            last_name
        }
        ${tenantSchema}_entity {
            id
            name
        }
    }
`;

const useData = (caseSchema) => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = caseSchema ? caseSchema : schema;

    return useQuery(queryUsersAndEntities(usedSchema), { fetchPolicy: 'no-cache' });
};

export default useData;
