import { useNavigate } from 'react-router-dom';

import Buttonize from 'components/Buttonize';
import CalendarIcon from 'components/icons/CalendarIcon';
import CasesIcon from 'components/icons/CasesIcon';
import ConnectorIcon from 'components/icons/ConnectorIcon';
import ContentPanel from 'components/ContentPanel';
import DocumentsIcon from 'components/icons/DocumentsIcon';
import ImportantText from 'components/ImportantText';
import PanelBody from 'components/PanelBody';
// import SkematicIcon from 'components/icons/SkematicIcon';

import styles from './styles.module.scss';

import useConfig from 'hooks/useConfig';

const ReportsPanel = ({ className = '' }) => {
    const { ACTIVITIES_REPORTS, CASES_REPORTS, LOGS_REPORTS, POLICY_REPORTS, /*SKEMATIC_REPORT*/ } = useConfig();

    const navigate = useNavigate();

    const runPoliciesReport = () => {
        navigate(POLICY_REPORTS);
    }
    const runActivitiesReport = () => {
        navigate(ACTIVITIES_REPORTS);
    }
    const runCasesReport = () => {
        navigate(CASES_REPORTS);
    }
    const runLogsReport = () => {
        navigate(LOGS_REPORTS);
    }
    // const runSkematicReport = () => {
    //     navigate(SKEMATIC_REPORT);
    // }

    const buttons = [
        {icon: <DocumentsIcon className={styles.icon} />, report: runPoliciesReport, text: "Policies"},
        {icon: <CalendarIcon className={styles.icon} />, report: runActivitiesReport, text: "Activities"},
        {icon: <CasesIcon className={styles.icon} />, report: runCasesReport, text: "Cases"},
        {icon: <ConnectorIcon className={styles.icon} />, report: runLogsReport, text: "Logs"}
    ]

    return (
        <ContentPanel className={`${className} ${styles.reportsPanel}`}>
            <PanelBody className={styles.panelBody}>
                {buttons.map(({ icon, report, text }) => (
                    <Buttonize
                        className={`${className} ${styles.reportPanelButton}`}
                        onClick={report}
                    >
                        {icon}

                        <ImportantText className={styles.buttonText} text={text} />
                    </Buttonize>
                ))}

                {/* <Buttonize
                        className={`${className} ${styles.reportPanelButton} ${styles.fullSizeButton}`}
                        onClick={runSkematicReport}
                    >
                    <SkematicIcon className={`${styles.icon}`} />

                    <ImportantText className={styles.buttonText} text={"Skematic"} />
                </Buttonize> */}
            </PanelBody>
        </ContentPanel>
    );
};

export default ReportsPanel;
