import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
${tenantSchema}_activity(where: {_or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}]}) {
  id
  activityLinkagesByLinkActivityId {
    activity {
      closed
      deleted
      due_date
      is_recurring
      name
      policy_id
      risk_label
      start_date
    }
  }
  activity_attachments {
    name
    file
  }
  activity_entities {
    entity {
      name
    }
  }
  activity_forms(where: {
		current: {_eq:true}
	}) {
    form {
      name
      is_active
    }
  }
  activity_linkages {
    case {
      case_name
      case_type
    }
  }
  activity_policies {
    document {
      name
    }
  }
  activity_regulatory_references {
    regulatory_reference {
      name
      is_active
      risk_label
    }
  }
  activity_type {
    name
    risk_label
  }
  cases {
    case_name
    case_type
  }
  closed
  closed_date
  deleted
  due_date
  log_activities {
    log {
      log_type
      risk_label
    }
  }
  name
  risk_label
  start_date
  user_activities(where: {status: {_neq: "removed"}}) {
    status
    assignment_type
    group {
      name
      id
    }
    tenant_user {
      id
      user_${tenantSchema} {
        first_name
        last_name
      }
    }
  }
}
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
