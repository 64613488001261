import { useState, useEffect } from 'react'

import { ReactComponent as Logo } from './image.svg';

import { altText } from './constants';

import styles from './styles.module.scss';

const Loader = ({ className = '', text = null }) => {
    const fillColor = window.location.origin.includes('skematic') ? '#00AB55' : '#826AF9';
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timeId = setTimeout(() => {
          setShow(true)
        }, 3000)
    
        return () => {
          clearTimeout(timeId)
        }
    }, []);

    return (
    <div className={`${className} ${styles.loaderContainer}`}>
        <Logo
            alt={altText}
            className={`${styles.loader}`}
            fill={fillColor}
        />

        {text && show && <span className={`${styles.loaderText}`}>{text}</span>}
    </div>
    );
}

export default Loader;
