import { useCallback } from 'react';

import BodyText from 'components/BodyText';
import CheckPermissions from 'components/CheckPermissions';
import SlideToggle from 'components/SlideToggle';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const AllMeToggle = ({ className = '', onToggle }) => {
    const {useIsAllFilter} = useGlobalStateHooks();
    const [isAll, setIsAll] = useIsAllFilter();

    const { ALL, ME } = useTranslation();

    const handleToggle = useCallback(
        isOn => {
            if (isFunction(onToggle)) {
                setIsAll(isOn);
                onToggle(!isOn);
            }
        },
        [onToggle, setIsAll]
    );

    const onStyles = isAll ? styles.isActive : '';
    const offStyles = onStyles ? '' : styles.isActive;

    return (
        <CheckPermissions permission={'all'}>
            <div className={`${className} ${styles.allMeToggle}`}>
                <BodyText className={`${onStyles} ${styles.bodyText}`} text={ALL} />

                <SlideToggle isActive={isAll} onToggle={handleToggle} />

                <BodyText className={`${offStyles} ${styles.bodyText}`} text={ME} />
            </div>
        </CheckPermissions>
    );
};

export default AllMeToggle;
