import CountPanel from 'components/CountPanel';

import translations from './hooks/useTranslation';

import styles from './styles.module.scss';

const DoneCountPanel = ({ className = '', count = 0 }) => {
    const { DONE } = translations();

    return (
        <CountPanel
            className={`${className} ${styles.doneCountPanel}`}
            count={count}
            text={DONE}
        />
    );
};

export default DoneCountPanel;
