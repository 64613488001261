import formatDate from 'utilities/formatDate';
import { getSchemaDataName } from 'utilities/schema';

const resolvePolicies = activityPolicies =>
    activityPolicies.reduce((accumulator, { document: policy, section }) => {

        if (section == null){
            accumulator.push({description: policy?.description, id: policy?.id, name: policy?.name, riskLevel: policy?.risk_label});
        }else{
            accumulator.push({description: section?.description, id: policy?.id, name: section?.name, riskLevel: section?.riskLevel})
        }

        return accumulator;
    }, []);

const schemaData = ({ data, isFlagged, querySchemas, schema, user }) => data[getSchemaDataName(schema)].reduce(
    (accumulator, activity) => {
        const {
            activity_entities: activityEntities,
            activity_flags: activityFlags,
            activity_recurrences: activityRecurrences,
            activity_type: activityType,
            activity_policies: activityPolicies,
            closed,
            closed_date: activityClosedDate,
            due_date: activityDueDate,
            id: activityId,
            is_hierarchical: isHierarchical,
            name: activityName,
            start_date: activityStartDate,
            user_activities: userActivities,
            group_completion_rate: groupCompletionRate,
            risk_label: riskLabel
        } = activity;

        const usersWithFlag = activityFlags.map(a => a.user_id);
        const flagged=usersWithFlag.includes(parseInt(user));

        if (userActivities.length > 0) {
            const groups = userActivities.reduce(
                (accumulator, user) => {
                    const {
                        assignment_type: group,
                        status,
                        tenant_user: userDetails,
                        group: userGroup
                    } = user

                    if(userDetails || userGroup){
                        const { id } = (userDetails) ? userDetails : userGroup;
                        if(querySchemas.length > 0 && querySchemas[0] !== schema){
                            for(const tenant of querySchemas){
                                const userArray = (userDetails) && (`users_${tenant.value}` in userDetails) && userDetails[`users_${tenant.value}`].length > 0
                                ? userDetails[`users_${tenant.value}`][0] : (userGroup)
                                ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                {'first_name': '', 'last_name': '', 'profile_photo': ''}
                                const {
                                    first_name: firstName,
                                    last_name: lastName,
                                    profile_photo: profilePhoto
                                } = userArray;

                                const values = {
                                    firstName,
                                    id,
                                    lastName,
                                    profilePhoto,
                                    status,
                                }
                                if(values.firstName !== ''){
                                    if (typeof accumulator === 'object' && group in accumulator){
                                        accumulator[group].users.push(values);
                                    }else if(typeof accumulator === 'object'){
                                        accumulator[group] = {users: [values]};
                                    }else{
                                        accumulator = {};
                                        accumulator[group] = {users: [values]};
                                    }
                                }
                            }
                        }else{
                            const userArray = (userDetails) && (`users_${schema}` in userDetails) && userDetails[`users_${schema}`].length > 0
                            ? userDetails[`users_${schema}`][0] : (userGroup)
                            ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                            {'first_name': '', 'last_name': '', 'profile_photo': ''}

                            const {
                                first_name: firstName,
                                last_name: lastName,
                                profile_photo: profilePhoto
                            } = userArray;

                            const values = {
                                firstName,
                                id,
                                lastName,
                                profilePhoto,
                                status,
                            }
                            if (typeof accumulator === 'object' && group in accumulator){
                                accumulator[group].users.push(values);
                            }else if(typeof accumulator === 'object'){
                                accumulator[group] = {users: [values]};
                            }else{
                                accumulator = {};
                                accumulator[group] = {users: [values]};
                            }
                        }
                    }
                    return accumulator
                },{}
            );
            const userGroups = Object.keys(groups)
                            .sort()
                            .reduce(function (acc, key) {
                                acc[key] = groups[key];
                                return acc;
                            }, {})
            for(const [index, [key]] of Object.entries(Object.entries(userGroups))){
                const group = userGroups[key].users;
                var groupProgress
                if(groupCompletionRate && groupCompletionRate[index]){
                    if (typeof groupCompletionRate[index] === 'string' && groupCompletionRate[index] === 'all'){
                        groupProgress = group.some(
                            ({ status }) => status !== 'complete'
                        );
                    }else if (typeof groupCompletionRate[index] === 'number' && groupCompletionRate[index] > 1){
                        const complete = group.filter(({status}) => status === 'complete');
                        groupProgress = ((complete.length/group.length)*100) >= groupCompletionRate['index']
                    }else{
                        groupProgress = group.every(
                            ({ status }) => status !== 'complete'
                        );
                    }
                    userGroups[key]['status'] = ((groupProgress) ? 'inprogress' : 'complete')
                }
            }

            const users = userActivities.reduce(
                (accumulator, { assignment_type: assignmentType, status, tenant_user: tenantUser, group }) => {
                    const groupStatus = userGroups[assignmentType]?.status === 'complete' ? userGroups[assignmentType]?.status : status;
                    if( tenantUser ){
                        const id = tenantUser.id;

                        var users;
                        if(querySchemas.length > 1){
                            for(const tenant of querySchemas){
                                if(tenantUser[`users_${tenant.value}`].length > 0){
                                    users = tenantUser[`users_${tenant.value}`];
                                }
                            }
                        }else{
                            users = tenantUser[`users_${schema}`];
                        }

                        const {
                            first_name: firstName,
                            last_name: lastName,
                            profile_photo: profilePhoto
                        } = users && users.length > 0 ? users[0] : {'first_name': '', 'last_name': '', 'profile_photo': ''};

                        const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                        if(assignmentType !== 'reviewer'){
                            accumulator.assigned.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName,
                                profilePhoto,
                                status
                            });
                        }else{
                            accumulator.reviewer.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName,
                                profilePhoto,
                                status
                            });
                        }

                        return accumulator;
                    }else if (group){
                        const id = group.id;

                        const {
                            name: firstName
                        } = group;

                        const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                        if(assignmentType !== 'reviewer'){
                            accumulator.assigned.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName: "Group",
                                profilePhoto: '',
                                status: ''
                            });
                        }else{
                            accumulator.reviewer.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName: "Group",
                                profilePhoto: '',
                                status: ''
                            });
                        }

                        return accumulator;
                    }else{
                        return accumulator
                    }
                },
                {assigned: [], reviewer: []}
            );

            const recurrence = activityRecurrences.reduce(
                (accumulator, activityRecurrence) => {
                    accumulator.push(activityRecurrence?.activity_recurrence?.recurrence_type);
                    return accumulator
                },
                []
            );

            const startDate = new Date(activityStartDate);
            const dueDate = new Date(activityDueDate);
            const closedDate = new Date(activityClosedDate);
            const userInt = parseInt(user);
            const entities = activityEntities.reduce(
                (accumulator, activityEntity) => {
                    const {entity} = activityEntity;
                    const {name} = entity;
                    accumulator.push(name);
                    return accumulator
                },[]
            );

            const usersSortOrder = ['inprogress', 'complete']
            const individualStatusSortedAssigned = [...users.assigned].sort(function(a, b) {
                return usersSortOrder.indexOf(a.status) - usersSortOrder.indexOf(b.status);
            });
            const assignmentSortedAssigned = [...individualStatusSortedAssigned].sort(function(a, b) {
                return a.assignmentNumber - b.assignmentNumber;
            });
            const sortedAssigned = [...assignmentSortedAssigned].sort(function(a, b) {
                return usersSortOrder.indexOf(a.groupStatus) - usersSortOrder.indexOf(b.groupStatus);
            });

            const sortedReviewer = users.reviewer.sort(function (x, y) {
                return x.id === userInt ? -1 : y.id === userInt ? 1 : 0;
            })

            const assignedCompletableArray = users?.assigned.map(x => {
                if(x?.groupStatus !== 'complete'){
                    return x;
                }else{
                    return null;
                }});
            const reviewerCompletableArray = users?.reviewer.map(x => {
                if(x?.groupStatus !== 'complete'){
                    return x;
                }else{
                    return null;
                }});
            const completableArray = [...assignedCompletableArray, ...reviewerCompletableArray];
            const nullRemovedCompletableArray = completableArray.filter(function (x) {
                return x !== null && x !== undefined;
            });
            const assignmentNumbers = nullRemovedCompletableArray.map(x => {
                return x?.assignmentNumber;
            });
            const completableAssignmentNumber = assignmentNumbers.includes(0) ? 0 : Math.max(...assignmentNumbers);
            const userAssignmentNumberArrayWithNulls = nullRemovedCompletableArray.map(x => {
                if(x?.id === parseInt(user)){
                    return x?.assignmentNumber
                }else{
                    return null
                }
            })
            const userAssignmentNumberArray = userAssignmentNumberArrayWithNulls.filter(function (x) {
                return x !== null && x !== undefined;
            });
            const userAssignmentNumber = userAssignmentNumberArray[0];
            const completable = completableAssignmentNumber === userAssignmentNumber;

            const activity = {
                active: (!isHierarchical && sortedAssigned.some(({ id }) => id === userInt) && sortedAssigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && sortedAssigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                        (isHierarchical && sortedAssigned.some(({ id }) => id === userInt) && (sortedAssigned.filter(x => x.id === userInt)[0].assignmentNumber === 1 || sortedAssigned.filter(y => y.assignmentNumber < sortedAssigned.filter(x => x.id === userInt)[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && sortedAssigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && sortedAssigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                        (sortedAssigned.every(x => x.groupStatus === 'complete') && sortedReviewer.some(({ id }) => id === userInt)) ||
                        (sortedAssigned.every(x => x.groupStatus === 'complete') && sortedReviewer.every(x => x.groupStatus === 'complete') && sortedAssigned.some(({ id }) => id === userInt)),
                assigned: sortedAssigned,
                closedDate: formatDate(closedDate),
                completable,
                dueDate: formatDate(dueDate),
                entities,
                flagged: flagged,
                id: activityId,
                isHierarchical,
                policyReference: activityPolicies.length > 0 ? resolvePolicies(activityPolicies) : null,
                recurrence: recurrence[0],
                reviewer: sortedReviewer,
                riskLabel,
                schema,
                sortDate: dueDate,
                startDate: formatDate(startDate),
                text: activityName,
                type: activityType.name,
            };

            // if (
            //     isAll ||
            //     (!activity.isHierarchical && activity.assigned.some(({ id }) => id === userInt) && activity.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && activity.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
            //     (activity.isHierarchical && activity.assigned.some(({ id }) => id === userInt) && (activity.assigned.filter(x => x.id === userInt)[0].assignmentNumber === 1 || activity.assigned.filter(y => y.assignmentNumber < activity.assigned.filter(x => x.id === userInt)[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && activity.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && activity.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
            //     (activity.assigned.every(x => x.groupStatus === 'complete') && activity.reviewer.some(({ id }) => id === userInt)) ||
            //     (activity.assigned.every(x => x.groupStatus === 'complete') && activity.reviewer.every(x => x.groupStatus === 'complete') && activity.assigned.some(({ id }) => id === userInt))
            // ) {
            if(isFlagged || activity.flagged){
                if (closed ) {
										activity.status = 'completed'
                    accumulator.completed.push(activity);
                } else if (dueDate.setHours(23) < new Date()) {
										activity.status = 'overdue'
                    accumulator.overdue.push(activity);
                } else if (startDate <= new Date()){
										activity.status = 'inProgress'
                    accumulator.inProgress.push(activity);
                } else {
										activity.status = 'upcoming'
                    accumulator.upcoming.push(activity);
                }
            }
            // }
        }else{
            // if(isAll){
                const activity = {
                    active: false,
                    assigned: [],
                    closedDate: formatDate(new Date(activityClosedDate)),
                    completable: false,
                    dueDate: formatDate(new Date(activityDueDate)),
                    entities: activityEntities.reduce(
                        (accumulator, activityEntity) => {
                            const {entity} = activityEntity;
                            const {name} = entity;
                            accumulator.push(name);
                            return accumulator
                        },[]),
                    flagged: flagged,
                    id: activityId,
                    isHierarchical,
                    policyReference: activityPolicies,
                    recurrence: activityRecurrences.reduce(
                        (accumulator, activityRecurrence) => {
                            accumulator.push(activityRecurrence?.activity_recurrence?.recurrence_type);
                            return accumulator
                        },
                        []
                    )[0],
                    reviewer: [],
                    riskLabel,
                    schema,
                    sortDate: new Date(activityDueDate),
                    startDate: formatDate(new Date(activityStartDate)),
                    text: activityName,
                    type: activityType.name,
                    };
                if (new Date(activityDueDate).setHours(23) < new Date()) {
										activity.status = 'overdue'
                    accumulator.overdue.push(activity);
                } else if (new Date(activityStartDate) <= new Date()){
										activity.status = 'inProgress'
                    accumulator.inProgress.push(activity);
                } else {
										activity.status = 'upcoming'
                    accumulator.upcoming.push(activity);
                }
            // }
        }

        accumulator.completed = accumulator.completed.sort(
            (objA, objB) => {
                if(objA.sortDate > objB.sortDate) return 1;
                if(objA.sortDate < objB.sortDate) return -1;
                if(objA.type > objB.type) return 1;
                if(objA.type < objB.type) return -1;
                if(objA.text > objB.text) return 1;
                if(objA.text < objB.text) return -1;
                return 0;
            }
        );

        accumulator.overdue = accumulator.overdue.sort(
            (objA, objB) => {
                if(objA.sortDate > objB.sortDate) return 1;
                if(objA.sortDate < objB.sortDate) return -1;
                if(objA.type > objB.type) return 1;
                if(objA.type < objB.type) return -1;
                if(objA.text > objB.text) return 1;
                if(objA.text < objB.text) return -1;
                return 0;
            }
        );

        accumulator.inProgress = accumulator.inProgress.sort(
            (objA, objB) => {
                if(objA.sortDate > objB.sortDate) return 1;
                if(objA.sortDate < objB.sortDate) return -1;
                if(objA.type > objB.type) return 1;
                if(objA.type < objB.type) return -1;
                if(objA.text > objB.text) return 1;
                if(objA.text < objB.text) return -1;
                return 0;
            }
        );

        accumulator.upcoming = accumulator.upcoming.sort(
            (objA, objB) => {
                if(objA.sortDate > objB.sortDate) return 1;
                if(objA.sortDate < objB.sortDate) return -1;
                if(objA.type > objB.type) return 1;
                if(objA.type < objB.type) return -1;
                if(objA.text > objB.text) return 1;
                if(objA.text < objB.text) return -1;
                return 0;
            }
        );

        return accumulator;
    },
    {
        completed: [],
        inProgress: [],
        overdue: [],
        upcoming: []
    }
);

const normalizeData = ({ data, isFlagged, querySchemas, schema, tenants, user }) => {
    if(Array.isArray(tenants) && tenants.length > 0){
        const tenantData = [];
        for(const t of tenants){
            if(t){
                tenantData.push(schemaData({ data, isFlagged, querySchemas, schema:t, user }));
            }
        }
        const completed = [];
        const inProgress = [];
        const overdue = [];
        const upcoming = [];
        for (const d of tenantData){
            completed.push(...d?.completed);
            inProgress.push(...d?.inProgress);
            overdue.push(...d?.overdue);
            upcoming.push(...d?.upcoming);
        }
        const tenantResults = {
            completed,
            inProgress,
            overdue,
            upcoming
        }
        return tenantResults;
    }else{
        return schemaData({ data, isFlagged, querySchemas, schema, user });
    }
}

export default normalizeData;
