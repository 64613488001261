import { useCallback } from 'react';

import Button from 'components/Button';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const Tab = props => {
    const { className = '', isActive = false, onSelect, text, value } = props;

    const handleClick = useCallback(() => {
        if (isFunction(onSelect)) {
            onSelect(value);
        }
    }, [onSelect, value]);

    return (
        <Button
            className={`${className} ${styles.tab} ${
                isActive ? styles.active : ''
            }`}
            onClick={handleClick}
            value={value}
        >
            {text}
        </Button>
    );
};

export default Tab;
