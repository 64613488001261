import ComponentOverlay from 'components/ComponentOverlay';
import Loader from 'components/Loader';

const PanelLoader = ({className, text}) => (
    <ComponentOverlay className={className}>
        <Loader text={text}/>
    </ComponentOverlay>
);

export default PanelLoader;
