import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('workflowSettingsTableHeader');

    return {
        APPROVING_GROUP: t('approvingGroup'),
        STATUS: t('status'),
        SUBMITTING_GROUP: t('submittingGroup')
    };
};

export default useTranslation;
