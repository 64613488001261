import { atom } from 'recoil';

const casePoliciesAtom = atom({
    default: [],
    key: 'casePolicies'
});

const caseTypesAtom = atom({
    default: [],
    key: 'caseTypes'
});

const createCaseModalAtom = atom({
    default: '',
    key: 'createCaseModal'
});

const state = {
    atoms: {
        casePoliciesAtom,
        caseTypesAtom,
        createCaseModalAtom
    }
};

export default state;
