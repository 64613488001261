import DetailedListItems from 'components/DetailedListItems';
import OverdueDetailedListItem from 'components/OverdueDetailedListItem';

const OverdueDetailedListItems = ({
    className = '',
    items = [],
    onDownloadItem,
    tenantCount
}) => (
    <DetailedListItems className={className} tenantCount={tenantCount}>
        {items.map((item, index) => {
            const {
                active,
                assigned,
                completable,
                dueDate,
                entities,
                flagged,
                id,
                isActive,
                policyReference,
                recurrence,
                reviewer,
                schema,
                startDate,
                text,
                type
            } = item;
            return (
                <OverdueDetailedListItem
                    active={active}
                    assigned={assigned}
                    completable={completable}
                    dueDate={dueDate}
                    entities={entities.join(', ')}
                    flagged={flagged}
                    id={id}
                    isActive={isActive}
										item={item}
                    key={`${text}.${index}`}
                    onDownloadItem={onDownloadItem}
                    policyReference={policyReference}
                    recurrence={recurrence}
                    reviewer={reviewer}
                    schema={schema}
                    startDate={startDate}
                    tenantCount={tenantCount}
                    text={text}
                    type={type}
                />
            );
        })}
    </DetailedListItems>
);

export default OverdueDetailedListItems;
