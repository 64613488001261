import { useCallback } from 'react';

import HollowButton from 'components/HollowButton';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const CancelButton = ({ className = '', isDisabled = false, onClick }) => {
    const { CANCEL } = useTranslation();

    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <HollowButton
            className={`${className} ${styles.cancelButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={CANCEL}
        />
    );
};

export default CancelButton;
