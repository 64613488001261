import BarListItem from 'components/BarListItem';
import TextLabel from 'components/TextLabel';

import styles from './styles.module.scss';

export const TitleListItem2 = ({
    className = '',
    items = [],
    onRenderItem = (item, index) => (
        <TextLabel key={`${item}.${index}`} text={item} />
    )
}) => (
    <BarListItem className={`${className} ${styles.titleListItem}`}>
        {items.map(onRenderItem)}
    </BarListItem>
);
