const useInitials = (text = '') => {
    const name = text.includes(' ')
        ? text
              .split(' ')
              .map(word => word[0])
              .join('')
        : text[0];

    return name?.toUpperCase();
};

export default useInitials;
