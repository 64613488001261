import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { policiesAtom } = state.policies.atoms;

const usePolicies = () => [
    useRecoilValue(policiesAtom),
    useSetRecoilState(policiesAtom)
];

export default usePolicies;
