import { useCallback, useState } from 'react';

import EditRecurrenceModal from 'components/EditRecurrenceModal';

import Button from 'components/Button';
import EditIcon from 'components/icons/EditIcon';

import styles from './styles.module.scss';

const EditRecurrenceButton = ({
    activityEntities,
    activityForms,
    activityId,
    activityInstructions,
    activityName,
    activityPolicy,
    activityRecurrence,
    activitySchema,
    activityType,
    activityUsers,
    className = '',
    currentActivityEndDate,
    currentActivityStartDate,
    hasEdit = true,
    recurrences
}) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            {hasEdit && (
                <Button
                    className={`${className} ${styles.editButton}`}
                    onClick={handleClick}
                >
                    <EditIcon />
                </Button>
            )}

            {isOpen && <EditRecurrenceModal
                activityEntities={activityEntities}
                activityForms={activityForms}
                activityId={activityId}
                activityInstructions={activityInstructions}
                activityName={activityName}
                activityPolicy={activityPolicy}
                activityRecurrence={activityRecurrence}
                activitySchema={activitySchema}
                activityType={activityType}
                activityUsers={activityUsers}
                currentActivityEndDate={currentActivityEndDate}
                currentActivityStartDate={currentActivityStartDate}
                onClose={handleClose}
                recurrences={recurrences}
            />}
        </>
    );
};

export default EditRecurrenceButton;
