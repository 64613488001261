import { forwardRef, useCallback } from 'react';

import InfoHollowButton from 'components/InfoHollowButton';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const MarkReviewedButton = forwardRef(
    ({ className = '', isDisabled = false, onClick }, ref) => {
        const { MARK_AS_REVIEWED } = useTranslation();

        const [_, markAsReviewed] = useData();

        const handleClick = useCallback(() => {
            markAsReviewed({
                id: 'mark-as-reviewed'
            });

            if (isFunction(onClick)) {
                onClick();
            }
        }, [markAsReviewed, onClick]);

        return (
            <InfoHollowButton
                className={`${className} ${styles.markReviewedButton}`}
                disabled={isDisabled}
                onClick={handleClick}
                ref={ref}
                text={MARK_AS_REVIEWED}
            />
        );
    }
);

export default MarkReviewedButton;
