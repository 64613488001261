import ContentPanel from 'components/ContentPanel';
import ContentBox from 'components/ContentBox';
import LinkInNewTabWithLabel from 'components/LinkInNewTabWithLabel';
import PanelBody from 'components/PanelBody';
import RiskPillWithLabel from 'components/RiskPillWithLabel';
import DateWithLabel from 'components/DateWithLabel';
import Title from 'components/Title';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ComplianceEntryCasePanel = props => {
    const { CASE_ROUTE } = useConfig();
    const {
        className = '',
        closedDate,
        createDate,
        creationReason,
        id,
        logSchema,
        name,
        riskLabel
    } = props;

    const {
        CASE_CREATED_DATE,
        CASE_CREATION_REASON,
        CASE_DETAILS,
        CASE_TYPE,
        DATE_CLOSED,
        RISK_LABEL
    } = useTranslation();

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={CASE_DETAILS} />

                <LinkInNewTabWithLabel
                    label={CASE_TYPE}
                    text={name}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(':caseSchema', logSchema)}`}
                />

                <RiskPillWithLabel level={riskLabel} text={RISK_LABEL} />

                <DateWithLabel
                    className={styles.dateClosed}
                    date={closedDate}
                    text={DATE_CLOSED}
                />

                <DateWithLabel
                    className={styles.caseCreatedDate}
                    date={createDate}
                    text={CASE_CREATED_DATE}
                />

                <ContentBox
                    className={styles.contentBox}
                    html={creationReason}
                    title={CASE_CREATION_REASON}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default ComplianceEntryCasePanel;
