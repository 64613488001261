const translations = {
    caseCreatedDate: 'Case Created Date',
    caseCreationReason: 'Case Creation Reason',
    caseDetails: 'Case Details',
    caseType: 'Case Type',
    dateClosed: 'Date Closed',
    riskLabel: 'Risk Label'
};

export default translations;
