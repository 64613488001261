import { useCallback, useState } from 'react';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const SlideToggle = ({ color = 'green', isActive = true, className = '', onToggle }) => {
    const [isOff, setIsOff] = useState(!isActive);

    const handleToggle = useCallback(() => {
        if (isFunction(onToggle)) {
            setIsOff(!isOff);
            onToggle(isOff);
        }
    }, [isOff, onToggle]);

    return (
        <div
            className={`${isOff ? color==='orange' ? styles.isInactiveOrange : styles.isInactive : ''} ${styles.slideToggle} ${className}`}
            onClick={handleToggle}
        >
            <div
                className={`${isOff ? color==='orange' ? styles.isInactiveOrange : styles.isInactive : ''} ${styles.knob}`}
            ></div>
        </div>
    );
};

export default SlideToggle;
