import { useState } from 'react';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const Hamburger = ({ className = '', onClick }) => {
    const [isOpen, setIsOpen] = useState();

    const handleClick = () => {
        setIsOpen(!isOpen);

        if (isFunction(onClick)) {
            onClick(!isOpen);
        }
    };

    return (
        <div
            className={`${className} ${styles.hamburger}`}
            onClick={handleClick}
        />
    );
};

export default Hamburger;
