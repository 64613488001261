import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addSectionModal');

    return {
        ADD_SECTION: t('addSection'),
        CATEGORIES: t('categories'),
        SECTION_CONTENTS: t('sectionContents'),
        SECTION_NAME: t('sectionName')
    };
};

export default useTranslation;
