import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UploadPolicyFromFileListItem from 'components/UploadPolicyFromFileListItem';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

const AddPolicyFromFileMenuListItem = ({ onClick }) => {
    const [triggered, setTriggered] = useState(false);
    const { ADD_POLICY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        setTriggered(!triggered);
    }, [setTriggered, triggered]);

    useEffect(() => {
        if (!triggered) {
            return;
        }

        if (isFunction(onClick)) {
            onClick();
        }

        navigate(ADD_POLICY_ROUTE);
    }, [ADD_POLICY_ROUTE, navigate, onClick, triggered]);

    return <UploadPolicyFromFileListItem onClick={handleClose} />;
};

export default AddPolicyFromFileMenuListItem;
