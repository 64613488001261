import normalizeListData from "utilities/normalizeListData";
import formatDate from "utilities/formatDate";

const schemaData = ({ data, schema }) =>
    data[`${schema}_log`].reduce((accumulator, caseItem) => {
        const {
            description,
            tenant_user: createdByObject,
            created_date: createdDateString,
            date_of_occurrence: dateOfOccurrenceString,
            risk_label: riskLabel,
            log_types: logCategoryObject
        } = caseItem;

        const createdById = createdByObject?.id;
        const createdByFirstName = createdByObject ? createdByObject[`user_${schema}`]?.first_name : createdByObject;
        const createdByLastName = createdByObject ? createdByObject[`user_${schema}`]?.last_name : createdByObject;
        const createdByUserName = createdByObject ? `${createdByFirstName} ${createdByLastName}` : createdByObject;
        const createdBy = {firstName: createdByFirstName, id: createdById, label: createdByUserName, lastName: createdByLastName, type: 'user', value:createdByUserName}

        const dateOfOccurrence = dateOfOccurrenceString ? formatDate(new Date(dateOfOccurrenceString)) : dateOfOccurrenceString;
        const createdDate = createdDateString ? formatDate(new Date(createdDateString)) : createdDateString;

        const logCategory = logCategoryObject[0]?.log_type?.name;

        // eslint-disable-next-line
        accumulator.push({description, logCategory, createdBy, riskLabel, dateOfOccurrence, createdDate});

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
