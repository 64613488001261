import { useCallback, useState } from 'react';

import Buttonize from 'components/Buttonize';
import CheckIcon from 'components/icons/CheckIcon';
import CloseIcon from 'components/icons/CloseIcon';
import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import PenIcon from 'components/icons/PenIcon';
import Pills from 'components/Pills';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const EditableMultiSelectWithAddNew = ({ className = '', inTableRow=false, items, onSave, options, setModified, title }) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleSave = useCallback(() => {
        if (isFunction(onSave)) {
            onSave();
        }

        setIsEditing(false);
    }, [onSave, setIsEditing]);

    const contentBoxStyle = inTableRow ? styles.inTableRow : '';

    return (
        <div className={`${className} ${styles.editableContentBox}`}>
            {!isEditing && (
                <>
                    <Pills className={`${styles.contentBox} ${contentBoxStyle}`} items={items.map(item => item?.label)} />

                    <Buttonize
                        className={styles.buttonize}
                        onClick={handleEdit}
                    >
                        <PenIcon />
                    </Buttonize>
                </>
            )}

            {isEditing && (
                <>
                    <DropdownMultiSelectWithLabel
                        change={setModified}
                        className={styles.textBox}
                        label={title}
                        options={options}
                        selectedOptions={items}
                    />

                    <Buttonize
                        className={styles.closeIcon}
                        onClick={handleCancel}
                    >
                        <CloseIcon />
                    </Buttonize>

                    <Buttonize
                        className={styles.checkIcon}
                        onClick={handleSave}
                    >
                        <CheckIcon />
                    </Buttonize>
                </>
            )}
        </div>
    );
};

export default EditableMultiSelectWithAddNew;
