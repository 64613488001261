import ImportantText from "components/ImportantText";

import styles from './styles.module.scss';

const CountChart = ({ className = '', data = [] }) => {
    const dataCount = data?.datasets[0]?.data.reduce((partialSum, a) => partialSum + a, 0);
    return (
        <ImportantText className={`${className} ${styles.entriesCount}`} text={dataCount} />
    );
};

export default CountChart;