import { useCallback, useState } from 'react';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';

import isFunction from 'utilities/isFunction';
import DeleteButton from 'components/DeleteButton';

const CancelSaveButtons = ({
    className = '',
    isDeleteDisabled = false,
    onCancel,
    onDelete,
}) => {
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(isDeleteDisabled);

    const handleCancel = useCallback(
        clickEvent => {
            if (isFunction(onCancel)) {
                onCancel(clickEvent);
            }
        },
        [onCancel]
    );

    const handleDelete = useCallback(
        clickEvent => {
            setIsDeleteButtonDisabled(true);

            if (isFunction(onDelete)) {
                onDelete(clickEvent);
            }
        },
        [onDelete]
    );

    return (
        <Buttons className={className}>
            <CancelButton onClick={handleCancel} />

            <DeleteButton
                isDisabled={isDeleteButtonDisabled}
                onClick={handleDelete}
            />
        </Buttons>
    );
};

export default CancelSaveButtons;
