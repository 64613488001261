const translations = {
    categories: 'Categories',
    company: 'Company',
    currentVersion: 'Current Version',
    effectiveDate: 'Effective Date',
    policyName: 'Policy Name',
    status: 'Status',
    visibility: 'Visibility'
};

export default translations;
