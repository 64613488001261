import { atom } from 'recoil';

const deleteActivityModalAtom = atom({
    default: '',
    key: 'deleteActivityModal'
});

const state = {
    atoms: {
        deleteActivityModalAtom
    }
};

export default state;
