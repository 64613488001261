import Icon from 'components/Icon';

import image from './image.svg';

import styles from './styles.module.scss';

const SpeedometerIcon = ({ className = '' }) => (
    <Icon className={`${className} ${styles.speedometerIcon}`} image={image} />
);

export default SpeedometerIcon;
