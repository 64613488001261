import downloadBlob from 'utilities/downloadBlob';

export const useExport = () => {
    async function exportToExcel({ buffer, filename }) {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        downloadBlob(blob, `${filename}.xlsx`);
    }

    return {
        exportToExcel
    };
};
