import { useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetailsPanelAtom } = state.caseDetailsPanel.atoms;

const DELETE_COMMENT = tenantSchema => gql`
    mutation delete_comment($commentId: Int!, $caseId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "deleted a comment") {
        update_${tenantSchema}_case_comments(
            where: { id: { _eq: $commentId } },
            _set: { deleted: true }
        ) {
            returning {
                id
            }
        }

				insert_${tenantSchema}_case_history_one(
            object: {
								case_id:$caseId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

const MUTATE_COMMENT = tenantSchema => gql`
    mutation insert_single_comment($caseComment: String!, $caseId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "added a comment") {
        insert_${tenantSchema}_case_comments_one(
            object: {
								case_id: $caseId,
								text: $caseComment,
								user_case_comments: {
									data: {user_id:$userId}
								}
							}
        ) {
					id
        }

				insert_${tenantSchema}_case_history_one(
            object: {
								case_id:$caseId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

const UPDATE_COMMENT = tenantSchema => gql`
    mutation update_comment($commentId: Int!, $newText: String!, $caseId: Int!, $userId: Int!, $activityHistoryColor: String = "green", $activityHistoryMessage: String = "edited a comment") {
        update_${tenantSchema}_case_comments(
            where: { id: { _eq: $commentId } },
            _set: { text: $newText }
        ) {
            returning {
                id
            }
        }

				insert_${tenantSchema}_case_history_one(
            object: {
								case_id:$caseId
								color: $activityHistoryColor,
                message: $activityHistoryMessage,
                user_id:$userId
            }
        ) {
           id
        }
    }
`;

export const useComment = (caseId, caseSchema) => {
    const { useUserId } = useGlobalStateHooks();
    const [userIdValue] = useUserId();

    const [deleteComment] = useMutation(DELETE_COMMENT(caseSchema));
    const [insertComment] = useMutation(MUTATE_COMMENT(caseSchema));
    const [updateComment] = useMutation(UPDATE_COMMENT(caseSchema));

    const userId = Number(userIdValue);

    const insertCommentText = async (caseId, caseComment) => {
        const outputComment = await insertComment({
            variables: {
                caseComment,
                caseId,
                userId
            }
        });

        const { id: commentId } =
            outputComment?.data[`insert_${caseSchema}_case_comments_one`];

        return commentId;
    };

    const deleteCommentText = async commentId => {
        await deleteComment({
            variables: {
                caseId,
                commentId,
                userId
            }
        });
    };

    const updateCommentText = async (commentId, newText) => {
        await updateComment({
            variables: {
                caseId,
                commentId,
                newText,
                userId
            }
        });
    };

    return [
        useRecoilValue(caseDetailsPanelAtom),
        insertCommentText,
        deleteCommentText,
        updateCommentText
    ];
};
