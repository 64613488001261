import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('rejectModal');

    return {
        NO_CANCEL: t('noCancel'),
        YES_REJECT: t('yesReject'),
        YOU_ARE_ABOUT_TO_REJECT: t('youAreAboutToReject')
    };
};

export default useTranslation;
