import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('requestUpdateModal');

    return {
        NO_CANCEL: t('noCancel'),
        YES_REQUEST_UPDATES: t('yesRequestUpdates')
    };
};

export default useTranslation;
