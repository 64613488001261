import { useCallback, useEffect, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import GroupDropdownMultiSelect from 'components/GroupDropdownMultiSelect';
import EditableTextWithLabel from 'components/EditableTextWithLabel';
import PanelBody from 'components/PanelBody';

import useGroupName from './hooks/useGroupName';
import useGroupDescription from './hooks/useGroupDescription';
import useAddSupervisor from './hooks/useAddSupervisor';
import useDeleteGroupSupervisor from './hooks/useDeleteGroupSupervisor';
import useDeleteUserSupervisor from './hooks/useDeleteUserSupervisor';
import useTranslation from './hooks/useTranslation';

const GroupDetailsPanel = ({
    description = '',
    id,
    form,
    name = '',
    selectedSupervisors,
    setDisplayedGroupTitle,
    setSupervisors,
    supervisorOptions = []
}) => {
    const { 
        GROUP_DESCRIPTION, 
        GROUP_NAME, 
        SUPERVISORS 
    } = useTranslation();

    const [savedGroupName, saveGroupName, resetGroupName] = useGroupName();
    const [savedGroupDescription, saveGroupDescription, resetGroupDescription] = useGroupDescription();
    const [addedSupervisor, addSupervisor, resetAddSupervisor] = useAddSupervisor();
    const [deletedGroupSupervisor, deleteGroupSupervisor, resetDeleteGroupSupervisor] = useDeleteGroupSupervisor();
    const [deletedUserSupervisor, deleteUserSupervisor, resetDeleteUserSupervisor] = useDeleteUserSupervisor();

    const [_errorMessage, setErrorMessage] = useState();
    const [displayedGroupName, setDisplayedGroupName] = useState(name);
    const [displayedGroupDescription, setDisplayedGroupDescription] = useState(description);

    const { handleError, handleSubmit, handleValid } = form;

    const handleGroupNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    groupId: id,
                }
            );

            saveGroupName(formObject);
        },
        [id, saveGroupName]
    );

    useEffect(() => {
        if (!savedGroupName) {
            return;
        }

        setDisplayedGroupName(savedGroupName);
        setDisplayedGroupTitle(savedGroupName);
        resetGroupName();
    }, [displayedGroupName, resetGroupName, savedGroupName, setDisplayedGroupName, setDisplayedGroupTitle]);   

    const handleGroupDescriptionSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    groupId: id,
                }
            );

            saveGroupDescription(formObject);
        },
        [id, saveGroupDescription]
    );

    useEffect(() => {
        if (!savedGroupDescription) {
            return;
        }

        setDisplayedGroupDescription(savedGroupDescription);
        resetGroupDescription();
    }, [displayedGroupDescription, resetGroupDescription, savedGroupDescription, setDisplayedGroupDescription]);   

    const handleSetSupervisors = useCallback(indexArray => {
        /** Note:
         *  indexArray - list of items after (de)selection
         *  selectedSupervisors - list of items before (de)selection
         **/
        if (indexArray.length < selectedSupervisors.length) {
            const deletedSupervisor = selectedSupervisors.filter(supervisor => indexArray.indexOf(supervisor) === -1)[0].split(' ');

            const supervisorType = deletedSupervisor[0];
            const supervisorId = deletedSupervisor[1];

            if (supervisorType === 'group') {
                const supervisorToAddOrDelete = {
                    groupId: id,
                    groupSupervisoryId: supervisorId
                };

                deleteGroupSupervisor(supervisorToAddOrDelete);
            } else {
                const supervisorToAddOrDelete = {
                    groupId: id,
                    userId: supervisorId
                };

                deleteUserSupervisor(supervisorToAddOrDelete);
            }
        } else if (selectedSupervisors.length < indexArray.length) {
            const addedSupervisor = indexArray.filter(supervisor => selectedSupervisors.indexOf(supervisor) === -1)[0].split(' ');

            const supervisorType = addedSupervisor[0];
            const supervisorId = addedSupervisor[1];

            const supervisorToAddOrDelete = {
                groupId: id,
                groupSupervisoryId: (supervisorType === 'group' ? supervisorId : null),
                userId: (supervisorType === 'user' ? supervisorId : null),
            }

            addSupervisor(supervisorToAddOrDelete);
        }

        return setSupervisors(indexArray);
    }, [id, addSupervisor, deleteGroupSupervisor, deleteUserSupervisor, selectedSupervisors, setSupervisors])

    useEffect(() => {
        if (!addedSupervisor) {
            return;
        }

        resetAddSupervisor();
    }, [resetAddSupervisor, addedSupervisor]);

    useEffect(() => {
        if (!deletedGroupSupervisor) {
            return;
        }

        resetDeleteGroupSupervisor();
    }, [resetDeleteGroupSupervisor, deletedGroupSupervisor]);

    useEffect(() => {
        if (!deletedUserSupervisor) {
            return;
        }

        resetDeleteUserSupervisor();
    }, [resetDeleteUserSupervisor, deletedUserSupervisor]);

    useEffect(() => {
        setDisplayedGroupName(name);
        setDisplayedGroupTitle(name);
    }, [name, setDisplayedGroupName, setDisplayedGroupTitle]);

    useEffect(() => {
        setDisplayedGroupDescription(description);
    }, [description, setDisplayedGroupDescription]);

    return (
        <ContentPanel>
            <PanelBody>
                <EditableTextWithLabel
                    label={GROUP_NAME}
                    onSave={handleGroupNameSave}
                    text={displayedGroupName}
                />

                <EditableTextWithLabel
                    label={GROUP_DESCRIPTION}
                    onSave={handleGroupDescriptionSave}
                    text={displayedGroupDescription}
                />

                <GroupDropdownMultiSelect
                    items={supervisorOptions}
                    label={SUPERVISORS}
                    onChange={handleSetSupervisors}
                    onError={handleError('supervisors')}
                    onSubmit={handleSubmit('supervisors')}
                    onValid={handleValid('supervisors')}
                    returnObject={true}
                    selected={selectedSupervisors}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default GroupDetailsPanel;
