import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createLogEntryModal');

    return {
        ACTIVITY_NAME: t('activityName'),
        ACTIVITY_TYPE: t('activityType'),
        CASE_NAME: t('caseName'),
        CASE_TYPE: t('caseType'),
        CLOSE_AND_CREATE_LOG_ENTRY: t('closeAndCreateLogEntry'),
        CREATE_LOG_ENTRY: t('createLogEntry')
    };
};

export default useTranslation;
