import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('logEntriesPanel');

    return {
        VIEW_ALL: t('viewAll')
    };
};

export default useTranslation;
