import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('activityDetailsPanel');

    return {
        ACTIVITY_NAME: t('activityName'),
        ACTIVITY_TYPE: t('activityType'),
        INSTRUCTIONS: t('instructions'),
        POLICY_REFERENCE: t('policyReference'),
        REGULATORY_REFERENCE: t('regulatoryReference'),
        RISK_LEVEL_ACTIVITY: t('riskLevelActivity')
    };
};

export default useTranslation;
