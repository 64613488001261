import { forwardRef, useCallback, useState } from 'react';

import AddPolicyMenu from 'components/AddPolicyMenu';
import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';

const AddPolicyButton = forwardRef(({ className = '', isDisabled = false }, ref) => {
    const { ADD_POLICY } = useTranslation();

    const [isButtonDisabled, setIsButtonDisabled] = useState(isDisabled);

    const handleClick = useCallback(() => {
        setIsButtonDisabled(true);
    }, [setIsButtonDisabled]);

    return (
        <AddPolicyMenu
            onClick={handleClick}
        >
            {handleClick => (
                <SubmitButton
                    className={className}
                    isDisabled={isButtonDisabled}
                    onClick={handleClick}
                    ref={ref}
                    text={`+ ${ADD_POLICY}`}
                />
            )}
        </AddPolicyMenu>
    );
});

export default AddPolicyButton;
