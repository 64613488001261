import { useEffect, useMemo, useState } from 'react';
import ContentPanel from 'components/ContentPanel';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import SearchInput from 'components/SearchInput';
import DocumentManagementTableHeader from 'components/DocumentManagementTableHeader';
import DocumentManagementTableRow from 'components/DocumentManagementTableRow';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import lexigraphicalSort from 'utilities/lexigraphicalSort';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const DocumentManagementPanel = props => {
    const { className = '', items = [], loading } = props;

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const [nameSelected, setNameFilter] = useState();
    const [versionSelected, setVersionFilter] = useState();
    const [startDateFilter, setDateRangeStartFilter] = useState();
    const [dueDateFilter, setDateRangeEndFilter] = useState();
    const [visibilitySelected, setVisibilityFilter] = useState();
    const [categoriesSelected, setCategoriesFilter] = useState();
    const [statusesSelected, setStatusFilter] = useState();
    const [displayedItems, setDisplayedItems] = useState(items);
    const [searchFilter, setSearchFilter] = useState("");

    useEffect(() => {
        // if (!dueDateFilter){
        //     setDateRangeEndFilter(new Date(new Date().setDate(new Date().getDate() + 730)));
        // }
        // if (!startDateFilter){
        //     setDateRangeStartFilter(new Date(new Date().setDate(new Date().getDate() - 730)));
        // }
        // eslint-disable-next-line
    }, [])

    const { CATEGORIES, DATE_RANGE_END, DATE_RANGE_START, NAME, SEARCH, STATUS, VERSION, VIEW_ALL, VISIBILITY  } = useTranslation();

    const nameItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { name: nameItem }) => {
                if (!(nameItem in accumulator.item)) {
                    accumulator.item[nameItem] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const versionItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { version }) => {
                if (!(version in accumulator.item)) {
                    accumulator.item[version] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const visibilityItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { visibleTo }) => {
                if(Array.isArray(visibleTo)){
                    for(const visible of visibleTo){
                        if (!(visible in accumulator.item)) {
                            accumulator.item[visible?.label] = 0;
                        }
                    }
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const categoryItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { selectedCategories }) => {
                if(Array.isArray(selectedCategories)){
                    for(const selectedCategory of selectedCategories){
                        if (!(selectedCategory in accumulator.item)) {
                            accumulator.item[selectedCategory?.label] = 0;
                        }
                    }
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const statusItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { status }) => {
                if (!(status in accumulator.item)) {
                    accumulator.item[status] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    if ((nameItems.filter(e => e.value === nameSelected).length === 0 && nameSelected === 'View All') || (Array.isArray(nameSelected) && nameSelected.length===0)) {
        setNameFilter(null);
    }
    if ((versionItems.filter(e => e.value === versionSelected).length === 0 && versionSelected === 'View All') || (Array.isArray(versionSelected) && versionSelected.length===0)) {
        setVersionFilter(null);
    }
    if ((visibilityItems.filter(e => e.value === visibilitySelected).length === 0 && visibilitySelected === 'View All') || (Array.isArray(visibilitySelected) && visibilitySelected.length===0)) {
        setVisibilityFilter(null);
    }
    if ((categoryItems.filter(e => e.value === categoriesSelected).length === 0 && categoriesSelected === 'View All') || (Array.isArray(categoriesSelected) && categoriesSelected.length===0)) {
        setCategoriesFilter(null);
    }
    if ((statusItems.filter(e => e.value === statusesSelected).length === 0 && statusesSelected === 'View All') || (Array.isArray(statusesSelected) && statusesSelected.length===0)) {
        setStatusFilter(null);
    }
    
    useEffect(() => {
        if (items) {
            let filteredItems = nameSelected
                ? items.filter(({ name }) => name === nameSelected)
                : items;
    
            filteredItems = versionSelected
                ? filteredItems.filter(({ version }) => version === versionSelected)
                : filteredItems;
    
            filteredItems = startDateFilter
                ? filteredItems.filter(({ effectiveDate }) => new Date(effectiveDate) >= startDateFilter)
                : filteredItems;
    
            filteredItems = dueDateFilter
                ? filteredItems.filter(({ effectiveDate }) => new Date(effectiveDate) <= dueDateFilter)
                : filteredItems;
    
            filteredItems = visibilitySelected
                ? filteredItems.filter(({ visibleTo }) => visibleTo.some(arrVal => arrVal?.label === visibilitySelected))
                : filteredItems;
    
            filteredItems = categoriesSelected
                ? filteredItems.filter(({ selectedCategories }) => selectedCategories.some(arrVal => arrVal?.label === categoriesSelected))
                : filteredItems;
    
            filteredItems = statusesSelected
                ? filteredItems.filter(({ status }) => status === statusesSelected)
                : filteredItems;
    
            if (searchFilter) {
                filteredItems = filteredItems.filter(item => 
                    item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                    (item.description && item.description.toLowerCase().includes(searchFilter.toLowerCase()))
                );
            }
    
            setDisplayedItems(filteredItems);
        }
    }, [items, nameSelected, versionSelected, startDateFilter, dueDateFilter, visibilitySelected, categoriesSelected, statusesSelected, searchFilter]);
    

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody} loading={loading}>
                <SearchInput
                    className={styles.search}
                    onChange={setSearchFilter}
                    placeholder={SEARCH}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={nameItems}
                    label={NAME}
                    onChange={setNameFilter}
                    selected={nameSelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={versionItems}
                    label={VERSION}
                    onChange={setVersionFilter}
                    selected={versionSelected}
                />

                <DatePicker
                    className={styles.startDate}
                    label={DATE_RANGE_START}
                    onChange={setDateRangeStartFilter}
                    placeholder={startDateFilter ? new Date(startDateFilter).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
                />

                <DatePicker
                    className={styles.endDate}
                    label={DATE_RANGE_END}
                    onChange={setDateRangeEndFilter}
                    placeholder={dueDateFilter ? new Date(dueDateFilter).toISOString().split('T')[0]: new Date().toISOString().split('T')[0]}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={visibilityItems}
                    label={VISIBILITY}
                    onChange={setVisibilityFilter}
                    selected={visibilitySelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={categoryItems}
                    label={CATEGORIES}
                    onChange={setCategoriesFilter}
                    selected={categoriesSelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={statusItems}
                    label={STATUS}
                    onChange={setStatusFilter}
                    selected={statusesSelected}
                />

                <Table className={styles.table}>
                    <DocumentManagementTableHeader
                        onSort={({ _isAscending, _sortBy }) => {
                        }}
                        tenantCount={tenants.length}
                    />

                    {displayedItems.length === 0 && (
                        <p className={styles.noItems}>No items to show</p>
                    )}

                    {displayedItems.map(
                        (
                            {
                                effectiveDate,
                                id,
                                name,
                                schema,
                                selectedCategories,
                                status,
                                version,
                                visibleTo
                            },
                            index
                        ) => (
                            <DocumentManagementTableRow
                                effectiveDate={effectiveDate}
                                id={id}
                                key={`${id}.${index}`}
                                name={name}
                                schema={schema}
                                selectedCategories={selectedCategories}
                                status={status}
                                tenantCount={tenants.length}
                                version={version}
                                visibleTo={visibleTo}
                            />
                        )
                    )}
                </Table>
            </PanelBody>
        </ContentPanel>
    );
};

export default DocumentManagementPanel;
