import { atom } from 'recoil';

const avatarsAtom = atom({
    default: {},
    key: 'avatars'
});

const state = {
    atoms: {
        avatarsAtom
    }
};

export default state;
