import { useEffect, useState } from 'react';

import AvatarWithTitle from 'components/AvatarWithTitle';
import BodyText from 'components/BodyText';
import formatDatetime from 'utilities/formatDatetime';
import HtmlContent from 'components/HtmlContent';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useTimeAgo from './hooks/useTimeAgo';

import styles from './styles.module.scss';
import setHyperlinks from 'utilities/setHyperlinks';
import PenIcon from 'components/icons/PenIcon';
import CloseIcon from 'components/icons/CloseIcon';
import Input from 'components/Input';
import CheckIcon from 'components/icons/CheckIcon';
import handleSubmitOnEnter from 'utilities/handleSubmitOnEnter';

const Comment = props => {
    const {
        className = '',
        closed,
        commentId,
        content,
        createdAt,
        deleted,
        hasEdit = true,
        onDelete,
        onUpdate,
        user
    } = props;

    const timeAgo = useTimeAgo(formatDatetime(new Date(createdAt + 'Z')));
    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();
    const [editedComment, setEditedComment] = useState(content);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setEditedComment(content);
    }, [content, editMode]);

    const updatedComment = setHyperlinks(content);
    const canInteractWithCommentOptions =
        hasEdit &&
        !deleted &&
        String(user.id) === String(userId) &&
        !editMode &&
        Boolean(onDelete) &&
        Boolean(onUpdate) &&
        !closed;

    const onSubmitEditComment = async () => {
        if (editedComment.trim() === content.trim()) {
            setEditMode(false);
            return;
        }

        if (editedComment.trim().length === 0) {
            return;
        }

        await onUpdate({
            commentId,
            newText: editedComment
        });

        setEditMode(false);
    };

    return (
        <div className={`${className} ${styles.comment}`}>
            <div className={styles.topRow}>
                <AvatarWithTitle user={user} />

                {canInteractWithCommentOptions && (
                    <div className={styles.iconsContainer}>
                        <div onClick={() => setEditMode(true)}>
                            <PenIcon className={styles.editIcon} />
                        </div>

                        <div onClick={() => onDelete(commentId)}>
                            <CloseIcon className={styles.deleteIcon} />
                        </div>
                    </div>
                )}
            </div>

            <BodyText className={styles.bodyText} text={timeAgo} />

            {!editMode && (
                <HtmlContent
                    className={styles.content}
                    html={deleted ? '<i>deleted</i>' : updatedComment}
                    sanitizeHtml={false}
                />
            )}

            {editMode && (
                <div className={styles.editContainer}>
                    <Input
                        className={styles.input}
                        onChange={value => setEditedComment(value)}
                        onKeyUp={handleSubmitOnEnter(onSubmitEditComment)}
                        value={editedComment}
                    />

                    <div className={styles.iconsEditContainer}>
                        <div onClick={() => setEditMode(false)}>
                            <CloseIcon className={styles.deleteIcon} />
                        </div>

                        <div onClick={onSubmitEditComment}>
                            <CheckIcon className={styles.editIcon} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Comment;
