import CountPanel from 'components/CountPanel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const UpcomingCountPanel = ({ className = '', count = 0 }) => {
    const { UPCOMING } = useTranslation();

    return (
        <CountPanel
            className={`${className} ${styles.upcomingCountPanel}`}
            count={count}
            text={UPCOMING}
        />
    );
};

export default UpcomingCountPanel;
