import AvatarWithTitle from 'components/AvatarWithTitle';
import BodyText from 'components/BodyText';
import ContentBox from 'components/ContentBox';
import ContentPanel from 'components/ContentPanel';
import HeaderText from 'components/HeaderText';
import ImportantLink from 'components/ImportantLink';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import TextWithLabel from 'components/TextWithLabel';
import WithLabel from 'components/WithLabel';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const FormDetailsPanel = ({
    className = '',
    form = {},
}) => {
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const { ACTIVITY_ROUTE } = useConfig();
    const {
        ACTIVITY_ASSIGNMENTS,
        ACTIVITY_NAME,
        ASSIGNMENT,
        ASSIGNMENT_TYPE,
        CREATED_BY,
        FORM_NAME,
        IS_ACTIVE,
        NOTES
    } = useTranslation();

    const headers = [
        ACTIVITY_NAME,
        ASSIGNMENT,
        ASSIGNMENT_TYPE
    ];

    const {
        activityForms = [],
        createdBy = {},
        isActive = false,
        name = '',
        notes = ''
    } = form

    const isActiveText = (isActive) ? '✓' : '✕';

    return (
        <ContentPanel className={`${className} ${styles.formDetailsPanel}`}>
            <PanelBody className={styles.panelBody}>
                <TextWithLabel label={FORM_NAME} text={name} />

                <WithLabel className={styles.withLabel} text={CREATED_BY}>
                    <AvatarWithTitle user={{id:createdBy?.id, name:`${createdBy?.firstName} ${createdBy?.lastName}`}} />
                </WithLabel>

                <TextWithLabel label={IS_ACTIVE} text={isActiveText} />

                <ContentBox
                    className={styles.notes}
                    html={notes}
                    title={NOTES}
                />


                <WithLabel className={`${styles.withLabel} ${styles.tableContainer}`} text={ACTIVITY_ASSIGNMENTS}>
                    <Table className={`${className} ${styles.activityTable}`}>
                        <TableRow className={`${className} ${styles.activityTableHeader}`}>
                            {headers.map((item, index) => (
                                <HeaderText
                                    className={`${className} ${styles.headerText}`}
                                    key={`${item}.${index}`}
                                    text={item}
                                />
                            ))}
                        </TableRow>

                        {activityForms.map(({activityId, activityName, assignment, assignmentType}) => {
                            const modifiedAssignment = assignment.replace('_', ' ')
                                .replace('-', ' ')
                                .toLowerCase()
                                .split(' ')
                                .map(val =>
                                    (val.length > 0) ? val.replace(val[0], val[0].toUpperCase()) : ""
                                )
                                .join(' ')
                            const modifiedAssignmentType = (assignmentType === '1') ? 'One Completion Required' :
                                    (assignmentType === 'all') ? 'All assignees required to complete' : assignmentType
                            return(
                                <TableRow className={styles.formTableRow}>
                                    <ImportantLink
                                        className={styles.titleLink}
                                        text={activityName}
                                        to={`${ACTIVITY_ROUTE.replace(':activityId', activityId).replace(':activitySchema', schema)}`}
                                    />
                        
                                    <BodyText className={styles.formCell} text={modifiedAssignment} />
                        
                                    <BodyText className={styles.formCell} text={modifiedAssignmentType} />
                                </TableRow>
                            )
                        })}
                    </Table>
                </WithLabel>
            </PanelBody>
        </ContentPanel>
    );
};

export default FormDetailsPanel;
