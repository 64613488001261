import styles from './styles.module.scss';

const DownloadIcon = ({ className = '', fill = 'white', onClick }) => {
    return (
        <div
            className={`${styles.iconContainer} ${className}`}
            onClick={onClick}
        >
            <svg
                className={className}
                fill="none"
                height="14"
                viewBox="0 0 12 14"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.54247 11.6225H10.4571C10.6454 11.6225 10.8265 11.692 10.9641 11.8169C11.1016 11.9419 11.1852 12.1129 11.1981 12.2954C11.2109 12.478 11.152 12.6585 11.0332 12.8004C10.9144 12.9424 10.7446 13.0352 10.5582 13.0602L10.4571 13.0669H1.54247C1.35425 13.0668 1.17306 12.9973 1.03553 12.8724C0.897994 12.7475 0.814363 12.5765 0.801534 12.3939C0.788704 12.2113 0.847633 12.0309 0.966414 11.8889C1.08519 11.747 1.25497 11.6541 1.44143 11.6292L1.54247 11.6225ZM5.89877 0.0736352L5.9998 0.0668945C6.17932 0.0669019 6.35277 0.130106 6.48806 0.244818C6.62335 0.359531 6.71135 0.517991 6.73576 0.690895L6.74269 0.789117V8.19238L8.97631 6.02186C9.10228 5.89944 9.2693 5.82509 9.44693 5.81236C9.62456 5.79964 9.80099 5.84938 9.94405 5.95252L10.0273 6.02186C10.1532 6.14432 10.2297 6.30669 10.2427 6.47938C10.2558 6.65207 10.2047 6.82359 10.0986 6.96267L10.0273 7.04356L6.52478 10.4476C6.39899 10.5699 6.23224 10.6443 6.05483 10.6572C5.87742 10.6701 5.70113 10.6207 5.55803 10.5179L5.47483 10.4476L1.97235 7.04356C1.83943 6.91469 1.76162 6.74168 1.75457 6.55931C1.74751 6.37694 1.81174 6.19874 1.93433 6.06053C2.05692 5.92232 2.22879 5.83435 2.41537 5.81432C2.60196 5.79428 2.78943 5.84366 2.94009 5.95252L3.02329 6.02186L5.25691 8.1943V0.789117C5.25692 0.614592 5.32194 0.445973 5.43993 0.314443C5.55792 0.182913 5.72092 0.0973707 5.89877 0.0736352Z"
                    fill={fill}
                />
            </svg>
        </div>
    );
};

export default DownloadIcon;
