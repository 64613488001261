import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

const { activityMetaPanel } = state;

const { updateActivityEntityAtom } = activityMetaPanel.atoms;

const MUTATE_DELETE_OLD_ENTITIES = tenantSchema => gql`
    mutation deleteActivityEntities($logId: Int!) {
        delete_${tenantSchema}_log_entity(where: {log_id: {_eq: $logId}}) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_INSERT_NEW_ENTITIES = tenantSchema => gql`
    mutation insertActivityEntity(
        $logId: Int!,
        $entityId: Int!,
    ) {
        insert_${tenantSchema}_log_entity(objects: {
            log_id: $logId, 
            entity_id: $entityId
        }) {
            returning {
                entity_id
            }
        }
    }
`;

// const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
//     mutation insertActivityHistoryDropdown($logId: Int!, $color: String = "yellow", $message: String = "changed the entities", $userId: Int!) {
//         insert_${tenantSchema}_log_history(
//             objects: {
//                 log_id: $logId,
//                 color: $color,
//                 message: $message,
//                 user_id: $userId
//             }
//         ) {
//             returning {
//                 id
//             }
//         }
//     }  
// `;

const useData = (providedSchema) => {
    const mutationDeleteOldEntities = useMutation(MUTATE_DELETE_OLD_ENTITIES(providedSchema));
    const mutationInsertNewEntities = useMutation(MUTATE_INSERT_NEW_ENTITIES(providedSchema));
    // const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY);

    const saveEntities = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
                await mutationDeleteOldEntities(variables);
                
                const newEntities = []
                for (const selection of variables?.entities){
                    variables['entityId'] = selection;
                    
                    const res = await mutationInsertNewEntities(variables);
                    newEntities.push(res?.data[`insert_${providedSchema}_log_entity`]?.returning[0].entity_id);
                }

                // await mutationActivityHistory(variables);

                set(updateActivityEntityAtom, newEntities);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityEntityAtom, '');
    });

    return [useRecoilValue(updateActivityEntityAtom), saveEntities, reset];
};

export default useData;
