import { forwardRef } from 'react';

import InputWithLabel from 'components/InputWithLabel';
import Textarea from 'components/Textarea';

const TextareaInput = forwardRef((props, ref) => {
    const {
        className = '',
        id = '',
        isDisabled = false,
        isReadOnly = false,
        isRequired = false,
        hasError = false,
        name = '',
        label = '',
        onBlur,
        onChange,
        onClick,
        onSubmit,
        placeholder = '',
        rows,
        text,
        type = 'text'
    } = props;

    return (
        <InputWithLabel className={className} text={label}>
            <Textarea
                defaultValue={text}
                disabled={isDisabled}
                hasError={hasError}
                id={id}
                isRequired={isRequired}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                onSubmit={onSubmit}
                placeholder={placeholder}
                readOnly={isReadOnly}
                ref={ref}
                rows={rows}
                text={text}
                type={type}
            />
        </InputWithLabel>
    );
});

export default TextareaInput;
