import { atom } from 'recoil';

const activityAtom = atom({
    default: [],
    key: 'activity'
});

const activityStatusAtom = atom({
    default: '',
    key: 'activityStatus'
});

const state = {
    atoms: {
        activityAtom,
        activityStatusAtom
    }
};

export default state;
