import { memo, useCallback } from 'react';

import BodyText from 'components/BodyText';
import RadioButtons from 'components/RadioButtons';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const RiskLevelRadioButtons = ({ className = '', disabledAllItems, hasEdit = true, label, onChange, riskLabel = '' }) => {
    const handleChange = useCallback(
        (_, radioButtonLabel, radioStates) => {
            if (isFunction(onChange)) {
                onChange?.(radioButtonLabel, radioStates);
            }
        },
        [onChange]
    );

    const radioButtons = [
        {
            className: styles.low,
            isChecked: (riskLabel === 'low'),
            isDisabled: disabledAllItems ? true : hasEdit ? false : true,
            name: 'risk',
            text: 'Low',
            value: 'low',
        },
        {
            className: styles.medium,
            isChecked: (riskLabel === 'medium'),
            isDisabled: disabledAllItems ? true : hasEdit ? false : true,
            name: 'risk',
            text: 'Medium',
            value: 'medium'
        },
        {
            className: styles.high,
            isChecked: (riskLabel === 'high'),
            isDisabled: disabledAllItems ? true : hasEdit ? false : true,
            name: 'risk',
            text: 'High',
            value: 'high'
        },
        {
            className: styles.critical,
            isChecked: (riskLabel === 'critical'),
            isDisabled: disabledAllItems ? true : hasEdit ? false : true,
            name: 'risk',
            text: 'Critical',
            value: 'critical'
        }
    ];

    return (
        <div className={`${className} ${styles.riskLevelRadioButtons}`}>
            <BodyText className={className} text={label} />

            <RadioButtons
                className={styles.radioButtons}
                items={radioButtons}
                onChange={handleChange}
            />
        </div>
    );
};

export default memo(RiskLevelRadioButtons);
