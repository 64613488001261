import ListItem from 'components/ListItem';

import styles from './styles.module.scss';

const BarListItem = ({
    children,
    className = '',
    isActive = false,
    onClick
}) => {
    const activeClass = isActive ? styles.isActive : '';

    return (
        <ListItem
            className={`${activeClass} ${className} ${styles.barListItem}`}
            onClick={onClick}
        >
            {children}
        </ListItem>
    );
};

export default BarListItem;
