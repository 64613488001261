import { useState } from 'react';

import DropdownMultiSelectWithLabel from 'components/DropdownMultiSelectWithLabel';
import Editor from 'components/Editor';
// import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import TextInput from 'components/TextInput';
// import TextBox from 'components/TextBox';
import WithLabel from 'components/WithLabel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const PolicySectionForm = ({ categories, className = '', form, setSectionCategories }) => {
    

    const { CATEGORIES, DESCRIPTION, ENTER_SECTION_NAME, _RISK_LABEL, SECTION_NAME } =
        useTranslation();

    const [editorState, setEditorState] = useState();

    const { handleError, handleSubmit, handleValid } = form;

    return (
        <div className={`${className} ${styles.policySectionForm}`}>
            <TextInput
                label={SECTION_NAME}
                onChange={handleValid('sectionName')}
                onError={handleError('sectionName')}
                onSubmit={handleSubmit('sectionName')}
                placeholder={ENTER_SECTION_NAME}
            />

            <DropdownMultiSelectWithLabel
                change={setSectionCategories}
                label={CATEGORIES}
                options={categories}
            />

            <WithLabel className={styles.description} text={DESCRIPTION}>
                <Editor
                    html={editorState}
                    onChange={setEditorState}
                    onValid={handleValid('description')}
                />
            </WithLabel>

            {/* <TextBox
                label={DESCRIPTION}
                onChange={handleValid('description')}
                onSubmit={handleSubmit('description')}
            /> */}

            {/* <RiskLevelRadioButtons
                label={RISK_LABEL}
                onChange={handleValid('riskLevel')}
            /> */}
        </div>
    );
};

export default PolicySectionForm;
