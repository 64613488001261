import BarListItems from 'components/BarListItems';
import { TitleListItem2 } from 'components/TitleListItem2';
import TextLabel from 'components/TextLabel';


export const DetailedListItems2 = ({
    children,
    className = '',
    header = [],
    headerClassName = '',
    onRenderItem = (item, index) => (
        <TextLabel key={`${item}.${index}`} text={item} />
    )
}) => {
    return (
        <BarListItems className={className}>
            <TitleListItem2
                className={headerClassName}
                items={header}
                onRenderItem={onRenderItem}
            />

            {children}
        </BarListItems>
    );
};
