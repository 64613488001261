import { useCallback, useState, useEffect } from 'react';

import AsideLink from 'components/AsideLink';
import CreatePolicyVersionModal from 'components/CreatePolicyVersionModal';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useTranslation from './hooks/useTranslation';

import useData from './hooks/useData';

const AddVersionLink = ({ className, maxValueVersion, providedSchema, requestId, type }) => {
    const { ADD_VERSION } = useTranslation();

    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState({})

    const [createdVersion, createVersion, reset] = useData(providedSchema);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleFileUpload = newFiles => {
        if(Object.keys(newFiles).length > 0){
            setFiles(newFiles);
        }
    };

    const handleSubmit = useCallback(() => {
        const file = Object.keys(files).length > 0 ? Object.keys(files).join(', ') : document.getElementById('hyperlinkBox').value;
        const newVersionNumber = maxValueVersion+1;

        const variables = {
            createdBy: userId,
            file,
            newVersionNumber,
            requestId,
            status: "draft",
            type
        }
        createVersion(variables);
    }, [createVersion, files, maxValueVersion, requestId, type, userId]);

    useEffect(() => {

        if (!createdVersion) {
            return;
        }

        reset();

        window.location.reload();
    }, [reset, createdVersion]);

    return (
        <>
            <AsideLink
                className={className}
                onClick={handleClick}
                text={`+ ${ADD_VERSION}`}
            />

            {isOpen && (
                <CreatePolicyVersionModal
                    fileUploader={handleFileUpload}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                    providedSchema={providedSchema}
                />
            )}
        </>
    );
};

export default AddVersionLink;
