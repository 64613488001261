import Logo from 'components/Logo';

import styles from './styles.module.scss';

const Header = () => {
    const originUrl = window.location.origin.includes('skematic') || window.location.origin.includes('localhost');
    return (
        <header className={styles.header}>
            
            {originUrl && 
                <Logo className={styles.logo} />
            }

        </header>
    );
}

export default Header;
