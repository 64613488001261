import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) => {
    const users = data[`${schema}_user`].reduce(
        (accumulator, user) => {
            accumulator.push({
                id: user.tenant_user_id,
                name: `${user.first_name} ${user.last_name}`,
                type: 'user'
            });

            return accumulator;
        }, []);
    const groups = data[`${schema}_group`].reduce(
        (accumulator, group) => {
            accumulator.push({
                id: group.id,
                name: `${group.name} Group`,
                type: 'group'
            });

            return accumulator;
        }, []);

    return [...groups, ...users]
    }

const normalizeUserData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeUserData;