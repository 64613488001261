import { atom } from 'recoil';

const peopleAtom = atom({
    default: [],
    key: 'people'
});

const state = {
    atoms: {
        peopleAtom
    }
};

export default state;
