import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import DeactivatePolicyVersionButton from 'components/DeactivatePolicyVersionButton';
import DeletePolicyVersionButton from 'components/DeletePolicyVersionButton';
import PublishPolicyVersionButton from 'components/PublishPolicyVersionButton';
import SubmitButton from 'components/SubmitButton';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useTranslation from './hooks/useTranslation';

const PolicyActions = props => {
    const { activeVersion, className = '', onCancel, onSave, policyId, publishedVersion, status } = props;

    const { SAVE } = useTranslation();

    const { DOCUMENT_MANAGEMENT_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }

        navigate(DOCUMENT_MANAGEMENT_ROUTE);
    }, [DOCUMENT_MANAGEMENT_ROUTE, navigate, onCancel]);

    return (
        <Buttons className={className}>
            <CancelButton onClick={handleCancel} />

            {status === 'active' && (
                <DeactivatePolicyVersionButton
                    policyName={activeVersion.name}
                />
            )}

            {status === 'draft' && (
                <DeletePolicyVersionButton
                    policyVersion={activeVersion.label}
                />
            )}

            {status === 'draft' && (
                <PublishPolicyVersionButton
                    policyId={policyId}
                    policyVersion={activeVersion.label}
                    publishedVersion={publishedVersion}
                />
            )}

            {status !== 'archived' && (
                <SubmitButton onClick={onSave} text={SAVE} />
            )}
        </Buttons>
    );
};

export default PolicyActions;
