import ContentPanel from 'components/ContentPanel';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import PanelBody from 'components/PanelBody';
import TextInput from 'components/TextInput';

import useTranslation from './hooks/useTranslation';

const AdminGroupFormDetailsPanel = ({
    description = '',
    form, 
    name = '',
    supervisorOptions = []
}) => {
    const { GROUP_DESCRIPTION, GROUP_NAME, SUPERVISORS } = useTranslation();

    const supervisorsItems = supervisorOptions.map(
        ({ id, name, type }) => ({
            label: name,
            type,
            value: id
        }),
        []
    );

    const { handleError, handleSubmit, handleValid } = form;

    return (
        <ContentPanel>
            <PanelBody>
                <TextInput
                    label={GROUP_NAME}
                    onChange={handleValid('name')}
                    onError={handleError('name')}
                    onSubmit={handleSubmit('name')}
                    value={name}
                />

                <TextInput
                    label={GROUP_DESCRIPTION}
                    onChange={handleValid('description')}
                    onError={handleError('description')}
                    onSubmit={handleSubmit('description')}
                    value={description}
                />

                <DropdownMultiSelect
                    items={supervisorsItems}
                    label={SUPERVISORS}
                    onError={handleError('supervisors')}
                    onSubmit={handleSubmit('supervisors')}
                    onValid={handleValid('supervisors')}
                    returnObject={true}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default AdminGroupFormDetailsPanel;
