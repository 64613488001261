import TextWithLabel from 'components/TextWithLabel';

import formatToUniversalDate from 'utilities/formatToUniversalDate';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ArchivedPolicyInformation = props => {
    const { className = '', date, name } = props;

    const { EFFECTIVE_DATE, POLICY_NAME } = useTranslation();

    return (
        <div className={`${className} ${styles.archivedPolicyInformation}`}>
            <TextWithLabel
                className={styles.name}
                label={POLICY_NAME}
                text={name}
            />

            <TextWithLabel
                className={styles.effectiveDate}
                label={EFFECTIVE_DATE}
                text={formatToUniversalDate(date)}
            />
        </div>
    );
};

export default ArchivedPolicyInformation;
