import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_document(order_by: {name: asc}) {
        id
        name
        policy_sections(order_by: {section: {name: asc}}, where: {deleted: {_is_null: true}}) {
            section {
                id
                name
            }
        }
    }
    ${tenantSchema}_regulatory_references(order_by: {name: asc}) {
        id
        name
    }
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
