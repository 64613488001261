import AsideMenu from 'components/AsideMenu';
import Logo from 'components/Logo';

import styles from './styles.module.scss';

const NavigationPanel = ({
    className = '',
    handleCollapse,
    handleExpand,
    isCollapsed,
    onNavigationClick
}) => (
    <aside className={`${className} ${styles.navigationPanel}`}>
        <div
            className={`${
                isCollapsed
                    ? styles.logoContainerCollapsed
                    : styles.logoContainer
            }`}
        >
            <Logo className={styles.logo} />
        </div>

        <AsideMenu
            className={styles.asideMenu}
            handleCollapse={handleCollapse}
            handleExpand={handleExpand}
            isCollapsed={isCollapsed}
            onNavigationClick={onNavigationClick}
        />
    </aside>
);

export default NavigationPanel;
