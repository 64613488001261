import { atom } from 'recoil';

const closeActivityMenuListItemAtom = atom({
    default: '',
    key: 'closeActivityMenuListItem'
});

const state = {
    atoms: {
        closeActivityMenuListItemAtom
    }
};

export default state;
