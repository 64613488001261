import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { caseDetailsAtom } = state.caseDetails.atoms;

const useCaseDetails = () => [
    useRecoilValue(caseDetailsAtom),
    useSetRecoilState(caseDetailsAtom)
];

export default useCaseDetails;
