import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE = tenantSchema => gql`
mutation mutateSectionMane($name: String!, $id: Int!) {
    update_${tenantSchema}_sections(where: {id: {_eq: $id}}, _set: {name: $name}) {
      returning {
        name
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const saveSectionName =  variables => {
        const runMutation = async () => {
            if(variables?.name !== ''){
              await mutation(variables);
            }
        };

        runMutation();
    };

    return [saveSectionName];
};

export default useData;
