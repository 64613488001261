const translations = {
    description: 'Description',
    dueDate: 'Due Date',
    expectedUseDate: 'Expected Use Date',
    highImportance: 'High Importance',
    instructions: 'Instructions',
    introduceNewDocumentSubmission: 'Introduce New Document Submission',
    recipient: 'Recipient',
    requestName: 'Request Name',
    selectedFiles: 'Selected Files'
};

export default translations;
