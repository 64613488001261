const normalizeListData = ({ data, querySchemas=[], schema, schemaData, tenants }) => {
    if(Array.isArray(tenants) && tenants.length > 0){
        const tenantData = [];
        for(const t of tenants){
            if(t){
                if(querySchemas.length > 0){
                    tenantData.push(schemaData({ data, querySchemas, schema:t }));
                }else{
                    tenantData.push(schemaData({ data, schema:t }));
                }
            }
        }
        const consolidatedTenantData = []
        for (const d of tenantData){
            if(d){
                consolidatedTenantData.push(...d);
            }
        }
        return consolidatedTenantData;
    }else{
        return schemaData({ data, schema });
    }
}

export default normalizeListData;