import httpPost from 'utilities/httpPost';

async function getFileSize(directoryName, fileName, apiKey, getFileSizeUrl) {
  const formData = new FormData();
  formData.append('file_name', fileName);
  formData.append('schema', directoryName);
  const accessToken = localStorage.getItem('accessToken')
  formData.append('access_token', accessToken);
  const file_size_response = await httpPost({data:formData, key: apiKey ? apiKey : '', url:getFileSizeUrl});
  return file_size_response.file_size;
}

export default getFileSize;