import ContentBox from 'components/ContentBox';
import ContentPanel from 'components/ContentPanel';
import DateWithLabel from 'components/DateWithLabel';
import LinkInNewTabWithLabel from 'components/LinkInNewTabWithLabel';
import PanelBody from 'components/PanelBody';
import RiskPillWithLabel from 'components/RiskPillWithLabel';
import Title from 'components/Title';

import useConfig from 'hooks/useConfig';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const ComplianceEntryPolicyPanel = props => {
    const { className = '', id, logSchema, name } = props;

    const { POLICY_ROUTE } = useConfig();

    const {
        DESCRIPTION,
        EFFECTIVE_DATE,
        POLICY_DETAILS,
        POLICY_NAME,
        POLICY_REFERENCE,
        RISK_LABEL
    } = useTranslation();

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={POLICY_DETAILS} />

                <LinkInNewTabWithLabel
                    label={POLICY_NAME}
                    text={name}
                    to={POLICY_ROUTE.replace(':policyId', id).replace(':policySchema', logSchema)}
                />

                <RiskPillWithLabel level={''} text={RISK_LABEL} />

                <LinkInNewTabWithLabel label={POLICY_REFERENCE} text={name} to={POLICY_ROUTE.replace(':policyId', id).replace(':policySchema', logSchema)}/>

                <DateWithLabel date={''} text={EFFECTIVE_DATE} />

                <ContentBox
                    className={styles.contentBox}
                    html={''}
                    title={DESCRIPTION}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default ComplianceEntryPolicyPanel;
