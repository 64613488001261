import formatDate from 'utilities/formatDate';

const buildNameFromUser = user => {
    if (!user) return '';

    return `${user.firstName ?? user.first_name} ${
        user.lastName ?? user.last_name
    }`.trim();
};

const splitRowsIntoSheets = rows => {
    const headersSet = new Set();
    const fittedRows = [];

    for (const row of rows) {
        for (const key of Object.keys(row)) {
            headersSet.add(key);
        }
    }

    const headers = Array.from(headersSet);

    for (const row of rows) {
        const fittedRow = [];
        for (const header of headers) {
            fittedRow.push(row[header] ?? '');
        }
        fittedRows.push(fittedRow);
    }

    const sheets = [
        {
            headerKey: 'ALL ROWS',
            headers,
            name: 'All Marketing Requests',
            rows: fittedRows
        }
    ];

    return sheets;
};

const parseDetailedDataForReport = (
    data = [],
    dataQuestionsAnswers,
    schema
) => {
    const rows = [];

    // Sort data first by status then by due date to match the dashboard
    //
    data = data.sort((a, b) => {
        if (a.status === b.status) {
            return new Date(a.due_date) - new Date(b.due_date);
        }
        const statusOrder = { approved: 1, draft: 0, withdrawn: 2 };

        return (statusOrder[a.status] ?? 0) - (statusOrder[b.status] ?? 0);
    });

    data.forEach(d => {
        const versions = d?.marketing_request_versions;
        let approvedDate = undefined;
        let approvedBy = undefined;
        if (versions && versions.length > 0) {
            const approvedVersion = versions
                .filter(x => x.status === 'approved')
                .slice(-1)[0];
            if (approvedVersion) {
                const histories =
                    approvedVersion?.marketing_request_version_histories;
                if (histories.length > 0) {
                    const approvedHistory = histories
                        .filter(x => x.message.includes('approved'))
                        .slice(-1)[0];
                    const unformattedApprovedDate = new Date(
                        approvedHistory?.created_at
                    );
                    approvedDate = unformattedApprovedDate;
                    approvedBy = `${
                        approvedHistory?.tenant_user[
                            `user_${localStorage.schema}`
                        ]?.first_name
                    } ${
                        approvedHistory?.tenant_user[
                            `user_${localStorage.schema}`
                        ]?.last_name
                    }`;
                }
            }
        }
        if (approvedDate) approvedDate = formatDate(new Date(approvedDate));

        const dataQuestionsSchema =
            dataQuestionsAnswers && Object.keys(dataQuestionsAnswers).length > 0
                ? dataQuestionsAnswers[Object.keys(dataQuestionsAnswers)[0]]
                : [];
        const dataQuestions =
            dataQuestionsSchema.length > 0 ? dataQuestionsSchema[0] : undefined;

        const marketingReviewUrl =
            schema && window.location.origin
                ? `${window.location.origin}/marketing-request/${schema}/${
                      d.id
                  }/${1}`
                : undefined;

        const relevantQuestions =
            dataQuestions?.form_questions.flatMap(formQuestion => {
                const dataQuestions =
                    formQuestion.marketing_form_answers.reduce(
                        (acc, answer) => {
                            if (answer.marketing_id === d.id) {
                                const data = {
                                    answers: answer.answer,
                                    question: formQuestion.question.name,
                                    questionId: formQuestion.question.id,
                                    questionUID:
                                        formQuestion.question.questionId
                                };
                                acc.push(data);
                            }
                            return acc;
                        },
                        []
                    );
                return dataQuestions.length > 0 ? dataQuestions : [];
            }) ?? [];

        const row = {};
        row['Request Name'] = d?.request_name ? d?.request_name : '';
        if (row['Request Name'] && marketingReviewUrl) {
            row['Request Name'] = {
                hyperlink: marketingReviewUrl,
                text: row['Request Name']
            };
        }
        row['Status'] =
            d?.status === 'approved'
                ? 'Approved'
                : d?.status === 'withdrawn'
                ? 'Withdrawn'
                : d?.status === 'draft'
                ? 'Draft'
                : undefined;
        row['Due Date'] = d?.due_date ? formatDate(new Date(d?.due_date)) : '';
        row['Expected Use Date'] = d?.expected_use_date
            ? formatDate(new Date(d?.expected_use_date))
            : '';
        row['Created Date'] =
            d?.marketing_request_versions.length > 0 &&
            d?.marketing_request_versions[0]?.created_date
                ? formatDate(
                      new Date(d?.marketing_request_versions[0]?.created_date)
                  )
                : '-';
        row['Assigned To'] = d?.marketing_request_assigned_tos
            ? d?.marketing_request_assigned_tos
                  .reduce((accumulator, user) => {
                      if (user?.tenant_user) {
                          accumulator.push(
                              `${
                                  user?.tenant_user[
                                      `user_${localStorage.schema}`
                                  ]?.first_name
                              } ${
                                  user?.tenant_user[
                                      `user_${localStorage.schema}`
                                  ]?.last_name
                              }`
                          );
                          return accumulator;
                      } else if (user?.group) {
                          accumulator.push(`${user?.group?.name} Group`);
                          return accumulator;
                      } else {
                          return accumulator;
                      }
                  }, [])
                  .join(', ')
            : '';
        row['Requested By'] = d?.tenant_user
            ? `${d?.tenant_user[`user_${localStorage.schema}`]?.first_name} ${
                  d?.tenant_user[`user_${localStorage.schema}`]?.last_name
              }`
            : '';
        row['Approved Version'] =
            d?.marketing_request_versions.length > 0
                ? d?.marketing_request_versions
                      .filter(x => x.status === 'approved')
                      .slice(-1)[0]?.version_number
                : '-';
        row['Approved On'] = approvedDate;
        row['Approved By'] = approvedBy;
        if (relevantQuestions.length > 0) {
            relevantQuestions.forEach(question => {
                row[
                    `questionName-${question.questionId}-${question.question}`
                ] = question.answers;
            });
        }

        row['Attachments'] =
            versions && versions.length > 0
                ? versions
                      .map(attachment =>
                          attachment.file_name ? `${attachment.file_name}` : ''
                      )
                      .join('\n')
                      .trim()
                : undefined;

        row['Comments'] = d?.marketing_request_version_comments
            ?.map(
                comment =>
                    `${formatDate(
                        new Date(comment.created_at)
                    )} - ${buildNameFromUser(
                        comment.tenant_user[Object.keys(comment.tenant_user)[0]]
                    )}\n${comment.text}`
            )
            .join('\n');

        rows.push(row);
    });

    return splitRowsIntoSheets(rows);
};

export default parseDetailedDataForReport;