import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deletePolicyVersionModal');

    return {
        CANCEL: t('cancel'),
        DELETE: t('delete'),
        YOU_ARE_ABOUT_TO_DELETE_DRAFT: t('youAreAboutToDeleteDraft')
    };
};

export default useTranslation;
