import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updatePolicyReference } = state;

const { updatePolicyReferenceAtom } = updatePolicyReference.atoms;

const MUTATE_DELETE_OLD_POLICY_REFERENCES = tenantSchema => gql`
    mutation deletePolicyCase($caseId: Int!) {
        delete_${tenantSchema}_case_policies(where: {case_id: {_eq: $caseId}}) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_INSERT_NEW_POLICY_REFERENCES = tenantSchema => gql`
    mutation insertCasePolicy(
        $caseId: Int!,
        $policyId: Int!,
        $sectionId: Int
    ) {
        insert_${tenantSchema}_case_policies(objects: {
            case_id: $caseId, 
            document_mapping_id: $policyId,
            document_section_id: $sectionId
        }) {
            returning {
                document {
                    description
                    id
                    name
                    risk_label
                }
                section {
                    description
                    id
                    name
                    riskLevel
                }
            }
        }
    }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the policy reference", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const resolvePolicies = casePolicies =>
    casePolicies.reduce((accumulator, { document: policy, section }) => {
        if (section == null){
            accumulator.push({description: policy?.description, id: policy?.id, name: policy?.name, riskLevel: policy?.risk_label});
        }else{
            accumulator.push({description: section?.description, id: policy?.id, name: section?.name, riskLevel: section?.riskLevel})
        }

        return accumulator;
    }, []);

const useData = (providedSchema) => {
    const mutationDeleteOldPolicyReferences = useGraphQL(MUTATE_DELETE_OLD_POLICY_REFERENCES, providedSchema);
    const mutationInsertNewPolicyReferences = useGraphQL(MUTATE_INSERT_NEW_POLICY_REFERENCES, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const savePolicyReference = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
                await mutationDeleteOldPolicyReferences(variables);
                
                const newPolicies = []
                for (const policy of variables?.policies){
                    variables['policyId'] = policy.split(".")[0];
                    variables['sectionId'] = (policy.split(".")[1] !== "0") ? policy.split(".")[1] : null;
                    const res = await mutationInsertNewPolicyReferences(variables);
                    newPolicies.push(res?.data[`insert_${usedSchema}_case_policies`]?.returning[0]);
                }

                const newPolicyReference = resolvePolicies(newPolicies);

                await mutationCaseHistory(variables);

                set(updatePolicyReferenceAtom, newPolicyReference);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updatePolicyReferenceAtom, '');
    });

    return [useRecoilValue(updatePolicyReferenceAtom), savePolicyReference, reset];
};

export default useData;
