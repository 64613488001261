import { atom, atomFamily } from 'recoil';

const complianceEntryDetailsPanelAtom = atom({
    default: '',
    key: 'complianceEntryDetailsPanel'
});

const complianceEntryDetailsPanelDropdownsAtom = atom({
    default: [],
    key: 'complianceEntryDetailsPanelDropdowns'
});

const updateDescriptionAtom = atom({
    default: '',
    key: 'updateDescription'
});

const complianceEntryDetailsPanelDropdownsAtomFamily = atomFamily({
    default: {
        id: undefined,
        options: [],
        selectedIndex: undefined,
        title: ''
    },
    key: ''
});

const state = {
    atomFamilies: {
        complianceEntryDetailsPanelDropdownsAtomFamily
    },

    atoms: {
        complianceEntryDetailsPanelAtom,
        complianceEntryDetailsPanelDropdownsAtom,
        updateDescriptionAtom
    }
};

export default state;
