import Avatar from 'components/Avatar';
import BodyText from 'components/BodyText';
import ImportantText from 'components/ImportantText';

import styles from './styles.module.scss';

const statusClassnames = {
    complete: styles.complete,
    'completed late': styles.completedLate,
    'past due': styles.pastDue
};

const ActivityUserListItem = ({ className = '', id, name, status }) => (
    <div className={`${className} ${styles.activityUserListItem}`}>
        <Avatar 
            className={styles.avatar}
            user={{id: id, name:name}}
        />

        <ImportantText className={styles.title} text={name} />

        <BodyText
            className={`${styles.status} ${statusClassnames[(status || '').toLowerCase()]}`}
            text={status}
        />
    </div>
);

export default ActivityUserListItem;
