import { useCallback, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import GroupUserList from 'components/GroupUserList';
import PanelBody from 'components/PanelBody';
import GroupSelectUsersDrawer from 'components/GroupSelectUsersDrawer';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdminGroupFormMembersPanel = ({ memberOptions, selectedMembers }) => {
    const { ADD_MEMBER } = useTranslation();

    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleAddOrRemove = useCallback((clickedUser) => {
        const foundUser = selectedUsers.find(
            selectedUser => (selectedUser.id === clickedUser.userId) && (selectedUser.type === clickedUser.type)
        );
        if (foundUser) {
            const remainingSelectedUsers = selectedUsers.filter(
                selectedUser => (selectedUser.id !== foundUser.userId) && (selectedUser.name !== foundUser.name)
            );
            selectedMembers.current.value = [...remainingSelectedUsers];            
            setSelectedUsers([...remainingSelectedUsers]);
        } else {
            const addedMember = memberOptions.find(
                member => member.id === clickedUser.userId && member.type === clickedUser.type
            );
            selectedMembers.current.value = [...selectedUsers, addedMember];            
            setSelectedUsers([...selectedUsers, addedMember]);
        }

    }, [memberOptions, selectedMembers, selectedUsers]);

    return (
        <ContentPanel>
            <PanelBody className={styles.panelBody}>
                <div className={styles.groupMemberSection}>
                    {/* <BodyText className={styles.title} text={`${GROUP_MEMBERS} (${memberOptions.length})`} /> */}

                    <GroupSelectUsersDrawer 
                        assignmentType={''} 
                        onChange={handleAddOrRemove}
                        selectedUsers={selectedUsers}
                        users={memberOptions}
                    >
                        {({ toggle }) => (
                            <GroupUserList
                                className={styles.members}
                                key={ADD_MEMBER}
                                onEditClick={toggle}
                                title={ADD_MEMBER}
                                users={selectedUsers}
                            />
                        )}
                    </GroupSelectUsersDrawer>
                </div>
            </PanelBody>
        </ContentPanel>
    );
};

export default AdminGroupFormMembersPanel;
