// import Pill from 'components/Pill';

// import styles from './styles.module.scss';

// import useTranslation from './hooks/useTranslation';

const NeutralRiskPill = (/*{ className = '' }*/) => {
    // const { NEUTRAL } = useTranslation();

    return (
        // <Pill
        //     className={`${className} ${styles.NeutralRiskPill}`}
        //     label={NEUTRAL}
        // />
        "-"
    );
};

export default NeutralRiskPill;
