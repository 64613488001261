import InProgressSummarizedListItems from 'components/InProgressSummarizedListItems';
import InProgressItems from 'components/InProgressItems';

const InProgressSummarizedItems = ({ className = '', items, onDownloadItem }) =>  (
    <InProgressItems className={className}>
        <InProgressSummarizedListItems
            items={items}
						onDownloadItem={onDownloadItem}
        />
    </InProgressItems>
);

export default InProgressSummarizedItems;
