const translations = {
    areYouSure: 'Are you sure?',
    cancel: 'No, cancel',
    publish: 'Yes, Publish',
    youAreAboutToPublish: `
        You are about to publish a new policy version "NEW_VERSION" from draft state. 
        This will archive the current active version "CURRENT_VERSION". 
        Do you still want to proceed ?
    `
};

export default translations;
