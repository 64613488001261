import Checkbox from 'components/Checkbox';
import HeaderText from 'components/HeaderText';
import SortableHeader from 'components/SortableHeader';
import TableRow from 'components/TableRow';

import styles from './styles.module.scss';

const TableHeader = ({
    className = '',
    items = [],
    onSort,
    sortBy,
    selectable,
    isAllSelected,
    onSelectAll
}) => (
    <TableRow className={`${className} ${styles.tableHeader}`}>
        {selectable && (
            <HeaderText
                text={
                    <Checkbox
                        isChecked={isAllSelected}
                        onChange={onSelectAll}
                    />
                }
            />
        )}

        {items.map(({ isSortable, key, text }) =>
            isSortable ? (
                <SortableHeader
                    isAscending={sortBy?.isAscending}
                    isSorted={sortBy?.key === key}
                    key={key}
                    onSort={onSort}
                    sortKey={key}
                    text={text}
                />
            ) : (
                <HeaderText key={key} text={text} />
            )
        )}
    </TableRow>
);

export default TableHeader;
