import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('cancelButton');

    return {
        CANCEL: t('cancel')
    };
};

export default useConstants;
