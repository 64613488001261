import { useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { marketingRequestMetaPanelAtom } = state.marketingRequestMetaPanel.atoms;

const DELETE_COMMENT = tenantSchema => gql`
    mutation delete_comment($commentId: Int!) {
        update_${tenantSchema}_marketing_request_version_comments(
            where: { id: { _eq: $commentId } },
            _set: { deleted: true }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_COMMENT = tenantSchema => gql`
    mutation insert_single_comment($comment: String!, $requestId: Int!, $versionId: Int!, $userId: Int!) {
        insert_${tenantSchema}_marketing_request_version_comments(
            objects: {
                text: $comment,
                request_id: $requestId,
                version_id: $versionId,
                created_by: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const UPDATE_COMMENT = tenantSchema => gql`
    mutation update_comment($commentId: Int!, $newText: String!) {
        update_${tenantSchema}_marketing_request_version_comments(
            where: { id: { _eq: $commentId } },
            _set: { text: $newText }
        ) {
            returning {
                id
            }
        }
    }
`;

const useComment = (requestId, requestSchema) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [deleteComment] = useMutation(DELETE_COMMENT(requestSchema));
    const [insertComment] = useMutation(MUTATE_COMMENT(requestSchema));
    const [updateComment] = useMutation(UPDATE_COMMENT(requestSchema));

    const insertCommentText = async (versionComment) => {
        const outputComment = await insertComment({
            variables: {
                comment: versionComment.addedComment,
                requestId: requestId,
                userId: Number(userId),
                versionId: versionComment.versionId,
            }
        });

        const { id: commentId } =
            outputComment?.data[`insert_${requestSchema}_marketing_request_version_comments`].returning[0];

        return commentId;
    };

    const deleteCommentText = async commentId => {
        await deleteComment({
            variables: {
                commentId
            }
        });
    };

    const updateCommentText = async (commentId, newText) => {
        await updateComment({
            variables: {
                commentId,
                newText
            }
        });
    };

    return [
        useRecoilValue(marketingRequestMetaPanelAtom),
        insertCommentText,
        deleteCommentText,
        updateCommentText
    ];
};

export default useComment;
