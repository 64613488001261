import { memo } from 'react';

import Avatar from 'components/Avatar';

import styles from './styles.module.scss';

const WithAvatar = ({ className = '', children, user }) => (
    <div className={`${className} ${styles.withAvatar}`}>
        <Avatar user={user} />

        {children}
    </div>
);

export default memo(WithAvatar);
