export const getFileNameFromUrl = url => {
    const parts = url.split('/');

    return parts.at(-1);
};

export const getDefaultFilesState = data =>
    data.reduce(
        (base, { name, url, validationErrors = [] }) => ({
            ...base,
            [name]: {
                isUploadError: false,
                isUploading: false,
                url,
                validationErrors
            }
        }),
        {}
    );

export const getStateFromUrls = urls => {
    const data = urls.map(url => ({
        name: getFileNameFromUrl(url),
        url
    }));

    return getDefaultFilesState(data);
};

export const getStateFromLocalFiles = files => {
    const data = files.map(file => ({
        name: file.newName,
        url: URL.createObjectURL(file)
    }));

    return getDefaultFilesState(data);
};

export const getRejectedFilesState = rejections => {
    const data = rejections.map(({ file, errors }) => ({
        name: file.newName,
        url: URL.createObjectURL(file),
        validationErrors: errors.map(err => err.message)
    }));

    return getDefaultFilesState(data);
};
