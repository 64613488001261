import { useCallback } from 'react';

import GroupTag from 'components/GroupTag';
import Tag from 'components/Tag';
import UserTag from 'components/UserTag';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const tagMap = {
    group: GroupTag,
    user: UserTag
};

const Tags = ({ className = '', items = [], onClose }) => {
    

    const handleClose = useCallback(
        index => () => {
            if (isFunction(onClose)) {
                onClose(index);
            }
        },
        [onClose]
    );

    return (
        <div className={`${className} ${styles.tags}`}>
            {items.map(({ label, type }, index) => {
                const TagComponent = tagMap[type] || Tag;

                return (
                    <TagComponent
                        key={label + index}
                        onClose={handleClose(index)}
                        text={label}
                    />
                );
            })}
        </div>
    );
};

export default Tags;
