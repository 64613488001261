const translations = {
    active: 'Active',
    inactive: 'Inactive',
    nameOfTheCase: 'Name of the case',
    notifyToGroups: 'Notify to groups',
    reasonForTriggering: 'Reason for triggering',
    status: 'Status'
};

export default translations;
