import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { updatePublishedVersion } = state;

const { updatePublishedVersionAtom } = updatePublishedVersion.atoms;

const MUTATE_UNPUBLISH = tenantSchema => gql`
  mutation unpublish($documentId: Int!, $versionNumber: Int!) {
    update_${tenantSchema}_document_version(where: {document_id: {_eq: $documentId}, version_number: {_eq: $versionNumber}}, _set: {is_active_version: false}) {
        returning {
          id
        }
      }
  }
`;

const MUTATE_PUBLISH = tenantSchema => gql`
    mutation publish($documentId: Int!, $versionNumber: Int!) {
        update_${tenantSchema}_document_version(where: {document_id: {_eq: $documentId}, version_number: {_eq: $versionNumber}}, _set: {is_active_version: true, is_draft: false}) {
            returning {
                id
            }
        }
}
`;

const useData = () => {
    const mutationUnpublish = useGraphQL(MUTATE_UNPUBLISH);
    const mutationPublish = useGraphQL(MUTATE_PUBLISH);

    const publishNewVersion = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
          
            await mutationUnpublish({documentId: variables?.policyId, versionNumber: variables?.publishedVersion});            
            await mutationPublish({documentId: variables?.policyId, versionNumber: variables?.policyVersion});

            set(updatePublishedVersionAtom, variables?.policyVersion)
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updatePublishedVersionAtom, '');
    });

    return [useRecoilValue(updatePublishedVersionAtom), publishNewVersion, reset];
};

export default useData;