import { atom } from 'recoil';

const updateCaseTypeMetaPanelNameAtom = atom({
    default: '',
    key: 'updateCaseTypeMetaPanelName'
})

const state = {
    atoms: {
        updateCaseTypeMetaPanelNameAtom,
    }
};

export default state;
