import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecGroup = (tenantSchema) => gql`
    query getGroup {
        ${tenantSchema}_group {
            id
            name
            description
            group_users {
                id
                group_id
                tenant_user_id
            }
            group_supervisors {
                tenant_user {
                    id
                    user_${tenantSchema} {
                        first_name
                        last_name
                    }
                }
                groupBySupervisoryGroupId {
                    id
                    name
                }
            }
        }
        ${tenantSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
            tenant_user_id
            first_name
            last_name
        }
    }
`;

const useData = groupId => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();

    return useQuery(querySpecGroup(schema, groupId, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
