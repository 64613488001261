import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import DropdownWithTags from 'components/DropdownWithTags';
import Editor from 'components/Editor';
import FormField from 'components/FormField';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import TextInput from 'components/TextInput';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useDataQuery from './hooks/useDataQuery';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AddSectionModal = ({ onClose }) => {
    const { CASE_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();
    const {
        ADD_SECTION,
        CATEGORIES,
        SECTION_NAME,
        SECTION_CONTENTS
    } = useTranslation();

    const navigate = useNavigate();

    const { useConsultancyUsers, useSchema, useTenants, useUserId, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();
    const [consultancyUsers] = useConsultancyUsers();

    const [_errorMessage, setErrorMessage] = useState();
    const [consultancyUsersForUse, setConsultancyUsersForUse] = useState([]);

    const [createdCase, createCase, reset] = useData();

    const [editorState, setEditorState] = useState(`<p></p>`);

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const { loading, error, data } = useDataQuery();

    const form = useForm({
        assignedTo: '',
        caseName: '',
        caseType: '',
        creationReason: '',
        dueDate: '',
        policyId: '',
        riskLevel: ''
    });

    const { handleError, handleForm, handleSubmit, handleValid } = form;

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};

        output.forEach((value, key) => (formObject[key] = value));

        formObject['createdBy'] = userId;
        var newDate;
        if (formObject['dueDate'] === ''){
            const today = new Date();
            newDate = new Date(today.setDate(today.getDate() + 180));
        }else{
            newDate = new Date(formObject['dueDate']);
        }
        formObject['dueDate'] = newDate;
        formObject['originated'] = 'adhoc';
        formObject['sectionId'] = formObject['policyId'].includes(".") ? formObject['policyId'].split('.')[1] !== '0'
                ? Number(formObject['policyId'].split('.')[1])
                : null
                : null;
        formObject['policyId'] = formObject['policyId'].includes(".") ? formObject['policyId'].split('.')[0] : formObject['policyId'];

        formObject['policyId'] = formObject['policyId'] !== '' ? Number(formObject['policyId']) : null;

        createCase(formObject);
    }, [createCase, handleForm, setErrorMessage, userId]);

    const _setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    useEffect(() => {
        if (data) {
            const tenantSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
            setConsultancyUsersForUse(consultancyUsers.filter(item => item.tenants.includes(tenantSchema)))
        }
    }, [consultancyUsers, data, schema, setConsultancyUsersForUse, tenants, userSetSchema]);

    useEffect(() => {
        if (!createdCase) {
            return;
        }

        if (isFunction(onClose)) {
            onClose(createdCase);

            navigate(CASE_ROUTE.replace(':caseId', createdCase).replace(':caseSchema', userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema));

            reset();
        }
    }, [CASE_ROUTE, createdCase, navigate, onClose, reset, userSetSchema, schema, tenants]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <Modal isOpen={true} onClose={handleClose} overlayClose={false}>
            <PanelBody loading={loading}>
                <PanelTitle className={styles.panelTitle} text={ADD_SECTION} />

                <TextInput
                    isRequired={true}
                    label={SECTION_NAME}
                    onChange={handleValid('caseName')}
                    onError={handleError('caseName')}
                    onSubmit={handleSubmit('caseName')}
                    placeholder={SECTION_NAME}
                />

                <FormField label={SECTION_CONTENTS} >
                    <Editor
                        html={editorState}
                        onChange={setEditorState}
                        onValid={handleValid('creationReason')}
                        placeholder={SECTION_CONTENTS}
                    />
                </FormField>

                <DropdownWithTags
                    items={consultancyUsersForUse}
                    label={CATEGORIES}
                    onChange={handleValid('assignedTo')}
                    tags={[]}
                />

                <CancelSaveButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onSave={handleSave}
                />
            </PanelBody>
        </Modal>
    );
};

export default AddSectionModal;
