import { useCallback } from 'react';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const RadioButton = ({
    className = '',
    isChecked,
    isDisabled = false,
    name = '',
    onChange,
    value = ''
}) => {
    const handleChange = useCallback(
        event => {
            if (onChange && isFunction(onChange)) {
                onChange(event);
            }
        },
        [onChange]
    );

    return (
        <input
            checked={isChecked}
            className={`${className} ${styles.radioButton} ${isDisabled ? styles.isDisabled : ''}`}
            disabled={isDisabled}
            name={name}
            onChange={handleChange}
            type="radio"
            value={value}
        />
    );
};

export default RadioButton;
