import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const filteredCasesDataAtom = atom({
    default: { closed: [], overdue: [], pending: [] },
    key: 'filteredCasesDataAtom'
});

export function useGetFilteredCasesData() {
    return useRecoilValue(filteredCasesDataAtom);
}

export function useSetFilteredCasesData() {
    return useSetRecoilState(filteredCasesDataAtom);
}