import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { policiesListAtom } = state.policiesList.atoms;

const usePoliciesList = () => [
    useRecoilValue(policiesListAtom),
    useSetRecoilState(policiesListAtom)
];

export default usePoliciesList;