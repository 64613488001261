import { useQuery } from '@apollo/client';

import generateQueryFromDict from 'utilities/generateQueryFromDict';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = (tenantSchema, userGQL, usersGQL) => `
    ${tenantSchema}_activity(where: {activity_attachments: {_not: {name: {_is_null: true}}}}){
      id
      name
			deleted
      activity_attachments(where: {_or: [{deleted: {_eq: false}}, {deleted: {_is_null: true}}]}) {
        id
        name
        file
        deleted
      }
      activity_type{
        name
      }
      created: activity_histories(where: {message: {_like: "created%"} }){
        message,
        created_at,
        tenant_user{
          ${usersGQL}
        }
      }
      uploaded: activity_histories(where: {message: {_like: "uploaded%"} }){
        message,
        created_at,
        tenant_user{
          ${usersGQL}
        }
      }
    }
`;

const useActivityData = () => {
  const { useQuerySchemas, useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [querySchemas] = useQuerySchemas();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
  var usersGQL = '';
  if(querySchemas.length > 0){
      for(const s of querySchemas){
          usersGQL += `users_${s.value} {
              first_name
              last_name
          }`
      }
  }else if(usedSchema){
      usersGQL += `users_${usedSchema} {
          first_name
          last_name
      }`
  }
  const genQuery = generateQueryFromDict({query, schema:usedSchema, tenants, userGQL:{}, usersGQL});
  console.log(genQuery)
  return useQuery(genQuery, { fetchPolicy: 'no-cache' });
};

export default useActivityData;
