import { useEffect, useState } from 'react';

import Activities from 'components/Activities';
import CompletedDetailedItems from 'components/CompletedDetailedItems';
import InProgressDetailedItems from 'components/InProgressDetailedItems';
import OverdueDetailedItems from 'components/OverdueDetailedItems';
import UpcomingDetailedItems from 'components/UpcomingDetailedItems';
import { getSchemaDataName } from 'utilities/schema';
// import { getFileName } from 'utilities/files';


// import useActivities from './hooks/useActivities';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import lexigraphicalSort from 'utilities/lexigraphicalSort';
import filterDataByName from 'utilities/searchFilterByName';
import normalizeData from '../Activities/data';
import useData from '../Activities/hooks/useData';
import useTranslation from './hooks/useTranslation';
import { useExportActivities } from 'hooks/useExportActivities';
import { completeActivitiesFilter, computeStatusFilterValues, inProgressActivitiesFilter, overdueActivitiesFilter, statusFilterLabelValues, upcomingActivitiesFilter } from './filters';
import { useSetFilteredActivitiesData } from 'components/Activities/hooks/useFilteredActivities';

const DetailedActivities = ({
		detailedData={},
    endDate: filterEndDate,
    entity,
    isAll,
    isFlagged,
    recurrency,
    review,
    searchFilter,
    setEntityItems,
    setRecurrencyItems,
    setReviewItems,
		setStatusItems,
    setTypeItems,
    setUserItems,
    startDate: filterStartDate,
		status,
    type: activityType,
    user
}) => {
    const { useActivities, useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
		const { exportDetailedActivities } = useExportActivities()
    const { VIEW_ALL } = useTranslation();
    const [activities, setActivities] = useActivities();

    const { _loading, error, data } = useData();

		const setFilteredActivitiesData = useSetFilteredActivitiesData()
    
        useEffect(() => {
        if (data && (!activities || !('completed' in activities))) {
            const normalizedData = normalizeData({
                data,
                isFlagged,
                querySchemas,
                schema,
                tenants,
                user: userId
            });

            setActivities(normalizedData);
        }
    // eslint-disable-next-line
    }, [data, isFlagged, querySchemas, tenants, schema, setActivities, userId]);

    const [filteredCompleteActivities, setFilteredCompleteActivities] = useState();
    const [filteredInProgressActivities, setFilteredInProgressActivities] = useState();
    const [filteredUpcomingActivities, setFilteredUpcomingActivities] = useState();
    const [filteredOverdueActivities, setFilteredOverdueActivities] = useState();
    const [hasCompletedItems, setHasCompletedItems] = useState();
    const [hasOverdueItems, setHasOverdueItems] = useState();
    const [hasInProgressItems, setHasInProgressItems] = useState();
    const [hasUpcomingItems, setHasUpcomingItems] = useState();
    const [completeActivities, setCompletedItems] = useState();
    const [overdueActivities, setOverdueItems] = useState();
    const [inProgressActivities, setInProgressItems] = useState();
    const [upcomingActivities, setUpcomingItems] = useState();

    useEffect(() => {
        const {
            completed,
            inProgress,
            overdue,
            upcoming
        } = activities;
        setCompletedItems(completed);
        setInProgressItems(inProgress);
        setOverdueItems(overdue);
        setUpcomingItems(upcoming);
    }, [activities, setCompletedItems, setOverdueItems, setInProgressItems, setUpcomingItems])

    useEffect(() => {
        if (completeActivities) {
            const sortedCompleteActivities = completeActivitiesFilter({
                activityType,
                completeActivities,
                entity,
                filterEndDate,
                filterStartDate,
                isAll,
                isFlagged,
                recurrency,
                review,
                status,
                user,
                userId
            });

            setFilteredCompleteActivities(sortedCompleteActivities);
            setHasCompletedItems(sortedCompleteActivities?.length > 0);
        }
    }, [status,isAll, isFlagged, userId, activityType, filterStartDate, filterEndDate, entity, completeActivities, setFilteredCompleteActivities, setHasCompletedItems, user,recurrency, review]);

    useEffect(() => {
        if (inProgressActivities) {

            const filteredInProgressActivities = inProgressActivitiesFilter({
                activityType,
                entity,
                inProgressActivities,
                isAll,
                isFlagged,
                recurrency,
                review,
                status,
                user,
                userId
            });

            setFilteredInProgressActivities(filteredInProgressActivities);
            setHasInProgressItems(filteredInProgressActivities?.length > 0);
        }
    }, [status, isAll, isFlagged, userId, activityType, filterStartDate, filterEndDate, entity, inProgressActivities, setFilteredInProgressActivities, setHasInProgressItems, user, recurrency, review]);

    useEffect(() => {
        if (upcomingActivities){
					const sortedUpcomingActivities = upcomingActivitiesFilter({
                        activityType,
                        entity,
                        filterEndDate,
                        filterStartDate,
                        isAll,
                        isFlagged,
                        recurrency,
                        review,
                        status,
                        upcomingActivities,
                        user,
                        userId
                    });

            setFilteredUpcomingActivities(sortedUpcomingActivities);
            setHasUpcomingItems(sortedUpcomingActivities?.length > 0);
        }
    }, [status, isAll, isFlagged, userId, activityType, filterStartDate, filterEndDate, entity, upcomingActivities, setFilteredUpcomingActivities, setHasUpcomingItems, user,recurrency, review]);

    useEffect(() => {
        if (overdueActivities){
            const filteredOverdueActivities = overdueActivitiesFilter({
                activityType,
                entity,
                isAll,
                isFlagged,
                overdueActivities,
                recurrency,
                review,
                status,
                user,
                userId,
            });

            setFilteredOverdueActivities(filteredOverdueActivities);
            setHasOverdueItems(filteredOverdueActivities?.length > 0);
        }
    }, [status, isAll, isFlagged, activityType, filterStartDate, filterEndDate, entity, overdueActivities, setFilteredOverdueActivities, setHasOverdueItems, user, userId,recurrency, review]);

		useEffect(() => {
        if (completeActivities || inProgressActivities || overdueActivities || upcomingActivities){
            const entries = []
            if (completeActivities){ entries.push(...completeActivities);}
            if (inProgressActivities){ entries.push(...inProgressActivities);}
            if (overdueActivities){ entries.push(...overdueActivities);}
            if (upcomingActivities){ entries.push(...upcomingActivities);}

            const entityDataItems = entries.reduce(
                (accumulator, { entities }) => {
                    for (let entity of entities){
                        if (!(entity in accumulator.items)) {
                            accumulator.items[entity] = 0;
                        }

                        accumulator.items[entity]++;
                    }
                    accumulator.totalCount++;

                    return accumulator;
                },
                { items: {}, totalCount: 0 }
            );

            const { items:entityItems } = entityDataItems;

            const entityOptions = Object.keys(entityItems).map(key => ({
                label: key,
                value: key
            }));

            const typeDataItems = entries.reduce(
                (accumulator, { type }) => {
                    if (!(type in accumulator.items)) {
                        accumulator.items[type] = 0;
                    }

                    accumulator.items[type]++;
                    accumulator.totalCount++;

                    return accumulator;
                },
                { items: {}, totalCount: 0 }
            );

            const { items:typeItems } = typeDataItems;

            const typeOptions = Object.keys(typeItems).map(key => ({
                label: `${key} (${typeItems[key]})`,
                value: key
            }));

            const usersDataItems = entries.reduce(
                (accumulator, { assigned }) => {
                    if (assigned.length === 0) {
                        return accumulator;
                    }

                    for (const assignee of assigned) {
                        const { firstName, lastName } = assignee;
                        const fullName = `${firstName} ${lastName}`

                        if (!(fullName in accumulator.items)) {
                            accumulator.items[fullName] = 0;
                        }

                        accumulator.items[fullName]++;
                        accumulator.totalCount++;
                    }

                    return accumulator;
                },
                { items: {}, totalCount: 0 }
            );

            const reviewDataItems = entries.reduce(
                (accumulator, { reviewer }) => {
                    if (reviewer.length === 0) {
                        return accumulator;
                    }

                    for (const assignee of reviewer) {
                        const { firstName, lastName } = assignee;
                        const fullName = `${firstName} ${lastName}`

                        if (!(fullName in accumulator.items)) {
                            accumulator.items[fullName] = 0;
                        }

                        accumulator.items[fullName]++;
                        accumulator.totalCount++;
                    }

                    return accumulator;
                },
                { items: {}, totalCount: 0 }
            );

            const {items: usersItems } = usersDataItems;
            const {items: reviewerItems } = reviewDataItems;

            const userOptions = Object.keys(usersItems).map(key => ({
                label: key,
                value: key
            })).sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1);

            const reviewOptions = Object.keys(reviewerItems).map(key => ({
                label: key,
                value: key
            })).sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1);

						const statusFilterValues = entries.reduce(computeStatusFilterValues,{ items: {}, totalCount: 0 })

            setEntityItems([...entityOptions]);
            setReviewItems([...reviewOptions]);
            setTypeItems([...lexigraphicalSort(typeOptions, ['label'])]);
            setUserItems([...userOptions]);
						setStatusItems([
                            ...Object.keys(statusFilterValues.items)
                                .map(key => ({
                                    label: statusFilterLabelValues[key],
                                    value: key
                                }))
                        ]);
        }
    }, [completeActivities, inProgressActivities, isFlagged, overdueActivities, upcomingActivities, setEntityItems, setReviewItems, setTypeItems, setUserItems, VIEW_ALL]);

		useEffect(() => {
            setFilteredActivitiesData({
                completed: filteredCompleteActivities,
                inProgress: filteredInProgressActivities,
                overdue: filteredOverdueActivities,
                upcoming: filteredUpcomingActivities
            });
        }, [
            filteredOverdueActivities,
            filteredCompleteActivities,
            filteredInProgressActivities,
            filteredUpcomingActivities,
            setFilteredActivitiesData
        ]);

		async function onDownloadItem(item){
				const dataItem = detailedData?.[
						getSchemaDataName(item.schema)
				].find(({ id }) => id === item.id);

				if (!dataItem) {
						return
				}

				const reportData = {};
				reportData[getSchemaDataName(schema)] = [dataItem];

				exportDetailedActivities({
						data: reportData,
						isAll,
						name: 'Activity',
						querySchemas,
						schema,
						tenants,
						userId
				});

		}

        useEffect(() => {
            if (activities) {
                const recurrenceTypes = new Set();
        
                ['completed', 'inProgress', 'overdue', 'upcoming'].forEach(category => {
                    activities[category]?.forEach(item => {
                        const recurrence = item?.recurrence?.toLowerCase() || 'no_recurrence';
                        if (recurrence !== 'no_recurrence') {
                            recurrenceTypes.add(recurrence);
                        }
                    });
                });
        
                const recurrenceOptions = Array.from(recurrenceTypes).map(type => ({
                    label: type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                    value: type
                }));
        
                setRecurrencyItems([...recurrenceOptions]);
            }
        }, [activities]);


    if (error) {
        return `Error! ${error}`;
    }


    return (
        <Activities hasCompleteItems={hasCompletedItems} hasInProgressItems={hasInProgressItems} hasOverdueItems={hasOverdueItems} hasUpcomingItems={hasUpcomingItems} isAll={true} isFlagged={isFlagged}>
            {hasOverdueItems && (
                <OverdueDetailedItems
                    items={filterDataByName(filteredOverdueActivities, searchFilter)}
										onDownloadItem={onDownloadItem}
                    tenantCount={tenants.length}
                />
            )}

            {hasInProgressItems && (
                <InProgressDetailedItems
                    items={filterDataByName(filteredInProgressActivities, searchFilter)}
										onDownloadItem={onDownloadItem}
                    tenantCount={tenants.length}
                />
            )}

            {hasUpcomingItems && (
                <UpcomingDetailedItems
                    items={filterDataByName(filteredUpcomingActivities, searchFilter)}
										onDownloadItem={onDownloadItem}
                    tenantCount={tenants.length}
                />
            )}

            {hasCompletedItems && (
                <CompletedDetailedItems
                    items={filterDataByName(filteredCompleteActivities, searchFilter)}
										onDownloadItem={onDownloadItem}
                    tenantCount={tenants.length}
                />
            )}
        </Activities>
    );
};

export default DetailedActivities;
