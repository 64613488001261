import { useRecoilCallback, useRecoilValue } from 'recoil'; 
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { completedActivityOverdueDetailed } = state;

const { completedActivityOverdueDetailedAtom } = completedActivityOverdueDetailed.atoms;

const MUTATE_STATUS = tenantSchema => gql`
mutation update_user_activity($activityId: Int!, $groupId: Int, $status: String!, $userId: Int) {
    update_${tenantSchema}_user_activity(where: {activity_id: {_eq: $activityId}, _and: {_or: [{user_id: {_eq: $userId}}, {group_id: {_eq: $groupId}}]}}, _set: {status: $status}) {
      returning {
        activity_id
        user_id
        group_id
        status
      }
    }
  }
`;

const MUTATE_CLOSE = tenantSchema => gql`
mutation closeActivity($activityId: Int!, $closedDate: timestamp!) {
    update_${tenantSchema}_activity(where: {
        id: {
            _eq: $activityId
        }
    }, 
    _set: {
        closed: true,
        closed_date: $closedDate
    }) {
        returning {
            id
            closed
        }
    }
}
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
mutation insert_activity_history($activityId: Int!, $color: String!, $message: String!, $userId: Int!) {
    insert_${tenantSchema}_activity_history(
        objects: {
            activity_id: $activityId,
            color: $color,
            message: $message,
            user_id: $userId
        }
    ) {
        returning {
            id
        }
    }
}  
`;

const MUTATE_ACTIVITY_FORM = tenantSchema => gql`
mutation update_activity_forms($activityId: Int!) {
    update_${tenantSchema}_activity_forms(where: {
        activity_id: {
            _eq: $activityId
        },
        
    },
    _set: {
        locked: true
    }) {
        returning {
            id
        }
    }
}
`;

const useComplete = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_CLOSE, providedSchema);
    const markAsComplete = useGraphQL(MUTATE_STATUS, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);
    const mutationActivityForm = useGraphQL(MUTATE_ACTIVITY_FORM, providedSchema);

    const closeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();
            const { activityId, completable, groupId, status, userId } = variables;
            const submissionVariables = {
              activityId: Number(activityId),
              groupId: (groupId) ? groupId : 0,
              status,
              userId: (userId) ? userId : 0
            }
            await markAsComplete(submissionVariables);
            if(completable){
                await mutation(variables);
            }

            await mutationActivityHistory({
              activityId: Number(activityId),
              color: 'green',
              message: `marked the activity complete and closed the activity`,
              userId: userId
            })

            await mutationActivityForm({ activityId: Number(activityId) });
            set(completedActivityOverdueDetailedAtom, activityId);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(completedActivityOverdueDetailedAtom, '');
    });

    return [useRecoilValue(completedActivityOverdueDetailedAtom), closeActivity, reset];
};

export default useComplete;
