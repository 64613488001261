import { useEffect } from 'react';

import DocumentManagementPanel from 'components/DocumentManagementPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import usePolicies from './hooks/usePolicies';
import useData from './hooks/useData';

import normalizeData from './data';
import Loader from 'components/Loader';

const DocumentManagementPolicyTab = () => {
    const { useSchema, useTenants, useQuerySchemas } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [querySchemas] = useQuerySchemas();

    const [policies, setPolicies] = usePolicies();
    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            setPolicies(normalizedData);
        }
    }, [data, schema, setPolicies, tenants]);

    if (error) {
        
    }

    if(tenants && tenants.length===1){
        return <DocumentManagementPanel items={policies} loading={loading} />;
    }else{
        return <Loader text={'Please select only one tenant to view a list of documents.'} />
    }
};

export default DocumentManagementPolicyTab;
