import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetailsPanel } = state;

const { caseUserAtom } = caseDetailsPanel.atoms;

const MUTATE_INSERT = tenantSchema => gql`
mutation mutateUpdateCaseUsers(
  $caseId: Int!,
  $userId: Int
) {
    insert_${tenantSchema}_user_case(
      objects: {
        case_id: $caseId,
        user_id: $userId
      }
    ) {
      returning {
        user_id
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "added case assignee", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_INSERT, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveCaseUser = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables)

            const newUser = res?.data[`insert_${usedSchema}_user_case`].returning[0]?.user_id;

            await mutationCaseHistory(variables);

            set(caseUserAtom, newUser);

          // if (variables?.previousRecord){
          //   variables.groupId = (variables?.groupId) ? variables?.groupId : 0;
          //   variables.userId = (variables?.userId) ? variables?.userId : 0;
          //   // const res = await mutationUpdate(variables);
          //   const newUser = res?.data[`update_${usedSchema}_user_activity`].returning[0];
          //   set(caseUserAtom, newUser);
          // }else{
          //   const res = await mutationInsert(variables);
          //   const newUser = res?.data[`insert_${usedSchema}_user_activity`].returning[0];
          //   set(caseUserAtom, newUser);
          // }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(caseUserAtom, '');
    });

    return [useRecoilValue(caseUserAtom), saveCaseUser, reset];
};

export default useData;
