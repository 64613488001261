import UnauthenticatedContent from 'components/UnauthenticatedContent';

import styles from './styles.module.scss';

const PlainLayout = () => (
    <div className={styles.plainLayout}>
        <UnauthenticatedContent />
    </div>
);

export default PlainLayout;
