import BodyText from 'components/BodyText';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const VersionButton = props => {
    const { className = '', isSelected, onClick, status, version } = props;

    const selectedClass = isSelected ? styles.selected : '';

    return (
        <Buttonize
            className={`${className} ${selectedClass} ${styles.versionButton}`}
            onClick={onClick}
        >
            <BodyText className={styles.text} text={version} />

            <BodyText className={styles.text} text={status} />
        </Buttonize>
    );
};

export default VersionButton;
