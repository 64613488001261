import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelButton from 'components/CancelButton';
import InviteButton from 'components/InviteButton';
import PageTitle from 'components/PageTitle';
import PersonalInformationPanel from 'components/PersonalInformationPanel';
import SaveButton from 'components/SaveButton';
import UploadPhotoPanel from 'components/UploadPhotoPanel';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import translations from './constants';

import styles from './styles.module.scss';

const AddPerson = () => {
    const { EDIT_PERSON } = translations();

    const { HUMAN_RESOURCES } = useConfig();

    const navigate = useNavigate();

    const form = useForm({
        emailAddress: '',
        firstName: '',
        group: '',
        lastName: '',
        role: '',
        username: ''
    });

    const handleCancel = useCallback(() => {
        navigate(HUMAN_RESOURCES);
    }, [HUMAN_RESOURCES, navigate]);

    const handleInvite = useCallback(() => {}, []);

    const handleSave = useCallback(() => {}, []);

    return (
        <div className={styles.editPerson}>
            <PageTitle className={styles.pageTitle} text={EDIT_PERSON} />

            <CancelButton
                className={styles.cancelButton}
                onClick={handleCancel}
            />

            <InviteButton
                className={styles.inviteButton}
                onClick={handleInvite}
            />

            <SaveButton className={styles.saveButton} onClick={handleSave} />

            <UploadPhotoPanel className={styles.uploadPhotoPanel} />

            <PersonalInformationPanel
                className={styles.personalInformationPanel}
                form={form}
            />
        </div>
    );
};

export default AddPerson;
