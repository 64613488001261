import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = tenantSchema => `
    ${tenantSchema}_log {
        created_date
        date_of_occurrence
        log_types {
            log_type {
                name
            }
        }
        description
        risk_label
        risk_color
        id
    }
`;

const useData = () => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    return useQuery(generateQueryFromDict({query, schema:usedSchema, tenants}), { fetchPolicy: 'no-cache' });
};

export default useData;
