import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageBody from 'components/PageBody';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import PolicyActions from 'components/PolicyActions';
import PolicyDetailsPanel from 'components/PolicyDetailsPanel';
import PolicyMappingPanel from 'components/PolicyMappingPanel';
import PolicyMetaPanel from 'components/PolicyMetaPanel';
import VerticalPanels from 'components/VerticalPanels';
import WithPageTitle from 'components/WithPageTitle';

import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useAttachments from './hooks/useAttachments';
import useData from './hooks/useData';
import useAddCategory from './hooks/useAddCategory';
import useRemoveCategory from './hooks/useRemoveCategory';
import useTranslation from './hooks/useTranslation';

import normalizeData from './data';

const Policy = () => {
    const { COMPLIANCE_POLICY } = useTranslation();

    const params = useParams();

    const { policyId, policySchema } = params;

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const [policy, setPolicy] = useState();
    const [
        createdAttachment,
        updateAttachments,
        reset,
        updateAttachment,
        deleteAttachment
    ] = useAttachments();

    const [activeVersion, setActiveVersion] = useState(-1);
    const [active, setActive] = useState({});
    const [_errorMessage, setErrorMessage] = useState();

    const [addCategory] = useAddCategory();
    const [removeCategory] = useRemoveCategory();
    const { loading, error, data } = useData(policyId, policySchema);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [updatedCategories, updateSelectedCategories] = useState([]);

    const attachmentsOnDelete = async params => {
        const { filename } = params;
        await deleteAttachment({ filename, id: active?.id, versionNumber: active?.label });
    };

    const attachmentsOnUpdate = async params => {
        const { newName } = params;
        await updateAttachment({ filename: newName, id: active?.id, versionNumber: active?.label });
    };

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema: policySchema ? policySchema : schema
            });

            setSelectedCategories(normalizedData?.selectedCategories);
            updateSelectedCategories(normalizedData?.selectedCategories);
            setPolicy(normalizedData?.policy);
            setCategories(normalizedData?.categories);
        }
    }, [data, policySchema, schema, setCategories, setPolicy, setSelectedCategories]);

    useEffect(() => {
        const isSameCategory = (a, b) => a.value === b.value && a.label === b.label;
        const onlyInLeft = (left, right, compareFunction) => 
            left.filter(leftValue =>
                !right.some(rightValue => 
                    compareFunction(leftValue, rightValue)));
        if(selectedCategories.length > updatedCategories.length){
            const removedCategory = onlyInLeft(selectedCategories, updatedCategories, isSameCategory);
            removeCategory({documentId:policyId, removedCategory})
        }else if(selectedCategories.length < updatedCategories.length){
            const addedCategory = onlyInLeft(updatedCategories, selectedCategories, isSameCategory);
            var newCategories = [];
            var existingCategories = [];
            for(const category of addedCategory){
                if(category?.__isNew__){
                    newCategories.push(category?.value);
                }else{
                    existingCategories.push(category)
                }
            }
            addCategory({documentId:policyId, existingCategories, newCategories})
        }
        setSelectedCategories(updatedCategories);
    // eslint-disable-next-line
    }, [updatedCategories])

    const { createdBy, id, versions, sections, visibleTo } = policy || {};

    const [displayedSections, setDisplayedSections] = useState(sections);

    const form = useForm({
        description: '',
        effectiveDate: '',
        policyName: '',
        visibleTo: []
    });

    const { handleForm } = form;

    const handleSave = useCallback(() => {
        

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        // const formObject = Array.from(output.entries()).reduce(
        //     (accumulator, [key, value]) => {
        //         accumulator[key] = value;

        //         return accumulator;
        //     },
        //     {}
        // );

    }, [handleForm]);

    const versionChangeHandler = useCallback(index => {
        const sortedVersions = versions.slice().sort((objA, objB) => (objA.id > objB.id) ? 1 : -1);
        const filteredVersions = sortedVersions.slice().filter(x => x.label === index);
        setActiveVersion(versions.indexOf(filteredVersions[0]));
    }, [versions]);

    const handleFileUpload = newFiles => {
        if (Object.keys(newFiles).length > 0) {
            const variables = {};
            variables['id'] = active?.id;
            variables['files'] = newFiles;
            updateAttachments(variables);
        }
    };

    useEffect(() => {
        if (versions) {
            setActive(versions.slice(activeVersion)[0]);
        }
    }, [versions, setActive, activeVersion]);

    useEffect(() => {
        if (
            createdAttachment &&
            createdAttachment.constructor.name !== 'Array'
        ) {
            reset();
            window.location.reload();
        }
        
    }, [createdAttachment, reset]);

    useEffect(() => {
        if(Array.isArray(versions)){
            const databaseActiveVersion = versions.findIndex(x => x.status === 'active');
            setActiveVersion(databaseActiveVersion);
        }
    }, [versions])

    if (error) {
        
    }

    if (!(versions && active?.hasOwnProperty('status'))) {
        return null;
    }

    const { description, document, effectiveDate, name, status } = active;

    const updateSection = updatedSection => {
        const newDisplayedSections = displayedSections ? [...displayedSections] : [...sections];
        const sectionIndex = newDisplayedSections.findIndex(x => x.id === updatedSection.id);
        const sectionToUpdate = {...newDisplayedSections[sectionIndex]};
        if(updatedSection?.type === "description"){
            sectionToUpdate.description = updatedSection?.text.replace('<p></p>', '<br>');
        }else{
            sectionToUpdate.name = updatedSection?.text;
        }
        newDisplayedSections[sectionIndex] = sectionToUpdate;
        setDisplayedSections(newDisplayedSections);

        setPolicy({ createdBy, id, sections:newDisplayedSections, versions, visibleTo });
    }

    const usedSchema = policySchema ? policySchema : schema

    return (
        <PageBody isLoading={loading || !active}>
            <WithPageTitle title={COMPLIANCE_POLICY}>
                <PolicyActions
                    activeVersion={active}
                    onSave={handleSave}
                    policyId={id}
                    publishedVersion={versions.filter(x => x.status === 'active')[0]?.label}
                    status={active?.status}
                />
            </WithPageTitle>

            <PanelWithAsidePanel>
                <VerticalPanels>
                    <PolicyDetailsPanel
                        attachmentOnDelete={attachmentsOnDelete}
                        attachmentOnUpdate={attachmentsOnUpdate}
                        categories={categories}
                        description={description}
                        document={document}
                        effectiveDate={effectiveDate}
                        form={form}
                        handleFileUpload={handleFileUpload}
                        key={`${name}.${effectiveDate}.policyDetailsPanel`}
                        name={name}
                        providedSchema={usedSchema}
                        selectedCategories={selectedCategories}
                        setCategories={updateSelectedCategories}
                        status={status}
                        visibleTo={visibleTo}
                    />

                    <PolicyMappingPanel categories={categories} id={id} providedSchema={usedSchema} sections={displayedSections ? displayedSections : sections} updateSection={updateSection} />
                </VerticalPanels>

                <PolicyMetaPanel
                    createdBy={createdBy}
                    key={active?.label}
                    onVersionChange={versionChangeHandler}
                    policyId={policyId}
                    providedSchema={usedSchema}
                    selectedVersion={active?.label}
                    versions={versions}
                />
            </PanelWithAsidePanel>
        </PageBody>
    );
};

export default Policy;
