import { atom } from 'recoil';

const usersAtom = atom({
    default: [],
    key: 'users'
});

const entitiesAtom = atom({
    default: [],
    key: 'entities'
});

const state = {
    atoms: {
        entitiesAtom,
        usersAtom
    }
};

export default state;
