import { useCallback } from 'react';

import Button from 'components/Button';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const HollowButton = ({
    children,
    className = '',
    isDisabled = false,
    onClick,
    text
}) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <Button
            className={`${className} ${styles.hollowButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
        >
            {children || text}
        </Button>
    );
};

export default HollowButton;
