const translations = {
    actions: 'Actions',
    activityType: 'Activity Type',
    assigned: 'Assigned',
    company: 'Company',
    dueDate: 'Due Date',
    entities: 'Entities',
    ref: 'Ref',
    recurrence: 'Recurrence',
    reviewer: 'Reviewer',
    startDate: 'Start Date',
    type: 'Type'
};

export default translations;
