import { atom } from 'recoil';

const addActivityAtom = atom({
    default: '',
    key: 'addActivity'
});

const state = {
    atoms: {
        addActivityAtom
    }
};

export default state;
