import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateGroupMembers } = state;

const { updateGroupMembersAtom } = updateGroupMembers.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateDeleteGroupMember(
  $groupId: Int!,
  $id: Int
  ) {
    delete_${tenantSchema}_group_user(
      where: {
        group_id: {_eq: $groupId},
        tenant_user_id: {_eq: $id}
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const deleteGroupMember = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const deletedGroupMember = res?.data[`delete_${usedSchema}_group_user`].returning[0]?.id;

            set(updateGroupMembersAtom, deletedGroupMember);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateGroupMembersAtom, '');
    });

    return [useRecoilValue(updateGroupMembersAtom), deleteGroupMember, reset];
};

export default useData;
