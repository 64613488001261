import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('markCompleteButton');

    return {
        MARK_AS_COMPLETE: t('markAsComplete')
    };
};

export default useTranslation;
