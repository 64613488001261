import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { usersAtom } = state.users.atoms;

const useUsers = () => [
    useRecoilValue(usersAtom),
    useSetRecoilState(usersAtom)
];

export default useUsers;
