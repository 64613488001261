import BodyText from 'components/BodyText';
import Buttons from 'components/Buttons';
import DeleteButton from 'components/DeleteButton';
import FileUpload from 'components/FileUpload';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';
import SubmitButton from 'components/SubmitButton';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const CreatePolicyVersionModal = ({ fileUploader, onClose, onSubmit, providedSchema }) => {
    const { CANCEL, CREATE_NEW_VERSION, CREATE_VERSION, TEXT } =
        useTranslation();

    return (
        <Modal isOpen onClose={onClose}>
            <PanelBody className={styles.panelBody}>
                <PanelTitle text={CREATE_NEW_VERSION} />

                <BodyText className={styles.bodyText} text={TEXT} />

                <FileUpload filesList={fileUploader} providedSchema={providedSchema} />

                <Buttons>
                    <DeleteButton onClick={onClose} text={CANCEL} />

                    <SubmitButton onClick={onSubmit} text={CREATE_VERSION} />
                </Buttons>
            </PanelBody>
        </Modal>
    );
};

export default CreatePolicyVersionModal;
