import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administrationCaseTypesTableHeader');

    return {
        CASE_TYPE: t('caseType'),
        RISK_LEVEL: t('riskLevel'),
        VISIBLE_TO: t('visibleTo')
    };
};

export default useTranslation;
