import { useMemo } from 'react';
import { formatDistanceStrict } from 'date-fns';

const useTimeAgo = timestamp =>
    useMemo(
        () =>
            formatDistanceStrict(new Date(timestamp), new Date(), {
                addSuffix: true
            }),
        [timestamp]
    );

export default useTimeAgo;
