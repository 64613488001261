import { memo, useCallback } from 'react';

import Label from 'components/Label';
import RadioButton from 'components/RadioButton';
import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const RadioButtonWithLabel = ({
    className = '',
    isChecked,
    isDisabled = false,
    name = '',
    onChange,
    text = '',
    value = ''
}) => {

    const handleChange = useCallback((event) => {
        if (onChange && isFunction(onChange)) {
            onChange(event);
        }
    }, [onChange]);

    const handleClick = useCallback((event) => {
        handleChange(event);
    }, [handleChange]);

    return (
        <div
            className={`${className} ${styles.radioButtonWithLabel}`}
            onClick={handleClick}
        >
            <RadioButton
                isChecked={isChecked}
                isDisabled={isDisabled}
                name={name}
                value={value}
            />

            <Label className={`${styles.label} ${isDisabled ? styles.isDisabled : ''}`} text={text} />
        </div>
    );
};

export default memo(RadioButtonWithLabel);
