import InfoContentBox from 'components/InfoContentBox';

import styles from './styles.module.scss';

const RequestedUpdates = ({ className = '', items, versionNumber }) => (
    <div className={`${className} ${styles.requestedUpdates}`}>
        {items.map(({ createdAt, message, type, user, version }) => (
            <InfoContentBox
                createdAt={createdAt}
                text={message}
                type={type}
                user={user}
                version={version}
                versionNumber={versionNumber} 
            />
        ))}
    </div>
);

export default RequestedUpdates;
