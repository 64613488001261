import ProgramOverviewPanel from 'components/ProgramOverviewPanel';
import PageTitle from 'components/PageTitle';

import styles from './styles.module.scss';

const ProgramOverview = () => (
    <div className={styles.programOverview}>
        <PageTitle text="Program Overview" />

        <ProgramOverviewPanel className={styles.programOverviewPanel} />
    </div>
);

export default ProgramOverview;
