import formatDate from 'utilities/formatDate';

const normalizeData = ({ activityData, caseData, querySchemas, schema, _tenants }) => {
    const caseTypeMapping = {};
    caseData[`${schema}_case_type`].forEach(type => {
        caseTypeMapping[type.id] = type.name;
    });

    caseData[`${schema}_case`].forEach(caseItem => {
        caseItem.case_type = caseTypeMapping[caseItem.case_type];
    });

    const caseCleanData = caseData[`${schema}_case`].flatMap(caseItem => {
        return caseItem.case_attachments.map(attachment => {
            const history = caseItem.case_histories.find(hist => {
                const filename = hist.message.split(": ").pop();
                return filename === attachment.file;
            });

            if (history) {
                if(querySchemas){
                    for(const s of querySchemas){
                        const uploader = history.tenant_user[`users_${s?.value}`][0];
                        return {
                            dateIntroduced: history.created_at ? formatDate(new Date(history.created_at)) : '',
                            id: attachment.id,
                            name: attachment.name,
                            referenceId: caseItem.id,
                            referenceName: caseItem.case_name,
                            referenceType: caseItem.case_type,
                            schema,
                            type: "Case",
                            uploadBy: `${uploader.first_name} ${uploader.last_name}`,
                        };
                    }
                }else{
                    const uploader = history.tenant_user[`users_${schema}`][0];
                    return {
                        dateIntroduced: history.created_at ? formatDate(new Date(history.created_at)) : '',
                        id: attachment.id,
                        name: attachment.name,
                        referenceId: caseItem.id,
                        referenceName: caseItem.case_name,
                        referenceType: caseItem.case_type,
                        schema,
                        type: "Case",
                        uploadBy: `${uploader.first_name} ${uploader.last_name}`,
                    };
                }
            }
        }).filter(item => item);
    });

    const activityCleanData = activityData[`${schema}_activity`].flatMap(activity => {
        return activity.activity_attachments.map(attachment => {
            let historyEvent;
            if (activity.uploaded.length > 0) {
                historyEvent = activity.uploaded.find(hist => {
                    const filename = hist.message.split(": ").pop();
                    return filename === attachment.file;
                });
            }

            if (!historyEvent) {
                historyEvent = activity.created[0];
            }

            const uploader =
            (historyEvent?.tenant_user ?? {
                [`users_${schema}`]: [{}]
            })[`users_${schema}`][0] || {};

            return {
                dateIntroduced: historyEvent?.created_at ? formatDate(new Date(historyEvent?.created_at)) : '',
                deleted: activity.deleted,
								id: attachment.id,
                name: attachment.name,
                referenceId: activity.id,
                referenceName: activity.name,
                referenceType: activity.activity_type.name,
                type: "Activity",
                schema,
                uploadBy: `${uploader.first_name || ''} ${uploader.last_name || ''}`,
            };
        });
    });

    return [...caseCleanData, ...activityCleanData];
}

export default normalizeData;