import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('rejectButton');

    return {
        REJECT: t('reject')
    };
};

export default useTranslation;
