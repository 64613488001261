import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { activityTypesAtom } = state.activityTypes.atoms;

const useTypes = () => [
    useRecoilValue(activityTypesAtom),
    useSetRecoilState(activityTypesAtom)
];

export default useTypes;
