import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addActivityQuestions');

    return {
        NEXT: t('next'),
        QUESTION: t('question'),
        QUESTIONS: t('questions'),
        SAVE: t('save'),
        SAVE_AND_MARK_AS_REVIEWED: t('saveAndMarkAsReviewed'),
        VIEW_FULL_FORM: t('viewFullForm')
    };
};

export default useTranslation;
