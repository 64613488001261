import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseMetaPanel');

    return {
        ASSIGNED_TO: t('assignedTo'),
        CASE_CREATED_DATE: t('caseCreatedDate'),
        COMPANY: t('company'),
        CREATED_BY: t('createdBy'),
        DUE_DATE: t('dueDate'),
        USERS_INVOLVED: t('usersInvolved'),
        VISIBLE_BY: t('visibleBy')
    };
};

export default useTranslation;
