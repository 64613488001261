import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import SubmitButton from 'components/SubmitButton';
import styles from './styles.module.scss';

export const ExportModal = ({
    hasPDFButton = false,
    isCreatingPDF,
    isOpen,
    onClose,
    onExportExcel,
    onExportPDF,
    onExportAll,
    onExportFiltered,
    title = 'Export'
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <PanelBody className={styles.exportModalPanelBody}>
                <PanelTitle className={styles.panelTitle} text={title} />

                {hasPDFButton && (
                    <>
                        {onExportExcel && (
                            <SubmitButton onClick={onExportExcel} text="Excel download" />
                        )}

                        {onExportPDF && (
                            <SubmitButton isDisabled={isCreatingPDF} onClick={onExportPDF} text="PDF download" />
                        )}
                    </>
                )}

                { onExportAll && (
                    <SubmitButton onClick={onExportAll} text="All data" />
                )}

                {onExportFiltered && (
                    <SubmitButton onClick={onExportFiltered} text="Filtered data" />
                )}

            </PanelBody>
        </Modal>
    );
};
