const translations = {
    assignedTo: 'Assigned To',
    caseCreationReason: 'Case Creation Reason',
    caseName: 'Case Name',
    caseType: 'Case Type',
    createCase: 'Create Case',
    dueDate: 'Due Date',
    enterCaseName: 'Enter Case Name',
    policyReference: 'Reference',
    riskLevelCase: 'Risk Level - Case'
};

export default translations;
