import StandoutText from 'components/StandoutText';
import WithLabel from 'components/WithLabel';

const TextWithLabel = props => {
    const { className = '', label = '', text = '' } = props;

    return (
        <WithLabel className={className} text={label}>
            <StandoutText text={text} />
        </WithLabel>
    );
};

export default TextWithLabel;
