const normalizeTypeData = ({ data, schema }) =>
    data[`${schema}_case_type`].reduce(
        (accumulator, type) => {
            accumulator.push({
                label: type.name,
                value: type.id
            });

            return accumulator;
        }, []);

export default normalizeTypeData;