import { useCallback, useState } from 'react';

import NegativeHollowButton from 'components/NegativeHollowButton';
import WithdrawModal from 'components/WithdrawModal';

import useTranslation from './hooks/useTranslation';

const RejectButton = ({ requestId, requestName, requestSchema, versionId }) => {
    const { WITHDRAW } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleModalClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <NegativeHollowButton onClick={handleClick} text={WITHDRAW} />

            {isOpen && (
                <WithdrawModal
                    onCancel={handleModalClose}
                    onSubmit={() => {}}
                    requestId={requestId}
                    requestName={requestName}
                    requestSchema={requestSchema} 
                    versionId={versionId}
                />
            )}
        </>
    );
};

export default RejectButton;
