import { atom } from 'recoil';

const addPersonAtom = atom({
    default: '',
    key: 'addPerson'
});

const state = {
    atoms: {
        addPersonAtom
    }
};

export default state;
