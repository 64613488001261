import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
${tenantSchema}_log {
    description
    tenant_user {
      id
      user_${tenantSchema} {
        first_name
        last_name
      }
    }
    created_date
    date_of_occurrence
    risk_label
    log_types {
      log_type {
        name
      }
    }
  }
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
