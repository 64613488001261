const normalizeEntityData = ({ data, schema }) =>
    data[`${schema}_entity`].reduce(
        (accumulator, entity) => {
            accumulator.push({
                label: entity.name,
                value: entity.id
            });

            return accumulator;
        }, []);

export default normalizeEntityData;