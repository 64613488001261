import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import BodyText from 'components/BodyText';
import DetailsButton from 'components/DetailsButton';
import EyeIcon from 'components/icons/EyeIcon';
import ImportantLink from 'components/ImportantLink';
import RiskPill from 'components/RiskPill';
import TableRow from 'components/TableRow';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import HtmlContent from 'components/HtmlContent';
import titleCase from 'utilities/titleCase';

const ComplianceLogTableRow = props => {
    const { id, logCategory, logSchema, occurredOn, description, riskLevel, tenantCount } = props;

    const { COMPLIANCE_LOG_ROUTE } = useConfig();
    const { VIEW } = useTranslation();

    const navigate = useNavigate();

    const complianceLogLink = `${COMPLIANCE_LOG_ROUTE}/${id}/${logSchema}`;

    if(tenantCount>1){
        return (
            <TableRow className={styles.complianceLogTableRowMultiTenant}>
                <ImportantLink 
                    className={styles.titleLink}
                    text={logCategory} 
                    to={complianceLogLink} />
    
                <BodyText text={titleCase(logSchema)} />
    
                <BodyText text={format(new Date(occurredOn), 'yyyy-MM-dd')} />
    
                <HtmlContent className={styles.description} html={description} />
    
                <RiskPill riskLevel={riskLevel} />
    
                <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <EyeIcon />,
                            onClick: () => {
                                navigate(complianceLogLink);
                            },
                            text: VIEW
                        }
                    ]}
                />
            </TableRow>
        );
    }else{
        return (
            <TableRow className={styles.complianceLogTableRow}>
                <ImportantLink 
                    className={styles.titleLink}
                    text={logCategory} 
                    to={complianceLogLink} />

                <BodyText text={format(new Date(occurredOn), 'yyyy-MM-dd')} />

                <HtmlContent className={styles.description} html={description} />

                <RiskPill riskLevel={riskLevel} />

                <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <EyeIcon />,
                            onClick: () => {
                                navigate(complianceLogLink);
                            },
                            text: VIEW
                        }
                    ]}
                />
            </TableRow>
        );
                }
};

export default ComplianceLogTableRow;
