import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateSectionDescription } = state;

const { updateSectionDescriptionAtom } = updateSectionDescription.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateSectionDescription($text: String!, $sectionId: Int!) {
    update_${tenantSchema}_sections(where: {id: {_eq: $sectionId}}, _set: {description: $text}) {
      returning {
        description
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveSectionDescription = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
              const res = await mutation(variables);

              const newDescription = res?.data[`update_${usedSchema}_sections`].returning[0]?.description;

              set(updateSectionDescriptionAtom, {id: variables?.sectionId, newDescription});
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateSectionDescriptionAtom, '');
    });

    return [useRecoilValue(updateSectionDescriptionAtom), saveSectionDescription, reset];
};

export default useData;
