import formatDate from 'utilities/formatDate';
import normalizeListData from "utilities/normalizeListData";
import titleCase from 'utilities/titleCase';

const schemaData = ({ data, schema }) =>
    data[`${schema}_sections`].reduce((accumulator, sections) => {
        const {
            id,
            name,
            description,
            status,
            published,
            riskLevel,
            effective_date: effectiveDate,
            version,
            section_categories: sectionCategories,
            policy_sections: documentAssignments
        } = sections;

        const selectedCategories = sectionCategories.reduce(
            (accumulator, category) => {
                const id = category?.category?.id;
                const label = category?.category?.label;
                if (label !== null){
                    accumulator.push({
                        label,
                        value: id
                    })
                }
                return accumulator
            }, []
        );

        const assignedDocuments = documentAssignments.reduce(
            (accumulator, document) => {
                const id = document?.document?.id;
                const name = document?.document?.name;
                const initialDocumentCategories = document?.document?.document_categories;
                const documentCategories = initialDocumentCategories.map((category) => {
                    return {label: category?.category?.label, value: category?.category?.id}
                })
                if (name !== null){
                    accumulator.push({
                        documentCategories, id, name
                    })
                }
                return accumulator
            }, []
        );

        
        const documentCategories = assignedDocuments.reduce(
            (accumulator, document) => {
                accumulator.push(...document?.documentCategories);
                return accumulator;
            }, []
        );

        accumulator.push({
            assignedDocuments,
            description,
            documentCategories,
            effectiveDate: formatDate(new Date(effectiveDate)),
            id,
            name,
            published: titleCase(published),
            riskLabel: riskLevel,
            schema,
            selectedCategories,
            sortDate: new Date(effectiveDate),
            status: titleCase(status),
            version: version
        });
        
        accumulator = accumulator.sort(
            (objA, objB) => (objA.sortDate > objB.sortDate ? 1 : -1)
        );

        return accumulator;
    }, []);

    const normalizeData = ({ data, querySchemas, schema, tenants }) => normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizeData;
