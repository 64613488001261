import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const filteredActivitiesDataAtom = atom({
    default: { completed: [], inProgress: [], overdue: [], upcoming: [] },
    key: 'filteredActivitiesDataAtom'
});

export function useGetFilteredActivitiesData() {
    return useRecoilValue(filteredActivitiesDataAtom);
}

export function useSetFilteredActivitiesData() {
    return useSetRecoilState(filteredActivitiesDataAtom);
}
