import ActiveIcon from 'components/icons/ActiveIcon';
import InactiveIcon from 'components/icons/InactiveIcon';

const iconMap = {
    false: InactiveIcon,
    true: ActiveIcon
};

const ActiveIndicator = ({ className = '', isActive }) => {
    const IconComponent = iconMap[isActive];

    return <IconComponent className={className} />;
};

export default ActiveIndicator;
