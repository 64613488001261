import { useCallback, useState } from 'react';

import InfoHollowButton from 'components/InfoHollowButton';
import EscalateModal from 'components/EscalateModal';

import useTranslation from './hooks/useTranslation';

const EscalateButton = ({ assignedUsers, requestId, requestName, requestSchema, versionId }) => {
    const { ESCALATE } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleModalClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <InfoHollowButton onClick={handleClick} text={ESCALATE} />

            {isOpen && (
                <EscalateModal
                    assignedUsers={assignedUsers}
                    onCancel={handleModalClose}
                    onSubmit={() => {}}
                    requestId={requestId}
                    requestName={requestName}
                    requestSchema={requestSchema} 
                    versionId={versionId}
                />
            )}
        </>
    );
};

export default EscalateButton;
