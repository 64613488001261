import { useCallback } from 'react';

import Icon from 'components/Icon';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';
import image from './image.svg';

const FlaggedIcon = ({ className = '', onClick }) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return(
        <Icon className={`${styles.color} ${className}`}  image={image} onClick={handleClick} />
    );
}

export default FlaggedIcon;
