import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('overviewPanel');

    return {
        DUE_DATE: t('dueDate'),
        ENTITIES: t('entities'),
        GROUPS: t('groups'),
        START_DATE: t('startDate')
    };
};

export default useConstants;
