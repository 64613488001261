import { useEffect } from 'react';

import DocumentManagementSectionsPanel from 'components/DocumentManagementSectionsPanel';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useSections from './hooks/useSections';
import useData from './hooks/useData';

import normalizeData from './data';
import Loader from 'components/Loader';

const DocumentManagementSectionsTab = () => {
    const { useSchema, useTenants, useQuerySchemas } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [querySchemas] = useQuerySchemas();

    const [sections, setSections] = useSections();
    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            setSections(normalizedData);
        }
    }, [data, schema, setSections, tenants]);

    if (error) {
        
    }

    if(tenants && tenants.length===1){
        return <DocumentManagementSectionsPanel items={sections} loading={loading} />;
    }else{
        return <Loader text={'Please select only one tenant to view a list of documents.'} />
    }
};

export default DocumentManagementSectionsTab;
