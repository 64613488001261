import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('activityForm');

    return {
        ACTIVITY_NAME: t('activityName'),
        ACTIVITY_TYPE: t('activityType'),
        ADD_INSTRUCTIONS_HERE: t('addInstructionsHere'),
        DUE_DATE: t('dueDate'),
        ENTER_ACTIVITY_NAME: t('enterActivityName'),
        FORM_NAME: t('formName'),
        INSTRUCTIONS: t('instructions'),
        POLICY_REFERENCE: t('policyReference'),
        RECIPIENT: t('recipient')
    };
};

export default useConstants;
