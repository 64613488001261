import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelDeleteButtons from 'components/CancelDeleteButtons';
import ImportantText from 'components/ImportantText';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PageTitle';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeleteActivityModal = ({ activityName, id, onClose, schema }) => {
    const { HOME } = useConfig();
    const { ARE_YOU_SURE, DELETE_ACTIVITY } = useTranslation();

    const navigate = useNavigate();

    const { useActivities, useUserId } = useGlobalStateHooks();
    const [_activities, setActivities] = useActivities();
    const [userId] = useUserId();

    const [deletedActivity, deleteActivity, reset] = useData(schema);

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const handleDelete = useCallback(() => {
        const formObject = {activityId:id, userId};

        deleteActivity(formObject);

    }, [deleteActivity, id, userId]);

    useEffect(() => {
        if (!deletedActivity) {
            return;
        }

        if (isFunction(onClose)) {
            onClose(deletedActivity);
            setActivities({});
            const urlExtension = window.location.href.replace(window.location.origin, '');
            const redirect = !(urlExtension === HOME);
            if(redirect){
                reset();
                navigate(HOME);
            }else{
                reset();
                window.location.reload();
            }
        }
    }, [HOME, navigate, reset, setActivities, deletedActivity, onClose]);

    return (
        <Modal isOpen={true} onClose={handleClose}>
            <PanelBody>
                <PanelTitle className={styles.panelTitle} text={DELETE_ACTIVITY} />

                <ImportantText text={`${ARE_YOU_SURE} ${activityName}.`} />

                <CancelDeleteButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onDelete={handleDelete}
                />
            </PanelBody>
        </Modal>
    );
};

export default DeleteActivityModal;
