import { useCallback, useEffect, useState } from 'react';

import AddActivitiesPanelHeader from 'components/AddActivitiesPanelHeader';
import ContentPanel from 'components/ContentPanel';
import DetailedActivities from 'components/DetailedActivities';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useStatusFilter } from './hook/useStatusFilter';

const AddActivitiesPanel = ({ className = '', detailedData={} }) => {
    const { useActivityTypeFilter, useEndDateFilter, useEntityFilter, useIsAllFilter, useIsFlaggedFilter, useReviewFilter, useStartDateFilter, useUserFilter} = useGlobalStateHooks()

    const [isAll, setIsAll] = useIsAllFilter();
    const [isFlagged, setIsFlagged] = useIsFlaggedFilter();
    const [endDateFilter, setEndDateFilter] = useEndDateFilter();
    const [entityFilter, setEntityFilter] = useEntityFilter();
    const [startDateFilter, setStartDateFilter] = useStartDateFilter();
    const [typeFilter, setTypeFilter] = useActivityTypeFilter();
    const [userFilter, setUserFilter] = useUserFilter();
    const [reviewFilter, setReviewFilter] = useReviewFilter();
		const [statusFilter, setStatusFilter] = useStatusFilter()
    const [recurrencyFilter, setRecurrencyFilter] = useState();

    const [entityItems, setEntityItems] = useState([]);
    const [typeItems, setTypeItems] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const [reviewItems, setReviewItems] = useState([]);
    const [statusItems, setStatusItems] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [recurrencyItems, setRecurrencyItems] = useState([]);

    const handleToggle = useCallback(isOn => {
        setIsAll(!isOn);
        // eslint-disable-next-line
    }, []);

    const handleFlaggedToggle = useCallback(isOn => {
        setIsFlagged(!isOn);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!endDateFilter){
            setEndDateFilter(new Date(new Date().setDate(new Date().getDate() + 90)));
        }
        if (!startDateFilter){
            setStartDateFilter(new Date(new Date().setDate(new Date().getDate() - 21)));
        }
        if (!isNaN(userFilter)){
            setUserFilter(JSON.parse(localStorage.users).filter(user => user?.id === userFilter)[0]?.searchString)
        }
        if (!isNaN(reviewFilter)){
            setReviewFilter(JSON.parse(localStorage.users).filter(user => user?.id === reviewFilter)[0]?.searchString)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <ContentPanel className={`${className} ${styles.addActivitiesPanel}`}>
            <AddActivitiesPanelHeader
                endDate={endDateFilter}
                endDateFilter={setEndDateFilter}
                entityFilter={setEntityFilter}
                entityItems={entityItems}
                entitySelected={entityFilter}
                isAll={isAll}
                isFlagged={isFlagged}
                onFlaggedToggle={handleFlaggedToggle}
                onToggle={handleToggle}
                recurrencyFilter={setRecurrencyFilter}
                recurrencyItems={recurrencyItems}
                reviewFilter={setReviewFilter}
                reviewItems={reviewItems}
                reviewSelected={reviewFilter}
                setSearchFilter={setSearchFilter}
                startDate={startDateFilter}
                startDateFilter={setStartDateFilter}
								statusFilter={setStatusFilter}
								statusItems={statusItems}
								statusSelected={statusFilter}
                typeFilter={setTypeFilter}
                typeItems={typeItems}
                typeSelected={typeFilter}
                userFilter={setUserFilter}
                userItems={userItems}
                userSelected={userFilter}
            />

            <DetailedActivities
								detailedData={detailedData}
                endDate={endDateFilter}
                entity={entityFilter}
                isAll={isAll}
                isFlagged={isFlagged}
                recurrency={recurrencyFilter}
                review={reviewFilter}
                searchFilter={searchFilter}
                setEntityItems={setEntityItems}
                setRecurrencyItems={setRecurrencyItems}
                setReviewItems={setReviewItems}
                setStatusItems={setStatusItems}
                setTypeItems={setTypeItems}
                setUserItems={setUserItems}
                startDate={startDateFilter}
								status={statusFilter}
                type={typeFilter}
                user={userFilter}
            />
        </ContentPanel>
    );
};

export default AddActivitiesPanel;
