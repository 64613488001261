const translations = {
    activitiesAppliedTo: 'Activities Applied To',
    answersReceived: 'Answers Received',
    company: 'Company',
    formName: 'Form Name',
    isActive: 'Is Active',
    questionsInForm: 'Questions in Form'
};

export default translations;
