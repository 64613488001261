function getUserReferences(comment) {
    const userReferenceIndices = [];
        if(comment.includes('@')){
            for (let index = 0; index < comment.length; index++) {
                if (comment[index] === '@') {
                    userReferenceIndices.push(index);
                }
            }
            const userReferences = [];
            for (const userReference of userReferenceIndices){
                const referenceStart = comment.slice(userReference);
                const spaceCount = referenceStart.split(' ').length-1
                const atReference = spaceCount >= 2 ? referenceStart.substring(0, referenceStart.indexOf(' ', referenceStart.indexOf(' ')+1)) : referenceStart;
                const reference = atReference.replace('@', '');
                const user = JSON.parse(localStorage.users || "[]").filter(({searchString}) => searchString === reference);
                const group = JSON.parse(localStorage.groups || "[]").filter(({searchString}) => searchString.includes(reference));
                if (user.length > 0){
                    userReferences.push(user[0]);
                }
                if (group.length > 0){
                    userReferences.push(...group[0]?.users);
                }
            }
            return userReferences;
        }
        return [];
  }
  
  export default getUserReferences;