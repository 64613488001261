import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deletePolicySectionModal');

    return {
        CANCEL: t('cancel'),
        YOU_ARE_ABOUT_TO_DELETE_POLICY_SECTION: t(
            'youAreAboutToDeletePolicySection'
        )
    };
};

export default useTranslation;
