import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('caseManagementPanel');

    return {
        ASSIGNED_TO: t('assignedTo'),
        CASE_TYPE: t('caseType'),
        DUE_DATE: t('dueDate'),
        ENTITIES: t('entities'),
        RISK_LABEL: t('riskLabel'),
        SEARCH: t('search'),
        START_DATE: t('startDate'),
        VIEW_ALL: t('viewAll')
    };
};

export default useTranslation;
