import { atom } from 'recoil';

const groupDetailsAtom = atom({
    default: '',
    key: 'groupDetails'
});

const supervisorsAtom = atom({
    default: '',
    key: 'supervisors'
});

const state = {
    atoms: {
        groupDetailsAtom,
        supervisorsAtom
    }
};

export default state;
