import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
${tenantSchema}_case {
    case_name
    caseTypeByCaseType {
      name
    }
    closed
    closed_date
    tenant_user {
      id
      user_${tenantSchema} {
        first_name
        last_name
      }
    }
    created_date
    creation_reason
    due_date
    originated
    risk_label
    user_cases {
      group {
        name
        id
      }
      tenant_user {
        id
        user_${tenantSchema} {
          first_name
          last_name
        }
      }
    }
  }
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
