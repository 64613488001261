import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateActivityName } = state;

const { updateActivityNameAtom } = updateActivityName.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateActivityInstructions($text: String!, $activityId: Int!) {
    update_${tenantSchema}_activity(where: {id: {_eq: $activityId}}, _set: {name: $text}) {
      returning {
        name
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_dropdown($activityId: Int!, $color: String = "yellow", $message: String = "changed the activity name", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveActivityName = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
              const res = await mutation(variables);

              const newName = res?.data[`update_${usedSchema}_activity`].returning[0]?.name;

              await mutationActivityHistory(variables);

              set(updateActivityNameAtom, newName);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityNameAtom, '');
    });

    return [useRecoilValue(updateActivityNameAtom), saveActivityName, reset];
};

export default useData;
