import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addMarketingVersion } = state;

const { addMarketingVersionAtom } = addMarketingVersion.atoms;

const MUTATE_VERSION = tenantSchema => gql`
    mutation insertMarketingRequestVersion (
        $createdBy: Int!, 
        $file: String!,
        $fileName: String!,
        $newVersionNumber: Int!, 
        $requestId: Int!, 
        $status: String!
    ) {
        insert_${tenantSchema}_marketing_request_version(
            objects: {
                request_id: $requestId,
                version_number: $newVersionNumber,
                created_by: $createdBy,
                file: $file,
                file_name: $fileName,
                status: $status
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_POLICY_VERSION = tenantSchema => gql`
    mutation insertPolicyVersion (
        $createdBy: Int!, 
        $file: String!, 
        $fileName: String!,
        $newVersionNumber: Int!, 
        $requestId: Int!
    ) {
        insert_${tenantSchema}_document_version(
            objects: {
                document_id: $requestId,
                version_number: $newVersionNumber,
                modified_by_user_id: $createdBy,
                file: $file
                file_name: $fileName
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_VERSION, providedSchema);
    const mutationPolicy = useGraphQL(MUTATE_POLICY_VERSION, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const createActivity = useRecoilCallback(({ set }) => variables => {
        const filenameFromFileIndex = variables['file'].lastIndexOf('_');
        const filenameExtensionIndex = variables['file'].lastIndexOf('.');
        variables['fileName'] = variables['file'].substr(0, filenameFromFileIndex) + variables['file'].substr(filenameExtensionIndex);
        const runMutation = async () => {
            let id;
            if(variables.type === 'marketing'){
                const res = await mutation(variables);

                id = res?.data[`insert_${usedSchema}_marketing_request_version`].returning[0]?.id;
            }else if(variables.type === 'policy'){
                const res = await mutationPolicy(variables);

                id = res?.data[`insert_${usedSchema}_document_version`].returning[0]?.id;
            }

            set(addMarketingVersionAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addMarketingVersionAtom, '');
    });

    return [useRecoilValue(addMarketingVersionAtom), createActivity, reset];
};

export default useData;
