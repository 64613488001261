import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const PolarAreaChart = ({ className = '', data = {} }) => {
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    
    return (
        <Scatter className={className} data={data} options={options} />
    );
};

export default PolarAreaChart;