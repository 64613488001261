import { useState } from 'react';

import Content from 'components/Content';
import Header from 'components/Header';
import NavigationDrawer from 'components/NavigationDrawer';
import NavigationPanel from 'components/NavigationPanel';

import {usePermissions} from 'hooks/usePermissions';

import styles from './styles.module.scss';

import { safeGetLocalStorage, safeSetLocalStorage } from 'utilities/localStorage';

const SHOW_SIDEBAR_PERMISSION = 'sidebar:read';

const MainLayout = () => {
    const [isOpen, setIsOpen] = useState();
    const [isCollapsed, setIsCollapsed] = useState(
        safeGetLocalStorage('isCollapsed', false)
    );

    const {hasPermissions} = usePermissions();

    const hasPermissionToShowSidebar = hasPermissions([SHOW_SIDEBAR_PERMISSION]);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        safeSetLocalStorage('isCollapsed', true);
    };

    const handleExpand = () => {
        setIsCollapsed(false);
        safeSetLocalStorage('isCollapsed', false);
    };

    const handleHamburgerOpen = menuState => {
        setIsOpen(menuState);
    };

    const handleHamburgerClose = () => {
        setIsOpen(false);
    };

    return (
        <div
            className={
                isCollapsed ? styles.mainLayoutCollapsed : styles.mainLayout
            }
        >
            <NavigationDrawer
                className={styles.navigationDrawer}
                isOpen={isOpen}
                onClose={handleHamburgerClose}
            />

            {hasPermissionToShowSidebar && (
                <NavigationPanel
                    className={styles.navigationPanel}
                    handleCollapse={handleCollapse}
                    handleExpand={handleExpand}
                    isCollapsed={isCollapsed}
                />
            )}


            <Header
                className={hasPermissionToShowSidebar ? styles.header : styles.headerFull}
                onHamburgerOpen={handleHamburgerOpen}
                showHamburguer={hasPermissionToShowSidebar}
            />

            <Content className={hasPermissionToShowSidebar ? styles.content : styles.contentFull} />
        </div>
    );
};

export default MainLayout;
