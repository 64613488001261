import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_entity`].reduce(
        (accumulator, entity) => {
            accumulator.push({
                label: entity.name,
                value: entity.id
            });

            return accumulator;
        }, []);

const normalizeEntityData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeEntityData;