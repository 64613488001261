import { useCallback } from 'react';

import GroupPill from 'components/GroupPill';
import UserPill from 'components/UserPill';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const pillMap = {
    group: GroupPill,
    user: UserPill
};

const DropdownItemWithPill = ({ className = '', onClick, text = '', type }) => {
    const PillComponent = pillMap[type];

    const handleClick = useCallback(
        event => {
            
            if (isFunction(onClick)) {
                onClick(event);
            }
        },
        [onClick]
    );

    return (
        <div
            className={`${className} ${styles.dropdownItemWithPill}`}
            onClick={handleClick}
        >
            {text}

            {PillComponent && <PillComponent />}
        </div>
    );
};

export default DropdownItemWithPill;
