import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('overdueCountPanel');

    return {
        OVERDUE: t('overdue')
    };
};

export default useTranslation;
