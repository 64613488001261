// import { useEffect } from 'react';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { caseDetails } = state;

const { caseAttachmentAtom } = caseDetails.atoms;

const DELETE_ATTACHMENT = tenantSchema => gql`
    mutation delete_attachment($attachmentId: Int!) {
        update_${tenantSchema}_case_attachment(
            where: { id: { _eq: $attachmentId } },
            _set: { deleted: true }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_CASE_ATTACHMENT = tenantSchema => gql`
    mutation insertCaseAttachment(
        $caseId: Int!, 
        $filename: String!,
        $name: String!
    ) {
        insert_${tenantSchema}_case_attachment(objects: {
            file: $filename,
            case_id: $caseId,
            name: $name
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insert_case_history_attachment($caseId: Int!, $color: String = "yellow", $message: String = "changed a dropdown", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const UPDATE_ATTACHMENT = tenantSchema => gql`
    mutation update_attachment($attachmentId: Int!, $newName: String!) {
        update_${tenantSchema}_case_attachment(
            where: { id: { _eq: $attachmentId } },
            _set: { name: $newName }
        ) {
            returning {
                id
            }
        }
    }
`;

const useAttachments = (providedSchema) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();
    const deleteAttachmentMutation = useGraphQL(DELETE_ATTACHMENT, providedSchema);
    const mutation = useGraphQL(MUTATE_CASE_ATTACHMENT, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);
    const updateAttachmentMutation = useGraphQL(UPDATE_ATTACHMENT, providedSchema);

    const createAttachment = useRecoilCallback(({ set }) => attachments => {
        const runMutation = async () => {
            for (let [key, value] of Object.entries(attachments.files)) {
                if(!value?.isUploadError){
                    const namePrefix = key.slice(0, key.lastIndexOf('_')).replaceAll('_', ' ').trim();
                    const fileExtension = key.slice(key.lastIndexOf('.') + 1);
                    const name = `${namePrefix}.${fileExtension}`;
                    await mutation({
                        caseId: attachments?.id,
                        filename: key,
                        name
                    });
                    await mutationCaseHistory({
                        caseId: attachments?.id,
                        message: `uploaded new file: ${key}`,
                        userId
                    })
                }
            }

            set(caseAttachmentAtom, true);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(caseAttachmentAtom, false);
    });

    const deleteAttachment = async params => {
        const { attachmentId, caseId, filename } = params;
        await deleteAttachmentMutation({ attachmentId });

        await mutationCaseHistory({
            caseId,
            message: `deleted file: ${filename}`,
            userId
        });
    };

    const updateAttachment = async params => {
        const { attachmentId, caseId, filename, newName } = params;
        await updateAttachmentMutation({ attachmentId, newName });

        await mutationCaseHistory({
            caseId,
            message: `updated filename of: ${filename}`,
            userId
        });
    };

    return [
        useRecoilValue(caseAttachmentAtom),
        createAttachment,
        reset,
        deleteAttachment,
        updateAttachment
    ];
};

export default useAttachments;
