import AsideLink from 'components/AsideLink';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const AddAssignee = ({ className = '', onClick = '' }) => (
    <Buttonize onClick={onClick}>
        <AsideLink
            className={`${className} ${styles.addAssignee}`}
            text="+ Add Assignee"
        />
    </Buttonize>
);

export default AddAssignee;
