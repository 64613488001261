import { atom } from 'recoil';

const updateRecurrenceEndDateModalAtom = atom({
    default: '',
    key: 'updateRecurrenceEndDateModal'
});

const state = {
    atoms: {
        updateRecurrenceEndDateModalAtom
    }
};

export default state;
