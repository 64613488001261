import activities from 'pages/Activities/translations/english';
import activitiesHeader from 'components/ActivitiesHeader/translations/english';
import activitiesViewSelector from 'components/ActivitiesViewSelector/translations/english';
import activity from 'pages/Activity/translations/english';
import activityDetailsPanel from 'components/ActivityDetailsPanel/translations/english';
import activityForm from 'components/ActivityForm/translations/english';
import activityFormTable from 'components/ActivityFormTable/translations/english';
import activityMetaPanel from 'components/ActivityMetaPanel/translations/english';
import activityQuestions from 'components/ActivityQuestions/translations/english';
import activityTabs from 'components/ActivityTabs/translations/english';
import activityTypeDetailsPanel from 'components/ActivityTypeDetailsPanel/translations/english';
import activityTypeForm from 'components/ActivityTypeForm/translations/english';
import addActivity from 'pages/AddActivity/translations/english';
import addActivityButton from 'components/AddActivityButton/translations/english';
import addActivityMetaPanel from 'components/AddActivityMetaPanel/translations/english';
import addActivityQuestions from 'components/AddActivityQuestions/translations/english';
import addActivityType from 'pages/AddActivityType/translations/english';
import addActivityTypeButton from 'components/AddActivityTypeButton/translations/english';
import addCaseButton from 'components/AddCaseButton/translations/english';
import addForm from 'pages/AddForm/translations/english';
import addFormButton from 'components/AddFormsButton/translations/english';
import addFromActivityButton from 'components/AddFromActivityButton/translations/english';
import addGroup from 'pages/AddGroup/translations/english';
import addGroupButton from 'components/AddGroupButton/translations/english';
import addLogEntry from 'pages/AddLogEntry/translations/english';
import addLogEntryButton from 'components/AddLogEntryButton/translations/english';
import addLogEntryModal from 'components/AddLogEntryModal/translations/english';
import addMarketingRequest from 'pages/AddMarketingRequest/translations/english';
import addNew from 'components/AddNew/translations/english';
import addPerson from 'pages/AddPerson/translations/english';
import addPersonButton from 'components/AddPersonButton/translations/english';
import addPolicy from 'pages/AddPolicy/translations/english';
import addPolicyButton from 'components/AddPolicyButton/translations/english';
import addPolicySectionButton from 'components/AddPolicySectionButton/translations/english';
import addPolicySectionModal from 'components/AddPolicySectionModal/translations/english';
import addRequestButton from 'components/AddRequestButton/translations/english';
import addSectionButton from 'components/AddSectionButton/translations/english';
import addSectionModal from 'components/AddSectionModal/translations/english';
import addVersionLink from 'components/AddVersionLink/translations/english';
import activityTypeEditForm from 'pages/ActivityTypeEditForm/translations/english';
import adminActivityTypeFormDetailsPanel from 'components/AdminActivityTypeFormDetailsPanel/translations/english';
import adminCase from 'components/AdminCase/translations/english';
import adminGroupFormDetailsPanel from 'components/AdminGroupFormDetailsPanel/translations/english';
import adminGroupFormMembersPanel from 'components/AdminGroupFormMembersPanel/translations/english';
import adminLogCategorySettings from 'components/AdminLogCategorySettings/translations/english';
import adminMasterEmailAddress from 'components/AdminMasterEmailAddress/translations/english';
import adminRiskMonitoring from 'components/AdminRiskMonitoring/translations/english';
import administration from 'pages/Administration/translations/english';
import administrationActivityTypesTableHeader from 'components/AdministrationActivityTypesTableHeader/translations/english';
import administrationActivityTypesTableRow from 'components/AdministrationActivityTypesTableRow/translations/english';
import administrationAdditionalSettingsSidebar from 'components/AdministrationAdditionalSettingsSidebar/translations/english';
import administrationCaseTypesTableHeader from 'components/AdministrationCaseTypesTableHeader/translations/english';
import administrationEmailsTableHeader from 'components/AdministrationEmailsTableHeader/translations/english';
import administrationEmailsTableRow from 'components/AdministrationEmailsTableRow/translations/english';
import administrationGroupsTableHeader from 'components/AdministrationGroupsTableHeader/translations/english';
import administrationGroupsTableRow from 'components/AdministrationGroupsTableRow/translations/english';
import administrationLogTypesTableHeader from 'components/AdministrationLogTypesTableHeader/translations/english';
import administrationMarketingSidebar from 'components/AdministrationMarketingSidebar/translations/english';
import administrationRolesTableHeader from 'components/AdministrationRolesTableHeader/translations/english';
import administrationRolesTableRow from 'components/AdministrationRolesTableRow/translations/english';
import allMeToggle from 'components/AllMeToggle/translations/english';
import approveButton from 'components/ApproveButton/translations/english';
import approveRequestContent from 'components/ApproveRequestContent/translations/english';
import approveRequestModal from 'components/ApproveRequestModal/translations/english';
import asideMenu from 'components/AsideMenu/translations/english';
import attachments from 'components/Attachments/translations/english';
import authenticatePanel from 'components/AuthenticatePanel/translations/english';
import authenticatingPanel from 'components/AuthenticatingPanel/translations/english';
import backButton from 'components/BackButton/translations/english';
import buildPolicy from 'pages/BuildPolicy/translations/english';
import calendarLink from 'components/CalendarLink/translations/english';
import cancelButton from 'components/CancelButton/translations/english';
import caseCreationReasonModal from 'components/CaseCreationReasonModal/translations/english';
import caseDetailsPanel from 'components/CaseDetailsPanel/translations/english';
import caseManagement from 'pages/CaseManagement/translations/english';
import caseManagementPanel from 'components/CaseManagementPanel/translations/english';
import caseMetaPanel from 'components/CaseMetaPanel/translations/english';
import casePage from 'pages/Case/translations/english';
import casePanel from 'components/CasePanel/translations/english';
import caseTable from 'components/CaseTable/translations/english';
import caseTypeMetaPanel from 'components/CaseTypeMetaPanel/translations/english';
import closeActivityButton from 'components/CloseActivityButton/translations/english';
import closeAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem/translations/english';
import closeAndCompleteActivityButton from 'components/CloseAndCompleteActivityButton/translations/english';
import closeAndCreateCaseMenuListItem from 'components/CloseAndCreateCaseMenuListItem/translations/english';
import closeAndCreateNewActivityMenuListItem from 'components/CloseAndCreateNewActivityMenuListItem/translations/english';
import closeCaseAndAddLogEntryMenuListItem from 'components/CloseCaseAndAddLogEntryMenuListItem/translations/english';
import closeCaseButton from 'components/CloseCaseButton/translations/english';
import closeCaseDirectButton from 'components/CloseCaseDirectButton/translations/english';
import closeMenuListItem from 'components/CloseMenuListItem/translations/english';
import commentInput from 'components/CommentInput/translations/english';
import comments from 'components/Comments/translations/english';
import completedItems from 'components/CompletedItems/translations/english';
import complianceEntryActivityPanel from 'components/ComplianceEntryActivityPanel/translations/english';
import complianceEntryCasePanel from 'components/ComplianceEntryCasePanel/translations/english';
import complianceEntryDetailsPanel from 'components/ComplianceEntryDetailsPanel/translations/english';
import complianceEntryPolicyPanel from 'components/ComplianceEntryPolicyPanel/translations/english';
import complianceLog from 'pages/ComplianceLog/translations/english';
import complianceLogEntry from 'pages/ComplianceLogEntry/translations/english';
import complianceLogEntryMetaPanel from 'components/ComplianceLogEntryMetaPanel/translations/english';
import complianceLogTableHeader from 'components/ComplianceLogTableHeader/translations/english';
import complianceLogTableRow from 'components/ComplianceLogTableRow/translations/english';
import confirmApproveRequestModal from 'components/ConfirmApproveRequestModal/translations/english';
import confirmRequestUpdateModal from 'components/ConfirmRequestUpdateModal/translations/english';
import confirmationModal from 'components/ConfirmationModal/translations/english';
import createActivityMenuListItemFromActivity from 'components/CreateActivityMenuListItemFromActivity/translations/english';
import createCaseMenuListItemFromActivity from 'components/CreateCaseMenuListItemFromActivity/translations/english';
import createCaseModal from 'components/CreateCaseModal/translations/english';
import createLogEntryForm from 'components/CreateLogEntryForm/translations/english';
import createLogEntryModal from 'components/CreateLogEntryModal/translations/english';
import createPolicyVersionModal from 'components/CreatePolicyVersionModal/translations/english';
import criticalRiskPill from 'components/CriticalRiskPill/translations/english';
import deactivatePolicyVersionButton from 'components/DeactivatePolicyVersionButton/translations/english';
import deactivatePolicyVersionModal from 'components/DeactivatePolicyVersionModal/translations/english';
import deleteActivityModal from 'components/DeleteActivityModal/translations/english';
import deleteAttachmentModal from 'components/DeleteAttachmentModal/translations/english';
import deleteButton from 'components/DeleteButton/translations/english';
import deletePolicySectionModal from 'components/DeletePolicySectionModal/translations/english';
import deletePolicyVersionModal from 'components/DeletePolicyVersionModal/translations/english';
import deleteRecurrenceModal from 'components/DeleteRecurrenceModal/translations/english';
import detailedListItems from 'components/DetailedListItems/translations/english';
import documentManagement from 'pages/DocumentManagement/translations/english';
import documentManagementPanel from 'components/DocumentManagementPanel/translations/english';
import documentManagementRepositoryPanel from 'components/DocumentManagementRepositoryPanel/translations/english';
import documentManagementRepositoryTableHeader from 'components/DocumentManagementRepositoryTableHeader/translations/english';
import documentManagementSectionsTableHeader from 'components/DocumentManagementSectionsTableHeader/translations/english';
import documentManagementSectionsTableRow from 'components/DocumentManagementSectionsTableRow/translations/english';
import documentManagementSideLetterTableHeader from 'components/DocumentManagementSideLetterTableHeader/translations/english';
import documentManagementSideLetterTableRow from 'components/DocumentManagementSideLetterTableRow/translations/english';
import documentManagementTableHeader from 'components/DocumentManagementTableHeader/translations/english';
import documentManagementTableRow from 'components/DocumentManagementTableRow/translations/english';
import doneCountPanel from 'components/DoneCountPanel/translations/english';
import downloadButton from 'components/DownloadButton/translations/english';
import dropdown from 'components/Dropdown/translations/english';
import dropdownWithTags from 'components/DropdownWithTags/translations/english';
import due from 'components/Due/translations/english';
import editDeleteDetailsButton from 'components/EditDeleteDetailsButton/translations/english';
import editGroup from 'pages/EditGroup/translations/english';
import editPerson from 'pages/EditPerson/translations/english';
import editPolicySectionModal from 'components/EditPolicySectionModal/translations/english';
import editRecurrenceButton from 'components/EditRecurrenceButton/translations/english';
import editRecurrenceModal from 'components/EditRecurrenceModal/translations/english';
import editSectionModal from 'components/EditSectionModal/translations/english';
import emailAddressInput from 'components/EmailAddressInput/translations/english';
import employeesPanel from 'components/EmployeesPanel/translations/english';
import entityTags from 'components/EntityTags/translations/english';
import escalateButton from 'components/EscalateButton/translations/english';
import escalateModal from 'components/EscalateModal/translations/english';
import fileUpload from 'components/FileUpload/translations/english';
import firstNameInput from 'components/FirstNameInput/translations/english';
import flaggedToggle from 'components/FlaggedToggle/translations/english';
import formDetailsPanel from 'components/FormDetailsPanel/translations/english';
import formMetaPanel from 'components/FormMetaPanel/translations/english';
import formsHeader from 'components/FormsHeader/translations/english';
import formsListItems from 'components/FormsListItems/translations/english';
import formsManagement from 'pages/FormsManagement/translations/english';
import formTable from 'components/FormTable/translations/english';
import formSettingsTableHeader from 'components/FormSettingsTableHeader/translations/english';
import fullFormDrawer from 'components/FullFormDrawer/translations/english';
import glancePanel from 'components/GlancePanel/translations/english';
import groupInput from 'components/GroupInput/translations/english';
import groupPill from 'components/GroupPill/translations/english';
import highRiskPill from 'components/HighRiskPill/translations/english';
import humanResources from 'pages/HumanResources/translations/english';
import inProgressCountPanel from 'components/InProgressCountPanel/translations/english';
import inProgressItems from 'components/InProgressItems/translations/english';
import inviteButton from 'components/InviteButton/translations/english';
import lastNameInput from 'components/LastNameInput/translations/english';
import listLink from 'components/ListLink/translations/english';
import logEntriesPanel from 'components/LogEntriesPanel/translations/english';
import loginPanel from 'components/LoginPanel/translations/english';
import lowRiskPill from 'components/LowRiskPill/translations/english';
import markCompleteButton from 'components/MarkCompleteButton/translations/english';
import markDirectCloseButton from 'components/MarkDirectCloseButton/translations/english';
import markReopenButton from 'components/MarkReopenButton/translations/english';
import markReviewedButton from 'components/MarkReviewedButton/translations/english';
import marketingPanel from 'components/MarketingPanel/translations/english';
import marketingRequest from 'pages/MarketingRequest/translations/english';
import marketingRequestDetailsPanel from 'components/MarketingRequestDetailsPanel/translations/english';
import marketingRequestForm from 'components/MarketingRequestForm/translations/english';
import marketingRequestMetaPanel from 'components/MarketingRequestMetaPanel/translations/english';
import marketingRequestReviewerActions from 'components/MarketingRequestReviewerActions/translations/english';
import marketingReview from 'pages/MarketingRequests/translations/english';
import marketingRequestsPanel from 'components/MarketingRequestsPanel/translations/english';
import markupInput from 'components/MarkupInput/translations/english';
import mediumRiskPill from 'components/MediumRiskPill/translations/english';
import neutralRiskPill from 'components/NeutralRiskPill/translations/english';
import overdue from 'components/Overdue/translations/english';
import overdueCountPanel from 'components/OverdueCountPanel/translations/english';
import overdueItems from 'components/OverdueItems/translations/english';
import overviewPanel from 'components/OverviewPanel/translations/english';
import passwordInput from 'components/PasswordInput/translations/english';
import personalInformationPanel from 'components/PersonalInformationPanel/translations/english'
import peopleTableRow from 'components/PeopleTableRow/translations/english';
import policy from 'pages/Policy/translations/english';
import policyActions from 'components/PolicyActions/translations/english';
import policyDetailsPanel from 'components/PolicyDetailsPanel/translations/english';
import policyForm from 'components/PolicyForm/translations/english';
import policyInformation from 'components/PolicyInformation/translations/english';
import policyMappingPanel from 'components/PolicyMappingPanel/translations/english';
import policyMappingTableHeader from 'components/PolicyMappingTableHeader/translations/english';
import policyMetaPanel from 'components/PolicyMetaPanel/translations/english';
import policyNameInput from 'components/PolicyNameInput/translations/english';
import policySectionForm from 'components/PolicySectionForm/translations/english';
import publishPolicyVersionButton from 'components/PublishPolicyVersionButton/translations/english';
import publishPolicyVersionModal from 'components/PublishPolicyVersionModal/translations/english';
import rejectButton from 'components/RejectButton/translations/english';
import rejectModal from 'components/RejectModal/translations/english';
import requestStatus from 'components/RequestStatus/translations/english';
import requestUpdateButton from 'components/RequestUpdateButton/translations/english';
import requestUpdateModal from 'components/RequestUpdateModal/translations/english';
import respondUpdateButton from 'components/RespondUpdateButton/translations/english';
import respondUpdateModal from 'components/RespondUpdateModal/translations/english';
import requestorActions from 'components/RequestorActions/translations/english';
import requestsTable from 'components/RequestsTable/translations/english';
import roleInput from 'components/RoleInput/translations/english';
import saveButton from 'components/SaveButton/translations/english';
import selectUserListItem from 'components/SelectUserListItem/translations/english';
import selectUsersDrawer from 'components/SelectUsersDrawer/translations/english';
import specActivity from 'pages/SpecActivity/translations/english';
import summarizedListItems from 'components/SummarizedListItems/translations/english';
import textarea from 'components/Textarea/translations/english';
import upcomingCountPanel from 'components/UpcomingCountPanel/translations/english';
import upcomingItems from 'components/UpcomingItems/translations/english';
import updateRecurrenceEndDateModal from 'components/UpdateRecurrenceEndDateModal/translations/english';
import uploadPolicyFromFileListItem from 'components/UploadPolicyFromFileListItem/translations/english';
import uploadPolicyFromRepoListItem from 'components/UploadPolicyFromRepoListItem/translations/english';
import userPill from 'components/UserPill/translations/english';
import usernameInput from 'components/UsernameInput/translations/english';
import versionHistory from 'components/VersionHistory/translations/english';
import versions from 'components/Versions/translations/english';
import visibleToDropdown from 'components/VisibleToDropdown/translations/english';
import withdrawButton from 'components/WithdrawButton/translations/english';
import withdrawModal from 'components/WithdrawModal/translations/english';
import workflowSettingsTableHeader from 'components/WorkflowSettingsTableHeader/translations/english';

const TRANSLATIONS_EN = {
    activities,
    activitiesHeader,
    activitiesViewSelector,
    activity,
    activityDetailsPanel,
    activityForm,
    activityFormTable,
    activityMetaPanel,
    activityQuestions,
    activityTabs,
    activityTypeDetailsPanel,
    activityTypeForm,
    addActivity,
    addActivityButton,
    addActivityMetaPanel,
    addActivityQuestions,
    addActivityType,
    addActivityTypeButton,
    addCaseButton,
    addForm,
    addFormButton,
    addFromActivityButton,
    addGroup,
    addGroupButton,
    addLogEntry,
    addLogEntryButton,
    addLogEntryModal,
    addMarketingRequest,
    addNew,
    addPerson,
    addPersonButton,
    addPolicy,
    addPolicyButton,
    addPolicySectionButton,
    addPolicySectionModal,
    addRequestButton,
    addSectionButton,
    addSectionModal,
    addVersionLink,
    activityTypeEditForm,
    adminActivityTypeFormDetailsPanel,
    adminCase,
    adminGroupFormDetailsPanel,
    adminGroupFormMembersPanel,
    adminLogCategorySettings,
    adminMasterEmailAddress,
    adminRiskMonitoring,
    administration,
    administrationActivityTypesTableHeader,
    administrationActivityTypesTableRow,
    administrationAdditionalSettingsSidebar,
    administrationCaseTypesTableHeader,
    administrationEmailsTableHeader,
    administrationEmailsTableRow,
    administrationGroupsTableHeader,
    administrationGroupsTableRow,
    administrationLogTypesTableHeader,
    administrationMarketingSidebar,
    administrationRolesTableHeader,
    administrationRolesTableRow,
    allMeToggle,
    approveButton,
    approveRequestContent,
    approveRequestModal,
    asideMenu,
    attachments,
    authenticatePanel,
    authenticatingPanel,
    backButton,
    buildPolicy,
    calendarLink,
    cancelButton,
    caseCreationReasonModal,
    caseDetailsPanel,
    caseManagement,
    caseManagementPanel,
    caseMetaPanel,
    casePage,
    casePanel,
    caseTable,
    caseTypeMetaPanel,
    closeActivityButton,
    closeAndAddLogEntryMenuListItem,
    closeAndCompleteActivityButton,
    closeAndCreateCaseMenuListItem,
    closeAndCreateNewActivityMenuListItem,
    closeCaseAndAddLogEntryMenuListItem,
    closeCaseButton,
    closeCaseDirectButton,
    closeMenuListItem,
    commentInput,
    comments,
    completedItems,
    complianceEntryActivityPanel,
    complianceEntryCasePanel,
    complianceEntryDetailsPanel,
    complianceEntryPolicyPanel,
    complianceLog,
    complianceLogEntry,
    complianceLogEntryMetaPanel,
    complianceLogTableHeader,
    complianceLogTableRow,
    confirmApproveRequestModal,
    confirmRequestUpdateModal,
    confirmationModal,
    createActivityMenuListItemFromActivity,
    createCaseMenuListItemFromActivity,
    createCaseModal,
    createLogEntryForm,
    createLogEntryModal,
    createPolicyVersionModal,
    criticalRiskPill,
    deactivatePolicyVersionButton,
    deactivatePolicyVersionModal,
    deleteActivityModal,
    deleteAttachmentModal,
    deleteButton,
    deletePolicySectionModal,
    deletePolicyVersionModal,
    deleteRecurrenceModal,
    detailedListItems,
    documentManagement,
    documentManagementPanel,
    documentManagementRepositoryPanel,
    documentManagementRepositoryTableHeader,
    documentManagementSectionsTableHeader,
    documentManagementSectionsTableRow,
    documentManagementSideLetterTableHeader,
    documentManagementSideLetterTableRow,
    documentManagementTableHeader,
    documentManagementTableRow,
    doneCountPanel,
    downloadButton,
    dropdown,
    dropdownWithTags,
    due,
    editDeleteDetailsButton,
    editGroup,
    editPerson,
    editPolicySectionModal,
    editRecurrenceButton,
    editRecurrenceModal,
    editSectionModal,
    emailAddressInput,
    employeesPanel,
    entityTags,
    escalateButton,
    escalateModal,
    fileUpload,
    firstNameInput,
    flaggedToggle,
    formDetailsPanel,
    formMetaPanel,
    formSettingsTableHeader,
    formTable,
    formsHeader,
    formsListItems,
    formsManagement,
    fullFormDrawer,
    glancePanel,
    groupInput,
    groupPill,
    highRiskPill,
    humanResources,
    inProgressCountPanel,
    inProgressItems,
    inviteButton,
    lastNameInput,
    listLink,
    logEntriesPanel,
    loginPanel,
    lowRiskPill,
    markCompleteButton,
    markDirectCloseButton,
    markReopenButton,
    markReviewedButton,
    marketingPanel,
    marketingRequest,
    marketingRequestDetailsPanel,
    marketingRequestForm,
    marketingRequestMetaPanel,
    marketingRequestReviewerActions,
    marketingRequestsPanel,
    marketingReview,
    markupInput,
    mediumRiskPill,
    neutralRiskPill,
    overdue,
    overdueCountPanel,
    overdueItems,
    overviewPanel,
    passwordInput,
    personalInformationPanel,
    peopleTableRow,
    policy,
    policyActions,
    policyDetailsPanel,
    policyForm,
    policyInformation,
    policyMappingPanel,
    policyMappingTableHeader,
    policyMetaPanel,
    policyNameInput,
    policySectionForm,
    publishPolicyVersionButton,
    publishPolicyVersionModal,
    rejectButton,
    rejectModal,
    requestStatus,
    requestUpdateButton,
    requestUpdateModal,
    requestorActions,
    requestsTable,
    respondUpdateButton,
    respondUpdateModal,
    roleInput,
    saveButton,
    selectUserListItem,
    selectUsersDrawer,
    specActivity,
    summarizedListItems,
    textarea,
    upcomingCountPanel,
    upcomingItems,
    updateRecurrenceEndDateModal,
    uploadPolicyFromFileListItem,
    uploadPolicyFromRepoListItem,
    userPill,
    usernameInput,
    versionHistory,
    versions,
    visibleToDropdown,
    withdrawButton,
    withdrawModal,
    workflowSettingsTableHeader
};

export { TRANSLATIONS_EN };
