import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('publishPolicyVersionModal');

    return {
        ARE_YOU_SURE: t('areYouSure'),
        CANCEL: t('cancel'),
        PUBLISH: t('publish'),
        YOU_ARE_ABOUT_TO_PUBLISH: t('youAreAboutToPublish')
    };
};

export default useTranslation;
