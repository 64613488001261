import Avatar from 'components/Avatar';

import styles from './styles.module.scss';

const Avatars = ({ className = '', users }) => {
    const usersSlice =
        users && users.length > 3 ? users.slice(0, 3) : users;
    return (
        <span className={`${className} ${styles.imageContainer}`}>
            {usersSlice && usersSlice.length === 3 ? (
                <Avatar className={styles.avatar3} user={usersSlice[2]} />
            ) : (
                <></>
            )}

            {usersSlice && usersSlice.length >= 2 ? (
                <Avatar className={styles.avatar2} user={usersSlice[1]} />
            ) : (
                <></>
            )}

            {usersSlice && usersSlice.length >= 1 ? (
                <Avatar className={styles.avatar1} user={usersSlice[0]} />
            ) : (
                <></>
            )}
        </span>
    );
};

export default Avatars;
