import styles from './styles.module.scss';

const Label = ({ text = '', className = '', isDisabled, isRequired=false }) => (
    <label className={`${className} ${styles.label} ${isDisabled ? styles.disabled : ''}`}>
        {text}

        {isRequired && <span className={styles.isRequired}> *</span>}
    </label>
);

export default Label;
