import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import DropdownWithTags from 'components/DropdownWithTags';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useQueryData from './hooks/useQueryData';
import useTranslation from './hooks/useTranslation';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

import InfoButton from 'components/InfoButton';

const EscalateModal = ({ assignedUsers, onCancel, requestId, requestName, requestSchema, versionId }) => {
    const { ESCALATE, ESCALATE_TO_USER_OR_GROUP, USERS_OR_GROUPS } = useTranslation();
    const { MARKETING_REQUESTS_ROUTE } = useConfig();

    const navigate = useNavigate();

    const { useConsultancyUsers, useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();
    const [consultancyUsers] = useConsultancyUsers();

    const [_errorMessage, setErrorMessage] = useState();

    const [escalatedRequest, escalateRequest, _reset] = useQueryData(requestSchema);

    const form = useForm({
        assignedTo: ''
    });

    const { handleValid } = form;

    const handleModalClose = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel(false);
        }
    }, [onCancel]);

    const handleSubmit = useCallback(
        form => () => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                handleModalClose();

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {}
            );
            formObject.assignedTo = JSON.parse(formObject.assignedTo);
            formObject.requestId = requestId;
            formObject.requestName = requestName;
            formObject.assignedUsers = assignedUsers;
            formObject.userId = userId;
            formObject.versionId = versionId;

            escalateRequest(formObject)

            
        },
        [handleModalClose, escalateRequest, requestId, requestName, assignedUsers, userId, versionId]
    );

    useEffect(() => {
        if (!escalatedRequest?.length) {
            return;
        }

        navigate(MARKETING_REQUESTS_ROUTE)
    }, [navigate, escalatedRequest, MARKETING_REQUESTS_ROUTE]);

    return (
        <Modal
            className={styles.escalateConfirmationModal}
            isOpen
            onClose={onCancel}
        >

            <PanelBody className={styles.panelBody}>
                <PanelTitle text={ESCALATE_TO_USER_OR_GROUP} />

                    <DropdownWithTags
                        className={styles.dropdownWithTags}
                        items={consultancyUsers ? consultancyUsers.filter(item => item.tenants.includes(requestSchema)) : []}
                        label={USERS_OR_GROUPS}
                        onChange={handleValid('assignedTo')}
                        tags={[]}
                    />

                    <Buttons>
                        <CancelButton onClick={onCancel} />

                        <InfoButton
                            onClick={handleSubmit(form)}
                            text={ESCALATE}
                        />
                    </Buttons>
            </PanelBody>
        </Modal>
    );
};

export default EscalateModal;
