import ApproveRequestButton from 'components/ApproveRequestButton';
import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import EscalateButton from 'components/EscalateButton';
import RejectButton from 'components/RejectButton';
import RequestUpdateButton from 'components/RequestUpdateButton';

const ActivePartyActions = props => {
    const { assignedUsers, className = '', createdById, onCancel, pendingRequest, requestId, requestName, requestSchema, status, versionId } = props;

    return (
        <Buttons className={className}>
            <CancelButton onClick={onCancel} />

            {status === 'draft' &&
                <>
                {!pendingRequest && 
                    <RequestUpdateButton createdById={createdById} requestId={requestId} requestName={requestName} requestSchema={requestSchema} versionId={versionId} />
                }

                <EscalateButton assignedUsers={assignedUsers} requestId={requestId} requestName={requestName} requestSchema={requestSchema} versionId={versionId} />

                <RejectButton createdById={createdById} requestId={requestId} requestName={requestName} requestSchema={requestSchema} versionId={versionId} />

                <ApproveRequestButton  createdById={createdById} requestId={requestId} requestName={requestName} requestSchema={requestSchema} versionId={versionId} />
                </>
            }
        </Buttons>
    );
};

export default ActivePartyActions;
