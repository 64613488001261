import BarListItem from 'components/BarListItem';

import styles from './styles.module.scss';

const PositiveBarListItem = ({
    children,
    className = '',
    isActive = false,
    onClick
}) => {
    const activeClass = isActive ? styles.isActive : '';

    return (
        <BarListItem
            className={`${activeClass} ${className} ${styles.positiveBarListItem}`}
            isActive={isActive}
            onClick={onClick}
        >
            {children}
        </BarListItem>
    );
};

export default PositiveBarListItem;
