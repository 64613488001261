import ContentPanel from 'components/ContentPanel';
import HistoryEntries from 'components/HistoryEntries';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

const ActivityHistoryPanel = ({ className = '', history }) => {
    return (
        <ContentPanel className={`${className} ${styles.activityHistoryPanel}`}>
            <PanelBody>
                <HistoryEntries items={history} />
            </PanelBody>
        </ContentPanel>
    );
};

export default ActivityHistoryPanel;
