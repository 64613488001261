import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import TextBox from 'components/TextBox';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTypes from './hooks/useTypes';
import useTranslation from './hooks/useTranslation';

import normalizeData from './data';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const CreateLogEntryForm = ({ onCancel, onSave, saveText }) => {
    const { INTERNAL_SERVER_ERROR } = useConfig();

    const navigate = useNavigate();

    const {
        DATE_OF_OCCURRENCE,
        LOG_CATEGORY,
        LOG_DESCRIPTION,
        RISK_LABEL_LOG_ENTRY
    } = useTranslation();

    const [_errorMessage, setErrorMessage] = useState();

    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [tenants] = useTenants();
    const [schema] = useSchema();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();

    const [types, setTypes] = useTypes();

    const { _loading, error, data } = useData(schema);

    const form = useForm({
        dateOfOccurrence: '',
        description: '',
        logCategory: '',
        riskLevel: ''
    });

    const { handleError, handleForm, handleSubmit, handleValid } = form;

    const handleCancel = useCallback(
        event => isFunction(onCancel) && onCancel(event),
        [onCancel]
    );

    const handleSave = useCallback(() => {
        

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        if (isFunction(onSave)) {
            onSave({ form });
        }
    }, [form, handleForm, onSave]);

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    useEffect(() => {
        if (data) {
            const tenantSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
            const normalizedData = normalizeData({ data, schema:tenantSchema });

            setTypes(normalizedData);
        }
    }, [data, schema, setTypes, tenants, userSetSchema]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);

        return;
    }

    

    return (
        <div className={styles.createLogEntryForm}>
            {tenants.length > 1 &&
                <Dropdown
                    items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                    label={'Company'}
                    onChange={setTenant}
                    onError={handleError('selectedTenant')}
                    onSubmit={handleSubmit('selectedTenant')}
                    onValid={handleValid('selectedTenant')}
                />
            }

            <Dropdown
                items={types ? types : []}
                label={LOG_CATEGORY}
                onChange={handleValid('logCategory')}
                onError={handleError('logCategory')}
                onSubmit={handleSubmit('logCategory')}
            />

            <DatePicker
                label={DATE_OF_OCCURRENCE}
                onChange={handleValid('dateOfOccurrence')}
            />

            <TextBox
                label={LOG_DESCRIPTION}
                onChange={handleValid('description')}
                onSubmit={handleSubmit('description')}
            />

            <RiskLevelRadioButtons
                className={styles.riskLevelRadioButtons}
                label={RISK_LABEL_LOG_ENTRY}
                onChange={handleValid('riskLevel')}
            />

            <CancelSaveButtons
                className={styles.cancelSaveButtons}
                onCancel={handleCancel}
                onSave={handleSave}
                saveText={saveText}
            />
        </div>
    );
};

export default CreateLogEntryForm;
