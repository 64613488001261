import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CancelButton from 'components/CancelButton';
import GroupDetailsPanel from 'components/GroupDetailsPanel';
import GroupMembersPanel from 'components/GroupMembersPanel';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useGroupDetails from './hooks/useGroupDetails';

import consolidateGroupsUsers from 'utilities/consolidateGroupsUsers';

import styles from './styles.module.scss';

import normalizeData from './data';

const Group = () => {
    const params = useParams();
    const { groupId } = params;

    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();

    const { loading, error, data } = useData(groupId);

    const [displayedGroupTitle, setDisplayedGroupTitle] = useState();
    const [groupsAndUsers, setGroupsAndUsers] = useState({});
    const [groupDetails, setGroupDetails] = useGroupDetails();
    const [members, setMembers] = useState();
    const [supervisors, setSupervisors] = useState();

    const { ADMIN } = useConfig();

    const navigate = useNavigate();

    const {
        groups,
        users
    } = groupsAndUsers;

    const consolidatedGroupsUsers = consolidateGroupsUsers(groups, users);
    const multiDropdownOptions = (consolidatedGroupsUsers ?? []).map(
        ({id, name, type}) => ({ 
            label: name, 
            type, 
            value: `${type} ${id}`
        })
    );

    const remappedUsers = (users ?? []);

    const {
        description,
        name
    } = groupDetails;

    const form = useForm({
        supervisors: ''
    });

    const handleCancel = useCallback(() => {
        navigate(ADMIN);
    }, [ADMIN, navigate]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });
            setGroupsAndUsers(normalizedData);

            const group = normalizedData.groups.filter(group => group.id === parseInt(groupId))[0];
            setGroupDetails(group);

            const { groupUsers, supervisors: selectedSupervisors } = group;

            if (!supervisors) {
                const supervisorsItems = (selectedSupervisors).map(({ type, value }) =>  `${type} ${value}` );
                setSupervisors(supervisorsItems);
            }

            const memberItems = (groupUsers ?? []).reduce((accumulator, member) => {
                const memberData = normalizedData.users.filter(user => user.id === member.userId)[0];
                
                const splitName = memberData.name.split(' ');

                accumulator.push({
                    firstName: splitName[0],
                    id: memberData.id,
                    lastName: splitName[1],
                    type: memberData.type
                })

                return accumulator;
            }, []);

            if (!members) {
                setMembers(memberItems);
            }
        }
    }, [data, groupId, members, schema, setGroupsAndUsers, setGroupDetails, setSupervisors, supervisors]);

    useEffect(() => {
        if (!groupsAndUsers) {
            return;
        }

    }, [groupsAndUsers]);

    if (loading){
        
    }
    if (error){
        
    }

    return (
        <div className={styles.addGroup}>
            <WithPageTitle title={displayedGroupTitle}>
                <CancelButton onClick={handleCancel} />
            </WithPageTitle>

            <GroupDetailsPanel
                description={description}
                form={form}
                id={groupId}
                name={name}
                selectedSupervisors={supervisors}
                setDisplayedGroupTitle={setDisplayedGroupTitle}
                setSupervisors={setSupervisors}
                supervisorOptions={multiDropdownOptions}
            />

            <GroupMembersPanel
                groupId={groupId}
                memberOptions={remappedUsers}
                selectedUsers={members}
                setSelectedUsers={setMembers}
            />
        </div>
    );
};

export default Group;
