import { useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './styles.module.scss';

const Menu = props => {
    const {
        children,
        className = '',
        placement = 'auto',
        referenceElement
    } = props;

    const [popperElement, setPopperElement] = useState(null);

    const {
        styles: { popper: popperStyles },
        attributes
    } = usePopper(referenceElement, popperElement, {
        placement,
        strategy: 'fixed'
    });

    const minWidth = referenceElement ? referenceElement.offsetWidth : 0;

    return (
        <div
            className={`${className} ${styles.menu}`}
            ref={setPopperElement}
            style={{
                ...popperStyles,
                minWidth
            }}
            tabIndex={0}
            {...attributes.popper}
        >
            {children}
        </div>
    );
};

export default Menu;
