import { useRef, useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


import DownloadButton from 'components/DownloadButton';
import { ExportModal } from 'components/ExportModal';
import { useCaseManagementPanelData } from 'components/CaseManagementPanel/hooks/useCasesData';
import {useGetFilteredCasesData} from 'components/CaseManagementPanel/hooks/useFilteredCases';

import { useExportCases } from 'hooks/useExportCases';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import { getFileName } from 'utilities/files';

const WithDownloadButtonCase = props => {
    const { className = '', children, hasPDFexport, data, isDisabled = false, name } = props;
    const { exportCases, exportDetailedCases } = useExportCases();
    const { data: cases } = useCaseManagementPanelData();
    const { useIsAllFilter, useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [tenants] = useTenants();
    const [isAll] = useIsAllFilter();
    const [userId] = useUserId();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();

    const [showExportModal, setShowExportModal] = useState(false);
    const [isCreatingPDF, setIsCreatingPDF] = useState(false);

    const exportActionRef = useRef({ action: () => ({}), type: '' });

    const filteredCases = useGetFilteredCasesData();


    const flattenFilteredCases = (filteredCases) => {
        return Object.values(filteredCases).flat();
    };

    const extractCaseIds = (filteredCases) => {
        const allCases = Object.values(filteredCases).flat();
        return new Set(allCases.map(caseItem => caseItem.id));
    };

    const filterDetailedData = (data, filteredCaseIds) => {
        return data.filter(item => filteredCaseIds.has(item.id));
    };



    const onSimpleExport = () => {
        const action = () => exportCases({ cases, name });
        exportActionRef.current = { action, type: 'simple' };
        setShowExportModal(true);
    };

    const onDetailedExport = () => {
        const action = () => exportDetailedCases({ data, isAll, name, querySchemas, schema, tenants, userId });
        exportActionRef.current = { action, type: 'detailed' };
        setShowExportModal(true);
    };

    const onHideExportModal = () => {
        setShowExportModal(false);
    };

    const onExportAll = () => {
        onHideExportModal();
        if (exportActionRef.current) {
            exportActionRef.current.action();
        }
    };

    const onExportFiltered = () => {
        onHideExportModal();
    
        const caseIds = extractCaseIds(filteredCases);
        const filteredData = filterDetailedData(data, caseIds);
    
        if (exportActionRef.current) {
            const action = exportActionRef.current.type === 'detailed'
                ? () => exportDetailedCases({ data: filteredData, isAll, name, querySchemas, schema, tenants, userId })
                : () => exportCases({ cases: flattenFilteredCases(filteredCases), name });
            action();
        }
    };

    const exportPDF = useCallback(async () => {
        try {
            const input = document.getElementById('capture');
            if (!input) {
                console.error('Element with ID "capture" not found.');
                return;
            }
    
            input.classList.add('pdf-export');
    
            const canvas = await html2canvas(input, {
                scale: 1,
                scrollX: 0,
                scrollY: 0,
                useCORS: true
            });
    
            const imgData = canvas.toDataURL('image/png');
            if (!imgData.startsWith('data:image/png')) {
                console.error('Invalid image data URL:', imgData);
                return;
            }
    
            const pdf = new jsPDF({
                format: 'a4',
                orientation: 'portrait',
                unit: 'mm',
            });
    
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
    
            const imgProps = pdf.getImageProperties(imgData);
            const imgWidth = pdfWidth;
            const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
    
            const pageHeightInCanvasPixels = pdfHeight * (imgProps.width / pdfWidth);
            const pageCount = Math.ceil(imgHeight / pdfHeight);
    
            const nameFile = getFileName('Cases');

            const spaceAtTop = 20;
    
            for (let i = 0; i < pageCount; i++) {
                if (i > 0) {
                    pdf.addPage();
                }
    
                const sourceY = i * pageHeightInCanvasPixels;
                const targetHeight = Math.min(pageHeightInCanvasPixels, imgProps.height - sourceY);
    
                const pageCanvas = document.createElement('canvas');
                pageCanvas.width = imgProps.width;
                pageCanvas.height = targetHeight;
                const ctx = pageCanvas.getContext('2d');
                ctx.drawImage(canvas, 0, sourceY, imgProps.width, targetHeight, 0, 0, imgProps.width, targetHeight);
    
                const pageImgData = pageCanvas.toDataURL('image/png');
                pdf.addImage(
                    pageImgData, 
                    'PNG', 
                    0, 
                    i === 0 ? spaceAtTop : 0,
                    pdfWidth, 
                    (targetHeight / imgProps.width) * pdfWidth
                );
            }
    
            pdf.save(nameFile + '.pdf');
        } catch (error) {
            console.error('Error exporting PDF:', error);
        } finally {
            setIsCreatingPDF(false);
        }
    }, []);

    useEffect(() => {
        if(isCreatingPDF){
            exportPDF();
        }
    }, [isCreatingPDF, exportPDF]);

    return (
        <>
            {showExportModal && (
                <ExportModal
                    hasPDFButton={hasPDFexport}
                    isOpen={showExportModal}
                    onClose={onHideExportModal}
                    onExportAll={onExportAll}
                    onExportFiltered={onExportFiltered}
                    title="Export Cases"
                />
            )}

            <div className={`${className} ${styles.withDownloadButton}`}>
                <DownloadButton
                    className={styles.downloadButton}
                    hasPDFButton={hasPDFexport}
                    isDisabled={isDisabled}
                    onExcelExport={onSimpleExport}
                    onExportPdfReport={() => setIsCreatingPDF(true)}
                    onPdfExport={onDetailedExport}
                />
                
                {children}
            </div>
        </>
    );
};

export default WithDownloadButtonCase;
