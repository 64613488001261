import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('adminGroupFormDetailsPanel');

    return {
        GROUP_DESCRIPTION: t('groupDescription'),
        GROUP_NAME: t('groupName'),
        SUPERVISORS: t('supervisors')
    };
};

export default useTranslation;
