import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const useGlobalState = namespace => {
    const [component, key = component] = namespace.split('.');

    if (!(component in state)) {
        console.error(
            `
                Could not find ${component} in the global store. Make sure the component has registered
                its state into the global store.`
        );
    }

    const atom = state[component].atoms[`${key}Atom`];

    return [useRecoilValue(atom), useSetRecoilState(atom)];
};

export default useGlobalState;
