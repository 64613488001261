import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addLogEntryModal } = state;

const { addLogEntryModalAtom } = addLogEntryModal.atoms;

const MUTATE_LOG_ENTRY = tenantSchema => gql`
mutation createLog(
    $createdBy: Int!,
    $dateOfOccurrence: timestamp,
    $description: String!,
    $riskLevel: String!) {
        insert_${tenantSchema}_log(objects: {
            created_by: $createdBy,
            date_of_occurrence: $dateOfOccurrence,
            description: $description,
            log_type: "",
            risk_label: $riskLevel
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LOG_TYPE_ENTRY = tenantSchema => gql`
mutation createLogType(
    $logId: Int!,
    $typeId: Int!) {
        insert_${tenantSchema}_log_type(objects: {
            log_id: $logId,
            type_id: $typeId
        }) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_LOG_ENTRY, providedSchema);
    const mutationType = useGraphQL(MUTATE_LOG_TYPE_ENTRY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const createLogEntry = useRecoilCallback(({ set }) => variables => {
        

        const runMutation = async () => {
            const res = await mutation(variables);

            const id =
                res?.data[`insert_${usedSchema}_log`]?.returning[0]
                    ?.id;

            const logType = { logId: id, typeId: variables.logCategory };

            await mutationType(logType);

            set(addLogEntryModalAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addLogEntryModalAtom, '');
    });

    return [useRecoilValue(addLogEntryModalAtom), createLogEntry, reset];
};

export default useData;
