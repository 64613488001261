import ActionMenu from 'components/ActionMenu2';
import CreateActivityMenuListItemFromActivity from 'components/CreateActivityMenuListItemFromActivity';
import CreateCaseMenuListItemFromActivity from 'components/CreateCaseMenuListItemFromActivity';
import CreateLogMenuListItemFromActivity from 'components/CreateLogMenuListItemFromActivity';

const AddFromActivityMenu = ({
    activityId,
    activityRecord,
    activitySchema,
    children,
    onClick,
    riskLabel
}) => (
    <ActionMenu
        renderReferenceElement={({ handleClick }) => children(handleClick)}
    >
        {onPopperClose => (
            <>
                <CreateActivityMenuListItemFromActivity
                    activityId={activityId}
                    activityName={activityRecord?.name}
                    // onClick={onPopperClose}
                />

                <CreateCaseMenuListItemFromActivity
                    activityId={activityId}
                    activityRecord={activityRecord}
                    activitySchema={activitySchema}
                    // onClick={onPopperClose}
                    onSave={onClick}
                    riskLabel={riskLabel}
                />

                <CreateLogMenuListItemFromActivity
                    activityId={activityId}
                    activityRecord={activityRecord}
                    onClick={onPopperClose}
                    onSave={onClick}
                />
            </>
        )}
    </ActionMenu>
);

export default AddFromActivityMenu;
