import { useNavigate } from 'react-router-dom';

import DetailsButton from 'components/DetailsButton';
import ImportantText from 'components/ImportantText';
import Link from 'components/Link';
import PenIcon from 'components/icons/PenIcon';
import TableRow from 'components/TableRow';

import useConfig from 'hooks/useConfig';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import RiskPill from 'components/RiskPill';
import PillGroup from 'components/Pills';

const AdministrationActivityTypesTableRow = props => {
    const { id, name, riskLevel, visibleTo = [] } = props;

    const { ACTIVITY_TYPE_ROUTE } = useConfig();

    const { EDIT } = useTranslation();

    const navigate = useNavigate();

    return (
        <TableRow className={styles.administrationActivityTypesTableRow}>
            <Link className={styles.link} to={`${ACTIVITY_TYPE_ROUTE.replace(':activityTypeId', id)}`}>
                <ImportantText text={name} />
            </Link>

            <RiskPill riskLevel={riskLevel} />

            <PillGroup items={visibleTo} />

            <DetailsButton
                className={styles.detailsButton}
                items={[
                    {
                        icon: <PenIcon />,

                        onClick: () => {
                            navigate(ACTIVITY_TYPE_ROUTE.replace(':activityTypeId', id));
                        },

                        text: EDIT
                    }
                ]}
            />
        </TableRow>
    );
};

export default AdministrationActivityTypesTableRow;
