const httpGetCors = async (submissionObject) => {
    const {data, key=null, options = {}, url = ''} = submissionObject;
    const requestOptions = {
        body: data,
        cache: 'no-cache',
        method: 'GET',
        mode: 'cors',
        redirect: 'follow',
        ...options
    };
    
    if(key){
        if (
            'headers' in requestOptions &&
            typeof requestOptions.headers === 'object' &&
            !Array.isArray(requestOptions.headers) &&
            requestOptions.headers !== null
        ) {
            requestOptions.headers['api_auth'] = `Bearer ${key}`
        }else{
            requestOptions.headers = {'api_auth': `Bearer ${key}`}
        }
    }

    try {
        const response = await fetch(url, requestOptions);

        return await response.json();
    } catch (error) {
        return error;
    }
};

export default httpGetCors;
