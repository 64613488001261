import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
const filteredRepositoriesDataAtom = atom({
    default: {},
    key: 'filteredRepositoriesDataAtom'
});
export function useGetFilteredRepositoriesData() {
    return useRecoilValue(filteredRepositoriesDataAtom);
}
export function useSetFilteredRepositoriesData() {
    return useSetRecoilState(filteredRepositoriesDataAtom);
}