const translations = {
    actionPlan: 'Action Plan',    
    activityReference: 'Originated',
    addActionPlan: '+ Add Action Plan',
    addProposedSolution: '+ Add Proposed Solution',
    addResolution: '+ Add Resolution',
    caseCreationReason: 'Case Creation Reason',
    caseName: 'Case Name',
    // caseType: 'Case Type',
    policyReference: 'Reference',
    proposedSolution: 'Proposed Solution',
    resolution: 'Resolution',
    riskLevelCase: 'Risk Level - Case'
};

export default translations;
