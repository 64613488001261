const translations = {
    documentManagement: 'Document Management',
    marketing: 'Marketing',
    policy: 'Policy',
    repository: 'Repository',
    sections: 'Sections',
    sideLetters: 'Side Letters',
};

export default translations;
