import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { closeActivityAndAddLogEntryMenuListItem } = state;

const { closeActivityAndAddLogEntryMenuListItemAtom } =
    closeActivityAndAddLogEntryMenuListItem.atoms;

const MUTATE_CLOSE = tenantSchema => gql`
mutation closeActivity($caseId: Int!, $closedDate: timestamp!) {
    update_${tenantSchema}_case(where: {
        id: {
            _eq: $caseId
        }
    }, 
    _set: {
        closed: true,
        closed_date: $closedDate
    }) {
        returning {
            id
            closed
        }
    }
}
`;

const MUTATE_LOG_ENTRY = tenantSchema => gql`
mutation createLog(
    $createdBy: Int!,
    $dateOfOccurrence: timestamp,
    $description: String!,
    $riskLevel: String!) {
        insert_${tenantSchema}_log(objects: {
            created_by: $createdBy,
            date_of_occurrence: $dateOfOccurrence,
            description: $description,
            log_type: "",
            risk_label: $riskLevel
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LOG_TYPE_ENTRY = tenantSchema => gql`
mutation createLogType(
    $logId: Int!,
    $typeId: Int!) {
        insert_${tenantSchema}_log_type(objects: {
            log_id: $logId,
            type_id: $typeId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LOG_CASE_ENTRY = tenantSchema => gql`
mutation createLogCase(
    $caseId: Int!,
    $logId: Int!) {
        insert_${tenantSchema}_log_case(objects: {
            log_id: $logId,
            case_id: $caseId
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_LINKAGE = tenantSchema => gql`
    mutation insertLinkage(
        $activityId: Int,
        $caseId: Int,
        $linkLogId: Int,
        $logId: Int
    ) {
        insert_${tenantSchema}_linkages(objects: {
            activity_id_1: $activityId
            case_id_1: $caseId
            log_id_1: $logId
            log_id_2: $linkLogId
        }) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_CLOSE, providedSchema);
    const mutationLogEntry = useGraphQL(MUTATE_LOG_ENTRY, providedSchema);
    const mutationLogTypeEntry = useGraphQL(MUTATE_LOG_TYPE_ENTRY, providedSchema);
    const mutationLogCaseEntry = useGraphQL(MUTATE_LOG_CASE_ENTRY, providedSchema);
    const mutationLinkage = useGraphQL(MUTATE_LINKAGE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const closeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();

            if (variables.toClose) {
                await mutation(variables);
            }

            const res = await mutationLogEntry(variables);

            const id = res
                ?.data[`insert_${usedSchema}_log`]?.returning[0]
                ?.id;

            variables['logId'] = id;

            await mutationLogTypeEntry(variables);
            await mutationLogCaseEntry(variables);

            await mutationLinkage({
                activityId: variables?.activityId,
                caseId: variables?.caseId,
                linkLogId: variables?.linkLog,
                logId:id
            })

            set(closeActivityAndAddLogEntryMenuListItemAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(closeActivityAndAddLogEntryMenuListItemAtom, '');
    });

    return [
        useRecoilValue(closeActivityAndAddLogEntryMenuListItemAtom),
        closeActivity,
        reset
    ];
};

export default useData;
