import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_document {
      id
      name
      is_active
      category
      tenant_user {
        id
        users_${tenantSchema} {
          first_name
          last_name
        }
      }
      risk_label
      description
      applied_tos {
        group {
          name
          id
          description
        }
        tenant_user {
          id
          users_${tenantSchema} {
            first_name
            last_name
          }
        }
      }
      policy_sections {
        section {
          name
          id
          file
          description
          riskLevel
          activity_policies {
            activity {
              name
              risk_label
              activity_type {
                name
              }
            }
          }
          case_policies {
            case {
              case_name
              risk_label
              caseTypeByCaseType {
                name
              }
            }
          }
          log_policies {
            log {
              log_type
              risk_label
            }
          }
        }
      }
    }
`;

const useData = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    return useQuery(generateQuery(query, schema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
