import { atom } from 'recoil';

const requestApproveModalAtom = atom({
    default: [],
    key: 'requestApproveModal'
});

const state = {
    atoms: {
        requestApproveModalAtom
    }
};

export default state;
