import Buttonize from 'components/Buttonize';
import CancelSaveButtons from 'components/CancelSaveButtons';
import CheckboxMenu from 'components/CheckboxMenu';
import DropdownMenu from 'components/DropdownMenu';
import ColumnsIcon from 'components/icons/ColumnsIcon';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import usePopperState from 'hooks/usePopperState';

const PeopleColumnsMenu = ({ columns = [], onMenuClick }) => {
    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    return (
        <>
            <Buttonize onClick={handleClick}>
                <ColumnsIcon />
            </Buttonize>

            {isOpen && (
                <DropdownMenu
                    isOpen
                    onClose={onClose}
                    placement="bottom-end"
                    referenceElement={referenceElement}
                >
                    <PanelBody>
                        <PanelTitle text="Columns" />

                        <CheckboxMenu
                            items={columns}
                            onChange={({ index }) => onMenuClick(index)}
                        />

                        <CancelSaveButtons onCancel={onClose} />
                    </PanelBody>
                </DropdownMenu>
            )}
        </>
    );
};

export default PeopleColumnsMenu;
