import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DocumentManagementSideLetterTableHeader = ({ onSort, tenantCount }) => {
    const { CATEGORIES, COMPANY, CURRENT_VERSION, LAST_REVIEWED_DATE, SIDE_LETTER_NAME, STATUS } =
        useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: SIDE_LETTER_NAME
            },
            {
                isSortable: false,
                key: 'version',
                text: CURRENT_VERSION
            },
            {
                isSortable: true,
                key: 'lastReviewedDate',
                text: LAST_REVIEWED_DATE
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            }
        ],
        [CATEGORIES, CURRENT_VERSION, LAST_REVIEWED_DATE, SIDE_LETTER_NAME, STATUS]
    );

    const headerItemsMulti = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: SIDE_LETTER_NAME
            },
            {
                isSortable: false,
                key: 'version',
                text: CURRENT_VERSION
            },
            {
                isSortable: false,
                key: 'version',
                text: COMPANY
            },
            {
                isSortable: true,
                key: 'lastReviewedDate',
                text: LAST_REVIEWED_DATE
            },
            {
                isSortable: false,
                key: 'categories',
                text: CATEGORIES
            },
            {
                isSortable: true,
                key: 'status',
                text: STATUS
            }
        ],
        [CATEGORIES, COMPANY, CURRENT_VERSION, LAST_REVIEWED_DATE, SIDE_LETTER_NAME, STATUS]
    );

    return (
        <TableHeader
            className={tenantCount>1 ? styles.documentManagementTableHeaderMulti : styles.documentManagementTableHeader}
            items={tenantCount>1 ? headerItemsMulti : headerItems}
            onSort={onSort}
        />
    );
};

export default DocumentManagementSideLetterTableHeader;
