import SummarizedListItems from 'components/SummarizedListItems';
import InProgressSummarizedListItem from 'components/InProgressSummarizedListItem';

const InProgressSummarizedListItems = ({
    className = '',
    items = [],
    onDownloadItem
}) => (
    <SummarizedListItems className={className}>
        {items.map((item, index) => {
            const {
                active,
                assigned,
                completable,
                dueDate,
                flagged,
                id,
                isActive,
                schema,
                text,
                type
            } = item;

            return (
                <InProgressSummarizedListItem
                    active={active}
                    assigned={assigned}
                    completable={completable}
                    dueDate={dueDate}
                    flagged={flagged}
                    id={id}
                    isActive={isActive}
                    item={item}
                    key={`${text}.${index}`}
                    onDownloadItem={onDownloadItem}
                    schema={schema}
                    text={text}
                    type={type}
                />
            );
        })}
    </SummarizedListItems>
);

export default InProgressSummarizedListItems;
