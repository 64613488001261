import AvatarsWithText from 'components/AvatarsWithText';
import BodyText from 'components/BodyText';
// import DetailsButton from 'components/DetailsButton';
// import EyeIcon from 'components/icons/EyeIcon';
import ImportantLink from 'components/ImportantLink';
// import TableRow from 'components/TableRow';
import { Link } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import PositiveBarListItem from 'components/PositiveBarListItem';
import titleCase from 'utilities/titleCase';
import styles from './styles.module.scss';
import InfoBarAlternativeListItem from 'components/InfoBarAlternativeListItem';
import InfoBarListItem from 'components/InfoBarListItem';
import { generatePath } from 'utilities/urls';

const rowBuilder = {
    approved: ({ children, className }) => (
        <PositiveBarListItem className={`${className} ${styles.hideBackgroundUntilHover}`}>
            {children}
        </PositiveBarListItem>
    ),
    draft: ({ children, className }) => (
        <InfoBarAlternativeListItem className={`${className} ${styles.hideBackgroundUntilHover}`}>
            {children}
        </InfoBarAlternativeListItem>
    ),
    withdrawn: ({ children, className }) => (
        <InfoBarListItem className={`${className} ${styles.hideBackgroundUntilHover}`}>{children}</InfoBarListItem>
    )
};

const RequestTableRow = ({
    approvedVersions = [],
    assignedTo = [],
    className,
    dateApproved,
    dateRequested,
    dueDate = '',
    expectedUseDate = '',
    id,
    isMultiTenant = false,
    requestName = '',
    requestSchema,
    requestedBy = {},
    status
}) => {
    const { MARKETING_REQUEST_ROUTE } = useConfig();

    const formattedDueDate = dueDate
        ? new Date(dueDate).toISOString().split('T')[0]
        : '';

    const formattedExpectedUseDate = expectedUseDate
        ? new Date(expectedUseDate).toISOString().split('T')[0]
        : '';

    const formattedDateRequested = dateRequested
        ? new Date(dateRequested).toISOString().split('T')[0]
        : '-';

    const formattedDateApproved = dateApproved
        ? new Date(dateApproved).toISOString().split('T')[0]
        : '-';

    const Row = rowBuilder[status] || rowBuilder.draft;

    const mostRecentApprovedFile = approvedVersions.at(-1);

    const itemLink = generatePath({
        params: {
            requestId: id,
            requestSchema,
            versionId: mostRecentApprovedFile?.versionNumber ?? 1
        },
        path: MARKETING_REQUEST_ROUTE
    });

    const versionLink = generatePath({
        params: {
            requestId: id,
            requestSchema,
            versionId: mostRecentApprovedFile?.versionNumber ?? 1
        },
        path: MARKETING_REQUEST_ROUTE
    });

    return (
        <Row className={className}>
            <ImportantLink
                className={styles.titleLink}
                text={requestName}
                to={itemLink}
            />

            {isMultiTenant && <BodyText text={titleCase(requestSchema)} />}

            <Link to={itemLink} className={styles.link}>
                <BodyText text={formattedDueDate} />
            </Link>

            <Link to={itemLink} className={styles.link}>
                <BodyText text={formattedExpectedUseDate} />
            </Link>

            <Link to={itemLink} className={styles.link}>
                <BodyText text={formattedDateApproved} />
            </Link>

            <Link to={itemLink} className={styles.link}>
                <BodyText text={formattedDateRequested} />
            </Link>

            <Link to={itemLink} className={styles.link}>
                <AvatarsWithText assigned={assignedTo} />
            </Link>

            <Link to={itemLink} className={styles.link}>
                <AvatarsWithText assigned={[requestedBy]} />
            </Link>

            {/* <BodyText text={status} /> */}

            {mostRecentApprovedFile ? (
                <div className={styles.approvedVersionContainer}>
                    <ImportantLink
                        className={styles.approvedVersion}
                        newTab
                        text={`${mostRecentApprovedFile.fileName}`}
                        to={versionLink}
                    />

                    {approvedVersions.length > 1 && (
                        <BodyText text={`, +${approvedVersions.length - 1}`} />
                    )}
                </div>
            ) : (
                <BodyText text="-" />
            )}
        </Row>
    );
};

export default RequestTableRow;
