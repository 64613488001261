import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('detailedListItems');

    return {
        ACTIONS: t('actions'),
        ACTIVITY_TYPE: t('activityType'),
        ASSIGNED: t('assigned'),
        COMPANY: t('company'),
        DUE_DATE: t('dueDate'),
        ENTITIES: t('entities'),
        REF: t('ref'),
        RECURRENCE: t('recurrence'),
        REVIEWER: t('reviewer'),
        START_DATE: t('startDate'),
        TYPE: t('type')
    };
};

export default useTranslation;
