import { useEffect, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import DynamicTable from 'components/DynamicTable'

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';

import normalizeData from './data';

import styles from './styles.module.scss';

const CasesReportPanel = ({ className = '' }) => {
    const { useSchema, useTenants } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const [chartData, setChartData] = useState();

    const { loading, error, data } = useData();
    

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ data, schema, tenants });

            setChartData(normalizedData)
        }
    }, [data, schema, setChartData, tenants]);

    if (error) {
        
    }

    if (loading) {
        
    }
    
    return (
        <ContentPanel className={`${className} ${styles.reportsPanel}`}>
            {chartData && 
                <DynamicTable
                    className={`${className} ${styles.table}`}
                    data={chartData}
                    defaultCharts={[
                        {alternativeTitle: 'Case Count', column: ['caseType'], type: 'count'},
                        {alternativeTitle: 'Cases Assigned to Users', column: ['userCases', 'label'], type: 'pie'},
                        {alternativeTitle: 'Case Risk Labels', column: ['riskLabel'], type: 'doughnut'},
                        {alternativeTitle: 'Case Types', column: ['caseType'], type: 'doughnut'}
                    ]}
                    hasCustomDateDropdown
                />
            }
        </ContentPanel>
    );
};

export default CasesReportPanel;
