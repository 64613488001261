import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addPolicyButton');

    return {
        ADD_POLICY: t('addPolicy')
    };
};

export default useTranslation;
