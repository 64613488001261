const translations = {
    cancel: 'No, cancel',
    delete: 'Yes, delete',
    youAreAboutToDeleteDraft: `
        You are about to delete draft policy version "VERSION"
        from the system permanently. Once deleted, you won't
        be able to retrieve data in the future. Do you still
        want to proceed?
    `
};

export default translations;
