import { useCallback, useState } from 'react';

import CloseButton from 'components/CloseButton';
import Drawer from 'components/Drawer';
import LargeTitle from 'components/LargeTitle';
import SearchInput from 'components/SearchInput';
import GroupSelectUserListItem from 'components/GroupSelectUserListItem';

import useSearchItems from './hooks/useSearchItems';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const GroupSelectUsersDrawer = props => {
    const {
        assignmentType,
        children,
        className = '',
        onChange,
        selectedUsers = [],
        users = []
    } = props;

    const { CHANGE_USER, SEARCH_AND_SELECT_USERS } = useTranslation();

    const [isOpen, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const toggle = useCallback(() => setOpen(current => !current), []);

    const displayUsers = useSearchItems({
        items: users,
        key: 'name',
        searchTerm
    });

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            {children({ isOpen, toggle })}

            <Drawer
                className={`${className} ${styles.selectUsersDrawer}`}
                isOpen={isOpen}
                onClose={handleClose}
                position="right"
            >
                <LargeTitle className={styles.title} text={CHANGE_USER} />

                <CloseButton className={styles.closeButton} onClick={toggle} />

                <SearchInput
                    className={styles.searchInput}
                    onChange={setSearchTerm}
                    placeholder={SEARCH_AND_SELECT_USERS}
                />

                {displayUsers.map(user => {
                    const isSelected = selectedUsers.some(
                        selectedUser => (selectedUser?.id === user.id && selectedUser.type === user.type && selectedUser.status !== 'removed')
                    );
                    const previousRecord = selectedUsers.some(
                        selectedUser => (selectedUser.id === user.id && selectedUser.type === user.type)
                    );

                    return (
                        <GroupSelectUserListItem
                            assignmentType={assignmentType}
                            className={styles.selectUserListItem}
                            email={user.email}
                            id={user.id}
                            image={user.avatar}
                            isSelected={isSelected}
                            key={`${user.id}_${user.type}`}
                            name={user.name}
                            onChange={onChange}
                            previousRecord={previousRecord}
                            type={user.type}
                        />
                    );
                })}
            </Drawer>
        </>
    );
};

export default GroupSelectUsersDrawer;
