import { atom } from 'recoil';

const activityIdAtom = atom({
    default: {},
    key: 'activityId'
});

const caseIdAtom = atom({
    default: {},
    key: 'caseId'
});

const closeActivityAndCreateNewActivityMenuListItemAtom = atom({
    default: '',
    key: 'closeActivityAndCreateNewActivityMenuListItem'
});

const state = {
    atoms: {
        activityIdAtom,
        caseIdAtom,
        closeActivityAndCreateNewActivityMenuListItemAtom
    }
};

export default state;
