import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_document`].reduce(
        (accumulator, policy) => {
            const { name, id, policy_sections: policySections } = policy
            const policies = [];
            const sections = [];
            policies.push({
                label: `${name}`,
                value: `${id}.0`
            })

            if (policySections && policySections.length > 0){
                const newSections = policySections.reduce((accumulator, section) => {
                    accumulator.push({
                        label: `${section.section.name}`,
                        type: 'subItem',
                        value: `${id}.${section.section.id}`
                    })
                    return accumulator
                }, [])
                Array.prototype.push.apply(sections,newSections)
            }

            const sortedSections = sections.sort((a, b) => (
                a.label.localeCompare(b.label, undefined, {
                  numeric: true,
                  sensitivity: 'base'
                })
            ))

            const options = [...policies, ...sortedSections]

            accumulator.push(options);

            return accumulator;
        }, []);

const normalizePolicyData = ({ data, schema }) => normalizeListData({ data, schema, schemaData });

export default normalizePolicyData;