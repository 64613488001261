import { useCallback, useState } from 'react';
import AvatarWithTextLink from 'components/AvatarWithTextLink'
import BodyLink from 'components/BodyLink';
import ListItemDetailsButton from 'components/ListItemDetailsButton';
import ImportantLink from 'components/ImportantLink';
import PositiveBarListItem from 'components/PositiveBarListItem';
import useConfig from 'hooks/useConfig';
import { usePermissions } from 'hooks/usePermissions';

import styles from './styles.module.scss';
import { safeGetLocalStorage } from 'utilities/localStorage';
import titleCase from 'utilities/titleCase';
import WithTooltipAvatar from 'components/WithTooltipAvatar';
import WithTooltip from 'components/WithTooltip';
import DocumentIcon from 'components/icons/DocumentIcon';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const CompletedDetailedListItem = ({
    assigned,
    className = '',
    dueDate,
    entities,
    flagged,
    id,
		item,
		onDownloadItem,
    policyReference,
    recurrence,
    reviewer = [],
    schema,
    startDate,
    tenantCount=1,
    text,
    type
}) => {
    const {ACTIVITY_ROUTE} = useConfig();

    const [complete, setComplete] = useState(false);

    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const handleComplete = useCallback(() => {
        setComplete(true);
        }, [setComplete]
    );

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    const policyReferenceTooltip =
        policyReference?.length > 0
            ? policyReference.map(reference => reference.name).join('\n')
            : undefined;

    if(tenantCount > 1){
        return (
            <>{!complete &&
            <PositiveBarListItem className={`${className} ${styles.completedDetailedListItemMultiTenant}`} >
                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={text}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={startDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={dueDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.avatar}
                    newTab={newTab}
                    text={type}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <WithTooltipAvatar assigned={assigned}>
                    <AvatarWithTextLink
                        assigned={assigned}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <WithTooltipAvatar assigned={reviewer}>
                    <AvatarWithTextLink
                        assigned={reviewer}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={schema ? titleCase(schema) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {policyReferenceTooltip ? (
                    <WithTooltip className="policyReference" text={policyReferenceTooltip}>
                        <DocumentIcon className={styles.icon} />
                    </WithTooltip>
                ):(
                    <BodyLink
                            className={styles.assigned}
                            newTab={newTab}
                            text={'-'}
                            to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                        />
                )}

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={recurrence ? titleCase(recurrence) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={entities.join(", ")}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {activitiesActionsPermission && (
                    <ListItemDetailsButton activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema} />
                )}
            </PositiveBarListItem>
            }</>
        );
    }else{
        return (
            <>{!complete &&
            <PositiveBarListItem className={`${className} ${styles.completedDetailedListItem}`} >
                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={text}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={startDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.date}
                    newTab={newTab}
                    text={dueDate}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.avatar}
                    newTab={newTab}
                    text={type}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <WithTooltipAvatar assigned={assigned}>
                    <AvatarWithTextLink
                        assigned={assigned}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                <WithTooltipAvatar assigned={reviewer}>
                    <AvatarWithTextLink
                        assigned={reviewer}
                        className={styles.link}
                        newTab={newTab}
                        to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                    />
                </WithTooltipAvatar>

                {policyReferenceTooltip ? (
                    <WithTooltip className="policyReference" text={policyReferenceTooltip}>
                        <DocumentIcon className={styles.icon} />
                    </WithTooltip>
                ):(
                    <BodyLink
                            className={styles.assigned}
                            newTab={newTab}
                            text={'-'}
                            to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                        />
                )}

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={recurrence ? titleCase(recurrence) : '-'}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                <BodyLink
                    className={styles.assigned}
                    newTab={newTab}
                    text={entities.join(", ")}
                    to={`${ACTIVITY_ROUTE.replace(':activityId', id).replace(':activitySchema', schema)}`}
                />

                {activitiesActionsPermission && (
                    <ListItemDetailsButton activityName={text} flagged={flagged} handleComplete={handleComplete} id={id} onDownload={()=>onDownloadItem(item)} schema={schema} />
                )}
            </PositiveBarListItem>
            }</>
        );
    }
};

export default CompletedDetailedListItem;