import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import CloseAndAddLogEntryMenuListItem from 'components/CloseAndAddLogEntryMenuListItem';

const CreateLogMenuListItemFromActivity = ({
    activityId,
    activityRecord,
    onClick,
    onSave
}) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [addedLog, addLog, reset] = useData();

    const { ACTIVITIES_ROUTE, HOME } = useConfig();

    const navigate = useNavigate();

    const [_errorMessage, setErrorMessage] = useState();

    const handleAddLog = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId,
                    createdBy: Number(userId),
                    toClose: false,
                }
            );

            formObject.dateOfOccurrence = new Date(formObject.dateOfOccurrence);

            formObject['typeId'] = formObject['logCategory'];

            addLog(formObject);
        },
        [activityId, addLog, userId]
    );

    const activityName = activityRecord?.name || '-';
    const activityType = activityRecord?.type || '-';

    useEffect(() => {
        if (!addedLog) {
            return;
        }

        if (isFunction(onSave)) {
            onSave();
        }

        if (isFunction(onClick)) {
            onClick(addedLog);
        }

        navigate(ACTIVITIES_ROUTE)

        reset();
    }, [ACTIVITIES_ROUTE, HOME, addedLog, navigate, onClick, onSave, reset]);

    return (
        <CloseAndAddLogEntryMenuListItem
            activityName={activityName}
            activityType={activityType}
            onSave={handleAddLog}
            toClose={false}
        />
    );
};

export default CreateLogMenuListItemFromActivity;
