import AddPolicySectionButton from 'components/AddPolicySectionButton';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import PolicyMappingTable from 'components/PolicyMappingTable';
import WithSectionTitle from 'components/WithSectionTitle';

import useTranslation from './hooks/useTranslation';

const PolicyMappingPanel = props => {
    const { categories, className = '', id, providedSchema, sections = [], updateSection } = props;

    const { POLICY_MAPPING } = useTranslation();

    return (
        <>
            <ContentPanel className={className}>
                <PanelBody>
                    <WithSectionTitle text={POLICY_MAPPING}>
                        <AddPolicySectionButton categories={categories} id={id} providedSchema={providedSchema} />
                    </WithSectionTitle>

                    <PolicyMappingTable categories={categories} policyId={id} sections={sections}  updateSection={updateSection} />
                </PanelBody>
            </ContentPanel>
        </>
    );
};

export default PolicyMappingPanel;
