import { atom } from 'recoil';

const addFormAtom = atom({
    default: '',
    key: 'addForm'
});

const state = {
    atoms: {
        addFormAtom
    }
};

export default state;
