import UpcomingSummarizedListItems from 'components/UpcomingSummarizedListItems';
import UpcomingItems from 'components/UpcomingItems';

const UpcomingSummarizedItems = ({ className = '', items, onDownloadItem }) =>  (
    <UpcomingItems className={className}>
        <UpcomingSummarizedListItems
            items={items}
						onDownloadItem={onDownloadItem}
        />
    </UpcomingItems>
);

export default UpcomingSummarizedItems;
