import DisabledClockIcon from 'components/icons/DisabledClockIcon';
import IconWithText from 'components/IconWithText';

import translations from './constants';

import styles from './styles.module.scss';

const Due = ({ className = '', count }) => {
    const { END_IN_DAYS } = translations();

    return (
        <IconWithText
            className={`${className} ${styles.due}`}
            icon={DisabledClockIcon}
            text={END_IN_DAYS.replace('COUNT', Math.abs(count))}
        />
    );
};

export default Due;
