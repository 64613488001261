import { gql } from '@apollo/client';
import { client } from 'graphql-client';

const getActivityFormByIdQueryGql = ({ activityFormId, tenantSchema }) => gql`
    query getActivityFormById {
        ${tenantSchema}_activity_forms_by_pk(id: ${activityFormId}) {
          id
          form {
						id
						parent_form {
							id
						}
						root_form {
							id
						}
					}
        }
      }
`;

const getActivityFormIdsGql = ({ activityId, rootFormId, tenantSchema }) => gql`
    query getFormsIdsFromRootForm {
        ${tenantSchema}_activity_forms(where: {_and: [{activity_id: {_eq: ${activityId}}}, {_or: [{form: {id: {_eq: ${rootFormId}}}}, {form: {root_form_id: {_eq: ${rootFormId}}}}]}]}){
    			id
  			}
      }
`;

const getRootFormIdFromForm = form => {
    return form?.root_form?.id ?? form?.id;
};

const getRootFormFromIdActivityForm = async ({
    activityFormId,
    tenantSchema
}) => {
    const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: getActivityFormByIdQueryGql({
            activityFormId,
            tenantSchema
        })
    });

    if (data) {
        const { form } = data[`${tenantSchema}_activity_forms_by_pk`] ?? {};
        return getRootFormIdFromForm(form);
    }
};

const getActivityFormIds = async ({ activityId, rootFormId, tenantSchema }) => {
    const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: getActivityFormIdsGql({
            activityId,
            rootFormId,
            tenantSchema
        })
    });

    return (data[`${tenantSchema}_activity_forms`] ?? []).map(({ id }) => id);
};

const enableCurrentActivityFormMutationGql = tenantSchema => gql`
		mutation mutateSetCurrentActivityForm($activityFormId: Int! ) {
				update_${tenantSchema}_activity_forms(where: {id: {_eq: $activityFormId}}, _set: {current: true}) {
					returning {
						id,
						current,
					}
				}
		}
`;

const enableCurrentActivityForm = async ({ activityFormId, tenantSchema }) => {
    return client.mutate({
        fetchPolicy: 'no-cache',
        mutation: enableCurrentActivityFormMutationGql(tenantSchema),
        variables: { activityFormId }
    });
};

const disableCurrentActivityFormsMutationGql = tenantSchema => gql`
		mutation mutateSetCurrentActivityForm($activityFormIds: [Int!]! ) {
				update_${tenantSchema}_activity_forms(where: {id: {_in: $activityFormIds}}, _set: {current: false}) {
					returning {
						id,
						current
					}
				}
		}
`;

const disableCurrentActivityForms = async ({
    activityFormIds,
    tenantSchema
}) => {
    return client.mutate({
        fetchPolicy: 'no-cache',
        mutation: disableCurrentActivityFormsMutationGql(tenantSchema),
        variables: { activityFormIds }
    });
};

export const useSetCurrentActivityForm = () => {
    const setCurrentActiveForm = async ({
        activityId,
        activityFormId,
        tenantSchema
    }) => {
        if (!activityId || !activityFormId || !tenantSchema) {
            return;
        }

        try {
            const rootFormId = await getRootFormFromIdActivityForm({
                activityFormId,
                tenantSchema
            });

            if (!rootFormId) {
                throw new Error('Unable to find the rootFormId');
            }

            const activityFormIds = await getActivityFormIds({
                activityId,
                rootFormId,
                tenantSchema
            });

            await disableCurrentActivityForms({
                activityFormIds,
                tenantSchema
            });

            await enableCurrentActivityForm({
                activityFormId,
                tenantSchema
            });
        } catch (error) {
            console.log(
                'Something went wrong setting the current activity form.',
                error
            );
        }
    };

    return setCurrentActiveForm;
};
