import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const getQuestionsAndAnswers = (tenantSchema) => gql`
  query getQuestionsAndAnswers {
    ${tenantSchema}_form(
      where: {
        form_questions_aggregate: { count: { predicate: { _gt: 0 } } }
        form_questions: {
          marketing_form_answers: { marketing_id: { _is_null: false } }
        }
      }
    ) {
      id
      form_questions(
        where: {
          marketing_form_answers_aggregate: { count: { predicate: { _gt: 0 } } }
          marketing_form_answers: { marketing_id: { _is_null: false } }
        }
      ) {
        marketing_form_answers(where: { marketing_id: { _is_null: false } }) {
          answer
          form_id
          id
          marketing_id
          question_id
          user_id
        }
        question {
          id
          index
          name
          questionId
          type
          choices {
            text
            question_id
            id
          }
        }
      }
    }
  }
`;

const useDataQuestionsAnswers = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    return useQuery(getQuestionsAndAnswers(schema), {
        fetchPolicy: 'no-cache'
    });
};

export default useDataQuestionsAnswers;
