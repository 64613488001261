import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('inviteButton');

    return {
        INVITE: t('invite')
    };
};

export default useConstants;
