import { useCallback } from 'react';

import AllMeToggle from 'components/AllMeToggle';
import SearchInput from 'components/SearchInput';
// import CalendarLink from 'components/CalendarLink';
import DatePicker from 'components/DatePicker';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
// import ListLink from 'components/ListLink';
import FlaggedToggle from 'components/FlaggedToggle';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
// import EditBulkButton from 'components/EditBulkButton';

const ActivitiesViewSelector = ({
    className = '',
    detailed=false,
    endDate,
    endDateFilter,
    entityFilter,
    entityItems = [],
    isAll,
    isFlagged,
    onFlaggedToggle,
    onToggle,
    recurrencyFilter,
    recurrencyItems = [],
    reviewFilter,
    reviewItems = [],
    setSearchFilter,
    startDate,
    startDateFilter,
		statusFilter,
    statusItems = [],
    typeFilter,
    typeItems = [],
    userFilter,
    userItems = [],
}) => {
    const { ACTIVITY_TYPES, END_DATE, ENTITIES, START_DATE, SEARCH } = useTranslation();

    // const [calendarIsActive, setCalendarIsActive] = useState(false);
    // const [listIsActive, setListIsActive] = useState(true);

    // const handleCalendarClick = useCallback(() => {
    //     setCalendarIsActive(true);
    //     setListIsActive(false);
    // }, [setCalendarIsActive, setListIsActive]);

    // const handleListClick = useCallback(() => {
    //     setCalendarIsActive(false);
    //     setListIsActive(true);
    // }, [setCalendarIsActive, setListIsActive]);

    const handleToggle = useCallback(
        isOn => {
            onToggle(!isOn);
        },
        [onToggle]
    );

    const handleFlaggedToggle = useCallback(
        isOn => {
            onFlaggedToggle(!isOn);
        },
        [onFlaggedToggle]
    );

    return (
        <div className={styles.activitiesViewSelectorContainer}>
            {detailed && (
                <div className={styles.activitiesViewSelector}>
                    <SearchInput
                        className={styles.search}
                        onChange={setSearchFilter}
                        placeholder={SEARCH}
                    />

                    <div className={styles.toggleWrap}>
                        <AllMeToggle
                            className={styles.allMeToggle}
                            isActive={isAll}
                            onToggle={handleToggle}
                        />

                        <FlaggedToggle
                            className={detailed ? styles.flaggedToggle : styles.flaggedToggleSummarized}
                            isActive={isFlagged}
                            onToggle={handleFlaggedToggle}
                        />
                    </div>


                </div>
            )}


            <div className={`${className} ${detailed ? styles.activitiesViewSelector : styles.activitiesViewSelectorSummarized}`}>
                {/* <CalendarLink
                    className={styles.calendarLink}
                    isActive={calendarIsActive}
                    onClick={handleCalendarClick}
                />

                <ListLink
                    className={styles.listLink}
                    isActive={listIsActive}
                    onClick={handleListClick}
                /> */}

                {detailed &&
                <>
                    <div className={styles.bulkEditButton}>
                        {/* <EditBulkButton

                        /> */}
                    </div>
                    
                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={statusItems}
                        label="Status"
                        onChange={statusFilter}
                        returnObject={true}
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={typeItems}
                        label={ACTIVITY_TYPES}
                        onChange={typeFilter}
                        returnObject={true}
                    />


                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={userItems}
                        label={"Assignees"}
                        onChange={userFilter}
                        returnObject={true}
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={reviewItems}
                        label={"Reviewer"}
                        onChange={reviewFilter}
                        returnObject={true}
                    />

                    <DatePicker
                        className={styles.startDate}
                        label={START_DATE}
                        onChange={startDateFilter}
                        placeholder={(startDate) ? startDate.toISOString().split('T')[0] : ''}
                                            value={startDate}
                    />

                    <DatePicker
                        className={styles.endDate}
                        label={END_DATE}
                        onChange={endDateFilter}
                        placeholder={(endDate) ? endDate.toISOString().split('T')[0] : ''}
                                            value={endDate}
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={entityItems}
                        label={ENTITIES}
                        onChange={entityFilter}
                        returnObject={true}
                    />

                    <DropdownMultiSelect
                        className={styles.recurrencyDropdown}
                        items={recurrencyItems}
                        label={"Recurrence"}
                        onChange={recurrencyFilter}
                        returnObject={true}
                    />
                </>
                }

                {!detailed && (
                    <AllMeToggle
                        className={detailed ? styles.allMeToggle : styles.allMeToggleSummarized}
                        isActive={isAll}
                        onToggle={handleToggle}
                    />
                )}
            </div>
        </div>
    );
};

export default ActivitiesViewSelector;
