import { atom, useRecoilState } from 'recoil';

const statusActivitiesPanelFilterAtom = atom({
    default: undefined,
    key: 'statusActivitiesPanelFilter'
});

export function useStatusFilter() {
    return useRecoilState(statusActivitiesPanelFilterAtom);
}
