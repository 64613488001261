import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import { useGetSchema } from 'hooks/useGetSchema';
import { useLockActivityFormsFromActivity } from 'hooks/useLockActivityForm';

const { closeActivityAndCreateNewActivityMenuListItem } = state;

const { closeActivityAndCreateNewActivityMenuListItemAtom } =
    closeActivityAndCreateNewActivityMenuListItem.atoms;

const MUTATE_CLOSE = tenantSchema => gql`
mutation closeActivity($activityId: Int!, $closedDate: timestamp!) {
    update_${tenantSchema}_activity(where: {
        id: {
            _eq: $activityId
        }
    },
    _set: {
        closed: true,
        closed_date: $closedDate
    }) {
        returning {
            id
            closed
        }
    }
}
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history($activityId: Int!, $color: String!, $message: String!, $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
		const tenantSchema = useGetSchema(providedSchema);
    const mutation = useGraphQL(MUTATE_CLOSE, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);
		const {setLockedActivityFormsFromActivity} = useLockActivityFormsFromActivity()

    const closeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            variables['closedDate'] = new Date();
            const res = await mutation(variables);

						const activityId = Number(variables?.activityId)

            await mutationActivityHistory({
              activityId,
              color: 'green',
              message: `closed the activity and created a new activity`,
              userId: variables?.userId
            })

						await setLockedActivityFormsFromActivity({
								activityId,
								tenantSchema
						})

            set(closeActivityAndCreateNewActivityMenuListItemAtom, res?.data);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(closeActivityAndCreateNewActivityMenuListItemAtom, '');
    });

    return [
        useRecoilValue(closeActivityAndCreateNewActivityMenuListItemAtom),
        closeActivity,
        reset
    ];
};

export default useData;
