import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdministrationActivityTypesTableHeader = ({ onSort, sortBy }) => {
    const { LOG_TYPE, RISK_LEVEL, VISIBLE_TO } = useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'name',
                text: LOG_TYPE
            },
            {
                isSortable: true,
                key: 'riskLevel',
                text: RISK_LEVEL
            },
            {
                isSortable: true,
                key: 'visibleTo',
                text: VISIBLE_TO
            }
        ],
        [LOG_TYPE, RISK_LEVEL, VISIBLE_TO]
    );

    return (
        <TableHeader
            className={styles.administrationActivityTypesTableHeader}
            items={headerItems}
            onSort={onSort}
            sortBy={sortBy}
        />
    );
};

export default AdministrationActivityTypesTableHeader;
