import CompletedDetailedListItems from 'components/CompletedDetailedListItems';
import CompletedItems from 'components/CompletedItems';
import styles from './styles.module.scss';

const CompletedDetailedItems = ({ className = '', items, onDownloadItem, tenantCount }) =>  (
    <CompletedItems className={className}>
        {items.length > 0 ? (
            <CompletedDetailedListItems
                items={items}
                            onDownloadItem={onDownloadItem}
                tenantCount={tenantCount}
            />
        ):(
            <p className={styles.noItems}>No items to show</p>
        )}
    </CompletedItems>
);

export default CompletedDetailedItems;
