import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_document(order_by: {name: asc}) {
        name
        id
        policy_sections(order_by: {section: {name: asc}}, where: {deleted: {_is_null: true}}) {
            section {
                id
                name
            }
        }
    }
    ${tenantSchema}_case_type {
        id
        name
    }
    ${tenantSchema}_user(where: {_or: {registration_status: {_neq: "disabled"}, employee_status: {_neq: "disabled"}}}) {
        tenant_user_id
        first_name
        last_name
    }
`;

const useDataQuery = () => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    return useQuery(generateQuery(query, usedSchema, tenants), { fetchPolicy: 'no-cache' });
};

export default useDataQuery;
