import { atom } from 'recoil';

const updateActivityRecurrenceAtom = atom({
    default: '',
    key: 'updateActivityRecurrence'
});

const state = {
    atoms: {
        updateActivityRecurrenceAtom
    }
};

export default state;
