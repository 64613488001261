import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('marketingRequestForm');

    return {
        DESCRIPTION: t('description'),
        DUE_DATE: t('dueDate'),
        EXPECTED_USE_DATE: t('expectedUseDate'),
        HIGH_IMPORTANCE: t('highImportance'),
        INSTRUCTIONS: t('instructions'),
        INTRODUCE_NEW_DOCUMENT_SUBMISSION: t('introduceNewDocumentSubmission'),
        RECIPIENT: t('recipient'),
        REQUEST_NAME: t('requestName'),
        SELECTED_FILES: t('selectedFiles')
    };
};

export default useTranslation;
