import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createActivityMenuListItemFromActivity');

    return {
        CREATE_AN_ACTIVITY: t('createActivity')
    };
};

export default useTranslation;
