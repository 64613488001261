import AsideLink from 'components/AsideLink';
import Buttonize from 'components/Buttonize';

import styles from './styles.module.scss';

const AddRadioButtonOption = ({ className = '', onClick = '' }) => (
    <Buttonize onClick={onClick}>
        <AsideLink
            className={`${className} ${styles.AddRadioButtonOption}`}
            text="+ Add Option"
        />
    </Buttonize>
);

export default AddRadioButtonOption;
