import { useCallback } from 'react';

import BodyText from 'components/BodyText';
import SlideToggle from 'components/SlideToggle';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';
import SubText from 'components/SubText';

const ToggleWithLabel = ({
    className = '',
    isActive = true,
    label = '',
    onToggle,
    sublabel = ''
}) => {
    const handleToggle = useCallback(
        isOn => {
            if (isFunction(onToggle)) {
                onToggle(!isOn);
            }
        },
        [onToggle]
    );

    const onStyles = isActive ? styles.isActive : '';

    return (
        <div className={`${className} ${styles.labeledToggle}`}>
            <BodyText
                className={`${onStyles} ${styles.bodyText}`}
                text={label}
            />

            <SlideToggle
                className={styles.toggle}
                isActive={isActive}
                onToggle={handleToggle}
            />

            {sublabel !== '' &&
                <SubText className={styles.sublabel} text={sublabel} />
            }
        </div>
    );
};

export default ToggleWithLabel;
