import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('due');

    return {
        END_IN_DAYS: t('endInDays')
    };
};

export default useConstants;
