import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useConfig from 'hooks/useConfig';

import state from 'app/state';
import titleCase from 'utilities/titleCase';
import sendActivityAssignedEmail from 'utilities/sendActivityAssignedEmail';

const { activityStatus } = state;

const { activityStatusAtom } = activityStatus.atoms;

const MUTATE_STATUS = tenantSchema => gql`
mutation update_user_activity($activityId: Int!, $groupId: Int, $status: String!, $userId: Int) {
    update_${tenantSchema}_user_activity(where: {activity_id: {_eq: $activityId}, _and: {_or: [{user_id: {_eq: $userId}}, {group_id: {_eq: $groupId}}]}}, _set: {status: $status}) {
      returning {
        activity_id
        user_id
        group_id
        status
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history($activityId: Int!, $color: String!, $message: String!, $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;


const useStatus = (providedSchema) => {
    const { API_KEY } = useConfig();
    const markAsComplete = useGraphQL(MUTATE_STATUS, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema, useSchemaDescription } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [schemaDescription] = useSchemaDescription();

    const usedSchema = providedSchema ? providedSchema : schema;
    const usedSchemaDescription = providedSchema ? providedSchema : schemaDescription;

    const setStatus = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const { assignmentType, id, groupId, nextUsers, status, userId, userName } = variables;

            const submissionVariables = {
              activityId: Number(id),
              groupId: (groupId) ? groupId : 0,
              status,
              userId: (userId) ? userId : 0
            }
            await markAsComplete(submissionVariables);
            await mutationActivityHistory({
              activityId: Number(id),
              color: 'green',
              message: `marked the activity complete for ${titleCase(userName)} in ${titleCase(assignmentType)}`,
              userId: userId
            })
            if(variables.hierarchy || variables.nextAssignmentType === 'reviewer'){
              for(const nextUser of nextUsers){
                if(nextUser?.type === 'user'){
                  sendActivityAssignedEmail(Number(id), variables.activity, variables.nextAssignmentType, new Date(variables.dueDate), null, usedSchema, usedSchemaDescription, nextUser.id, API_KEY ? API_KEY : '')
                }else{
                  sendActivityAssignedEmail(Number(id), variables.activity, variables.nextAssignmentType, new Date(variables.dueDate), nextUser.id, usedSchema, usedSchemaDescription, null, API_KEY ? API_KEY : '')
                }
              }
            }
            set(activityStatusAtom, {id, status});
        };

        runMutation();
    });

    return [useRecoilValue(activityStatusAtom), setStatus];
};

export default useStatus;
