import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConnectorIcon from 'components/icons/ConnectorIcon';
import DocumentsIcon from 'components/icons/DocumentsIcon';
// import ListIcon from 'components/icons/ListIcon';
import MenuListItems from 'components/MenuListItems';
import PasswordIcon from 'components/icons/PasswordIcon';
import PeopleIcon from 'components/icons/PeopleIcon';
import ReportsIcon from 'components/ReportsIcon';
import ShieldIcon from 'components/icons/ShieldIcon';
import SpeedometerIcon from 'components/icons/SpeedometerIcon';

import isFunction from 'utilities/isFunction';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';
import CasesIcon from 'components/icons/CasesIcon';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { usePermissions } from 'hooks/usePermissions';

const DOCUMENT_MANAGEMENT_PERMISSIONS_CATALOG = [
    'policies:read',
    'sideLetters:read',
    'sections:read',
    'repository:read'
];

const AsideMenu = ({
    className = '',
    handleCollapse,
    handleExpand,
    isCollapsed,
    onNavigationClick
}) => {
    const {
        ADMIN,
        CASE_MANAGEMENT,
        COMPLIANCE_CENTRAL,
        COMPLIANCE_LOG,
        DOCUMENT_MANAGEMENT,
        FORMS_MANAGEMENT,
        HR,
        // PROGRAM_OVERVIEW,
        REPORTS
    } = useTranslation();

    const {
        ADMIN: ADMIN_URL,
        CASE_MANAGEMENT_ROUTE,
        COMPLIANCE_LOG_ROUTE,
        DOCUMENT_MANAGEMENT_ROUTE,
        FORMS_MANAGEMENT_ROUTE,
        HOME,
        HUMAN_RESOURCES,
        // PROGRAM_OVERVIEW: PROGRAM_OVERVIEW_URL,
        REPORTS: REPORTS_URL
    } = useConfig();

    const navigate = useNavigate();

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const { hasPermissions } = usePermissions();

    const documentmanagementPermission = hasPermissions(
        DOCUMENT_MANAGEMENT_PERMISSIONS_CATALOG
    );

    const [activeItem, setActiveItem] = useState(0);

    const getSingleTenantItems = () => {
        const items = [
            {
                icon: SpeedometerIcon,
                text: COMPLIANCE_CENTRAL,
                url: HOME
            },
            {
                icon: PeopleIcon,
                text: HR,
                url: HUMAN_RESOURCES
            },
            {
                icon: ReportsIcon,
                text: REPORTS,
                url: REPORTS_URL
            },
            {
                icon: ShieldIcon,
                text: ADMIN,
                url: ADMIN_URL
            },
            {
                icon: CasesIcon,
                text: CASE_MANAGEMENT,
                url: CASE_MANAGEMENT_ROUTE
            },
            {
                icon: PasswordIcon,
                text: FORMS_MANAGEMENT,
                url: FORMS_MANAGEMENT_ROUTE
            },
            {
                icon: ConnectorIcon,
                text: COMPLIANCE_LOG,
                url: COMPLIANCE_LOG_ROUTE
            }
            // {
            //     icon: ListIcon,
            //     text: PROGRAM_OVERVIEW,
            //     url: PROGRAM_OVERVIEW_URL
            // }
        ];

        if (documentmanagementPermission) {
            items.splice(4, 0, {
                icon: DocumentsIcon,
                text: DOCUMENT_MANAGEMENT,
                url: DOCUMENT_MANAGEMENT_ROUTE
            });
        }

        return items;
    };

    const getMultiTenantItems = () => {
        const items = [
            {
                icon: SpeedometerIcon,
                text: COMPLIANCE_CENTRAL,
                url: HOME
            },
            {
                icon: PeopleIcon,
                text: HR,
                url: HUMAN_RESOURCES
            },
            {
                icon: ReportsIcon,
                text: REPORTS,
                url: REPORTS_URL
            },
            {
                icon: CasesIcon,
                text: CASE_MANAGEMENT,
                url: CASE_MANAGEMENT_ROUTE
            },
            // {
            //     icon: PasswordIcon,
            //     text: FORMS_MANAGEMENT,
            //     url: FORMS_MANAGEMENT_ROUTE
            // },
            {
                icon: ConnectorIcon,
                text: COMPLIANCE_LOG,
                url: COMPLIANCE_LOG_ROUTE
            }
        ];

        if (documentmanagementPermission) {
            items.splice(3, 0, {
                icon: DocumentsIcon,
                text: DOCUMENT_MANAGEMENT,
                url: DOCUMENT_MANAGEMENT_ROUTE
            });
        }

        return items;
    };

    const handleClick = useCallback(
        (_, index) => {
            setActiveItem(index);

            const navigateToURL =
                tenants && tenants.length > 1
                    ? getMultiTenantItems()
                    : getSingleTenantItems();

            navigate(navigateToURL[index].url);

            if (isFunction(onNavigationClick)) {
                onNavigationClick();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tenants, navigate, onNavigationClick, setActiveItem]
    );

    return (
        <MenuListItems
            activeItem={activeItem}
            className={className}
            handleCollapse={handleCollapse}
            handleExpand={handleExpand}
            isCollapsed={isCollapsed}
            items={
                tenants && tenants.length > 1
                    ? getMultiTenantItems()
                    : getSingleTenantItems()
            }
            mainMenu={true}
            onClick={handleClick}
            showCollapse
        />
    );
};

export default AsideMenu;
