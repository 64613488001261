import { forwardRef, useCallback } from 'react';

import PositiveHollowButton from 'components/PositiveHollowButton';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const MarkCompleteButton = forwardRef(
    ({ className = '', isDisabled = false, onClick }, ref) => {
        const { MARK_AS_COMPLETE } = useTranslation();

        const [_, markAsComplete] = useData();

        const handleClick = useCallback(() => {
            markAsComplete({
                id: 'mark-as-complete'
            });

            if (isFunction(onClick)) {
                onClick();
            }
        }, [markAsComplete, onClick]);

        return (
            <PositiveHollowButton
                className={`${className} ${styles.markCompleteButton} `}
                disabled={isDisabled}
                onClick={handleClick}
                ref={ref}
                text={MARK_AS_COMPLETE}
            />
        );
    }
);

export default MarkCompleteButton;
