import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import CloseIcon from 'components/icons/CloseIcon';
import ContentPanel from 'components/ContentPanel';
import Overlay from 'components/Overlay';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';
import Buttonize from 'components/Buttonize';

const Modal = ({ children, className = '', isOpen, onClose, overlayClose=true}) => {
    const handleClose = useCallback(
        event => {
            if (!isOpen) {
                return;
            }

            if (!isFunction(onClose)) {
                return;
            }

            return onClose(event);
        },
        [isOpen, onClose]
    );

    useEffect(() => {
        const rootElement = document.getElementById('root');

        rootElement.classList.add(styles.hideWhenModalIsOpen);

        return () => {
            rootElement.classList.remove(styles.hideWhenModalIsOpen);
        };
    }, []);

    return createPortal(
        <Overlay className={styles.modal} isOpen={isOpen} onClose={handleClose} overlayClose={overlayClose}>
            <ContentPanel className={`${className} ${styles.contentPanel}`}>
                <Buttonize className={styles.buttonize} onClick={handleClose}>
                    <CloseIcon />
                </Buttonize>

                {children}
            </ContentPanel>
        </Overlay>,
        document.body
    );
};

export default Modal;
