const httpAuth = async (url = '', data, options = {}) => {
    const bearer = 'Bearer'.concat(" ", localStorage.getItem('accessToken'))
    const requestOptions = {
        body: data,
        //cache: 'no-cache',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': bearer
        },
        method: 'GET',
        //mode: 'no-cors',
        redirect: 'follow',
        ...options
    };

    try {
        const response = await fetch(url, requestOptions);
        
        return await response.json();
    } catch (error) {
        return {};
    }
};

export default httpAuth;
