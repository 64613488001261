import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const WorkflowSettingsTableHeader = ({ onSort }) => {
    const { APPROVING_GROUP, STATUS, SUBMITTING_GROUP } = useTranslation();

    const headerItems = useMemo(
        () => [
            {
                isSortable: true,
                key: 'submittingGroup',
                text: SUBMITTING_GROUP
            },
            {
                isSortable: false,
                key: 'approvingGroup',
                text: APPROVING_GROUP
            },
            {
                isSortable: false,
                key: 'status',
                text: STATUS
            }
        ],
        [APPROVING_GROUP, STATUS, SUBMITTING_GROUP]
    );

    return (
        <TableHeader
            className={styles.workflowSettingsTableHeader}
            items={headerItems}
            onSort={onSort}
        />
    );
};

export default WorkflowSettingsTableHeader;
