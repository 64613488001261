import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import AddLogEntryButton from 'components/AddLogEntryButton';
import LogEntriesPanel from 'components/LogEntriesPanel';
import WithDownloadButton from 'components/WithDownloadButton';
import WithPageTitle from 'components/WithPageTitle';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

import useData from './hooks/useData';

const ComplianceLog = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { COMPLIANCE_LOG } = useTranslation();

    const { ADD_LOG_ENTRY_ROUTE } = useConfig();

    const form = useForm({
        dueDate: '',
        entities: '',
        groups: '',
        startDate: ''
    });

    const navigate = useNavigate();

    const handleAdd = useCallback(() => {
        navigate(ADD_LOG_ENTRY_ROUTE);
    }, [ADD_LOG_ENTRY_ROUTE, navigate]);

    const { loading, error, data } = useData();
    const [logData, setLogData] = useState();
    
    if(loading){}
    if(error){}

    useEffect(() => {
        if(data){
            const dataArray = data[`${schema}_log`]
            if (Array.isArray(dataArray)) {
                setLogData(dataArray);
            }
        }
    }, [data, schema, setLogData]);

    return (
        <div className={styles.complianceLog}>
            <WithPageTitle title={COMPLIANCE_LOG}>
                <WithDownloadButton data={logData} hasPDFexport={false} name={'Compliance Log'} >
                    <AddLogEntryButton
                        className={styles.addLogEntryButton}
                        onClick={handleAdd}
                    />
                </WithDownloadButton>
            </WithPageTitle>

            <LogEntriesPanel className={styles.logEntriesPanel} form={form} />
        </div>
    );
};

export default ComplianceLog;
