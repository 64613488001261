import { atom } from 'recoil';

const completedActivityInProgressSummarizedAtom = atom({
    default: '',
    key: 'completedActivityInProgressSummarized'
});

const state = {
    atoms: {
        completedActivityInProgressSummarizedAtom
    }
};

export default state;
