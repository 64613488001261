import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useMutation, gql } from '@apollo/client';

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insert_case_history_dropdown($caseId: Int!, $color: String = "green", $message: String = "re-opened the case", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        },

        update_${tenantSchema}_case(where: {
        id: {
            _eq: $caseId
        }
    },

    _set: {
        closed: false,
        closed_date: null
    }) {
        returning {
            id
        }
    }
    }  
`;

const useReopenCase = (caseSchema) => {

    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();
    const [insertCaseHistory] = useMutation(MUTATE_CASE_HISTORY(caseSchema));

    const reopenCase = async (caseId) => {
        try {
            await insertCaseHistory({
                variables: {
                    caseId,
                    message: 're-opened the case',
                    userId
                }
            });
            window.location.reload();
        } catch (error) {
            console.log(error);   
        }
    }

    return [reopenCase];

}

export default useReopenCase