const normalizeData = ({ data, schema }) => {

    const activityTypes = data[`${schema}_activity_type`][0];

    return {
        activityTypes,
    };
};

export default normalizeData;
