import AvatarsWithText from 'components/AvatarsWithText';
import BodyText from 'components/BodyText';
import RiskPill from 'components/RiskPill';
import TableRow from 'components/TableRow';
import stripHtml from 'utilities/stripHtml';
import titleCase from 'utilities/titleCase';
import styles from './styles.module.scss';

const DynamicTableRow = ({ className, hasCustomDateDropdown, data }) => {
    let dateCustomColumns
    const columns = Object.keys(data).map((key, index) => {
         dateCustomColumns = <BodyText key={index} className={styles.text} text={data['dueDate']} />;
        const item = data[key];
        if(key === 'dueDate'){
            return null;
        }
        if(key === 'id'){
            return null;
        }
        if (typeof item === 'string') {
            if (['low', 'medium', 'high', 'critical'].includes(item)) {
                return <RiskPill key={index} riskLevel={item} />;
            } else {
                return <BodyText key={index} className={styles.text} text={stripHtml(item)} />;
            }
        } else if (typeof item === 'number' || typeof item === 'boolean') {
            return <BodyText key={index} className={styles.text} text={titleCase(item.toString())} />;
        } else if (item === null || item === undefined) {
            return <BodyText key={index} className={styles.text} text={'-'} />;
        } else if (Array.isArray(item)) {
            // Array handling
            if (item.length === 0) {
                return <BodyText key={index} className={styles.text} text={'-'} />;
            } else if (['string', 'number', 'boolean'].includes(typeof item[0])) {
                return <BodyText key={index} className={styles.text} text={item.join(', ')} />;
            } else if (typeof item[0] === 'object' && !Array.isArray(item[0]) && item[0] !== null) {
                // Object handling within arrays
                if ('type' in item[0] && 'label' in item[0] && 'id' in item[0]) {
                    return <AvatarsWithText key={index} assigned={item} />;
                } else if ('name' in item[0]) {
                    const nameArray = item.reduce((accumulator, i) => {
                        accumulator.push(i?.name);
                        return accumulator;
                    }, []);
                    return <BodyText key={index} className={styles.text} text={nameArray.join(', ')} />;
                } else {
                    return <BodyText key={index} className={styles.text} text={item.length.toString()} />;
                }
            } else {
                return <BodyText key={index} className={styles.text} text={''} />;
            }
        } else if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
            // Single object handling
            if ('type' in item && 'label' in item && 'id' in item) {
                return <AvatarsWithText key={index} assigned={[item]} />;
            } else {
                return <BodyText key={index} className={styles.text} text={Object.keys(item).length.toString()} />;
            }
        } else {
            return <BodyText key={index} className={styles.text} text={''} />;
        }
    });

    let allColumns;
    if (!hasCustomDateDropdown) {
        allColumns = [columns];
    }else{
        allColumns = [dateCustomColumns, columns];
    }

    return (
        <TableRow className={`${className} ${styles.dynamicTableRow} ${styles.defaultHover}`}>
            {allColumns}
        </TableRow>
    );
};

export default DynamicTableRow;
