import { atom } from 'recoil';

const policiesAtom = atom({
    default: [],
    key: 'policies'
});

const state = {
    atoms: {
        policiesAtom
    }
};

export default state;