import { useEffect, useMemo, useState } from 'react';
import ContentPanel from 'components/ContentPanel';
import Dropdown from 'components/Dropdown';
import DocumentManagementSectionsTableHeader from 'components/DocumentManagementSectionsTableHeader';
import DocumentManagementSectionsTableRow from 'components/DocumentManagementSectionsTableRow';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';
import SearchInput from 'components/SearchInput';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import lexigraphicalSort from 'utilities/lexigraphicalSort';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const DocumentManagementSectionsPanel = props => {
    const { useTenants } = useGlobalStateHooks();
    const { className = '', items = [], loading } = props;

    const [tenants] = useTenants();

    const [nameSelected, setNameFilter] = useState();
    const [versionSelected, setVersionFilter] = useState();
    const [publishedSelected, setPublishedFilter] = useState();
    const [riskSelected, setRiskFilter] = useState();
    const [categoriesSelected, setCategoriesFilter] = useState();
    const [statusesSelected, setStatusFilter] = useState();
    const [documentsSelected, setDocumentsFilter] = useState();
    const [documentTagsSelected, setDocumentTagsFilter] = useState();
    const [displayedItems, setDisplayedItems] = useState(items);
    const [searchFilter, setSearchFilter] = useState("");

    const { DOCUMENT_TAGS, DOCUMENTS, NAME, PUBLISHED_STATE, _RISK_LEVEL, SECTION_TAGS, STATUS, SEARCH, _VERSION, VIEW_ALL  } = useTranslation();

    const nameItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { name: nameItem }) => {
                if (!(nameItem in accumulator.item)) {
                    accumulator.item[nameItem] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const versionItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { version }) => {
                if (!(version in accumulator.item)) {
                    accumulator.item[version] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const documentItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { assignedDocuments }) => {
                if(Array.isArray(assignedDocuments)){
                    for(const document of assignedDocuments){
                        if (!(document?.name in accumulator.item)) {
                            accumulator.item[document?.name] = 0;
                        }
                    }
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const documentTagItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { documentCategories }) => {
                if(Array.isArray(documentCategories)){
                    for(const document of documentCategories){
                        if (!(document?.label in accumulator.item)) {
                            accumulator.item[document?.label] = 0;
                        }
                    }
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const publishedItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { published }) => {
                if (!(published in accumulator.item)) {
                    accumulator.item[published] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const riskItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { riskLabel }) => {
                if (!(riskLabel in accumulator.item)) {
                    accumulator.item[riskLabel] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const categoryItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { selectedCategories }) => {
                if(Array.isArray(selectedCategories)){
                    for(const selectedCategory of selectedCategories){
                        if (!(selectedCategory in accumulator.item)) {
                            accumulator.item[selectedCategory?.label] = 0;
                        }
                    }
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    const statusItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { status }) => {
                if (!(status in accumulator.item)) {
                    accumulator.item[status] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        const defaultItem = {
            label: VIEW_ALL,
            value: null
        };

        return [
            defaultItem,
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [VIEW_ALL, items]);
    
    if ((nameItems.filter(e => e.value === nameSelected).length === 0 && nameSelected === 'View All') || (Array.isArray(nameSelected) && nameSelected.length===0)) {
        setNameFilter(null);
    }
    if ((versionItems.filter(e => e.value === versionSelected).length === 0 && versionSelected === 'View All') || (Array.isArray(versionSelected) && versionSelected.length===0)) {
        setVersionFilter(null);
    }
    if ((publishedItems.filter(e => e.value === publishedSelected).length === 0 && publishedSelected === 'View All') || (Array.isArray(publishedSelected) && publishedSelected.length===0)) {
        setPublishedFilter(null);
    }
    if ((riskItems.filter(e => e.value === riskSelected).length === 0 && riskSelected === 'View All') || (Array.isArray(riskSelected) && riskSelected.length===0)) {
        setRiskFilter(null);
    }
    if ((categoryItems.filter(e => e.value === categoriesSelected).length === 0 && categoriesSelected === 'View All') || (Array.isArray(categoriesSelected) && categoriesSelected.length===0)) {
        setCategoriesFilter(null);
    }
    if ((statusItems.filter(e => e.value === statusesSelected).length === 0 && statusesSelected === 'View All') || (Array.isArray(statusesSelected) && statusesSelected.length===0)) {
        setStatusFilter(null);
    }
    if ((documentItems.filter(e => e.value === documentsSelected).length === 0 && documentsSelected === 'View All') || (Array.isArray(documentsSelected) && documentsSelected.length===0)) {
        setDocumentsFilter(null);
    }
    if ((documentTagItems.filter(e => e.value === documentTagsSelected).length === 0 && documentTagsSelected === 'View All') || (Array.isArray(documentTagsSelected) && documentTagsSelected.length===0)) {
        setDocumentTagsFilter(null);
    }
    
    useEffect(() => {
        if (items) {
            let filteredItems = items;
    
            if (nameSelected) {
                filteredItems = filteredItems.filter(({ name }) => name === nameSelected);
            }
    
            if (versionSelected) {
                filteredItems = filteredItems.filter(({ version }) => version === versionSelected);
            }
    
            if (publishedSelected) {
                filteredItems = filteredItems.filter(({ published }) => published === publishedSelected);
            }
    
            if (riskSelected) {
                filteredItems = filteredItems.filter(({ riskLabel }) => riskLabel === riskSelected);
            }
    
            if (categoriesSelected && categoriesSelected.some(e => e !== undefined && e !== null)) {
                filteredItems = filteredItems.filter(({ selectedCategories }) =>
                    selectedCategories.some(arrVal => categoriesSelected.includes(arrVal?.label))
                );
            }
    
            if (documentsSelected && documentsSelected.some(e => e !== undefined && e !== null)) {
                filteredItems = filteredItems.filter(({ assignedDocuments }) =>
                    assignedDocuments.some(arrVal => documentsSelected.includes(arrVal?.name))
                );
            }
    
            if (documentTagsSelected && documentTagsSelected.some(e => e !== undefined && e !== null)) {
                filteredItems = filteredItems.filter(({ documentCategories }) =>
                    documentCategories.some(arrVal => documentTagsSelected.includes(arrVal?.label))
                );
            }
    
            if (statusesSelected) {
                filteredItems = filteredItems.filter(({ status }) => status === statusesSelected);
            }
    
            if (searchFilter) {
                filteredItems = filteredItems.filter(item =>
                    item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                    (item.description && item.description.toLowerCase().includes(searchFilter.toLowerCase()))
                );
            }
    
            setDisplayedItems(filteredItems);
        }
    }, [
        items,
        nameSelected,
        versionSelected,
        publishedSelected,
        riskSelected,
        categoriesSelected,
        documentsSelected,
        documentTagsSelected,
        statusesSelected,
        setDisplayedItems,
        searchFilter
    ]);
    

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody} loading={loading}>
                <SearchInput
                    className={styles.search}
                    onChange={setSearchFilter}
                    placeholder={SEARCH}
                />

                <Dropdown
                    className={styles.dropdown}
                    items={nameItems}
                    label={NAME}
                    onChange={setNameFilter}
                    selected={nameSelected}
                />
                
                {/* <Dropdown
                    className={styles.dropdown}
                    items={versionItems}
                    label={VERSION}
                    onChange={setVersionFilter}
                    selected={versionSelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={riskItems}
                    label={RISK_LEVEL}
                    onChange={setRiskFilter}
                    selected={riskSelected}
                /> */}
                
                <DropdownMultiSelect
                    className={styles.dropdown}
                    items={categoryItems}
                    label={SECTION_TAGS}
                    onChange={setCategoriesFilter}
                    selected={categoriesSelected}
                />
                
                <DropdownMultiSelect
                    className={styles.dropdown}
                    items={documentItems}
                    label={DOCUMENTS}
                    onChange={setDocumentsFilter}
                    selected={documentsSelected}
                />
                
                <DropdownMultiSelect
                    className={styles.dropdown}
                    items={documentTagItems}
                    label={DOCUMENT_TAGS}
                    onChange={setDocumentTagsFilter}
                    selected={documentTagsSelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={statusItems}
                    label={STATUS}
                    onChange={setStatusFilter}
                    selected={statusesSelected}
                />
                
                <Dropdown
                    className={styles.dropdown}
                    items={publishedItems}
                    label={PUBLISHED_STATE}
                    onChange={setPublishedFilter}
                    selected={publishedSelected}
                />

                <Table className={styles.table}>
                    <DocumentManagementSectionsTableHeader
                        onSort={({ _isAscending, _sortBy }) => {
                        }}
                        tenantCount={tenants.length}
                    />

                    {displayedItems.length === 0 && (
                        <p className={styles.noItems}>No items to show</p>
                    )}

                    {displayedItems.map(
                        (
                            {
                                assignedDocuments,
                                description,
                                documentCategories,
                                effectiveDate,
                                id,
                                name: sectionName,
                                published,
                                riskLabel,
                                schema,
                                selectedCategories,
                                status,
                                version
                            },
                            index
                        ) => (
                            <DocumentManagementSectionsTableRow
                                assignedDocuments={assignedDocuments}
                                description={description}
                                documentCategories={documentCategories}
                                effectiveDate={effectiveDate}
                                id={id}
                                key={`${id}.${index}`}
                                name={sectionName}
                                published={published}
                                riskLabel={riskLabel}
                                schema={schema}
                                selectedCategories={selectedCategories}
                                status={status}
                                tenantCount={tenants.length}
                                version={version}
                            />
                        )
                    )}
                </Table>
            </PanelBody>
        </ContentPanel>
    );
};

export default DocumentManagementSectionsPanel;
