import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { unflaggedActivity } = state;

const { unflaggedActivityAtom } = unflaggedActivity.atoms;

const MUTATE_UNFLAG_ACTIVITY = tenantSchema => gql`
mutation deleteFlag($activityId: Int!, $userId: Int!) {
    delete_${tenantSchema}_activity_flag(where: {_and: {activity_id: {_eq: $activityId}, user_id: {_eq: $userId}}}){
      returning {
        id
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_deleted($activityId: Int!, $color: String = "yellow", $message: String = "unflagged the activity", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useUnflagged = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_UNFLAG_ACTIVITY, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const removeActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);
            await mutationActivityHistory(variables);

            const deletedArray = res?.data[`delete_${usedSchema}_activity_flag`].returning
            if(deletedArray.length > 0){
                set(unflaggedActivityAtom, variables?.activityId);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(unflaggedActivityAtom, '');
    });

    return [useRecoilValue(unflaggedActivityAtom), removeActivity, reset];
};

export default useUnflagged;
