import Overlay from 'components/Overlay';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const Drawer = props => {
    const {
        className = '',
        children,
        isOpen = false,
        onClose,
        position = 'left'
    } = props;

    const handleClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    return (
        <Overlay
            className={`${styles.overlay} ${styles[position]}`}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <div
                className={`${styles.drawer} ${styles[position]} ${
                    isOpen ? styles.isOpen : ''
                } ${className}`}
            >
                {children}
            </div>
        </Overlay>
    );
};

export default Drawer;
