import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('downloadButton');

    return {
        EXPORT_ALL_DATA_EXCEL: t('exportAllDataExcel'),
        EXPORT_FILTERED_DATA_EXCEL: t('exportFilteredDataExcel'),
        EXPORT_TO_EXCEL: t('exportToExcel'),
        EXPORT_TO_PDF: t('exportToPdf'),
    };
};

export default useTranslation;
