import { atom } from 'recoil';

const updateSectionNameAtom = atom({
    default: "",
    key: 'updateSectionName'
});

const updateSectionDescriptionAtom = atom({
    default: "",
    key: 'updateSectionDescription'
});

const state = {
    atoms: {
        updateSectionDescriptionAtom,
        updateSectionNameAtom
    }
};

export default state;
