import { useState, useEffect, useMemo, useCallback } from 'react';

import ContentPanel from 'components/ContentPanel';
import DocumentManagementRepositoryTableHeader from 'components/DocumentManagementRepositoryTableHeader';
import DocumentManagementRepositoryTableRow from 'components/DocumentManagementRepositoryTableRow';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';
import Dropdown from 'components/Dropdown';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import Modal from 'components/Modal';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import SearchInput from 'components/SearchInput';

import lexigraphicalSort from 'utilities/lexigraphicalSort';
import useTranslation from './hooks/useTranslation';
import styles from './styles.module.scss';
import {useSetFilteredRepositoriesData} from 'components/DocumentManagementRepositoryTab/hooks/useFilteredData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const dateDropdownOptions = [
    { label: 'All', value: 'all' },
    { label: 'This quarter', value: 'thisQuarter' },
    { label: 'Last quarter', value: 'lastQuarter' },
    { label: 'This year', value: 'thisYear' },
    { label: 'Last year', value: 'lastYear' },
    { label: 'Custom', value: 'custom' }
];

const DocumentManagementPanel = props => {
    const { className = '', items = [], loading } = props;

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();
    const [displayedItems, setDisplayedItems] = useState(items);
    const [nameSelected, setNameFilter] = useState([]);
    const [typeSelected, setTypeFilter] = useState(null);
    const [referenceNameSelected, setReferenceNameFilter] = useState([]);
    const [referenceTypeSelected, setReferenceTypeFilter] = useState([]);
    const [uploadBySelected, setUploadByFilter] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState(null);
    const [customDates, setCustomDates] = useState({startDate: null, endDate: null});
    const [customDatesInput, setCustomDatesInput] = useState({
        endDate: "",
        startDate: "",
    });
    const [searchFilter, setSearchFilter] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const { DATE, NAME, REFERENCE_NAME, REFERENCE_TYPE, SEARCH, TYPE, UPLOAD_BY, VIEW_ALL } = useTranslation();

    const setFilteredRepositoriesData = useSetFilteredRepositoriesData();

		function excludeDeletedItems(elements = []) {
            return elements.filter(({ deleted }) => !deleted);
        }

    const applyCustomDateFilter = () => {
        if(customDates.startDate && customDates.endDate){
            const startDate = new Date(customDates.startDate);
            const endDate = new Date(customDates.endDate);
            const startDateFilter = new Date(startDate.setHours(0, 0, 0, 0));
            const endDateFilter = new Date(endDate.setHours(23, 59, 59, 999));
            setSelectedDateFilter("custom");
            setCustomDatesInput({startDate: startDateFilter, endDate: endDateFilter});
        }
        setOpenModal(false);
    };

    const formatDateToYYYYMMDD = (date) => {
        const d = new Date(date);
        let month = '' + (d.getUTCMonth() + 1),
            day = '' + d.getUTCDate(),
            year = d.getUTCFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const getQuarterDates = (currentDate, isCurrent) => {
        let year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        let quarter = Math.floor((month + 3) / 3);

        if (!isCurrent) {
            quarter = quarter - 1;
            if (quarter === 0) {
                quarter = 4;
                year = year - 1;
            }
        }

        const firstDayOfQuarter = new Date(Date.UTC(year, (quarter - 1) * 3, 1));
        const lastDayOfQuarter = new Date(Date.UTC(year, quarter * 3, 0));

        setCustomDatesInput({
            endDate: formatDateToYYYYMMDD(lastDayOfQuarter),
            startDate: formatDateToYYYYMMDD(firstDayOfQuarter),
        })


        return { end: lastDayOfQuarter, start: firstDayOfQuarter };
    };

    const getYearDates = (currentDate, isCurrent) => {
        const year = isCurrent ? currentDate.getFullYear() : currentDate.getFullYear() - 1;
        const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
        const lastDayOfYear = new Date(Date.UTC(year, 11, 31));

        setCustomDatesInput({
            endDate: formatDateToYYYYMMDD(lastDayOfYear),
            startDate: formatDateToYYYYMMDD(firstDayOfYear),
        })

        return { end: lastDayOfYear, start: firstDayOfYear };
    };

    const getDateRange = () => {
        const currentDate = new Date();
        switch (selectedDateFilter) {
            case 'all':
                return null;
            case 'thisQuarter':
                return getQuarterDates(currentDate, true);
            case 'lastQuarter':
                return getQuarterDates(currentDate, false);
            case 'thisYear':
                return getYearDates(currentDate, true);
            case 'lastYear':
                return getYearDates(currentDate, false);
            case 'custom':
                if (!customDatesInput.startDate && !customDatesInput.endDate) {
                    return null;
                }
                return {
                    start: new Date(customDatesInput.startDate),
                    end: new Date(customDatesInput.endDate)
                };
            default:
                return null;
        }
    };

    const nameItems = useMemo(() => {
        if (!items) {
            return [];
        }

        const dataItems = items.reduce(
            (accumulator, { name: nameItem }) => {
                if (!(nameItem in accumulator.item)) {
                    accumulator.item[nameItem] = 0;
                }
                return accumulator;
            },
            { item: {} }
        );

        const { item } = dataItems;

        const options = Object.keys(item).map(key => ({
            label: key,
            value: key
        }));

        return [
            ...lexigraphicalSort(options, ['label'])
        ];
    }, [items]);

    const typeItems = [
        {
            label: VIEW_ALL,
            value: null
        },
        {
            label: 'Case',
            value: 'Case'
        },
        {
            label: 'Activity',
            value: 'Activity'
        }
    ]

    const referenceNameItems = useMemo(() => {
        if (!items) {
            return [];
        }

            const dataItems = items.reduce(
                (accumulator, { referenceName: referenceNameItem }) => {
                    if (!(referenceNameItem in accumulator.item)) {
                        accumulator.item[referenceNameItem] = 0;
                    }
                    return accumulator;
                },
                { item: {} }
            );

            const { item } = dataItems;

            const options = Object.keys(item).map(key => ({
                label: key,
                value: key
            }));

            return [
                ...lexigraphicalSort(options, ['label'])
            ];
    }, [items]);

    const referenceTypeItems = useMemo(() => {
        if (!items) {
            return [];
        }

            const dataItems = items.reduce(
                (accumulator, { referenceType: referenceTypeItem }) => {
                    if (!(referenceTypeItem in accumulator.item)) {
                        accumulator.item[referenceTypeItem] = 0;
                    }
                    return accumulator;
                },
                { item: {} }
            );

            const { item } = dataItems;

            const options = Object.keys(item).map(key => ({
                label: key,
                value: key
            }));

            return [
                ...lexigraphicalSort(options, ['label'])
            ];
    }, [items]);

    const uploadByItems = useMemo(() => {
        if (!items) {
            return [];
        }

            const dataItems = items.reduce(
                (accumulator, { uploadBy: uploadByItem }) => {
                    if (!(uploadByItem in accumulator.item)) {
                        accumulator.item[uploadByItem] = 0;
                    }
                    return accumulator;
                },
                { item: {} }
            );

            const { item } = dataItems;

            const options = Object.keys(item).map(key => ({
                label: key,
                value: key
            }));

            return [
                ...lexigraphicalSort(options, ['label'])
            ];
    }, [items]);

    if ((typeItems.filter(e => e.value === typeSelected).length === 0 && typeSelected === 'View All') || (Array.isArray(typeSelected) && typeSelected.length===0)) {
        setTypeFilter(null);
    }

    const sortedData = useCallback(() => {
        const dateRange = getDateRange();
        let filteredItems = excludeDeletedItems(items);

        if (dateRange) {
            const startDate = new Date(dateRange.start).getTime();
            const endDate = new Date(dateRange.end).getTime();

            filteredItems = filteredItems.filter(item => {
                const itemDate = new Date(item.dateIntroduced).getTime();
                return itemDate >= startDate && itemDate <= endDate;
            });
        }

        const nameFilteredItems = nameSelected && nameSelected.length > 0
            ? filteredItems.filter(({ name }) => nameSelected.includes(name))
            : filteredItems;

        const typeFilteredItems = typeSelected
            ? nameFilteredItems.filter(({ type }) => type === typeSelected)
            : nameFilteredItems;

        const referenceNameFilteredItems = referenceNameSelected && referenceNameSelected.length > 0
            ? typeFilteredItems.filter(({ referenceName }) => referenceNameSelected.includes(referenceName))
            : typeFilteredItems;

        const referenceTypeFilteredItems = referenceTypeSelected && referenceTypeSelected.length > 0
            ? referenceNameFilteredItems.filter(({ referenceType }) => referenceTypeSelected.includes(referenceType))
            : referenceNameFilteredItems;

        const uploadByFilteredItems = uploadBySelected && uploadBySelected.length > 0
            ? referenceTypeFilteredItems.filter(({ uploadBy }) => uploadBySelected.includes(uploadBy))
            : referenceTypeFilteredItems;

        if(searchFilter){
            const searchFilteredItems = uploadByFilteredItems.filter(( item ) => item.name.toLowerCase().includes(searchFilter.toLowerCase())
                || (item.referenceName && item.referenceName.toLowerCase().includes(searchFilter.toLowerCase()))
                || (item.referenceType && item.referenceType.toLowerCase().includes(searchFilter.toLowerCase()))
            );
            setDisplayedItems(searchFilteredItems);
            setFilteredRepositoriesData(searchFilteredItems);
        } else {
            setDisplayedItems(uploadByFilteredItems);
            setFilteredRepositoriesData(uploadByFilteredItems);
        }
}, [items, nameSelected, typeSelected, referenceNameSelected, referenceTypeSelected, uploadBySelected, searchFilter]);


    useEffect(() => {
        if (items) {
            sortedData();
        }
    }, [items, sortedData]);

    return (
        <>
            <Modal className={styles.modal} isOpen={openModal} onClose={() => setOpenModal(false)} overlayClose={true}>
                <div className={styles.dateCustomContainer}>
                    <p>Select dates:</p>

                    <div className={styles.dateCustomWrap}>
                        <div className={styles.dateCustomAddDates} key="initial-date-dropdown">
                            <p>Initial Date:</p>

                            <Input
                                onChange={(value) => setCustomDates(prev => ({ ...prev, startDate: value }))}
                                type="date"
                                value={customDates.startDate}
                            />
                        </div>

                        <div className={styles.dateCustomAddDates} key="final-date-dropdown">
                            <p>End Date:</p>

                            <Input
                                onChange={(value) => setCustomDates(prev => ({ ...prev, endDate: value }))}
                                type="date"
                                value={customDates.endDate}
                            />
                        </div>
                    </div>

                    <div className={styles.dateCustomAddDatesButtons}>
                        <SubmitButton
                            className={styles.dateCustomAddDatesButton}
                            onClick={applyCustomDateFilter}
                        >
                            Apply
                        </SubmitButton>

                        <SubmitButton
                            className={styles.dateCustomAddDatesButton}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancel
                        </SubmitButton>
                    </div>
                </div>
            </Modal>

            <ContentPanel className={className}>
                <PanelBody className={styles.panelBody} loading={loading}>

                <div className={styles.wrapFirstRowFilters}>
                    <SearchInput
                        className={styles.search}
                        onChange={setSearchFilter}
                        placeholder={SEARCH}
                    />

                    <Dropdown
                        items={dateDropdownOptions}
                        label={DATE}
                        onChange={(value) => {
                            setSelectedDateFilter(value);
                            if(value === 'custom'){
                                setOpenModal(true);
                            }else{
                                setOpenModal(false);
                            }
                        }}
                        returnObject={true}
                        selected={selectedDateFilter}
                    />
                </div>

                <div className={styles.wrapSecondRowFilters}>

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={nameItems}
                        label={NAME}
                        onChange={setNameFilter}
                        selected={nameSelected}
                    />

                    <Dropdown
                        className={styles.dropdown}
                        items={typeItems}
                        label={TYPE}
                        onChange={setTypeFilter}
                        selected={typeSelected}
                    />

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={referenceNameItems}
                        label={REFERENCE_NAME}
                        onChange={setReferenceNameFilter}
                        selected={referenceNameSelected}
                    />

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={referenceTypeItems}
                        label={REFERENCE_TYPE}
                        onChange={setReferenceTypeFilter}
                        selected={referenceTypeSelected}
                    />

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={uploadByItems}
                        label={UPLOAD_BY}
                        onChange={setUploadByFilter}
                        selected={uploadBySelected}
                    />

                </div>

                    <Table className={styles.table}>
                        <DocumentManagementRepositoryTableHeader
                            onSort={({ _isAscending, _sortBy }) => {
                            }}
                            tenantCount={tenants.length}
                        />

                        {displayedItems.map(
                            (
                                {
                                    dateIntroduced,
                                    id,
                                    name,
                                    referenceId,
                                    referenceName,
                                    referenceType,
                                    schema,
                                    type,
                                    uploadBy
                                },
                                index
                            ) => (
                                <DocumentManagementRepositoryTableRow
                                    dateIntroduced={dateIntroduced}
                                    key={`${id}.${index}`}
                                    name={name}
                                    referenceId={referenceId}
                                    referenceName={referenceName}
                                    referenceType={referenceType}
                                    schema={schema}
                                    tenantCount={tenants.length}
                                    type={type}
                                    uploadBy={uploadBy}
                                />
                            )
                        )}
                    </Table>
                </PanelBody>
            </ContentPanel>
        </>
    );
};

export default DocumentManagementPanel;
