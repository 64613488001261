import ComplianceLogTableHeader from 'components/ComplianceLogTableHeader';
import ComplianceLogTableRow from 'components/ComplianceLogTableRow';
import Table from 'components/Table';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const ComplianceLogTable = props => {
    const { entries = [] } = props;

    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    return (
        <Table>
            <ComplianceLogTableHeader
                onSort={({ _isAscending, _sortBy }) => {
                }}
                tenantCount={tenants.length}
            />

            {entries.map(
                ({ id, dateOfOccurrence, description, logSchema, riskLabel, type }) => (
                    <ComplianceLogTableRow
                        description={description}
                        id={id}
                        key={id}
                        logCategory={type}
                        logSchema={logSchema}
                        occurredOn={dateOfOccurrence}
                        riskLevel={riskLabel}
                        tenantCount={tenants.length}
                    />
                )
            )}
        </Table>
    );
};

export default ComplianceLogTable;
