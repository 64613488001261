export const statusFilterLabelValues = {
    completed: 'Completed',
    inProgress: 'Pending',
    overdue: 'Overdue',
    upcoming: 'Upcoming'
};

export function computeStatusFilterValues(
    accumulator = {
        items: {},
        totalCount: 0
    },
    item
) {
    if (item.status) {
        if (!(item.status in accumulator.items)) {
            accumulator.items[item.status] = 0;
        }

        accumulator.items[item.status]++;
        accumulator.totalCount++;
    }

    return accumulator;
}

export function completeActivitiesFilter({
    completeActivities = [],
    entity,
    activityType,
    user,
    recurrency,
    review,
    filterStartDate,
    filterEndDate,
    isFlagged,
    isAll,
    status,
    userId
}) {
    if (status && status.length > 0 && !status.includes('completed')) {
        return [];
    }

    const entityFilteredCompleteActivities = entity && entity.length > 0
        ? completeActivities.filter(({ entities }) =>
              entities.some(ent => entity.includes(ent))
          )
        : completeActivities;

    const typeFilteredCompleteActivities = activityType && activityType.length > 0
        ? entityFilteredCompleteActivities.filter(
                ({ type }) => activityType.includes(type)
            )
        : entityFilteredCompleteActivities;

    const userFilteredCompleteActivities = user && user.length > 0
        ? typeFilteredCompleteActivities.filter(({ assigned }) =>
              assigned.some(assignee => user.includes(`${assignee.firstName} ${assignee.lastName}`))
          )
        : typeFilteredCompleteActivities;

    const recurrencyFilteredCompleteActivities = recurrency && recurrency.length > 0
        ? userFilteredCompleteActivities.filter(
              ({ recurrence: activityRecurrence }) => recurrency.includes(activityRecurrence)
          )
        : userFilteredCompleteActivities;

    const reviewerFilteredCompleteActivities = review && review.length > 0
        ? recurrencyFilteredCompleteActivities.filter(({ reviewer }) =>
            reviewer.some(reviewer => review.includes(`${reviewer.firstName} ${reviewer.lastName}`))
          )
        : recurrencyFilteredCompleteActivities;

    const startDateFilteredCompleteActivities = filterStartDate
        ? reviewerFilteredCompleteActivities.filter(
              ({ closedDate }) => new Date(closedDate) >= filterStartDate
          )
        : reviewerFilteredCompleteActivities;

    const endDateFilteredCompleteActivities = filterEndDate
        ? startDateFilteredCompleteActivities.filter(
              ({ closedDate }) => new Date(closedDate) <= filterEndDate
          )
        : startDateFilteredCompleteActivities;

    const flagFilteredCompleteActivities = !isFlagged
        ? endDateFilteredCompleteActivities.filter(({ flagged }) => flagged)
        : endDateFilteredCompleteActivities;

    const filteredCompleteActivities = isAll
        ? flagFilteredCompleteActivities
        : flagFilteredCompleteActivities.filter(
              ({ assigned, isHierarchical, reviewer }) =>
                  (!isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus === 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status === 'complete') ||
                  (isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      (assigned.filter(x => x.id === Number(userId))[0]
                          .assignmentNumber === 1 ||
                          assigned
                              .filter(
                                  y =>
                                      y.assignmentNumber <
                                      assigned.filter(
                                          x => x.id === Number(userId)
                                      )[0].assignmentNumber
                              )
                              .every(x => x.groupStatus === 'complete')) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus === 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status === 'complete') ||
                  ((assigned.some(({ id }) => id === Number(userId)) ||
                      reviewer.some(({ id }) => id === Number(userId))) &&
                      assigned.every(x => x.groupStatus === 'complete') &&
                      reviewer.some(({ id }) => id === Number(userId))) ||
                  ((assigned.some(({ id }) => id === Number(userId)) ||
                      reviewer.some(({ id }) => id === Number(userId))) &&
                      assigned.every(x => x.groupStatus === 'complete') &&
                      reviewer.every(x => x.groupStatus === 'complete'))
          );

    let sortedCompleteActivities = filteredCompleteActivities;
    try {
        sortedCompleteActivities = sortedCompleteActivities.sort(
            (a, b) => new Date(b.dueDate) - new Date(a.dueDate)
        );
    } catch (error) {
        console.error(error);
    }

    return sortedCompleteActivities;
}

export function inProgressActivitiesFilter({
    inProgressActivities = [],
    entity,
    activityType,
    user,
    recurrency,
    review,
    status,
    isFlagged,
    isAll,
    userId
}) {

    if (status && status.length > 0 && !status.includes('inProgress')) {
        return [];
    }

    const entityFilteredInProgressActivities = entity && entity.length > 0
        ? inProgressActivities.filter(({ entities }) =>
            entities.some(ent => entity.includes(ent))
          )
        : inProgressActivities;

    const typeFilteredInProgressActivities = activityType && activityType.length > 0
        ? entityFilteredInProgressActivities.filter(
              ({ type }) => activityType.includes(type)
          )
        : entityFilteredInProgressActivities;

    const userFilteredInProgressActivities = user && user.length > 0
        ? typeFilteredInProgressActivities.filter(({ assigned }) =>
            assigned.some(assignee => user.includes(`${assignee.firstName} ${assignee.lastName}`))
          )
        : typeFilteredInProgressActivities;

    const reviewerFilteredInProgressActivities = review && review.length > 0
        ? userFilteredInProgressActivities.filter(({ reviewer }) =>
            reviewer.some(reviewer => review.includes(`${reviewer.firstName} ${reviewer.lastName}`))
          )
        : userFilteredInProgressActivities;

    const recurrenceFilteredInProgressActivities = recurrency && recurrency.length > 0
        ? reviewerFilteredInProgressActivities.filter(({ recurrence: activityRecurrence }) => recurrency.includes(activityRecurrence))
        : reviewerFilteredInProgressActivities;
          

    const flagFilteredInProgressActivities = !isFlagged
        ? recurrenceFilteredInProgressActivities.filter(({ flagged }) => flagged)
        : recurrenceFilteredInProgressActivities;

    const filteredInProgressActivities = isAll
        ? flagFilteredInProgressActivities
        : flagFilteredInProgressActivities.filter(
              ({ assigned, isHierarchical, reviewer }) =>
                  (!isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      (assigned.filter(x => x.id === Number(userId))[0]
                          .assignmentNumber === 1 ||
                          assigned
                              .filter(
                                  y =>
                                      y.assignmentNumber <
                                      assigned.filter(
                                          x => x.id === Number(userId)
                                      )[0].assignmentNumber
                              )
                              .every(x => x.groupStatus === 'complete')) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (assigned.every(x => x.groupStatus === 'complete') &&
                      reviewer.some(({ id }) => id === Number(userId)))
          );
    return filteredInProgressActivities;
}

export function upcomingActivitiesFilter({
    upcomingActivities = [],
    entity,
    activityType,
    user,
    recurrency,
    review,
    isFlagged,
    isAll,
    status,
    userId,
    filterStartDate,
    filterEndDate
}) {

    if (status && status.length > 0 && !status.includes('upcoming')) {
        return [];
    }

    const entityFilteredUpcomingActivities = entity && entity.length > 0
        ? upcomingActivities.filter(({ entities }) => entities.some(ent => entity.includes(ent)))
        : upcomingActivities;

    const typeFilteredUpcomingActivities = activityType && activityType.length > 0
        ? entityFilteredUpcomingActivities.filter(
              ({ type }) => activityType.includes(type)
          )
        : entityFilteredUpcomingActivities;

    const userFilteredUpcomingActivities = user && user.length > 0
        ? typeFilteredUpcomingActivities.filter(({ assigned }) =>
            assigned.some(assignee => user.includes(`${assignee.firstName} ${assignee.lastName}`))
          )
        : typeFilteredUpcomingActivities;

    const reviewerFilteredUpcomingActivities = review && review.length > 0
        ? userFilteredUpcomingActivities.filter(({ reviewer }) =>
            reviewer.some(reviewer => review.includes(`${reviewer.firstName} ${reviewer.lastName}`))
          )
        : userFilteredUpcomingActivities;

    const startDateFilteredUpcomingActivities = filterStartDate
        ? reviewerFilteredUpcomingActivities.filter(
              ({ dueDate }) => new Date(dueDate) >= filterStartDate
          )
        : reviewerFilteredUpcomingActivities;

    const endDateFilteredUpcomingActivities = filterEndDate
        ? startDateFilteredUpcomingActivities.filter(
              ({ dueDate }) => new Date(dueDate) <= filterEndDate
          )
        : startDateFilteredUpcomingActivities;

    const recurrenceFilteredUpcomingActivities = recurrency && recurrency.length > 0
        ? endDateFilteredUpcomingActivities.filter(({ recurrence: activityRecurrence }) => recurrency.includes(activityRecurrence))
        : endDateFilteredUpcomingActivities;

    const flagFilteredUpcomingActivities = !isFlagged
        ? recurrenceFilteredUpcomingActivities.filter(({ flagged }) => flagged)
        : recurrenceFilteredUpcomingActivities;

    const filteredUpcomingActivities = isAll
        ? flagFilteredUpcomingActivities
        : flagFilteredUpcomingActivities.filter(
              ({ assigned, isHierarchical, reviewer }) =>
                  (!isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      (assigned.filter(x => x.id === Number(userId))[0]
                          .assignmentNumber === 1 ||
                          assigned
                              .filter(
                                  y =>
                                      y.assignmentNumber <
                                      assigned.filter(
                                          x => x.id === Number(userId)
                                      )[0].assignmentNumber
                              )
                              .every(x => x.groupStatus === 'complete')) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (assigned.every(x => x.groupStatus === 'complete') &&
                      reviewer.some(({ id }) => id === Number(userId)))
          );

    let sortedUpcomingActivities = filteredUpcomingActivities;
    try {
        sortedUpcomingActivities = filteredUpcomingActivities.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
    } catch (error) {
        console.error(error);
    }
    return sortedUpcomingActivities;
}

export function overdueActivitiesFilter({
    overdueActivities = [],
    entity,
    activityType,
    user,
    review,
    isFlagged,
    isAll,
    status,
    userId,
    recurrency,
}) {
    if (status && status.length > 0 && !status.includes('overdue')) {
        return [];
    }

    const entityFilteredOverdueActivities = entity && entity.length > 0
        ? overdueActivities.filter(({ entities }) => entities.some(ent => entity.includes(ent)))
        : overdueActivities;

    const typeFilteredOverdueActivities = activityType && activityType.length > 0
        ? entityFilteredOverdueActivities.filter(
              ({ type }) => activityType.includes(type)
          )
        : entityFilteredOverdueActivities;

    const userFilteredOverdueActivities = user && user.length > 0
        ? typeFilteredOverdueActivities.filter(({ assigned }) =>
            assigned.some(assignee => user.includes(`${assignee.firstName} ${assignee.lastName}`))
          )
        : typeFilteredOverdueActivities;

    const reviewerFilteredOverdueActivities = review && review.length > 0
        ? userFilteredOverdueActivities.filter(({ reviewer }) =>
            reviewer.some(reviewer => review.includes(`${reviewer.firstName} ${reviewer.lastName}`))
          )
        : userFilteredOverdueActivities;

    const recurrenceFilteredOverdueActivities = recurrency && recurrency.length > 0
        ? reviewerFilteredOverdueActivities.filter(({ recurrence: activityRecurrence }) => recurrency.includes(activityRecurrence))
        : reviewerFilteredOverdueActivities;

    const flagFilteredOverdueActivities = !isFlagged
        ? recurrenceFilteredOverdueActivities.filter(({ flagged }) => flagged)
        : recurrenceFilteredOverdueActivities;

    const filteredOverdueActivities = isAll
        ? flagFilteredOverdueActivities
        : flagFilteredOverdueActivities.filter(
              ({ assigned, isHierarchical, reviewer }) =>
                  (!isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (isHierarchical &&
                      assigned.some(({ id }) => id === Number(userId)) &&
                      (assigned.filter(x => x.id === Number(userId))[0]
                          .assignmentNumber === 1 ||
                          assigned
                              .filter(
                                  y =>
                                      y.assignmentNumber <
                                      assigned.filter(
                                          x => x.id === Number(userId)
                                      )[0].assignmentNumber
                              )
                              .every(x => x.groupStatus === 'complete')) &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.groupStatus !== 'complete' &&
                      assigned.filter(x => x.id === Number(userId))[0]
                          ?.status !== 'complete') ||
                  (assigned.every(x => x.groupStatus === 'complete') &&
                      reviewer.some(({ id }) => id === Number(userId)))
          );
    return filteredOverdueActivities;
}
