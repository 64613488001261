import { memo } from 'react';

import BuildPolicyForm from 'components/BuildPolicyForm';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

const BuildPolicyPanel = ({ categories, className = '', fileUploader, form, setSectionCategories, visibleTo }) => (
    <ContentPanel className={className}>
        <PanelBody>
            <BuildPolicyForm categories={categories} fileUploader={fileUploader} form={form} setSectionCategories={setSectionCategories} visibleTo={visibleTo} />
        </PanelBody>
    </ContentPanel>
);

export default memo(BuildPolicyPanel);
