const { useState, useEffect } = require('react');

// TODO: Investigate why this hook is global and why it throws
//       errors.
const useSearchItems = ({ items, key, searchTerm = '' }) => {
    const [searchResults, setSearchResults] = useState(items);

    useEffect(() => {
        const results = items.filter(item =>
            item[key]?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setSearchResults(results);
    }, [items, key, searchTerm]);

    return searchResults;
};

export default useSearchItems;
