import ContentBoxAvatars from 'components/ContentBoxAvatars';
import Menu from 'components/Menu';

import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

const WithTooltipAvatar = props => {
    const { assigned, className = '', children, placement = 'bottom', reviewer=[], section=null } = props;
    const { handleClick, onClose, referenceElement } = usePopperState();

    if([...assigned, ...reviewer].length > 1){
        return (
            <div
                className={`${className}`}
                onMouseEnter={handleClick}
                onMouseLeave={onClose}
            >
                {children}

                {referenceElement && (
                    <Menu className={styles.overlay} placement={placement} referenceElement={referenceElement}>
                        <ContentBoxAvatars
                            assigned={assigned}
                            className={styles.content}
                            reviewer={reviewer}
                            section={section}
                        />
                    </Menu>
                )}
            </div>
        );
    }else{
        return (
            <div
                className={className}
            >
                {children}
            </div>
        );
    }
};

export default WithTooltipAvatar;
