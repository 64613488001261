import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addActivityTypeButton');

    return {
        ADD_ACTIVITY_TYPE: t('addActivityType')
    };
};

export default useTranslation;
