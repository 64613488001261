import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceEntryActivityPanel');

    return {
        ACTIVITY_DETAILS: t('activityDetails'),
        ACTIVITY_NAME: t('activityName'),
        DATE_CLOSED: t('dateClosed'),
        DUE_DATE: t('dueDate'),
        INSTRUCTIONS: t('instructions'),
        RISK_LABEL: t('riskLabel'),
        START_DATE: t('startDate')
    };
};

export default useTranslation;
