import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('adminGroupFormMembersPanel');

    return {
        ADD_MEMBER: t('addMember'),
        GROUP_MEMBERS: t('groupMembers'),
        SEARCH_MEMBERS: t('searchMembers')
    };
};

export default useTranslation;
