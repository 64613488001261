import { useCallback, useRef } from 'react';

import MenuListItems from 'components/MenuListItems';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const AdministrationMarketingSidebar = ({
    className = '',
    onClick,
    activeIndex = 0
}) => {
    const { FORM_SETTINGS, WORKFLOW_SETTINGS } = useTranslation();

    const items = useRef([
        {
            text: FORM_SETTINGS
        },
        {
            text: WORKFLOW_SETTINGS
        }
    ]);

    const handleClick = useCallback(
        (_, index) => {
            onClick(index);
        },
        [onClick]
    );

    return (
        <MenuListItems
            activeItem={activeIndex}
            className={`${className} ${styles.administrationMarketingSidebar}`}
            itemClassName={styles.item}
            items={items.current}
            onClick={handleClick}
        />
    );
};

export default AdministrationMarketingSidebar;
