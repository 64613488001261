import { useNavigate } from 'react-router-dom';

// import CancelButton from 'components/CancelButton';
// import ConfirmApproveRequestModal from 'components/ConfirmApproveRequestModal';
// import PositiveHollowButton from 'components/PositiveHollowButton';

import useConfig from 'hooks/useConfig';

// import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import { useEffect } from 'react';

const ApproveRequestModal = ({ createdById, _onCancel, requestId, requestName, requestSchema, versionId }) => {
    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    // const { APPROVE } = useTranslation();

    const navigate = useNavigate();

    const { MARKETING_REQUESTS_ROUTE } = useConfig();

    const [, approveRequest] = useData(requestSchema);
    
    const handleSubmit = async () => {
        await approveRequest({
            createdById,
            requestId,
            requestName,
            userId,
            versionId
        });

        navigate(MARKETING_REQUESTS_ROUTE)
    };

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default ApproveRequestModal;
