import { useState } from 'react';

import Tabs from 'components/Tabs';
import TabContent from 'components/TabContent';

import styles from './styles.module.scss';

const TabToggle = props => {
    const { children, className = '', items, onTabChange, selectedTab } = props;

    const [selectedValue, setSelectedValue] = useState(selectedTab);

    const handleSelect = value => {
        setSelectedValue(value);
        if (onTabChange) {
            onTabChange(value);
        }
    };

    return (
        <div className={`${className} ${styles.tabToggle}`}>
            <Tabs
                items={items}
                onSelect={handleSelect}
                selectedTab={selectedValue}
            />

            <TabContent>{children(selectedValue)}</TabContent>
        </div>
    );
};

export default TabToggle;
