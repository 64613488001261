import ContentPanel from 'components/ContentPanel';
import HistoryEntries from 'components/HistoryEntries';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

const CaseHistoryPanel = props => {
    const { className = '', history } = props;

    return (
        <ContentPanel className={`${className} ${styles.caseHistoryPanel}`}>
            <PanelBody>
                <HistoryEntries items={history} />
            </PanelBody>
        </ContentPanel>
    );
};

export default CaseHistoryPanel;
