import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('deactivatePolicyVersionModal');

    return {
        ARE_YOU_SURE: t('areYouSure'),
        CANCEL: t('cancel'),
        DEACTIVATE: t('deactivate'),
        YOU_ARE_ABOUT_TO_DEACTIVATE: t('youAreAboutToDeactivate')
    };
};

export default useTranslation;
