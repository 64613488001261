import Pill from 'components/Pill';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';

const CriticalRiskPill = ({ className = '' }) => {
    const { CRITICAL } = useTranslation();

    return (
        <Pill
            className={`${className} ${styles.criticalRiskPill}`}
            label={CRITICAL}
        />
    );
};

export default CriticalRiskPill;
