import { atom } from 'recoil';

const tokenAtom = atom({
    default: '',
    key: 'token'
});

const state = {
    atoms: {
        tokenAtom
    }
};

export default state;
