import { useCallback, useEffect, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import DateWithLabel from 'components/DateWithLabel';
import Dropdowns from 'components/Dropdowns';
import PanelBody from 'components/PanelBody';
import RiskPillWithLabel from 'components/RiskPillWithLabel';
import TextWithLabel from 'components/TextWithLabel';
import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';
import useDropdowns from './hooks/useDropdowns';
import useData from './hooks/useData';

import styles from './styles.module.scss';
import EditableEditor from 'components/EditableEditor';

const ComplianceEntryDetailsPanel = ({ className = '', entry, logSchema }) => {
    const {
        category,
        description,
        dropdownSelections,
        dropdowns,
        occuredOn,
        id,
        riskLevel
    } = entry;

    const {
        DATE_OF_OCCURRENCE,
        DESCRIPTION,
        LOG_CATEGORY,
        LOG_DETAILS,
        RISK_LABEL
    } = useTranslation();

    const [savedTextbox, saveTextbox, reset] = useData(logSchema);

    const [__, updateDropdown] = useDropdowns(dropdowns);

    const [_errorMessage, setErrorMessage] = useState();
    const [savedDescription, setDescription] = useState(description);
    

    useEffect(() => {
        setDescription(description);
    }, [description]);

    const handleDropdownsChange = useCallback(
        ({
            changedDropdownId,
            changedDropdownIndex,
            dropdownSelection,
            formData,
            selectedIndex
        }) => {
            updateDropdown({
                changedDropdownId,
                changedDropdownIndex,
                dropdownSelection,
                formData,
                logId: id,
                selectedIndex
            });
        },
        [id, updateDropdown]
    );

    const handleSave = useCallback(
        ({ form }) => {

            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    policyId: id
                }
            );
            if(formObject?.text !== '' || formObject?.text !== '<p></p>'){
                saveTextbox(formObject);
            }
        },
        [id, saveTextbox]
    );

    useEffect(() => {
        

        if (!savedTextbox) {
            return;
        }

        setDescription(savedTextbox);
        reset();
    }, [reset, savedTextbox, setDescription]);

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={LOG_DETAILS} />

                <TextWithLabel label={LOG_CATEGORY} text={category} />

                <RiskPillWithLabel level={riskLevel} text={RISK_LABEL} />

                <DateWithLabel
                    className={styles.dateOfOccurrence}
                    date={occuredOn}
                    key={`${id}.occurrence`}
                    text={DATE_OF_OCCURRENCE}
                />

                <EditableEditor
                    className={styles.contentBox}
                    key={`${id}.description`}
                    onSave={handleSave}
                    text={savedDescription}
                    title={DESCRIPTION}
                />

                <Dropdowns
                    className={styles.dropdowns}
                    items={dropdowns}
                    key={`${id}.dropdowns`}
                    onChange={handleDropdownsChange}
                    selections={dropdownSelections}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default ComplianceEntryDetailsPanel;
