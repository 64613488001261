import AddPersonButton from 'components/AddPersonButton';
import EmployeesPanel from 'components/EmployeesPanel';
import WithDownloadButton from 'components/WithDownloadButton';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import styles from './styles.module.scss';

const HumanResources = () => {
    const { PEOPLE } = translations();

    return (
        <div className={styles.humanResources}>
            <WithPageTitle title={PEOPLE}>
                <WithDownloadButton hasPDFexport={false}>
                    <AddPersonButton className={styles.addPersonButton} />
                </WithDownloadButton>
            </WithPageTitle>

            <EmployeesPanel className={styles.employeesPanel} />
        </div>
    );
};

export default HumanResources;
