import { useCallback, useState } from 'react';

import InfoHollowButton from 'components/InfoHollowButton';
import RequestUpdateModal from 'components/RequestUpdateModal';

import useTranslation from './hooks/useTranslation';

const RequestUpdateButton = ({ createdById, requestId, requestName, requestSchema, versionId }) => {
    const { REQUEST_UPDATE } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleModalClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <InfoHollowButton onClick={handleClick} text={REQUEST_UPDATE} />

            {isOpen && (
                <RequestUpdateModal
                    createdById={createdById}
                    onCancel={handleModalClose}
                    requestId={requestId}
                    requestName={requestName}
                    requestSchema={requestSchema}
                    versionId={versionId}
                />
            )}
        </>
    );
};

export default RequestUpdateButton;
