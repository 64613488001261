import { memo, /*useCallback, useState*/ } from 'react';

import ActivityForm from 'components/ActivityForm';
// import ActivityFormTable from 'components/ActivityFormTable';
// import ActivityTypeForm from 'components/ActivityTypeForm';
import ContentPanel from 'components/ContentPanel';
import FileUpload from 'components/FileUpload';
import PanelBody from 'components/PanelBody';

import styles from './styles.module.scss';

// const activityFormItems = [
//     {
//         dueDate: '24 Aug 2021',
//         formName: '',
//         recipient: ''
//     }
// ];

// const typeMap = {
//     default: ({ form }) => (
//         <ActivityFormTable
//             className={styles.activityFormTable}
//             form={form}
//             items={activityFormItems}
//         />
//     ),
//     expenseReporting: ({ form }) => <ActivityTypeForm form={form} />
// };

const AddActivityDetailsPanel = ({ addReviewer, assignees, className = '', fileUploader, form, forms, providedSchema, setForms }) => {
    const defaultAssignees = [
        {label: 'Assignee 1', value: 'assignee-1'},
        {label: 'Assignee 2', value: 'assignee-2'},
    ];

    const assigneeArray = [];

    for (let i = 0; i < assignees.length; i++) { 
        assigneeArray.push({label: `Assignee ${i+3}`, value: `assignee-${i+3}`});
    }

    const reviewer = (addReviewer) ? [{label: 'Reviewer', value: 'reviewer'}] : [];

    const allAssignees = defaultAssignees.concat(assigneeArray, reviewer);

    return (
        <ContentPanel
            className={`${className} ${styles.addActivityDetailsPanel}`}
        >
            <PanelBody className={styles.panelBody}>
                <ActivityForm assignees={allAssignees} form={form} forms={forms} setForms={setForms} /*onTypeChange={handleTypeChange}*/ />

                {/* <ActivityComponent form={form} /> */}

                <FileUpload
                    className={styles.fileUpload}
                    filesList={fileUploader}
                    providedSchema={providedSchema}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default memo(AddActivityDetailsPanel);
