const normalizeData = ({ data, schema }) =>
    {
        const groups = data[`${schema}_group`].reduce(
            (accumulator, group) => {
                accumulator.push({
                    id: group?.id,
                    label: group?.name
                })
                return accumulator
            }, []
        );
        const departments = data[`${schema}_department`].reduce(
            (accumulator, department) => {
                accumulator.push({
                    id: department?.id,
                    label: department?.description
                })
                return accumulator
            }, []
        );
        const positions = data[`${schema}_position`].reduce(
            (accumulator, position) => {
                accumulator.push({
                    id: position?.id,
                    label: position?.title
                })
                return accumulator
            }, []
        );

        return { departments, groups, positions }
    }

export default normalizeData;
