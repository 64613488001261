import { memo } from 'react';

import ImportantText from 'components/ImportantText';

import useInitials from './hooks/useInitials';

import styles from './styles.module.scss';

const Initials = ({ className = '', text = '' }) => {
    const initials = useInitials(text);

    return (
        <ImportantText
            className={`${className} ${styles.initials}`}
            text={initials}
        />
    );
};

export default memo(Initials);
