import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administrationMarketingSidebar');

    return {
        FORM_SETTINGS: t('formSettings'),
        WORKFLOW_SETTINGS: t('workflowSettings')
    };
};

export default useTranslation;
