import Tag from 'components/Tag';

import styles from './styles.module.scss';

const UserTag = ({ className = '', onClose, text = '' }) => (
    <Tag
        className={`${className} ${styles.userTag}`}
        onClose={onClose}
        text={text}
    />
);

export default UserTag;
