import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_document`].reduce((accumulator, policies) => {
        const {
            // id,
            name,
            // is_active: isActive,
            // category,
            // tenant_user: createdByUser,
            risk_label: riskLevel,
            description,
            applied_tos: policyAppliedTos,
            policy_sections: policySections = [{activity_policies: [{}], case_policies: [{}], log_policies: [{}]}]
        } = policies;

        const appliedTos = policyAppliedTos.reduce((accumulator, appliedTo) => {
            const { group, tenant_user: tenantUser } = appliedTo;
            if (group !== null){
                accumulator.push({firstName: group?.name, id: group?.id, label: `${group?.name} Group`, lastName: 'Group', type: 'group', value:`${group?.name} Group`});
            } else if (tenantUser !== null){
                const userId = tenantUser?.id;
                const user = tenantUser[`users_${schema}`][0];
                const userName = `${user?.first_name} ${user?.last_name}`
                accumulator.push({firstName: user?.first_name, id: userId, label: userName, lastName: user?.last_name, type: 'user', value:userName});
            }
            return accumulator;
        },[]);

        const sections = policySections.reduce((accumulator, { section }) => {
            const {
                name: sectionName,
                id: sectionId,
                file: sectionFile,
                description: sectionDescription,
                riskLevel: sectionRiskLevel,
                activity_policies: sectionActivities,
                case_policies: sectionCases,
                log_policies: sectionLogs
            } = section;

            const activities = sectionActivities.length > 0 ? sectionActivities.reduce((accumulator, { activity }) => {
                const {
                    name: activityName,
                    risk_label: activityRiskLevel,
                    activity_type: activityTypeObject
                } = activity;
                const { name: activityType } = activityTypeObject;
                accumulator.push({
                    name: activityName,
                    riskLevel: activityRiskLevel,
                    type: activityType
                })
                return accumulator
            },[]) : [];

            const cases = sectionCases.length > 0 ? sectionCases.reduce((accumulator, { case: individualCase }) => {
                const {
                    case_name: caseName,
                    risk_label: caseRiskLevel,
                    caseTypeByCaseType: caseTypeObject
                } = individualCase;
                const { name: caseType } = caseTypeObject;
                accumulator.push({
                    name: caseName,
                    riskLevel: caseRiskLevel,
                    type: caseType
                })
                return accumulator
            },[]) : [];

            const logs = sectionLogs.length > 0 ? sectionLogs.reduce((accumulator, { log }) => {
                const {
                    log_type: logType,
                    risk_label: logRiskLevel,
                } = log;
                accumulator.push({
                    riskLevel: logRiskLevel,
                    type: logType
                })
                return accumulator
            },[]) : [];

            accumulator.push({
                activities,
                cases,
                description: sectionDescription,
                file: sectionFile,
                id: sectionId,
                logs,
                name: sectionName,
                riskLevel: sectionRiskLevel
            });
            return accumulator
        },[])

        // const createdBy = {firstName: createdByUser[`users_${schema}`][0]?.first_name, id: createdByUser?.id, label: `${createdByUser[`users_${schema}`][0]?.first_name} ${createdByUser[`users_${schema}`][0]?.last_name}`, lastName: createdByUser[`users_${schema}`][0]?.last_name, type: 'user', value:`${createdByUser[`users_${schema}`][0]?.first_name} ${createdByUser[`users_${schema}`][0]?.last_name}`};
        
        appliedTos.sort(function(a, b){
            if (a.type < b.type) return 1;
            if (a.type > b.type) return -1;
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        });

        // eslint-disable-next-line
        const accumulatorObject = {name, appliedTos, description, riskLevel, sections}
        accumulator.push(accumulatorObject);

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeData;
