import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useVisibleTo from './hooks/useVisibleTo';
import useTranslation from './hooks/useTranslations';

import BuildPolicyPanel from 'components/BuildPolicyPanel';
import CancelSaveButtons from 'components/CancelSaveButtons';
import WithPageTitle from 'components/WithPageTitle';

import styles from './styles.module.scss';

import normalizeData from './data';

const BuildPolicy = () => {
    const { useSchema, useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const [schema] = useSchema();

    const { BUILD_NEW_POLICY } = useTranslation();

    const { DOCUMENT_MANAGEMENT_ROUTE, INTERNAL_SERVER_ERROR, POLICY_ROUTE } = useConfig();

    const [addedPolicy, addPolicy, reset] = useData();
    const [files, setFiles] = useState({})

    const [visibleTo, setVisibleTo] = useState();

    const [_errorMessage, setErrorMessage] = useState();

    const navigate = useNavigate();

    const form = useForm({
        description: '',
        effectiveDate: '',
        policyName: '',
        visibleTo: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(DOCUMENT_MANAGEMENT_ROUTE);
    }, [DOCUMENT_MANAGEMENT_ROUTE, navigate]);

    const handleSave = useCallback(() => {
        

        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;

                return accumulator;
            },
            {
                createdBy: userId,
                isActive: true,
                isDraft: true,
                isPublished: false,
                modifiedByUserId: userId,
                versionNumber: 1
            }
        );

        formObject['name'] = formObject['policyName'];
        formObject['effectiveDate'] = new Date(formObject['effectiveDate']);
        formObject['file'] = Object.keys(files).length > 0 ? Object.keys(files)[0] : document.getElementById('hyperlinkBox').value;
        addPolicy(formObject);
    }, [addPolicy, files, handleForm, userId]);

    const handleFileUpload = newFiles => {
        if(Object.keys(newFiles).length > 0){
            setFiles(newFiles);
        }
    }

    const { _loading, error, data } = useVisibleTo();

    useEffect(() => {
        

        if (!addedPolicy) {
            console.error('[AddPolicy][useEffect] could not add policy');

            return;
        }
        navigate(POLICY_ROUTE.replace(':policyId', addedPolicy));
    }, [addedPolicy, reset, POLICY_ROUTE, navigate]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            setVisibleTo(normalizedData);
        }
    }, [data, schema, setVisibleTo]);


    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <div className={styles.addPolicy}>
            <WithPageTitle title={BUILD_NEW_POLICY}>
                <CancelSaveButtons
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </WithPageTitle>

            <BuildPolicyPanel fileUploader={handleFileUpload} form={form} visibleTo={visibleTo} />
        </div>
    );
};

export default memo(BuildPolicy);
