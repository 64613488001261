import { format } from 'date-fns';

import AvatarWithTextLink from 'components/AvatarWithTextLink';
// import DetailsButton from 'components/DetailsButton';
// import CalendarIcon from 'components/icons/CalendarIcon';
// import CloseIcon from 'components/icons/CloseIcon';
// import EntryIcon from 'components/icons/EntryIcon';
// import EyeIcon from 'components/icons/EyeIcon';
import ImportantLink from 'components/ImportantLink';
import RiskPill from 'components/RiskPill';
import TableRow from 'components/TableRow';

import useConfig from 'hooks/useConfig';

import WithTooltipAvatar from 'components/WithTooltipAvatar';
import WithTooltipHtml from 'components/WithTooltipHtml';
import { safeGetLocalStorage } from 'utilities/localStorage';
import titleCase from 'utilities/titleCase';
import styles from './styles.module.scss';
// import DownloadIcon from 'components/DownloadIcon';
// import { ClickableArea } from 'components/ClickableArea';

const CaseTableRow = ({
    assignedTo = '',
    caseName = '',
    caseSchema,
    caseType = '',
    className = '',
    closedDate,
    createdDate,
    creationReason,
    dashboard,
    dueDate = '',
    entityTags = [],
    id,
    // item,
    // onDownloadItem,
    originated,
    proposedSolution,
    resolution,
    riskLabel,
    styleType,
    tenantCount = 1,
    usersInvolved
}) => {
    const { CASE_ROUTE } = useConfig();

    var tooltipHTML = originated
        ? originated.length > 100
            ? `<p><b>Originated: </b>${originated.slice(0, 99)}...</p><br/>`
            : `<p><b>Originated: </b>${originated}</p><br/>`
        : '';
    tooltipHTML += creationReason
        ? creationReason.length > 100
            ? `<p><b>Creation Reason: </b>${creationReason.slice(
                  0,
                  99
              )}...</p><br/>`
            : `<p><b>Creation Reason: </b>${creationReason}</p><br/>`
        : '';
    tooltipHTML += proposedSolution
        ? proposedSolution.length > 100
            ? `<p><b>Proposed Solution: </b>${proposedSolution.slice(
                  0,
                  99
              )}...</p><br/>`
            : `<p><b>Proposed Solution: </b>${proposedSolution}</p><br/>`
        : '';
    tooltipHTML += resolution
        ? resolution.length > 100
            ? `<p><b>Resolution: </b>${resolution.slice(0, 99)}...</p>`
            : `<p><b>Resolution: </b>${resolution}</p>`
        : '';

    const newTab = safeGetLocalStorage('openActivitiesAndCasesInNewTab', false);

    if (dashboard) {
        return (
            <TableRow
                className={`${className} ${styles.caseTableRowDashboard} ${
                    styleType === 'overdue'
                        ? styles.overdueHover
                        : styleType === 'pending'
                        ? styles.pendingHover
                        : styleType === 'closed'
                        ? styles.closedHover
                        : styles.defaultHover
                }`}
            >
                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={caseType}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={caseName}
                    to={`${CASE_ROUTE.replace(':caseId', id)}`}
                />

                <ImportantLink
                    newTab={newTab}
                    text={format(new Date(dueDate), 'yyyy-MM-dd')}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <WithTooltipAvatar assigned={assignedTo}>
                    <AvatarWithTextLink
                        assigned={assignedTo}
                        className={styles.link}
                        newTab={newTab}
                        to={`${CASE_ROUTE.replace(':caseId', id).replace(
                            ':caseSchema',
                            caseSchema
                        )}`}
                    />
                </WithTooltipAvatar>

                {/* {onDownloadItem && (
                    <div>
                        <ClickableArea onClick={() => onDownloadItem(item)}>
                            <DownloadIcon fill="black" />
                        </ClickableArea>
                    </div>
                )} */}

                {/* <DetailsButton
                    className={styles.detailsButtonDashboard}
                    items={[
                        {
                            icon: <EyeIcon />,
                            onClick: () => {

                            },
                            text: 'View'
                        },
                        {
                            icon: <CloseIcon />,
                            onClick: () => {

                            },
                            text: 'Close'
                        },
                        {
                            icon: <CalendarIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A New Activity'
                        },
                        {
                            icon: <EntryIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A Log Entry'
                        }
                    ]}
                /> */}

                <></>
            </TableRow>
        );
    } else if (tenantCount > 1) {
        return (
            <TableRow
                className={`${className} ${styles.caseTableRowMultiTenant} ${
                    styleType === 'overdue'
                        ? styles.overdueHover
                        : styleType === 'pending'
                        ? styles.pendingHover
                        : styleType === 'closed'
                        ? styles.closedHover
                        : styles.defaultHover
                }`}
            >
                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={caseType}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <WithTooltipHtml
                    className={styles.overflowTooltip}
                    text={tooltipHTML}
                >
                    <ImportantLink
                        className={styles.text}
                        newTab={newTab}
                        text={caseName}
                        to={`${CASE_ROUTE.replace(':caseId', id).replace(
                            ':caseSchema',
                            caseSchema
                        )}`}
                    />
                </WithTooltipHtml>

                <ImportantLink
                    newTab={newTab}
                    text={format(new Date(dueDate), 'yyyy-MM-dd')}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={closedDate ? Math.round((new Date(closedDate).getTime() - new Date(createdDate).getTime()) / (1000*3600*24)) : Math.round(
                        (new Date().getTime() -
                            new Date(createdDate).getTime()) /
                            (1000 * 3600 * 24)
                    )}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <WithTooltipAvatar assigned={assignedTo}>
                    <AvatarWithTextLink
                        assigned={assignedTo}
                        className={styles.link}
                        newTab={newTab}
                        to={`${CASE_ROUTE.replace(':caseId', id).replace(
                            ':caseSchema',
                            caseSchema
                        )}`}
                    />
                </WithTooltipAvatar>

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={
                        closedDate
                            ? format(new Date(closedDate), 'yyyy-MM-dd')
                            : ''
                    }
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <RiskPill className={styles.riskPill} riskLevel={riskLabel} />

                <AvatarWithTextLink
                    assigned={usersInvolved}
                    className={styles.link}
                    newTab={newTab}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.entities}
                    newTab={newTab}
                    text={caseSchema ? titleCase(caseSchema) : '-'}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.entities}
                    newTab={newTab}
                    text={entityTags.join(', ')}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                {/* {onDownloadItem && (
                    <div>
                        <ClickableArea onClick={() => onDownloadItem(item)}>
                            <DownloadIcon fill="black" />
                        </ClickableArea>
                    </div>
                )} */}

                {/* <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <EyeIcon />,
                            onClick: () => {

                            },
                            text: 'View'
                        },
                        {
                            icon: <CloseIcon />,
                            onClick: () => {

                            },
                            text: 'Close'
                        },
                        {
                            icon: <CalendarIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A New Activity'
                        },
                        {
                            icon: <EntryIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A Log Entry'
                        }
                    ]}
                /> */}

                <></>
            </TableRow>
        );
    } else {
        return (
            <TableRow
                className={`${className} ${styles.caseTableRow} ${
                    styleType === 'overdue'
                        ? styles.overdueHover
                        : styleType === 'pending'
                        ? styles.pendingHover
                        : styleType === 'closed'
                        ? styles.closedHover
                        : styles.defaultHover
                }`}
            >
                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={caseType}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <WithTooltipHtml
                    className={styles.overflowTooltip}
                    text={tooltipHTML}
                >
                    <ImportantLink
                        className={styles.text}
                        newTab={newTab}
                        text={caseName}
                        to={`${CASE_ROUTE.replace(':caseId', id).replace(
                            ':caseSchema',
                            caseSchema
                        )}`}
                    />
                </WithTooltipHtml>

                <ImportantLink
                    newTab={newTab}
                    text={format(new Date(dueDate), 'yyyy-MM-dd')}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={closedDate ? Math.round((new Date(closedDate).getTime() - new Date(createdDate).getTime()) / (1000*3600*24)) : Math.round(
                        (new Date().getTime() -
                            new Date(createdDate).getTime()) /
                            (1000 * 3600 * 24)
                    )}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <WithTooltipAvatar assigned={assignedTo}>
                    <AvatarWithTextLink
                        assigned={assignedTo}
                        className={styles.link}
                        newTab={newTab}
                        to={`${CASE_ROUTE.replace(':caseId', id).replace(
                            ':caseSchema',
                            caseSchema
                        )}`}
                    />
                </WithTooltipAvatar>

                <ImportantLink
                    className={styles.text}
                    newTab={newTab}
                    text={
                        closedDate
                            ? format(new Date(closedDate), 'yyyy-MM-dd')
                            : ''
                    }
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <RiskPill className={styles.riskPill} riskLevel={riskLabel} />

                <AvatarWithTextLink
                    assigned={usersInvolved}
                    className={styles.link}
                    newTab={newTab}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                <ImportantLink
                    className={styles.entities}
                    newTab={newTab}
                    text={entityTags.join(', ')}
                    to={`${CASE_ROUTE.replace(':caseId', id).replace(
                        ':caseSchema',
                        caseSchema
                    )}`}
                />

                {/* {onDownloadItem && (
                    <div>
                        <ClickableArea onClick={() => onDownloadItem(item)}>
                            <DownloadIcon fill="black" />
                        </ClickableArea>
                    </div>
                )} */}

                {/* <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <EyeIcon />,
                            onClick: () => {

                            },
                            text: 'View'
                        },
                        {
                            icon: <CloseIcon />,
                            onClick: () => {

                            },
                            text: 'Close'
                        },
                        {
                            icon: <CalendarIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A New Activity'
                        },
                        {
                            icon: <EntryIcon />,
                            onClick: () => {

                            },
                            text: 'Close And Create A Log Entry'
                        }
                    ]}
                /> */}

                <></>
            </TableRow>
        );
    }
};

export default CaseTableRow;
