import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import AddActivityTypeButton from 'components/AddActivityTypeButton';
// import AddGroupButton from 'components/AddGroupButton';
import AdministrationActivityTypesTab from 'components/AdministrationActivityTypesTab';
import AdministrationAdditionalSettingsTab from 'components/AdministrationAdditionalSettingsTab';
import AdministrationCaseTypesTab from 'components/AdministrationCaseTypesTab';
// import AdministrationEmailManagementTab from 'components/AdministrationEmailManagementTab';
// import AdministrationGroupsTab from 'components/AdministrationGroupsTab';
import AdministrationLogTypesTab from 'components/AdministrationLogTypesTab';
import AdministrationMarketingTab from 'components/AdministrationMarketingTab';
// import AdministrationRolesTab from 'components/AdministrationRolesTab';
import TabToggle from 'components/TabToggle';
import WithPageTitle from 'components/WithPageTitle';

import useTranslation from './hooks/useTranslation';
import {usePermissions} from 'hooks/usePermissions';

import styles from './styles.module.scss';

const ACTIVITY_TYPE_PERMISSION_CREATE = 'activityType:create';

const Administration = () => {
    const {
        ACTIVITY_TYPES,
        // ADDITIONAL_SETTINGS,
        ADMINISTRATION,
        CASE_TYPES,
        // EMAIL_MANAGEMENT,
        // GROUPS,
        LOG_TYPES,
        MARKETING,
        // ROLES
    } = useTranslation();

    const {hasPermissions} = usePermissions();

    const [currentTab, setCurrentTab] = useState('activityTypes')

    const hasPermissionToAddActivityType = hasPermissions([ACTIVITY_TYPE_PERMISSION_CREATE]);

    const {
        // ADD_GROUP,
        ADD_ACTIVITY_TYPE
    } = useConfig();

    const navigate = useNavigate();

    // const urlParams = new URLSearchParams(window.location.search);
    // const groupTypeFilter = urlParams.get('filter')

    // const handleAddGroup = useCallback(() => {
    //     navigate(ADD_GROUP);
    // }, [ADD_GROUP, navigate]);

    const handleAddActivityType = useCallback(() => {
        navigate(ADD_ACTIVITY_TYPE);
    }, [ADD_ACTIVITY_TYPE, navigate]);

    const tabMap = {
        activityTypes: () => <AdministrationActivityTypesTab />,
        additionalSettings: () => <AdministrationAdditionalSettingsTab />,
        caseTypes: () => <AdministrationCaseTypesTab />,
        // emailManagement: () => <AdministrationEmailManagementTab />,
        // groups: () => <AdministrationGroupsTab />,
        logTypes: () => <AdministrationLogTypesTab />,
        marketing: () => <AdministrationMarketingTab />,
        // roles: () => <AdministrationRolesTab />
    };

    const tabs = useRef([
        // {
        //     label: GROUPS,
        //     value: 'groups'
        // },
        // {
        //     label: ROLES,
        //     value: 'roles'
        // },
        {
            label: ACTIVITY_TYPES,
            value: 'activityTypes'
        },
        {
            label: CASE_TYPES,
            value: 'caseTypes'
        },
        // {
        //     label: EMAIL_MANAGEMENT,
        //     value: 'emailManagement'
        // },
        {
            label: LOG_TYPES,
            value: 'logTypes'
        },
        {
            label: MARKETING,
            value: 'marketing'
        },
        // {
        //     label: ADDITIONAL_SETTINGS,
        //     value: 'additionalSettings'
        // }
    ]).current;

    return (
        <div className={styles.administration}>
            <WithPageTitle title={ADMINISTRATION} >
                {/* {currentTab === 'groups' &&
                    <AddGroupButton 
                    className={styles.addGroupButton}
                    onClick={handleAddGroup}
                    />                    
                } */}

                {currentTab === 'activityTypes' && hasPermissionToAddActivityType &&
                    <AddActivityTypeButton 
                    className={styles.addGroupButton}
                    onClick={handleAddActivityType}
                    />                    
                }
            </WithPageTitle>

            <TabToggle items={tabs} selectedTab={currentTab}>
                {selectedTab => {
                    const TabComponent = tabMap[selectedTab];
                    setCurrentTab(selectedTab)

                    return <TabComponent />;
                }}
            </TabToggle>
        </div>
    );
};

export default Administration;