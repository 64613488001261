import ModalWithIcon from 'components/ModalWithIcon';
import DocumentsIcon from 'components/icons/DocumentsIcon';

const DocumentsModal = ({ children, onClose }) => (
    <ModalWithIcon onClose={onClose}>
        <DocumentsIcon />

        {children}
    </ModalWithIcon>
);

export default DocumentsModal;
