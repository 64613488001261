import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementSectionsTableHeader');

    return {
        CATEGORIES: t('categories'),
        COMPANY: t('company'),
        CURRENT_VERSION: t('currentVersion'),
        DOCUMENT_TAGS: t('documentTags'),
        DOCUMENTS: t('documents'),
        LAST_REVIEWED_DATE: t('lastReviewedDate'),
        PUBLISHED: t('published'),
        RISK_LEVEL: t('riskLevel'),
        SECTION_NAME: t('sectionName'),
        STATUS: t('status')
    };
};

export default useTranslation;
