import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyInformation');

    return {
        EFFECTIVE_DATE: t('effectiveDate')
    };
};

export default useTranslation;
