import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { addFormAnswer } = state;

const { addFormAnswerAtom } = addFormAnswer.atoms;

const MUTATE_ACTIVITY = tenantSchema => gql`
mutation insertActivityFormAnswer($activityId: Int!, $answer: String!, $formId: Int!, $questionId: Int!, $userId: Int!) {
    insert_${tenantSchema}_activity_form_answers(objects: {
        activity_id: $activityId,
        form_id: $formId,
        user_id: $userId,
        question_id: $questionId,
        answer: $answer
    }) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_ACTIVITY, providedSchema);

    const createFormAnswer = useRecoilCallback(({ set }) => formAnswer => {
        const runMutation = async (formAnswer) => {
            for (const answer of formAnswer?.answers){
              await mutation({
                  activityId: formAnswer?.activityId,
                  answer: answer?.answer,
                  formId: formAnswer?.formId,
                  questionId: answer?.questionId,
                  userId: formAnswer?.userId,
                }
              );
            }

            set(addFormAnswerAtom, 'complete');
        };

        runMutation(formAnswer);
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addFormAnswerAtom, '');
    });

    return [useRecoilValue(addFormAnswerAtom), createFormAnswer, reset];
};

export default useData;
