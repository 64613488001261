import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import CancelButton from 'components/CancelButton';
import PageTitle from 'components/PageTitle';

import translations from './constants';

import styles from './styles.module.scss';

const AddLogEntry = () => {
    const { COMPLIANCE_LOG_ENTRY } = translations();

    const { COMPLIANCE_LOG_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleCancel = useCallback(() => {
        navigate(COMPLIANCE_LOG_ROUTE);
    }, [COMPLIANCE_LOG_ROUTE, navigate]);

    return (
        <div className={styles.addLogEntry}>
            <PageTitle
                className={styles.pageTitle}
                text={COMPLIANCE_LOG_ENTRY}
            />

            <CancelButton
                className={styles.cancelButton}
                onClick={handleCancel}
            />
        </div>
    );
};

export default AddLogEntry;
