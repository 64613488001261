import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { updateRecurrenceEndDateModal } = state;

const { updateRecurrenceEndDateModalAtom } = updateRecurrenceEndDateModal.atoms;

const MUTATE_UPDATE_ACTIVITIES = tenantSchema => gql`
mutation updateActivity(
    $id: Int!,
    $dueDate: timestamp!) {
    update_${tenantSchema}_activity(
        where: { id: { _eq: $id } },
        _set: { due_date: $dueDate }
    ) {
        returning {
            id
        }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_UPDATE_ACTIVITIES, providedSchema);

    const updateActivities = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            for(const recurrence of variables?.recurrences){
                const newDueDate = new Date(new Date(recurrence?.dueDate).getTime() + parseInt(variables?.dateDiff)*24*60*60*1000)
                if(variables?.all || newDueDate>new Date()){
                    console.log({dueDate:newDueDate, id:recurrence?.id});
                    await mutation({dueDate:newDueDate, id:recurrence?.id});
                }
            }
            set(updateRecurrenceEndDateModalAtom, true);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateRecurrenceEndDateModalAtom, false);
    });

    return [useRecoilValue(updateRecurrenceEndDateModalAtom), updateActivities, reset];
};

export default useData;
