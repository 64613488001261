import { memo, useCallback, useEffect, useState } from 'react';

import RadioButtonWithLabel from 'components/RadioButtonWithLabel';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const RadioButtons = ({ className = '', items = [], onChange }) => {
    const [selectedRadioButton, setSelectedRadioButton] = useState(-1);

    useEffect(() => {
        if (items.map(item => item.isChecked).indexOf(true) > -1) {
            setSelectedRadioButton(items.map(item => item.isChecked).indexOf(true));
        }
    }, [items])

    const handleChange = useCallback(
        (radioButtonLabel, index) => event => {
            if (index === selectedRadioButton) {
                setSelectedRadioButton(-1);
            } else {
                setSelectedRadioButton(index);
            }

            if (onChange && isFunction(onChange)) {
                onChange(event, radioButtonLabel);
            }
        },
        [onChange, selectedRadioButton]
    );

    return (
        <div className={`${className} ${styles.radioButtons}`}>
            {items.map(
                (
                    {
                        className: radioButtonWithLabelClass = '',
                        isDisabled,
                        name,
                        text,
                        value
                    },
                    index
                ) => (
                    <RadioButtonWithLabel
                        className={radioButtonWithLabelClass}
                        isChecked={index === selectedRadioButton}
                        isDisabled={isDisabled}
                        key={text + index}
                        name={`${name}.${index}`}
                        onChange={isDisabled ? undefined : handleChange(value, index)}
                        text={text}
                        value={value}
                    />
                )
            )}
        </div>
    );
};

export default memo(RadioButtons);
