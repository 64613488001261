import { useQuery, gql } from '@apollo/client';

const queryUsersAndEntities = tenantSchema => gql`
    query getUsersAndEntities {
        ${tenantSchema}_log_types {
            id
            name
        }
    }
`;

const useData = schema =>
    useQuery(queryUsersAndEntities(schema), { fetchPolicy: 'no-cache' });

export default useData;
