import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
        ${tenantSchema}_document(order_by: {name: asc}) {
            name
            id
            policy_sections(order_by: {section: {name: asc}}, where: {deleted: {_is_null: true}}) {
                section {
                    id
                    name
                }
            }
        }
        ${tenantSchema}_activity_type {
            id
            name
        }
        ${tenantSchema}_form(where: {is_active: {_eq: true}}) {
            id
            name
        }
        ${tenantSchema}_user {
            tenant_user_id
            first_name
            last_name
        }
        ${tenantSchema}_group {
            name
            id
        }
        ${tenantSchema}_entity {
            id
            name
        }
`;

const useDataQuery = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const queryText = generateQuery(query, schema, tenants);
    return useQuery(queryText, { fetchPolicy: 'no-cache' });
};

export default useDataQuery;
