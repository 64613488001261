import CloseButton from 'components/CloseButton';

import styles from './styles.module.scss';

const Tag = ({ className = '', onClose, text = '' }) => (
    <div className={`${className} ${styles.tag}`}>
        {text}

        <CloseButton className={styles.closeButton} onClick={onClose} />
    </div>
);

export default Tag;
