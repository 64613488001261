import { atom } from 'recoil';

const addGroupAtom = atom({
    default: '',
    key: 'addGroup'
});

const state = {
    atoms: {
        addGroupAtom
    }
};

export default state;
