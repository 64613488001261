import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('activityTabs');

    return {
        DETAILS: t('details'),
        HISTORY: t('history'),
        META: t('meta')
    };
};

export default useConstants;
