import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('uploadPolicyFromFileListItem');

    return {
        UPLOAD: t('upload')
    };
};

export default useTranslation;
