import { useEffect, useState } from 'react';
import CountChart from 'components/charts/chartElements/CountChart';
import DoughnutChart from 'components/charts/chartElements/DoughnutChart';
import HorizontalBarChart from 'components/charts/chartElements/HorizontalBarChart';
import PieChart from 'components/charts/chartElements/PieChart';
import PolarAreaChart from 'components/charts/chartElements/PolarAreaChart';
import RadarChart from 'components/charts/chartElements/RadarChart';
import ScatterChart from 'components/charts/chartElements/ScatterChart';
import Title from 'components/Title';
import VerticalBarChart from 'components/charts/chartElements/VerticalBarChart';
import titleCase from 'utilities/titleCase';

import styles from './styles.module.scss';


const reduceArray = (accumulator, datum, column) => { 
    if(typeof datum[column] !== "object"){
        if(datum[column] in accumulator){
            accumulator[datum[column]] += 1;
        }else{
            accumulator[datum[column]] = 1;
        }
    }else if(Array.isArray(datum[column])){
        if(datum[column].length in accumulator){
            accumulator[datum[column].length] += 1;
        }else{
            accumulator[datum[column].length] = 1;
        }
    }
}

const Chart = ({ alternativeTitle=null, className = '', column, data, label, type }) => {
    const [chartData, setChartData] = useState();

    useEffect(() => {
        if (data && column) {
            const labelsAndData = data.reduce(
                (accumulator, datum) => {
                    if(column.length === 1){
                        reduceArray(accumulator, datum, column)
                    }else if(column.length === 2){
                        if(Array.isArray(datum[column[0]])){
                            const subResults = datum[column[0]].reduce((accumulator, subDatum) => {
                                reduceArray(accumulator, subDatum, column[1]);
                                return accumulator
                            }, {})
                            for (var key of Object.keys(subResults)){
                                if(key in accumulator){
                                    accumulator[key] += subResults[key];
                                }else{
                                    accumulator[key] = subResults[key];
                                }
                            }
                        }
                    }
                    return accumulator;
                },{}
            );
            const colors = Object.keys(labelsAndData).reduce(
                (accumulator, _) => {
                    accumulator.push(`rgba(${Math.floor(Math.random() * 255) + 1}, ${Math.floor(Math.random() * 255) + 1}, ${Math.floor(Math.random() * 255) + 1}, 0.5)`)
                    return accumulator;
                },[]
            );
            const chartData = {
                datasets: [
                    {
                        backgroundColor: colors,
                        borderWidth: 1,
                        data: Object.values(labelsAndData),
                        label: label,
                    },
                ],
                labels: Object.keys(labelsAndData),
            };

            setChartData(chartData)
        }
    }, [data, column, label]);

    const chartMap = {
        count: CountChart,
        doughnut: DoughnutChart,
        horizontalBar: HorizontalBarChart,
        pie: PieChart,
        polar: PolarAreaChart,
        radar: RadarChart,
        scatter: ScatterChart,
        verticalBar: VerticalBarChart
    };

    const ChartComponent = chartMap[type];
    const chartTitle = alternativeTitle !== null ? alternativeTitle : titleCase(column.join(' '));

    return (
        <div className={styles.centerChart}>
            {chartData &&
                <span className={styles.centerChart}>
                    <Title className={styles.chartTitle} text={chartTitle} />

                    <ChartComponent className={className} data={chartData} label={column} title={label} />
                </span>
            }
        </div>
    );
};

export default Chart;
