const translations = {
    company: 'Company',
    dueDate: 'Due Date',
    frequency: 'Frequency',
    hierarchy: 'Hierarchy',
    sendEmail: 'Send Email Updates',
    startDate: 'Start Date',
    usersInvolved: 'Users Involved',
    visibleBy: 'Visible By'
};

export default translations;
