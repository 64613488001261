import Title from 'components/Title';

import styles from './styles.module.scss';

const WithSectionTitle = props => {
    const { className = '', children, text = '' } = props;

    return (
        <div className={`${className} ${styles.withSectionTitle}`}>
            <Title className={styles.title} text={text} />

            {children}
        </div>
    );
};

export default WithSectionTitle;
