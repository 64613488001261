// import { useEffect } from 'react';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { activityDetailsPanel } = state;

const { activityDetailsPanelAttachmentsAtom } = activityDetailsPanel.atoms;

const DELETE_ATTACHMENT = tenantSchema => gql`
    mutation delete_attachment($attachmentId: Int!) {
        update_${tenantSchema}_activity_attachment(
            where: { id: { _eq: $attachmentId } },
            _set: { deleted: true }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_ATTACHMENT = tenantSchema => gql`
    mutation insertActivityAttachment(
        $activityId: Int!, 
        $filename: String!,
        $name: String!
    ) {
        insert_${tenantSchema}_activity_attachment(objects: {
            file: $filename,
            activity_id: $activityId,
            name: $name
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_dropdown($activityId: Int!, $color: String = "yellow", $message: String = "changed a dropdown", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const UPDATE_ATTACHMENT = tenantSchema => gql`
    mutation update_attachment($attachmentId: Int!, $newName: String!) {
        update_${tenantSchema}_activity_attachment(
            where: { id: { _eq: $attachmentId } },
            _set: { name: $newName }
        ) {
            returning {
                id
            }
        }
    }
`;

const useAttachments = providedSchema => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();
    const deleteAttachmentMutation = useGraphQL(DELETE_ATTACHMENT, providedSchema);
    const mutation = useGraphQL(MUTATE_ACTIVITY_ATTACHMENT, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);
    const updateAttachmentMutation = useGraphQL(UPDATE_ATTACHMENT, providedSchema);

    const createAttachment = useRecoilCallback(({ set }) => attachments => {
        const runMutation = async () => {
            const newAttachment = []
            for (let [key, value] of Object.entries(attachments.files)) {
                if(!value?.isUploadError){
                    var name;
                    var url;
                    if(!key.includes('http')){
                        const namePrefix = key.slice(0, key.lastIndexOf('_')).replaceAll('_', ' ').trim();
                        const fileExtension = key.slice(key.lastIndexOf('.') + 1);
                        name = `${namePrefix}.${fileExtension}`;
                        url = key;
                    }else{
                        var count = (key.match(/http/g) || []).length;
                        var nameString;
                        var urlString;
                        if(count > 1){
                            nameString = key.split('/').slice(2).join('/');
                            urlString = key.split('/').slice(2).join('/');
                        }else{
                            nameString = key;
                            urlString = key;
                        }
                        if(key.replaceAll('/', '').length > (key.length - 3)){
                            name = nameString;
                            url = urlString;
                        }else{
                            name = nameString.slice(0, nameString.indexOf('/', 9));
                            url = urlString;
                        }
                    }
                    const response = await mutation({
                        activityId: attachments?.id,
                        filename: key,
                        name
                    });
                    await mutationActivityHistory({
                        activityId: attachments?.id,
                        message: `uploaded new file: ${key}`,
                        userId
                    })

                    const id = response.data[`insert_${providedSchema}_activity_attachment`].returning[0].id;
                    newAttachment.push({
                        file: key,
                        id,
                        name,
                        uploaded_timestamp: new Date().toISOString(),
                        url
                    });
                }
            }

            set(activityDetailsPanelAttachmentsAtom, newAttachment);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(activityDetailsPanelAttachmentsAtom, false);
    });

    const deleteAttachment = async params => {
        const { activityId, attachmentId, filename } = params;
        await deleteAttachmentMutation({ attachmentId });

        await mutationActivityHistory({
            activityId,
            message: `deleted file: ${filename}`,
            userId
        });
    };

    const updateAttachment = async params => {
        const { activityId, attachmentId, filename, newName } = params;
        await updateAttachmentMutation({ attachmentId, newName });

        await mutationActivityHistory({
            activityId,
            message: `updated filename of: ${filename}`,
            userId
        });
    };

    return [
        useRecoilValue(activityDetailsPanelAttachmentsAtom),
        createAttachment,
        reset,
        deleteAttachment,
        updateAttachment
    ];
};

export default useAttachments;
