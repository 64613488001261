import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { entitiesAtom } = state.entities.atoms;

const useEntities = () => [
    useRecoilValue(entitiesAtom),
    useSetRecoilState(entitiesAtom)
];

export default useEntities;
