import { useCallback, useEffect, useState } from 'react';

import AddCaseQuestions from 'components/AddCaseQuestions';
import AddNewFormButton from 'components/AddNewFormButton';
import Attachments from 'components/Attachments';
import Comments from 'components/Comments';
import ContentPanel from 'components/ContentPanel';
import Dropdowns from 'components/Dropdowns';
import EditableMultiSelectWithLabel from 'components/EditableMultiSelectWithLabel';
import EditableTextWithLabel from 'components/EditableTextWithLabel';
import FileUpload from 'components/FileUpload';
import ImportantLink from 'components/ImportantLink';
import ImportantLinkInNewTab from 'components/ImportantLinkInNewTab';
import PanelBody from 'components/PanelBody';
import RiskPill from 'components/RiskPill';
import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import SectionWithBorder from 'components/SectionWithBorder';
import WithLabel from 'components/WithLabel';
import WithTooltip from 'components/WithTooltip';

// import useForm from 'hooks/useForm';
import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useCaseName from './hooks/useCaseName';
import useCreationReason from './hooks/useCreationReason';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import { useComment } from './hooks/useComment';
import useDropdowns from './hooks/useDropdowns';
import usePolicies from './hooks/usePolicies';
import usePolicyReference from './hooks/usePolicyReference';
import useRiskLevel from './hooks/useRiskLevel';

import useActionPlan from './hooks/useActionPlan';
import useProposedSolution from './hooks/useProposedSolution';
import useResolution from './hooks/useResolution';

import styles from './styles.module.scss';

import normalizePolicyData from './dataPolicies';
import EditableEditor from 'components/EditableEditor';

const CaseDetailsPanel = ({
    attachments,
    attachmentsOnDelete,
    attachmentsOnUpdate,
    className = '',
    caseSchema,
    cases = {},
    handleFileUpload,
    isCreatingPDF,
}) => {
    const {
        actionPlan,
        activityCase,
        answers,
        caseName,
        closed,
        comments = [],
        dropdownSelections,
        dropdowns,
        forms,
        id,
        proposedSolution,
        resolution,
        riskLabel
    } = cases;

    // const form = useForm({
    //     riskLevel: '',
    // });
    // const { handleValid } = form;

    const { /*type,*/ policyReference, activity, creationReason } =
        activityCase || {};

    /**
     * Note: Shape of policyReference -
     * {
     *      description: 'No associated policies',
     *      id: 0,
     *      name: 'No associated policies',
     *      riskLevel: 'neutral'
     * }
     */

    const { ACTIVITY_ROUTE, POLICY_ROUTE } = useConfig();

    const {
        ACTION_PLAN,
        ACTIVITY_REFERENCE,
        ADD_ACTION_PLAN,
        ADD_PROPOSED_SOLUTION,
        ADD_RESOLUTION,
        CASE_CREATION_REASON,
        CASE_NAME,
        // CASE_TYPE,
        POLICY_REFERENCE,
        PROPOSED_SOLUTION,
        RESOLUTION,
        RISK_LEVEL_CASE
    } = useTranslation();

    const { useSchema, useTenants, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const {___, data} = useData();
    const [__, updateDropdown] = useDropdowns(dropdowns);
    const [_, createComment, deleteComment, updateComment] = useComment(id, caseSchema);
    const [policies, setPolicies] = usePolicies();

    const [savedCaseName, saveCaseName, resetCaseName] = useCaseName(caseSchema);
    const [savedCreationReason, saveCreationReason, resetCreationReason] = useCreationReason(caseSchema);
    const [savedPolicyReference, savePolicyReference, resetPolicyReference] = usePolicyReference(caseSchema);

    const [savedActionPlan, saveActionPlan, resetSaveActionPlan] = useActionPlan(caseSchema);
    const [savedProposedSolution, saveProposedSolution, resetSaveProposedSolution] = useProposedSolution(caseSchema);
    const [savedResolution, saveResolution, resetSaveResolution] = useResolution(caseSchema);

    const [_errorMessage, setErrorMessage] = useState();
    const [caseComments, setCaseComments] = useState(comments);
    const [displayedCaseName, setDisplayedCaseName] = useState(caseName);
    const [displayedCreationReason, setDisplayedCreationReason] = useState(creationReason);
    const [displayedPolicyReference, setDisplayedPolicyReference] = useState(policyReference);
    const [selectedRiskLabel, setSelectedRiskLabel] = useState(riskLabel);
    const [updatedRiskLevel, updateRiskLevel, resetUpdateRiskLevel] = useRiskLevel();

    const [displayedActionPlan, setDisplayedActionPlan] = useState(actionPlan);
    const [displayedProposedSolution, setDisplayedProposedSolution] = useState(proposedSolution);
    const [displayedResolution, setDisplayedResolution] = useState(resolution);
    const [expandActionPlanForm, setExpandActionPlanForm] = useState(false);
    const [expandProposedSolutionForm, setExpandProposedSolutionForm] = useState(false);
    const [expandResolutionForm, setExpandResolutionForm] = useState(false);

    useEffect(() => {
        if (data) {
            const normalizedPolicyData = normalizePolicyData({ data, schema:caseSchema, tenants });

            setPolicies([].concat.apply([], normalizedPolicyData));
        }
    }, [caseSchema, data, schema, setPolicies, tenants]);

    const handleCommentSubmit = useCallback(
        async formData => {
            const addedComment = formData.get('comment');

            const commentId = await createComment(id, addedComment);

            const commentDate = new Date();

            const newComment = {
                content: addedComment,
                createdAt: commentDate.toISOString().slice(0, -1),
                id: commentId,
                user: {
                    id: userId,
                    name: `${localStorage.firstName} ${localStorage.lastName}`
                }
            };

            const newComments = [...caseComments, newComment].sort(
                (objA, objB) => (objA.createdAt < objB.createdAt ? 1 : -1)
            );

            setCaseComments(newComments);


        },
        [caseComments, createComment, id, userId]
    );

    const handleDeleteComment = async commentId => {
        await deleteComment(commentId);
        setCaseComments(
            caseComments.map(comment =>
                comment.id === commentId
                    ? { ...comment, deleted: true }
                    : comment
            )
        );
    };

    const handleUpdateComment = async params => {
        const { commentId, newText } = params;
        await updateComment(commentId, newText);
        setCaseComments(
            caseComments.map(comment =>
                comment.id === commentId
                    ? { ...comment, content: newText }
                    : comment
            )
        );
    };

    const handleDropdownsChange = useCallback(
        ({
            changedDropdownId,
            changedDropdownIndex,
            dropdownSelection,
            formData,
            selectedIndex
        }) => {
            updateDropdown({
                caseId: id,
                changedDropdownId,
                changedDropdownIndex,
                dropdownSelection,
                formData,
                selectedIndex
            });
        },
        [id, updateDropdown]
    );

    const handleCreationReasonSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            if(formObject?.text !== ''){
                saveCreationReason(formObject);
            }
        },
        [id, saveCreationReason, userId]
    );

    useEffect(() => {
        if (!savedCreationReason) {
            return;
        }

        setDisplayedCreationReason(savedCreationReason);
        resetCreationReason();
    }, [resetCreationReason, savedCreationReason, setDisplayedCreationReason]);

    const handleCaseNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );

            if(formObject?.text !== ''){
                saveCaseName(formObject);
            }
    }, [id, saveCaseName, userId]);

    useEffect(() => {
        if (!savedCaseName) {
            return;
        }

        setDisplayedCaseName(savedCaseName);
        resetCaseName();
    }, [resetCaseName, savedCaseName, setDisplayedCaseName]);


    const handlePolicyReferenceSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            formObject['policies'] = JSON.parse(formObject.selected)

            savePolicyReference(formObject);
        },
        [id, savePolicyReference, userId]
    );

    useEffect(() => {
        if (!savedPolicyReference) {
            return;
        }

        setDisplayedPolicyReference(savedPolicyReference);
        resetPolicyReference();
    }, [resetPolicyReference, savedPolicyReference, setDisplayedPolicyReference]);

    const handleSetRiskLevel = useCallback((clickedRiskLevel) => {
        const updatedRiskLevel = {};

        if (selectedRiskLabel === clickedRiskLevel) {
            updatedRiskLevel.caseId = id;
            updatedRiskLevel.riskLevel = null;
            updatedRiskLevel.userId = userId;
        } else {
            updatedRiskLevel.caseId = id;
            updatedRiskLevel.riskLevel = clickedRiskLevel;
            updatedRiskLevel.userId = userId;
        }

        updateRiskLevel(updatedRiskLevel);

        return setSelectedRiskLabel(selectedRiskLabel === clickedRiskLevel ? null : clickedRiskLevel);
    }, [id, selectedRiskLabel, setSelectedRiskLabel, updateRiskLevel, userId]);

    useEffect(() => {
        if (!updatedRiskLevel) {
            return;
        }

        resetUpdateRiskLevel();
    }, [resetUpdateRiskLevel, updatedRiskLevel]);

    useEffect(() => {
        setSelectedRiskLabel(riskLabel)
    }, [riskLabel])

    const handleExpandActionPlanForm = () => {
        setExpandActionPlanForm(!expandActionPlanForm)
    };

    const handleExpandProposedSolutionForm = () => {
        setExpandProposedSolutionForm(!expandProposedSolutionForm)
    };

    const handleExpandResolutionForm = () => {
        setExpandResolutionForm(!expandResolutionForm)
    };

    const handleSaveActionPlan = useCallback(
        ({ form }) => {
            const { handleForm } = form;
            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            if(formObject?.text !== ''){
                saveActionPlan(formObject);
            }
        },[id, saveActionPlan, userId]
    );

    useEffect(() => {
        if (!savedActionPlan) {
            return;
        };

        setDisplayedActionPlan(savedActionPlan);
        resetSaveActionPlan();
    }, [resetSaveActionPlan, savedActionPlan, setDisplayedActionPlan]);


    // useEffect(() => {
    //     if (!displayedActionPlan || displayedActionPlan === ' ') {
    //         setExpandActionPlanForm(false);
    //     } else {
    //         setExpandActionPlanForm(true);
    //     }
    //     // setExpandActionPlanForm(!expandActionPlanForm)
    // }, [displayedActionPlan, setExpandActionPlanForm])

    const handleSaveProposedSolution = useCallback(
        ({ form }) => {
            const { handleForm } = form;
            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            if(formObject?.text !== ''){
                saveProposedSolution(formObject);
            }
        },[id, saveProposedSolution, userId]
    );

    useEffect(() => {
        if (!savedProposedSolution) {
            return;
        }

        setDisplayedProposedSolution(savedProposedSolution);
        resetSaveProposedSolution();
    }, [resetSaveProposedSolution, savedProposedSolution, setDisplayedProposedSolution]);

    const handleSaveResolution = useCallback(
        ({ form }) => {
            const { handleForm } = form;
            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    caseId: id,
                    userId: userId
                }
            );
            if(formObject?.text !== ''){
                saveResolution(formObject);
            }
        },[id, saveResolution, userId]
    );

    useEffect(() => {
        if (!savedResolution) {
            return;
        }

        setDisplayedResolution(savedResolution);
        resetSaveResolution();
    }, [resetSaveResolution, savedResolution, setDisplayedResolution]);

    const questionsWithAnswers = (Array.isArray(forms?.questions) && forms?.questions.length > 0) ? forms?.questions.reduce((accumulator, question) => {
        const {choices, formComponent, id, index, name, questionId, required, type} = question;
        const answerQuestions = answers?.questions.filter(function (e){return e.id === id;})[0]?.answers;
        const answer = answerQuestions[answerQuestions.length - 1]?.questionAnswer;
        const answerUser = answerQuestions[answerQuestions.length - 1]?.answerUser;
        accumulator.push({
            answer,
            answerUser,
            choices,
            formComponent,
            id,
            index,
            name,
            questionId,
            required,
            type
        })
        return accumulator
    },[]) : [];

    return (
        <ContentPanel className={`${className} ${styles.caseDetailsPanel}`}>
            <PanelBody className={styles.panelBody}>
                <EditableTextWithLabel
                    hasEdit={!closed}
                    label={CASE_NAME}
                    onSave={handleCaseNameSave}
                    text={displayedCaseName}
                />

                <EditableMultiSelectWithLabel
                    className={styles.multipleWithLabel}
                    hasEdit={!closed}
                    items={policies}
                    label={POLICY_REFERENCE}
                    onSave={handlePolicyReferenceSave}
                    selected={displayedPolicyReference?.map(({ id }) =>(id))}
                    wrapperButtonize={styles.wrapperButtonize}
                >
                {displayedPolicyReference.length > 0
                ?
                displayedPolicyReference.map(policy => {
                    return (
                        <>
                            <WithTooltip text={policy?.description}>
                                <ImportantLinkInNewTab
                                    className={policy?.id ? styles.policyReferenceWithLink : styles.policyReferenceWithoutLink}
                                    openNewTab={policy?.id}
                                    text={policy.name}
                                    to={POLICY_ROUTE.replace(':policyId', policy.id.split('.')[0])}
                                />
                            </WithTooltip>

                            <RiskPill
                                className={styles.policyReferenceRiskPill}
                                riskLevel={policy?.riskLevel}
                            />

                            <span></span>
                        </>
                    );
                })
                :
                <ImportantLink
                    className={styles.policyReferenceWithoutLink}
                    text={'-'}
                />
                }
                </EditableMultiSelectWithLabel>

                <WithLabel className={styles.activityReferenceContainer} text={ACTIVITY_REFERENCE}>
                    <ImportantLinkInNewTab
                        className={activity.id ? styles.activityReferenceWithLink : styles.activityReferenceWithoutLink}
                        openNewTab={activity.id}
                        text={activity.title || '-'}
                        to={ACTIVITY_ROUTE.replace(':activityId', activity?.id).replace(':activitySchema', caseSchema)}
                    />

                    <RiskPill
                        className={styles.activityReferenceRiskPill}
                        riskLevel={activity.riskLevel}
                    />
                </WithLabel>

                <EditableEditor
                    className={styles.creationReason}
                    hasEdit={!closed}
                    key={`${id}.description`}
                    onSave={handleCreationReasonSave}
                    text={displayedCreationReason}
                    title={CASE_CREATION_REASON}
                />


                <div className={styles.forms}>
                    {(expandActionPlanForm || displayedActionPlan) &&
                        <EditableEditor
                            className={styles.creationReason}
                            key={`${id}.actionPlan`}
                            onSave={handleSaveActionPlan}
                            text={displayedActionPlan}
                            title={ACTION_PLAN}
                        />
                    }

                    {(expandProposedSolutionForm || displayedProposedSolution) &&
                        <EditableEditor
                            className={styles.creationReason}
                            key={`${id}.proposedSolution`}
                            onSave={handleSaveProposedSolution}
                            text={displayedProposedSolution}
                            title={PROPOSED_SOLUTION}
                        />
                    }

                    {(expandResolutionForm || displayedResolution) &&
                        <EditableEditor
                            className={styles.creationReason}
                            key={`${id}.resolution`}
                            onSave={handleSaveResolution}
                            text={displayedResolution}
                            title={RESOLUTION}
                        />
                    }

                    {!closed && (
                        <div>
                            {!expandActionPlanForm && !displayedActionPlan &&
                                <AddNewFormButton
                                onClick={handleExpandActionPlanForm}
                                text={ADD_ACTION_PLAN}
                            />
                            }

                            {!expandProposedSolutionForm && !displayedProposedSolution &&
                                <AddNewFormButton
                                    onClick={handleExpandProposedSolutionForm}
                                    text={ADD_PROPOSED_SOLUTION}
                                />
                            }

                            {!expandResolutionForm && !displayedResolution &&
                            <AddNewFormButton
                                onClick={handleExpandResolutionForm}
                                text={ADD_RESOLUTION}
                            />
                            }

                        </div>
                    )}
                </div>

                <SectionWithBorder className={styles.sectionWithBorder}>
                    <RiskLevelRadioButtons
                        className={styles.riskLevelRadioButtons}
                        hasEdit={!closed}
                        label={RISK_LEVEL_CASE}
                        // onChange={handleValid('riskLevel')}
                        onChange={handleSetRiskLevel}
                        riskLabel={selectedRiskLabel}
                    />
                </SectionWithBorder>

                <Dropdowns
                    className={styles.dropdowns}
                    hasEdit={!closed}
                    items={dropdowns}
                    onChange={handleDropdownsChange}
                    selections={dropdownSelections}
                />

                {forms && Object.keys(forms).length > 0 && (
                    <AddCaseQuestions
                        caseId={id}
                        className={styles.addQuestions}
                        formId={forms?.formId}
                        inactive={closed}
                        questions={questionsWithAnswers}
                    />
                )}

                <Comments
                    className={styles.comments}
                    commentType={'case'}
                    commentTypeId={id}
                    commentTypeName={caseName}
                    comments={caseComments}
                    hasEdit={!closed}
                    isCreatingPDF={isCreatingPDF}
                    onDelete={handleDeleteComment}
                    onSubmit={handleCommentSubmit}
                    onUpdate={handleUpdateComment}
                    usedSchema={caseSchema}
                    user={{
                        id: userId,
                        name: `${localStorage.firstName} ${localStorage.lastName}`
                    }}
                />

                {attachments && (
                    <Attachments
                        className={styles.attachments}
                        hasEdit={!closed}
                        hasEditPermission={true} // TODO - once permissions are ready, update this
                        items={attachments}
                        onDelete={attachmentsOnDelete}
                        onUpdate={attachmentsOnUpdate}
                        providedSchema={caseSchema}
                    />
                )}

                <FileUpload
                    className={styles.fileUpload}
                    filesList={handleFileUpload}
                    persist={false}
                    providedSchema={caseSchema}
                />

            </PanelBody>
        </ContentPanel>
    );
};

export default CaseDetailsPanel;
