import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('glancePanel');

    return {
        GLANCE: t('glance')
    };
};

export default useConstants;
