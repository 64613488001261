import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { flaggedActivity } = state;

const { flaggedActivityAtom } = flaggedActivity.atoms;

const MUTATE_FLAG_ACTIVITY = tenantSchema => gql`
mutation flagActivity($activityId: Int!, $userId: Int!) {
    insert_${tenantSchema}_activity_flag(
        objects: {
            activity_id: $activityId,
            user_id: $userId
        }
    ) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_deleted($activityId: Int!, $color: String = "yellow", $message: String = "flagged the activity", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useFlagged = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_FLAG_ACTIVITY, providedSchema);
    const mutationActivityHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const flagActivity = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            await mutation(variables);
            await mutationActivityHistory(variables);
            set(flaggedActivityAtom, variables?.activityId);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(flaggedActivityAtom, '');
    });

    return [useRecoilValue(flaggedActivityAtom), flagActivity, reset];
};

export default useFlagged;
