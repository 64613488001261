import { atom } from 'recoil';

const policyAtom = atom({
    default: [],
    key: 'policy'
});

const policyAttachmentAtom = atom({
    default: [],
    key: 'policyAttachment'
});

const state = {
    atoms: {
        policyAtom,
        policyAttachmentAtom
    }
};

export default state;
