import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('policyNameInput');

    return {
        ENTER_POLICY_NAME: t('enterPolicyName'),
        POLICY_NAME: t('policyName')
    };
};

export default useTranslation;
