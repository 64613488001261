import { useCallback } from 'react';

import PanelHeader from 'components/PanelHeader';

import ActivitiesViewSelector from 'components/ActivitiesViewSelector';

import styles from './styles.module.scss';

const AddActivitiesPanelHeader = ({
    className = '',
    endDate,
    endDateFilter,
    entityFilter,
    entityItems,
    entitySelected,
    isAll,
    isFlagged,
    onFlaggedToggle,
    onToggle,
    recurrencyFilter,
    recurrencyItems = [],
    reviewFilter,
    reviewItems,
    reviewSelected,
    setSearchFilter,
    startDate,
    startDateFilter,
		statusFilter,
    statusItems = [],
    statusSelected,
    typeFilter,
    typeItems,
    typeSelected,
    userFilter,
    userItems,
    userSelected
}) => {
    const handleToggle = useCallback(isOn => {
        onToggle(!isOn);
    }, [onToggle]);

    const handleFlaggedToggle = useCallback(isOn => {
        onFlaggedToggle(!isOn);
    }, [onFlaggedToggle]);

    const handleStartDateFilter = useCallback(startDate => {
        startDateFilter(startDate);
    }, [startDateFilter]);

    return (
        <PanelHeader className={`${className} ${styles.addActivitiesPanelHeader}`}>
            <ActivitiesViewSelector
                detailed={true}
                endDate={endDate}
                endDateFilter={endDateFilter}
                entityFilter={entityFilter}
                entityItems={entityItems}
                entitySelected={entitySelected}
                isAll={isAll}
                isFlagged={isFlagged}
                onFlaggedToggle={handleFlaggedToggle}
                onToggle={handleToggle}
                recurrencyFilter={recurrencyFilter}
                recurrencyItems={recurrencyItems}
                reviewFilter={reviewFilter}
                reviewItems={reviewItems}
                reviewSelected={reviewSelected}
                setSearchFilter={setSearchFilter}
                startDate={startDate}
                startDateFilter={handleStartDateFilter}
								statusFilter={statusFilter}
								statusItems={statusItems}
								statusSelected={statusSelected}
                typeFilter={typeFilter}
                typeItems={typeItems}
                typeSelected={typeSelected}
                userFilter={userFilter}
                userItems={userItems}
                userSelected={userSelected}
            />
        </PanelHeader>
    );
};

export default AddActivitiesPanelHeader;
