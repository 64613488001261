import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { caseIdAtom } = state.caseId.atoms;

const useCaseId = () => [
    useRecoilValue(caseIdAtom),
    useSetRecoilState(caseIdAtom)
];

export default useCaseId;

