import { useEffect, useState } from 'react';

import BellIcon from 'components/icons/BellIcon';
import NotificationDropdown from 'components/NotificationDropdown';
import NotificationItem from 'components/NotificationItem';
import NotificationHeader from 'components/NotificationHeader';

import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import usePopperState from 'hooks/usePopperState';

import styles from './styles.module.scss';

import normalizeData from './data';

const NotificationIcon = ({ className = '' }) => {
    const { useQuerySchemas, useSchema, useTenants, useUserId } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [count, setCount] = useState();
    const [alerts, setAlerts] = useState();
    const { loading, error, data } = useData();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants,
                userId
            });

            setAlerts(normalizedData);
            const activitiesCount = normalizedData?.activites ? normalizedData?.activites.length : 0;
            const casesCount = normalizedData?.cases ? normalizedData?.cases.length : 0;
            const marketingRequestsCount = normalizedData?.marketingRequests ? normalizedData?.marketingRequests.length : 0;
            const newCount = activitiesCount + casesCount + marketingRequestsCount;
            setCount(newCount);
        }
    }, [data, querySchemas, tenants, schema, setAlerts, setCount, userId]);

    const { handleClick, isOpen, onClose, referenceElement } = usePopperState();

    if(error){

    }

    return (
        <div
            className={`${className} ${styles.notificationIcon}`}
            data-count={count}
        >
            <BellIcon
                onClick={handleClick}
            />

            {!loading && !error && alerts &&
                <NotificationDropdown
                    className={styles.dropdownMenu}
                    isOpen={isOpen}
                    key={`notificationDropdown`}
                    onClose={onClose}
                    placement={"bottom"}
                    referenceElement={referenceElement}
                >
                    <NotificationHeader title={"Activities"}>
                        {["Due Date"]}
                    </NotificationHeader>

                    {alerts?.activites?.map(({ dueDate, highImportance, id, name, type }, index) => (
                        <NotificationItem
                            dueDate={dueDate}
                            highImportance={highImportance}
                            id={id}
                            key={`activityNotificationIcon_${id}_${index}`}
                            name={name}
                            onClick={onClose}
                            schema={schema}
                            type={type}
                        />
                    ))}

                    <NotificationHeader title={"Cases"}>
                        {["Due Date"]}
                    </NotificationHeader>

                    {alerts?.cases?.map(({ dueDate, highImportance, id, name, type }) => (
                        <NotificationItem
                            dueDate={dueDate}
                            highImportance={highImportance}
                            id={id}
                            key={`caseNotificationIcon_${id}`}
                            name={name}
                            onClick={onClose}
                            schema={schema}
                            type={type}
                        />
                    ))}

                    <NotificationHeader title={"Marketing Requests"}>
                        {["Due Date", "High Importance"]}
                    </NotificationHeader>

                    {alerts?.marketingRequests?.map(({ dueDate, highImportance, id, name, type }) => (
                        <NotificationItem
                            dueDate={dueDate}
                            highImportance={highImportance}
                            id={id}
                            key={`marketingNotificationIcon_${id}`}
                            name={name}
                            onClick={onClose}
                            schema={schema}
                            type={type}
                        />
                    ))}

                </NotificationDropdown>
            }
        </div>
    );
}

export default NotificationIcon;
