import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagement');

    return {
        DOCUMENT_MANAGEMENT: t('documentManagement'),
        MARKETING: t('marketing'),
        POLICY: t('policy'),
        REPOSITORY: t('repository'),
        SECTIONS: t('sections'),
        SIDE_LETTERS: t('sideLetters'),
    };
};

export default useTranslation;
