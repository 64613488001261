import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Authenticated from 'components/Authenticated';
import Loader from 'components/Loader';

import MainLayout from 'layouts/MainLayout';
import PlainLayout from 'layouts/PlainLayout';

import setTabTitle from 'utilities/setTabTitle'
import useConfig from 'hooks/useConfig';

import './state';

const loading = <Loader />;

const App = () => {
    const { LOGIN, LOGIN_PASSWORD } = useConfig();

    setTabTitle()

    return (
        <BrowserRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route element={<PlainLayout />} path={`${LOGIN}/*`} />

                    <Route element={<PlainLayout />} path={`${LOGIN_PASSWORD}/*`} />

                    <Route
                        element={
                            <Authenticated>
                                <MainLayout />
                            </Authenticated>
                        }
                        path="/*"
                    />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default App;
