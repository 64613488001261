import useData from './useData';

export function useCaseManagementData() {
    const { loading, error, data } = useData();

    const caseData =
        data && Array.isArray(data)
            ? data
            : [];

    return {
        data: caseData,
        error,
        loading
    };
}
