import styles from './styles.module.scss';

const LoginHeader = ({ className = '', text = '', subtext = '' }) => (
    <div className={styles.loginHeader}>

        <h1 className={`${className} ${styles.test}`}>{text}</h1><br/>

        <p className={`${className} ${styles.subText}`}>{subtext}</p><br/>

    </div>
);

export default LoginHeader;
