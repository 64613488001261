import VersionButton from 'components/VersionButton';

import styles from './styles.module.scss';

const ArchivedVersionButton = props => {
    const {
        className = '',
        isSelected = false,
        onClick,
        status,
        version
    } = props;

    const selectedClass = isSelected ? styles.selected : '';

    return (
        <VersionButton
            className={`${className} ${selectedClass} ${styles.archivedVersionButton}`}
            isSelected={isSelected}
            onClick={onClick}
            status={status}
            version={version}
        />
    );
};

export default ArchivedVersionButton;
