import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('closeAndCreateCaseMenuListItem');

    return {
        CLOSE_AND_CREATE_CASE: t('closeAndCreateCase')
    };
};

export default useTranslation;
