import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const InProgressItems = ({ children, className = '' }) => {
    const { UPCOMING } = useTranslation();

    return (
        <div className={`${className} ${styles.upcomingItems}`}>
            <Title className={styles.title} text={UPCOMING} />

            {children}
        </div>
    );
};

export default InProgressItems;
