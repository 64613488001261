import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { logDetailsAtom } = state.logDetails.atoms;

const useLogDetails = () => [
    useRecoilValue(logDetailsAtom),
    useSetRecoilState(logDetailsAtom)
];

export default useLogDetails;
