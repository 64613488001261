import { useCallback, useState } from 'react';

import Dropdown from 'components/Dropdown';

import useForm from 'hooks/useForm';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

/*

items = [{
    id: 'some-dropdown-id',
    options: [{
        label: 'dropdown item label',
        value: 'dropdown item value'
    }],
    selectedIndex: 0,
    title: 'some dropdown title
}];

*/

const Dropdowns = ({ className = '', hasEdit = true, items, onChange, /*selections = []*/ }) => {
    const [_changedDropdown, setChangedDropdown] = useState();
    const [_changedDropdownId, setChangedDropdownId] = useState();
    const [_changedDropdownIndex, setChangedDropdownIndex] = useState();
    const [_selectedIndex, setSelectedIndex] = useState();

    const prepareDefaultFormValues = () => ({
        ...prepareDefaultDropdownFormValues(items)
    });

    const prepareDefaultDropdownFormValues = dropdowns =>
        dropdowns.reduce((accumulator, dropdown) => {
            if (!(dropdown?.id in accumulator)) {
                accumulator[dropdown.id] = '';
            }

            return accumulator;
        }, {});

    const defaultFormValues = prepareDefaultFormValues();

    const { handleChange, handleError, handleForm, handleSubmit, handleValid } =
        useForm(defaultFormValues);

    const handleDropdownChange = useCallback(
        (formField, index) => (fieldValue, _, selectedDropdownItemIndex) => {
            handleChange(formField)(fieldValue);

            setChangedDropdown(new Date());
            setChangedDropdownId(formField);
            setChangedDropdownIndex(index);
            setSelectedIndex(selectedDropdownItemIndex);

            const output = handleForm();

            if (typeof output === 'string') {
                return;
            }

            const dropdownSelection = output.get(formField);

            if (isFunction(items?.[index]?.onChange)) {
                items[index].onChange(dropdownSelection);
            }

            if (isFunction(onChange)) {
                onChange({
                    changedDropdownId: formField,
                    changedDropdownIndex: index,
                    dropdownSelection,
                    formData: output,
                    selectedIndex: selectedDropdownItemIndex
                });
            }
        },
        [handleChange, handleForm, items, onChange]
    );

    return (
        <div className={`${className} ${styles.dropdowns}`}>
            {items.map(({ id, options, selectedIndex, title }, index) => (
                <Dropdown
                    items={options}
                    isDisabled={!hasEdit}
                    key={`${title}${index}`}
                    label={title}
                    onChange={handleDropdownChange(id, index)}
                    onError={handleError(id)}
                    onSubmit={handleSubmit(id)}
                    onValid={handleValid(id)}
                    selected={selectedIndex}
                />
            ))}
        </div>
    );
};

export default Dropdowns;
