import EditButton from 'components/EditButton';
import EntityTagList from 'components/EntityTagList';
import Title from 'components/Title';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const EntityTags = ({ className = '', hasEdit = true, items, onEditClick }) => {
    const { ENTITY_TAGS } = useTranslation();

    return (
        <div className={`${className} ${styles.entityTags}`}>
            <Title className={styles.title} text={ENTITY_TAGS} />

            {onEditClick && hasEdit && (
                <EditButton
                    className={styles.editButton}
                    onClick={onEditClick}
                />
            )}

            <EntityTagList className={styles.entityTagList} items={items} />
        </div>
    );
};

export default EntityTags;
