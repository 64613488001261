import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('authenticatingPanel');

    return {
        AUTHENTICATING: t('authenticating')
    };
};

export default useTranslation;
