import { useQuery } from '@apollo/client';

import generateQuery from 'utilities/generateQuery';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_activity_type(order_by: {name: asc}) {
        id
        name
    }
    ${tenantSchema}_document(order_by: {name: asc}) {
        id
        name
        policy_sections(order_by: {section: {name: asc}}, where: {deleted: {_is_null: true}}) {
            section {
                id
                name
            }
        }
    }
    ${tenantSchema}_form(order_by: {name: asc}, where: {is_active: {_eq: true}}) {
        id
        name
    }
`;

const useData = () => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    return useQuery(generateQuery(query, usedSchema, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
