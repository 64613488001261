import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecRequest = (tenantSchema, requestId, userGQL) => gql`
query getMarketingRequest {
    ${tenantSchema}_marketing_request(where: {id: {_eq: ${requestId}}}) {
      description
      dropdown_selections
      due_date
      expected_use_date
      high_importance
      id
      recipient
      request_name
      status
      tenant_user {
        id
        ${userGQL}
      }
      marketing_request_versions {
        created_date
        deleted
        file
        file_name
        id
        status
        uploaded_timestamp
        version_number
        tenant_user {
          id
          ${userGQL}
        }
        marketing_request_version_histories {
          color
          created_at
          id
          version_id
          message
          tenant_user {
            id
            ${userGQL}
          }
        }
      }
      marketing_request_version_comments {
        created_at
        created_by
        deleted
        id
        text
        version_id
        tenant_user {
          id
          ${userGQL}
        }
      }
      marketing_request_feedbacks {
        id
        message
        type
        created_at
        tenant_user {
          id
          ${userGQL}
        }
        marketing_request_version {
          version_number
        }
      }
      marketing_request_assigned_tos {
        status
        group {
          id
          name
        }
        tenant_user {
          id
          ${userGQL}
        }
      }
    }
  }  
`;

const useData = (requestId, requestSchema) => {
    const { useQuerySchemas, useSchema } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    var userGQL = '';
    if(querySchemas.length > 0){
      for(const s of querySchemas){
          if(s.value){
              userGQL += `user_${s.value} {
                first_name
                last_name
              }`
          }
      }
    }else{
      if(schema){
          userGQL += `user_${schema} {
            first_name
            last_name
          }`
      }
    }

    return useQuery(querySpecRequest(requestSchema, requestId, userGQL), {
        fetchPolicy: 'no-cache'
    });
};

export default useData;
