const consolidateGroupsUsers = (groups, users) => {
    const allGroupsAndUsers = (groups ?? []).map(({ id, name, type = 'group' }) => (
        { id, name, type }
    ));
    
    (users ?? []).forEach(user => allGroupsAndUsers.push(user));

    return allGroupsAndUsers;
};

export default consolidateGroupsUsers;