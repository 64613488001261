import UpcomingDetailedListItems from 'components/UpcomingDetailedListItems';
import UpcomingItems from 'components/UpcomingItems';
import styles from './styles.module.scss';

const UpcomingDetailedItems = ({ className = '', items, onDownloadItem, tenantCount }) =>  (
    <UpcomingItems className={className}>
        {items.length > 0 ? (
            <UpcomingDetailedListItems
                items={items}
                            onDownloadItem={onDownloadItem}
                tenantCount={tenantCount}
            />
        )
        : (
            <p className={styles.noItems}>No items to show</p>
        )}
    </UpcomingItems>
);

export default UpcomingDetailedItems;
