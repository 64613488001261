function setHyperlinks(str) {
    const arr = ['.com', '.org', '.co', '.io'];
    const splitComment = str.split(" ");
    const arrIndexes = arr.map((element, _index) => {
        return splitComment.map((word, index) => {
            if(word.includes(element)){
                return index;
            }else{
                return -1
            }
        })
    })
    const dupArray = [].concat.apply([], arrIndexes).filter(i => i >= 0);
    const filteredArray = dupArray.filter(function(item, pos) {
        return dupArray.indexOf(item) === pos;
    })
    
    var updatedComment = str;
    for (const link of filteredArray){
        updatedComment = updatedComment.replace(splitComment[link], `<a href="${splitComment[link].includes('http') ? '' : 'http://'}${splitComment[link]}" target="_blank">${splitComment[link]}</a>`)
    }
    return updatedComment;
  }

export default setHyperlinks