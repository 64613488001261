const translations = {
    categories: 'Section Tags',
    company: 'Company',
    currentVersion: 'Current Version',
    documentTags: 'Document Tags',
    documents: 'Documents', 
    lastReviewedDate: 'Last Reviewed Date',
    published: 'Published',
    riskLevel: 'Risk Level', 
    sectionName: 'Section Name',
    status: 'Status'
};

export default translations;
