import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useCaseId from './hooks/useCaseId';
import useData from './hooks/useData';

import CloseAndCreateNewActivityMenuListItem from 'components/CloseAndCreateNewActivityMenuListItem';

const CloseCaseAndCreateNewActivityMenuListItem = ({
    caseId, 
    caseName, 
    onClick 
}) => {
    const [_, setCaseLinkId] = useCaseId();
    // const [_, setActivityLinkId] = useActivityId();
    const [closedActivity, closeActivity, reset] = useData();

    const { ADD_ACTIVITY_ROUTE } = useConfig();

    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        closeActivity({
            caseId
        });

        setCaseLinkId({
            caseId: caseId,
            caseName: caseName
        });
    }, [caseId, caseName, closeActivity, setCaseLinkId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(closedActivity);
        };

        navigate(ADD_ACTIVITY_ROUTE);

        reset();
    }, [ADD_ACTIVITY_ROUTE, closedActivity, navigate, onClick, reset]);
    return <CloseAndCreateNewActivityMenuListItem onClick={handleClose} />;
};

export default CloseCaseAndCreateNewActivityMenuListItem;
