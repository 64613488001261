import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import titleCase from 'utilities/titleCase';

// import sendActivityAssignedEmail from 'utilities/sendActivityAssignedEmail';

const { updateActivityUser } = state;

const { updateActivityUserAtom } = updateActivityUser.atoms;

const MUTATE_UPDATE = tenantSchema => gql`
mutation mutateUpdateActivityUsers(
  $activityId: Int!,
  $assignmentType: String!,
  $status: String!,
  $groupId: Int,
  $userId: Int
) {
    update_${tenantSchema}_user_activity(where: {activity_id: {_eq: $activityId}, _and: {_or: [{user_id: {_eq: $userId}}, {group_id: {_eq: $groupId}}]}}, _set: {
      assignment_type: $assignmentType
      status: $status
    }) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_INSERT = tenantSchema => gql`
mutation mutateUpdateActivityUsers(
  $activityId: Int!,
  $assignmentType: String!,
  $status: String!,
  $groupId: Int,
  $userId: Int
) {
    insert_${tenantSchema}_user_activity(objects: {activity_id: $activityId, assignment_type: $assignmentType, group_id: $groupId, status: $status, user_id: $userId}) {
      returning {
        id
      }
    }
  }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_dropdown($activityId: Int!, $color: String!, $message: String!, $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutationInsert = useGraphQL(MUTATE_INSERT, providedSchema);
    const mutationUpdate = useGraphQL(MUTATE_UPDATE, providedSchema);
    const mutationHistory = useGraphQL(MUTATE_ACTIVITY_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveActivityUser = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
          if (variables?.previousRecord){
            variables.groupId = (variables?.groupId) ? variables?.groupId : 0;
            variables.userId = (variables?.userId) ? variables?.userId : 0;
            const res = await mutationUpdate(variables);
            const newUser = res?.data[`update_${usedSchema}_user_activity`].returning[0];
            // if(variables?.userId !== 0 && variables?.status === 'inprogress'){
            //     await sendActivityAssignedEmail(newUser?.id, variables?.activityName, variables?.assignmentType, new Date(variables?.dueDate), variables?.userId)
            // }
            await mutationHistory({
              activityId: variables?.activityId,
              color: variables?.status === 'removed' ? 'red' : 'green',
              message: `removed ${variables?.userName} from ${titleCase(variables?.assignmentType)}`,
              userId: variables?.actingUserId
            })
            set(updateActivityUserAtom, newUser);
          }else{
            const res = await mutationInsert(variables);
            const newUser = res?.data[`insert_${usedSchema}_user_activity`].returning[0];
            // if(variables?.userId !== 0 && variables?.status === 'inprogress'){
            //     await sendActivityAssignedEmail(newUser?.id, variables?.activityName, variables?.assignmentType, new Date(variables?.dueDate), variables?.userId)
            // }
            await mutationHistory({
              activityId: variables?.activityId,
              color: variables?.status === 'removed' ? 'red' : 'green',
              message: `added ${variables?.userName} to ${titleCase(variables?.assignmentType)}`,
              userId: variables?.actingUserId
            })
            set(updateActivityUserAtom, newUser);
          }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityUserAtom, '');
    });

    return [useRecoilValue(updateActivityUserAtom), saveActivityUser, reset];
};

export default useData;
