import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { peopleAtom } = state.people.atoms;

const usePeople = () => [
    useRecoilValue(peopleAtom),
    useSetRecoilState(peopleAtom)
];

export default usePeople;
