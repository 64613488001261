const translations = {
    activityDetails: 'Activity Details',
    activityName: 'Activity Name',
    dateClosed: 'Date Closed',
    dueDate: 'Due Date',
    instructions: 'Instructions',
    riskLabel: 'Risk Label',
    startDate: 'Start Date'
};

export default translations;
