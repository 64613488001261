import { atom } from 'recoil';

const addPolicyAtom = atom({
    default: '',
    key: 'addPolicy'
});

const state = {
    atoms: {
        addPolicyAtom
    }
};

export default state;
