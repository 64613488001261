import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ActivePartyActions from 'components/ActivePartyActions';
import MarketingRequestActions from 'components/MarketingRequestActions';
import MarketingRequestDetailsPanel from 'components/MarketingRequestDetailsPanel';
import MarketingRequestMetaPanel from 'components/MarketingRequestMetaPanel';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import RequestorActions from 'components/RequestorActions';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import normalizeData from './data';
import useAttachments from './hooks/useAttachments';

const actionsMap = {
    activeParty: ActivePartyActions,
    default: MarketingRequestActions,
    requestor: RequestorActions,
    reviewer: ActivePartyActions
};

const MarketingRequest = () => {
    const params = useParams();

    const { requestId, requestSchema, versionId } = params;

    const { useQuerySchemas, useUserId } = useGlobalStateHooks();

    const { loading, error, data } = useData(requestId, requestSchema);

    const [querySchemas] = useQuerySchemas();
    const [userId] = useUserId();

    const [request, setRequest] = useState({});

    const [updateAttachment, deleteAttachment] = useAttachments(requestSchema);

    const {
        activeParty = [],
        comments = [],
        createdBy = {},
        dropdownSelections,
        feedback,
        id,
        instructions = '',
        name: requestName,
        pendingRequest = false,
        userType = 'default',
        versions = []
    } = request;

    const requestorId = (feedback && feedback.length > 0) ? feedback[0]?.user?.id : 0;

    const [activeVersion, setActiveVersion] = useState();

    const navigate = useNavigate();

    const { HOME } = useConfig();

    const { MARKETING_REVIEW } = useTranslation();

    const versionChangeHandler = useCallback(
        versionLabel => {
            const version = versions.find(
                version => version.label === versionLabel
            );

            setActiveVersion(version);
        },
        [versions]
    );

    const form = useForm({
        category1: '',
        category2: '',
        category3: '',
        description: '',
        dueDate: '',
        expectedUseDate: '',
        highImportance: '',
        recipient: '',
        requestName: ''
    });

    const handleCancel = useCallback(() => {
        navigate(HOME);
    }, [HOME, navigate]);

    const handleFileUpload = _newFiles => {
    };

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema: requestSchema,
                user: userId
            });
            
            setRequest(normalizedData);
            if(versionId === '-' || versionId === ':versionId'){
                setActiveVersion(normalizedData.versions[0])
            }else{
                const version = normalizedData.versions.find(
                    version => version.label === Number(versionId)
                );
                setActiveVersion(version)
            }
        }
    }, [data, requestSchema, setRequest, userId, versionId]);

    const attachmentsOnDelete = async (params) => {
        const { filename } = params;
        await deleteAttachment({ filename, id: activeVersion?.id, versionNumber: activeVersion?.versionNumber });
    }

    const attachmentsOnUpdate = async (params) => {
        const { newName } = params;
        await updateAttachment({ filename: newName, id: activeVersion?.id, versionNumber: activeVersion?.versionNumber });
    }

    const { label, status } = activeVersion || {};

    if (error) {
        return `Error! ${error}`;
    }

    if (loading) {
        
    }

    const ActionsComponent = actionsMap[userType] || actionsMap.default;

    if(activeVersion){
        return (
            <div className={styles.marketingRequest} key={id}>
                <WithPageTitle title={MARKETING_REVIEW}>
                    <ActionsComponent
                        assignedUsers={activeParty.reduce((accumulator, user) => {
                            accumulator.push(String(user?.id))
                            return accumulator;
                        },[])}
                        createdById={createdBy?.id}
                        onCancel={handleCancel}
                        pendingRequest={pendingRequest}
                        requestId={id}
                        requestName={requestName}
                        requestSchema={requestSchema}
                        requestorId={requestorId}
                        status={status}
                        versionId={activeVersion?.id}
                    />
                </WithPageTitle>

                <PanelWithAsidePanel>
                    <MarketingRequestDetailsPanel
                        attachmentsOnDelete={attachmentsOnDelete}
                        attachmentsOnUpdate={attachmentsOnUpdate}
                        dropdownSelections={dropdownSelections}
                        fileUploader={handleFileUpload}
                        form={form}
                        instructions={instructions}
                        request={request}
                        requestSchema={requestSchema}
                        version={activeVersion}
                    />

                    <MarketingRequestMetaPanel
                        activeParty={activeParty}
                        comments={comments}
                        createdBy={createdBy}
                        onVersionChange={versionChangeHandler}
                        requestId={id}
                        requestName={requestName}
                        requestSchema={requestSchema}
                        selectedVersion={label}
                        versions={versions}
                    />
                </PanelWithAsidePanel>
            </div>
        );
    }else{
        <></>
    }
};

export default MarketingRequest;
