import { atom } from 'recoil';

const addFormAnswerAtom = atom({
    default: '',
    key: 'addFormAnswer'
});

const state = {
    atoms: {
        addFormAnswerAtom
    }
};

export default state;
