import CountPanel from 'components/CountPanel';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const InProgressCountPanel = ({ className = '', count = 0 }) => {
    const { IN_PROGRESS } = useTranslation();

    return (
        <CountPanel
            className={`${className} ${styles.inProgressCountPanel}`}
            count={count}
            text={IN_PROGRESS}
        />
    );
};

export default InProgressCountPanel;
