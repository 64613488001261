import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('escalateModal');

    return {
        ESCALATE: t('escalate'),
        ESCALATE_TO_USER_OR_GROUP: t('escalateToUserOrGroup'),
        USERS_OR_GROUPS: t('usersOrGroups')
    };
};

export default useTranslation;
