import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceEntryDetailsPanel');

    return {
        DATE_OF_OCCURRENCE: t('dateOfOccurrence'),
        DESCRIPTION: t('description'),
        LOG_CATEGORY: t('logCategory'),
        LOG_DETAILS: t('logDetails'),
        RISK_LABEL: t('riskLabel')
    };
};

export default useTranslation;
