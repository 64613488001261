import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementRepositoryTableHeader');

    return {
        COMPANY: t('company'),
        DATE_INTRODUCED: t('dateIntroduced'),
        DOCUMENT_NAME: t('documentName'),
        REFERENCE_NAME: t('referenceName'),
        REFERENCE_TYPE: t('referenceType'),
        TYPE: t('type'),
        UPLOAD_BY: t('uploadBy')
    };
};

export default useTranslation;
