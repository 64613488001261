import styles from './styles.module.scss';

const DropdownItem = ({
    className = '',
    isSelected = false,
    onClick,
    text = '',
    type = undefined
    // value = ''
}) => {
    if (type === 'subItem'){
        return(
            <div className={`${className} ${styles.dropdownItem} ${(isSelected) ? styles.selected : ''} ${styles.subItem}`} onClick={onClick}>
                {text}
            </div>
        );
    }else{
        return(
            <div className={`${className} ${(isSelected) ? styles.selected : ''} ${styles.dropdownItem}`} onClick={onClick}>
                {text}
            </div>
        );
    }
};

export default DropdownItem;
