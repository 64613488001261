import { forwardRef, useCallback, useState } from 'react';

import Input from 'components/Input';

import styles from './styles.module.scss';

const InputWithLabel = forwardRef((props, ref) => {
    const {
        children,
        className = '',
        id = '',
        isDisabled = false,
        isReadOnly = false,
        name = '',
        onBlur,
        onChange,
        onClick,
        onKeyUp,
        text = '',
        type = 'text',
        value
    } = props;

    const [hasFocus, setHasFocus] = useState(false);

    const handleBlur = useCallback(() => {
        setHasFocus(false);
    }, [setHasFocus]);

    const handleClick = useCallback(() => {
        setHasFocus(true);
    }, [setHasFocus]);

    const focusClassName = hasFocus ? styles.hasFocus : '';
    const noLabelClass = !text ? styles.noLabel : '';

    return (
        <div
            className={`${className} ${focusClassName} ${noLabelClass} ${styles.inputWithLabel}`}
            onBlur={handleBlur}
            onClick={handleClick}
        >
            {children}

            <Input
                className={`${focusClassName} ${noLabelClass} ${styles.inputLabel}`}
                defaultValue={value}
                disabled={isDisabled}
                hasFocus={hasFocus}
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                onKeyUp={onKeyUp}
                placeholder={text}
                readOnly={isReadOnly}
                ref={ref}
                type={type}
            />
        </div>
    );
});

export default InputWithLabel;
