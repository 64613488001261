import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddFormRow from 'components/AddFormRow';
import AddNewFormButton from 'components/AddNewFormButton';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import Editor from 'components/Editor';
import FormField from 'components/FormField';
import HeaderText from 'components/HeaderText';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import TextInput from 'components/TextInput';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';
import useForms from './hooks/useForms';
import useTranslation from './hooks/useTranslation';
import usePolicies from './hooks/usePolicies';
import useTypes from './hooks/useTypes';

import styles from './styles.module.scss';

import normalizeFormData from './dataForms';
import normalizePolicyData from './dataPolicies';
import normalizeTypeData from './dataTypes';
import titleCase from 'utilities/titleCase';

const ActivityForm = ({ assignees, className = '', form, forms, onTypeChange, setForms }) => {
    const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const { INTERNAL_SERVER_ERROR } = useConfig();

    const navigate = useNavigate();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();
    const [policies, setPolicies] = usePolicies();
    const [types, setTypes] = useTypes();

    const [databaseForms, setDatabaseForms] = useForms();

    const [editorState, setEditorState] = useState(`<p></p>`);
    const [expandForm, setExpandForm] = useState(false);

    const { handleError, handleSubmit, handleValid } = form;

    const {
        ACTIVITY_NAME,
        ACTIVITY_TYPE,
        ADD_INSTRUCTIONS_HERE,
        DUE_DATE,
        ENTER_ACTIVITY_NAME,
        FORM_NAME,
        INSTRUCTIONS,
        POLICY_REFERENCE,
        RECIPIENT
    } = useTranslation();

    const headers = [
        FORM_NAME,
        DUE_DATE,
        RECIPIENT
    ];

    const { error, data } = useData();

    const handleExpandForm = () => {
        setExpandForm(!expandForm);
    }

    const handleSetFormName = (formId, index) => {
        const modifiedForm = forms;
        modifiedForm[index]['formId'] = formId;
        setForms(modifiedForm);
    }

    const handleSetFormDate = (formDate, index) => {
        const modifiedForm = forms;
        modifiedForm[index]['dueDate'] = formDate;
        setForms(modifiedForm);
    }

    const handleSetAssignee = (assignee, index) => {
        const modifiedForm = forms;
        modifiedForm[index]['assignee'] = assignee;
        setForms(modifiedForm);
    }

    const handleNewForm = () => {
        const modifiedForm = forms;
        modifiedForm.push({})
        setForms(modifiedForm);
    }

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    useEffect(() => {
        if (data) {
            const tenantSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
            const normalizedPolicyData = normalizePolicyData({ data, schema:tenantSchema, tenants });
            const normalizedTypeData = normalizeTypeData({ data, schema:tenantSchema, tenants });
            const normalizedFormData = normalizeFormData({ data, schema:tenantSchema, tenants });

            setDatabaseForms(normalizedFormData);
            setPolicies([].concat.apply([], normalizedPolicyData));
            setTypes(normalizedTypeData);
        }
    }, [data, schema, setDatabaseForms, setPolicies, setTypes, tenants, userSetSchema]);

    useEffect(() => {
        if (data) {
            const tenantSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
            const normalizedPolicyData = normalizePolicyData({ data, schema:tenantSchema });
            const normalizedTypeData = normalizeTypeData({ data, schema:tenantSchema });
            const normalizedFormData = normalizeFormData({ data, schema:tenantSchema });

            setDatabaseForms(normalizedFormData);
            setPolicies([].concat.apply([], normalizedPolicyData));
            setTypes(normalizedTypeData);
        }
    }, [userSetSchema, data, schema, setDatabaseForms, setPolicies, setTypes, tenants])

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <div className={`${className} ${styles.activityForm}`}>
            <TextInput
                className={styles.textInput}
                isRequired={true}
                label={ACTIVITY_NAME}
                onChange={handleValid('activity')}
                onError={handleError('activity')}
                onSubmit={handleSubmit('activity')}
                placeholder={ENTER_ACTIVITY_NAME}
            />

            {tenants.length > 1 &&
                <Dropdown
                    logId="tenant"
                    className={styles.dropdownWide}
                    isRequired={true}
                    items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                    label="Tenant"
                    onChange={setTenant}
                    onError={handleError('tenant')}
                    onSubmit={handleSubmit('tenant')}
                    onValid={handleValid('tenant')}
                />
            }

            <DropdownMultiSelect
                items={policies}
                label={POLICY_REFERENCE}
                onError={handleError('policy')}
                onSubmit={handleSubmit('policy')}
                onValid={handleValid('policy')}
            />

            <Dropdown
                logId="activity-type"
                isRequired={true}
                items={types ? types : []}
                label={ACTIVITY_TYPE}
                onChange={onTypeChange}
                onError={handleError('activity-type')}
                onSubmit={handleSubmit('activity-type')}
                onValid={handleValid('activity-type')}
            />

            <FormField className={styles.editor} label={INSTRUCTIONS}>
                <Editor
                    html={editorState}
                    onChange={setEditorState}
                    onValid={handleValid('instructions')}
                    placeholder={ADD_INSTRUCTIONS_HERE}
                />
            </FormField>

            {expandForm &&
            <div className={styles.tableContainer}>
                <Table className={`${className} ${styles.activityTable}`}>
                    <TableRow className={`${className} ${styles.activityTableHeader}`}>
                        {headers.map((item, index) => (
                            <HeaderText
                                className={`${className} ${styles.headerText}`}
                                key={`${item}.${index}`}
                                text={item}
                            />
                        ))}
                    </TableRow>

                    {forms && forms.map((_element, index) => {
                        return(
                            <TableRow className={styles.formTableRow} key={`row_${index}`} >

                                <Dropdown
                                    logId={`name_${index}`}
                                    items={databaseForms}
                                    key={`name_${index}`}
                                    onChange={id => {handleSetFormName(id, index)}}
                                />

                                <DatePicker
                                    key={`date_${index}`}
                                    onChange={date => {handleSetFormDate(date, index)}}
                                />

                                <Dropdown
                                    logId={`assignee_${index}`}
                                    items={assignees}
                                    key={`assignee_${index}`}
                                    onChange={assignee => {handleSetAssignee(assignee, index)}}
                                />
                            </TableRow>
                        )
                    })}

                    <AddFormRow onClick={handleNewForm} />
                </Table>
            </div>
            }

            {!expandForm &&
            <AddNewFormButton onClick={handleExpandForm} text={`+ Add Form`}/>
            }
        </div>
    );
};

export default ActivityForm;
