import OverdueDetailedListItems from 'components/OverdueDetailedListItems';
import OverdueItems from 'components/OverdueItems';
import styles from './styles.module.scss';

const OverdueDetailedItems = ({ className = '', items, tenantCount, onDownloadItem }) => {
    return (
    <OverdueItems className={className}>
        {items.length > 0 ? (
            <OverdueDetailedListItems
                items={items}
                            onDownloadItem={onDownloadItem}
                tenantCount={tenantCount}
            />
        ) : (
            <p className={styles.noItems}>No items to show</p>
        )}

    </OverdueItems>
    )
}  

export default OverdueDetailedItems;
