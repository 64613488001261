import { useRecoilValue, useSetRecoilState } from 'recoil';

import state from 'app/state';

const { activityAtom } = state.activity.atoms;

const useActivity = () => [
    useRecoilValue(activityAtom),
    useSetRecoilState(activityAtom)
];

export default useActivity;
