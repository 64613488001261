import AvatarWithTitle from 'components/AvatarWithTitle';
import EditButton from 'components/EditButton';
import Title from 'components/Title';

import styles from './styles.module.scss';

const UserList = props => {
    const { className = '', hasEdit = true, onEditClick, title, users = [] } = props;

    return (
        <div className={`${className} ${styles.userList}`}>
            <Title className={styles.title} text={title} />

            {onEditClick && hasEdit ? (
                <EditButton
                    className={styles.editButton}
                    onClick={onEditClick}
                />
            ) : null}

            {users.map(({ id, firstName, lastName, name }) => {
                if (name){
                    return (
                        <AvatarWithTitle
                            className={styles.user}
                            key={`${id}_${name}`}
                            user={{id:id, name:name}}
                        />);
                }else{
                    return (
                    <AvatarWithTitle
                        className={styles.user}
                        key={`${id}_${firstName}_${lastName}`}
                        user={{id:id, name:`${firstName} ${lastName}`}}
                    />);
                }
            })}
        </div>
    );
};

export default UserList;
