import ErrorMessage from 'components/ErrorMessage';
import Label from 'components/Label';

import styles from './styles.module.scss';

const FormField = props => {
    const {
        children,
        className = '',
        errorMessage = '',
        isRequired = false,
        label = null,
        labelClassName = ''
    } = props;

    return (
        <div className={`${styles.formField} ${className}`}>
            {label && (
                <Label
                    className={`${styles.label} ${labelClassName}`}
                    isRequired={isRequired}
                    text={label}
                />
            )}

            {children}

            {errorMessage && (
                <ErrorMessage className={styles.error} text={errorMessage} />
            )}
        </div>
    );
};

export default FormField;
