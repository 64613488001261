import AvatarWithText from 'components/AvatarWithText';
import SectionWithBorder from 'components/SectionWithBorder';
import ImportantText from 'components/ImportantText';

import styles from './styles.module.scss';

const ContentBoxAvatars = props => {
    const { assigned=[], className = '', reviewer=[], section, sectionWithBorderOverride = '', _title } = props;

    return (
        <SectionWithBorder className={`${className} ${styles.contentBox} ${sectionWithBorderOverride}`}>
            {assigned.length > 0 &&
                <>
                    <ImportantText className={styles.topPadded} text={'Assigned'} />

                    {assigned.map((user, _index) => {
                        const modifiedUser = {...user};
                        if(!modifiedUser?.name && modifiedUser?.firstName && modifiedUser?.lastName){
                            modifiedUser.name = `${modifiedUser?.firstName} ${modifiedUser?.lastName}`;
                        }
                        modifiedUser.negative = modifiedUser.groupStatus==='inprogress' && section==='overdue' ? false : false;
                        modifiedUser.positive = modifiedUser.groupStatus==='complete' ? true : false;
                        modifiedUser.warning = false;
                        return (
                            <AvatarWithText
                                text={modifiedUser?.name}
                                user={modifiedUser}
                            />
                        );
                    })}
                </>
            }

            {reviewer.length > 0 &&
                <>
                    <ImportantText className={styles.topPadded} text={'Reviewer'} />

                    {reviewer.map((user, _index) => {
                        const modifiedUser = {...user};
                        if(!modifiedUser?.name && modifiedUser?.firstName && modifiedUser?.lastName){
                            modifiedUser.name = `${modifiedUser?.firstName} ${modifiedUser?.lastName}`;
                        }
                        modifiedUser.negative = modifiedUser.groupStatus==='inprogress' && section==='overdue' ? false : false;
                        modifiedUser.positive = modifiedUser.groupStatus==='complete' ? true : false;
                        modifiedUser.warning = false;
                        return (
                            <AvatarWithText
                                text={modifiedUser?.name}
                                user={modifiedUser}
                            />
                        );
                    })}
                </>
            }
        </SectionWithBorder>
    );
};

export default ContentBoxAvatars;
