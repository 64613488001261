import AdministrationActivityTypesTableHeader from 'components/AdministrationActivityTypesTableHeader';
import AdministrationActivityTypesTableRow from 'components/AdministrationActivityTypesTableRow';
import Table from 'components/Table';

const AdministrationActivityTypesTable = ({ items = [], onSort, sortBy }) => (
    <Table>
        <AdministrationActivityTypesTableHeader
            onSort={onSort}
            sortBy={sortBy}
        />

        {items.map(({ id, name, riskLevel, visibleTo }) => (
            <AdministrationActivityTypesTableRow
                id={id}
                key={id}
                name={name}
                riskLevel={riskLevel}
                visibleTo={visibleTo}
            />
        ))}
    </Table>
);

export default AdministrationActivityTypesTable;
