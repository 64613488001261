import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_activity_type`].reduce(
        (accumulator, type) => {
            accumulator.push({
                label: type.name,
                value: type.id
            });

            return accumulator;
        }, []);

const normalizeTypeData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeTypeData;