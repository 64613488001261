import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('addActivity');

    return {
        ADD_NEW_ACTIVITY: t('addNewActivity')
    };
};

export default useConstants;
