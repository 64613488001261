import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = tenantSchema => `
        ${tenantSchema}_form {
            id
            is_active
            name
            form_questions_aggregate {
              aggregate {
                count(columns: question_id)
              }
            }
            activity_forms_aggregate(where: {
							current: {_eq:true}
						}) {
              aggregate {
                count(distinct: true, columns: activity_id)
              }
            }
            activity_form_answers_aggregate {
              aggregate {
                count(columns: id)
              }
            }
        }
`;

const useData = () => {
  const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

  return useQuery(generateQueryFromDict({query, schema:usedSchema, tenants}), { fetchPolicy: 'no-cache' });
};

export default useData;
