import DropdownWithTags from 'components/DropdownWithTags';

import useTranslation from './hooks/useTranslation';

const VisibleToDropdown = ({ className = '', items, onChange }) => {
    const { VISIBLE_TO } = useTranslation();

    return (
        <DropdownWithTags
            className={className}
            items={items}
            label={VISIBLE_TO}
            onChange={onChange}
        />
    );
};

export default VisibleToDropdown;
