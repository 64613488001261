import { useQuery } from '@apollo/client';

import generateQueryFromDict from 'utilities/generateQueryFromDict';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const query = tenantSchema => `
    ${tenantSchema}_sections {
      id
      name
      description
      status
      published
      riskLevel
      effective_date
      version
      section_categories {
        category {
          label
          id
        }
      }
      policy_sections {
        document {
          name
          id
          document_categories {
            category {
              label
              id
            }
          }
        }
      }
    }
`;

const useData = () => {
  const { useSchema, useTenants, useUserSetSchema } = useGlobalStateHooks();
  const [schema] = useSchema();
  const [tenants] = useTenants();
  const [userSetSchema] = useUserSetSchema();
  const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
  return useQuery(generateQueryFromDict({query, schema:usedSchema, tenants}), { fetchPolicy: 'no-cache' });
};

export default useData;
