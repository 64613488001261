import Link from 'components/Link';

import styles from './styles.module.scss';

const AsideLink = ({ className = '', onClick, text = '', to = '' }) => (
    <Link
        className={`${className} ${styles.asideLink}`}
        onClick={onClick}
        to={to}
    >
        {text}
    </Link>
);

export default AsideLink;
