import { useEffect, useMemo, useState } from 'react';
import {useSearchParams} from 'react-router-dom';

import RequestsTable from 'components/RequestsTable';
import ContentPanel from 'components/ContentPanel';
import Dropdown from 'components/Dropdown';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import PanelBody from 'components/PanelBody';
import SearchInput from 'components/SearchInput';


import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useRequests from './hooks/useRequests';
import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import normalizeData from './data';

import styles from './styles.module.scss';

const MarketingRequestsPanel = props => {
    const { className = '' } = props;

    const [searchParams] = useSearchParams();
    const userIdParam = searchParams.get('userId');
    const statusParam = searchParams.get('status');
    const openRequestUserIdParam = searchParams.get('openRequestUserId');

    const [statusFilter, setStatusFilter] = useState(statusParam || null);
    const [assignedToFilter, setAssignedToFilter] = useState([]);
    const [requestedByFilter, setRequestedByFilter] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");

    const { useQuerySchemas, useSchema, useTenants } = useGlobalStateHooks();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();

    const { loading, error, data } = useData();
    const [filteredRequests=[], setFilteredRequests] = useState([]);
    const [requests, setRequests] = useRequests();

    const { REQUEST_STATUS, SEARCH, VIEW_ALL, ASSIGNED_TO, REQUESTED_BY } = useTranslation();

    const dropdownItems = useMemo(() => {
        if (!requests) {
            return [];
        }

        const dataItems = requests.reduce(
            (accumulator, { status }) => {
                if (!(status in accumulator.items)) {
                    accumulator.items[status] = 0;
                }

                accumulator.items[status]++;
                accumulator.totalCount++;

                return accumulator;
            },
            { items: {}, totalCount: 0 }
        );

        const { items, totalCount } = dataItems;

        const options = Object.keys(items).map(key => ({
            label: `${key} (${items[key]})`,
            value: key
        }));

        const defaultItem = {
            label: `${VIEW_ALL} (${totalCount})`,
            value: null
        };

        return [defaultItem, ...options];
    }, [VIEW_ALL, requests]);

    const assignedToDropdownItems = useMemo(() => {
        if (!requests || requests.length === 0) {
            return [];
        }

        const assignedToOptions = requests.reduce((acc, request) => {
            request.assignedTo.forEach(person => {
                const idStr = `${person.id}`;
                if (!acc.find(option => option.value === idStr)) {
                    acc.push({
                        label: `${person.firstName} ${person.lastName}`,
                        value: idStr,
                    });
                }
            });
            return acc;
        }, []);

        return [...assignedToOptions];
    }, [requests]);

    const requestedByDropdownItems = useMemo(() => {
        if (!requests || requests.length === 0) {
            return [];
        }

        const requestedByOptions = requests.reduce((acc, request) => {
            const idStr = `${request.requestedBy.id}`;
            if (!acc.find(option => option.value === idStr)) {
                acc.push({
                    label: `${request.requestedBy.firstName} ${request.requestedBy.lastName}`,
                    value: idStr,
                });
            }
            return acc;
        }, []);

        return [...requestedByOptions];
    }, [requests]);

    useEffect(() => {
        if (assignedToDropdownItems.length > 0 && openRequestUserIdParam) {
            const filteredUserIds = assignedToDropdownItems
                .filter(item => item.value !== openRequestUserIdParam)
                .map(item => item.value);

            setAssignedToFilter(filteredUserIds);
        } else if (userIdParam) {
            setAssignedToFilter([userIdParam]);
        }
    }, [assignedToDropdownItems, openRequestUserIdParam, userIdParam]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });
            let shownRequests = normalizedData;

            // Filter by status
            if (statusFilter) {
                shownRequests = shownRequests.filter(({ status }) => status === statusFilter);
            }

            // Filter by Assigned To
            if (assignedToFilter && assignedToFilter.length > 0) {
                shownRequests = shownRequests.filter(({ assignedTo }) =>
                    assignedTo.some(person => assignedToFilter.map(id => `${id}`).includes(`${person.id}`)));
            }

            // Filter by Requested By
            if (requestedByFilter && requestedByFilter.length > 0) {
                shownRequests = shownRequests.filter(({ requestedBy }) =>
                    requestedByFilter.map(id => `${id}`).includes(`${requestedBy.id}`));
            }

            if (searchFilter.length > 3 &&  searchFilter !== "") {
                shownRequests = shownRequests.filter(({ requestName }) => requestName.toLowerCase().includes(searchFilter.toLowerCase()));
            }

            let ordering = {}, sortOrder = ['draft', 'approved', 'withdrawn'];
            for (let i=0; i<sortOrder.length; i++)
                ordering[sortOrder[i]] = i;

            if(normalizedData.length > 0 && (!requests || requests.length === 0)){
                setRequests(normalizedData);
            }
            setFilteredRequests(shownRequests);
        }
    }, [data, querySchemas, schema, setRequests, setFilteredRequests, statusFilter, tenants, assignedToFilter, requestedByFilter, searchFilter, requests]);

    if (error) {
        return `Error! ${error}`;
    }

		const isEmpty = filteredRequests.length === 0;

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody} isLoading={loading}>
                <div className={styles.panelHeader}>
                    <Dropdown
                        className={styles.dropdown}
                        items={dropdownItems}
                        label={REQUEST_STATUS}
                        onChange={setStatusFilter}
                        selected={statusFilter}
                    />

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={assignedToDropdownItems}
                        label={ASSIGNED_TO}
                        onChange={setAssignedToFilter}
                        selected={assignedToFilter}
                    />

                    <DropdownMultiSelect
                        className={styles.dropdown}
                        items={requestedByDropdownItems}
                        label={REQUESTED_BY}
                        onChange={setRequestedByFilter}
                        selected={requestedByFilter}
                    />

                    <SearchInput
                        className={styles.search}
                        onChange={setSearchFilter}
                        placeholder={SEARCH}
                    />
                </div>

                {isEmpty && (
                    <div className={styles.emptyTable}>
                        <p>No items to show</p>
                    </div>
                )}

                {!isEmpty && (
                    <RequestsTable
                        className={styles.table}
                        items={filteredRequests}
                    />
                )}
            </PanelBody>
        </ContentPanel>
    );
};

export default MarketingRequestsPanel;
