import { useRecoilCallback } from 'recoil';
import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const MUTATE_INSERT_NEW_CATEGORY = tenantSchema => gql`
mutation addCategory($label: String!) {
    insert_${tenantSchema}_categories_one(object: {label: $label}) {
      id
    }
  }
`;

const MUTATE_INSERT_DOCUMENT_CATEGORY = tenantSchema => gql`
mutation addSectionCategory($categoryId: Int!, $sectionId: Int!) {
    insert_${tenantSchema}_section_category_one(object: {category_id: $categoryId, section_id: $sectionId}) {
      id
    }
  }
`;

const useAddCategory = (providedSchema) => {
    const mutationNewCategory = useGraphQL(MUTATE_INSERT_NEW_CATEGORY, providedSchema);
    const mutationNewDocumentCategory = useGraphQL(MUTATE_INSERT_DOCUMENT_CATEGORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const addCategory = useRecoilCallback(() => variables => {
        const runMutation = async () => {
            const { existingCategories, newCategories, sectionId } = variables;
            const consolidatedCategories = [...existingCategories]
            for(const nc of newCategories){
              const ncidRes = await mutationNewCategory({label:nc});
              const ncid = ncidRes?.data[`insert_${usedSchema}_categories_one`]?.id
              consolidatedCategories.push({label:nc, value:ncid});
            }
            const selectedCategoryIds = consolidatedCategories.map((obj) => obj.value).filter((obj) => obj !== undefined);

            for(const scid of selectedCategoryIds){
                await mutationNewDocumentCategory({categoryId:scid, sectionId});
            }
        };

        runMutation();
    });

    return [addCategory];
};

export default useAddCategory;
