const schemaData = ({ data={}, querySchemas=[], schema, user }) => {
    const marketingRequests = data[`${schema}_marketing_request_assigned_to`].reduce(
        (accumulator, { marketing_request: marketingRequest }) => {
            const {
                request_name: name,
                due_date: dueDate,
                high_importance: highImportance,
                id
            } = marketingRequest;
            accumulator.push({
                dueDate,
                highImportance,
                id,
                name,
                type: 'marketing'
            })
            return accumulator;
        }, []
    );

    const activities = data[`${schema}_user_activity`].reduce(
        (accumulator, { activity }) => {
            const {
                due_date: dueDate,
                id,
                is_hierarchical: isHierarchical,
                name,
                user_activities: userActivities=[],
                group_completion_rate: groupCompletionRate
            } = activity;
            const userInt = parseInt(user);

            const groups = userActivities.reduce(
                (accumulator, user) => {
                    const {
                        assignment_type: group,
                        status,
                        tenant_user: userDetails,
                        group: userGroup
                    } = user

                    if(userDetails || userGroup){
                        const { id } = (userDetails) ? userDetails : userGroup;
                        if(querySchemas && querySchemas.length > 0 && querySchemas[0] !== schema){
                            for(const tenant of querySchemas){
                                const userArray = (userDetails) && (`users_${tenant.value}` in userDetails) && userDetails[`users_${tenant.value}`].length > 0
                                ? userDetails[`users_${tenant.value}`][0] : (userGroup)
                                ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                {'first_name': '', 'last_name': '', 'profile_photo': ''}
                                const {
                                    first_name: firstName,
                                    last_name: lastName,
                                    profile_photo: profilePhoto
                                } = userArray;

                                const values = {
                                    firstName,
                                    id,
                                    lastName,
                                    profilePhoto,
                                    status,
                                }
                                if(values.firstName !== ''){
                                    if (typeof accumulator === 'object' && group in accumulator){
                                        accumulator[group].users.push(values);
                                    }else if(typeof accumulator === 'object'){
                                        accumulator[group] = {users: [values]};
                                    }else{
                                        accumulator = {};
                                        accumulator[group] = {users: [values]};
                                    }
                                }
                            }
                        }else{
                            const userArray = (userDetails) && (`users_${schema}` in userDetails) && userDetails[`users_${schema}`].length > 0
                            ? userDetails[`users_${schema}`][0] : (userGroup)
                            ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                            {'first_name': '', 'last_name': '', 'profile_photo': ''}

                            const {
                                first_name: firstName,
                                last_name: lastName,
                                profile_photo: profilePhoto
                            } = userArray;

                            const values = {
                                firstName,
                                id,
                                lastName,
                                profilePhoto,
                                status,
                            }
                            if (typeof accumulator === 'object' && group in accumulator){
                                accumulator[group].users.push(values);
                            }else if(typeof accumulator === 'object'){
                                accumulator[group] = {users: [values]};
                            }else{
                                accumulator = {};
                                accumulator[group] = {users: [values]};
                            }
                        }
                    }
                    return accumulator
                },{}
            );
            const userGroups = Object.keys(groups)
                            .sort()
                            .reduce(function (acc, key) {
                                acc[key] = groups[key];
                                return acc;
                            }, {})
            for(const [index, [key]] of Object.entries(Object.entries(userGroups))){
                const group = userGroups[key].users;
                var groupProgress
                if(groupCompletionRate && groupCompletionRate[index]){
                    if (typeof groupCompletionRate[index] === 'string' && groupCompletionRate[index] === 'all'){
                        groupProgress = group.some(
                            ({ status }) => status !== 'complete'
                        );
                    }else if (typeof groupCompletionRate[index] === 'number' && groupCompletionRate[index] > 1){
                        const complete = group.filter(({status}) => status === 'complete');
                        groupProgress = ((complete.length/group.length)*100) >= groupCompletionRate['index']
                    }else{
                        groupProgress = group.every(
                            ({ status }) => status !== 'complete'
                        );
                    }
                    userGroups[key]['status'] = ((groupProgress) ? 'inprogress' : 'complete')
                }
            }
            const users = userActivities.reduce(
                (accumulator, { assignment_type: assignmentType, status, tenant_user: tenantUser, group }) => {
                    const groupStatus = userGroups[assignmentType]?.status === 'complete' ? userGroups[assignmentType]?.status : status;
                    if( tenantUser ){
                        const id = tenantUser.id;

                        var users;
                        if(querySchemas.length > 0 && querySchemas[0] !== schema){
                            for(const tenant of querySchemas){
                                if(tenantUser[`users_${tenant.value}`].length > 0){
                                    users = tenantUser[`users_${tenant.value}`];
                                }
                            }
                        }else{
                            users = tenantUser[`users_${schema}`];
                        }

                        const {
                            first_name: firstName,
                            last_name: lastName,
                            profile_photo: profilePhoto
                        } = users && users.length > 0 ? users[0] : {'first_name': '', 'last_name': '', 'profile_photo': ''};

                        const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                        if(assignmentType !== 'reviewer'){
                            accumulator.assigned.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName,
                                profilePhoto,
                                status
                            });
                        }else{
                            accumulator.reviewer.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName,
                                profilePhoto,
                                status
                            });
                        }

                        return accumulator;
                    }else if (group){
                        const id = group.id;

                        const {
                            name: firstName
                        } = group;

                        const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                        if(assignmentType !== 'reviewer'){
                            accumulator.assigned.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName: "Group",
                                profilePhoto: '',
                                status: ''
                            });
                        }else{
                            accumulator.reviewer.push({
                                assignmentNumber,
                                firstName,
                                groupStatus,
                                id,
                                lastName: "Group",
                                profilePhoto: '',
                                status: ''
                            });
                        }

                        return accumulator;
                    }else{
                        return accumulator
                    }
                },
                {assigned: [], reviewer: []}
            );
            if (
                (!isHierarchical && users.assigned.some(({ id }) => id === userInt) && users.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && users.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                (isHierarchical && users.assigned.some(({ id }) => id === userInt) && (users.assigned.filter(x => x.id === userInt)[0].assignmentNumber === 1 || users.assigned.filter(y => y.assignmentNumber < users.assigned.filter(x => x.id === userInt)[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && users.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && users.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                (users.assigned.every(x => x.groupStatus === 'complete') && users.reviewer.some(({ id }) => id === userInt))
            ) {
                accumulator.push({
                    dueDate,
                    highImportance: false,
                    id,
                    name,
                    type: 'activity'
                })
            }
            return accumulator;
        }, []
    );
    const cases = data[`${schema}_user_case`].reduce(
        (accumulator, { case: userCase }) => {
            const {
                caseTypeByCaseType,
                due_date: dueDate,
                id
            } = userCase;
            const {
                name
            } = caseTypeByCaseType;
            accumulator.push({
                dueDate,
                highImportance: false,
                id,
                name,
                type: 'case'
            })
            return accumulator;
        }, []
    );
    return {activities, cases, marketingRequests}
};

const normalizeData = ({ data, querySchemas, schema, tenants, userId }) => {
    if(Array.isArray(tenants) && tenants.length > 0){
        const tenantActivities = [];
        const tenantCases = [];
        const tenantMarketingRequests = [];
        for(const t of tenants){
            if(t){
                const { activities, cases, marketingRequests } = schemaData({ data, querySchemas, schema:t, user:userId });
                tenantActivities.push(...[...activities].sort(
                    (a, b) => (a.dueDate > b.dueDate ? 1 : -1)
                ));
                tenantCases.push(...[...cases].sort(
                    (a, b) => (a.dueDate > b.dueDate ? 1 : -1)
                ));
                tenantMarketingRequests.push(...[...marketingRequests].sort(
                    (a, b) => (a.dueDate > b.dueDate ? 1 : -1)
                ));
            }
        }
        return { activites: tenantActivities, cases: tenantCases, marketingRequests: tenantMarketingRequests};
    }else{
        return schemaData({ data, schema });
    }
}

export default normalizeData;
