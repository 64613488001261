import normalizeListData from "utilities/normalizeListData";

const schemaData = ({ data, schema }) =>
    data[`${schema}_regulatory_references`].reduce(
        (accumulator, reference) => {
            const { id, name } = reference;

            accumulator.push({
                label: name,
                value: id,
            })

            return accumulator;
        }, []);

const normalizeRegulatoryReferencesData = ({ data, schema, tenants }) => normalizeListData({ data, schema, schemaData, tenants });

export default normalizeRegulatoryReferencesData;