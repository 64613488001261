import { gql } from '@apollo/client';

import useGraphQL from 'hooks/useGraphQL';

const MUTATE = tenantSchema => gql`
mutation mutateSectionMane($description: String!, $id: Int!) {
    update_${tenantSchema}_sections(where: {id: {_eq: $id}}, _set: {description: $description}) {
      returning {
        name
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const saveSectionName =  variables => {
        const runMutation = async () => {
            if(variables?.description !== ''){
              await mutation(variables);
            }
        };

        runMutation();
    };

    return [saveSectionName];
};

export default useData;
