import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('activity');

    return {
        DELETED: t('deleted'),
    };
};

export default useConstants;
