import { atom } from 'recoil';

const addPolicySectionModalAtom = atom({
    default: '',
    key: 'addPolicySectionModal'
});

const state = {
    atoms: {
        addPolicySectionModalAtom
    }
};

export default state;
