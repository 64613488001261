import FormSettingsTableHeader from 'components/FormSettingsTableHeader';
import FormSettingsTableRow from 'components/FormSettingsTableRow';
import Table from 'components/Table';

const FormSettingsTable = ({ items = [] }) => {
    return (
        <Table>
            <FormSettingsTableHeader />

            {items.map(({ field, id, status, visibility }) => (
                <FormSettingsTableRow
                    field={field}
                    key={id}
                    status={status}
                    visibility={visibility}
                />
            ))}
        </Table>
    );
};

export default FormSettingsTable;
