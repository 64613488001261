import AsideLink from 'components/AsideLink';

import styles from './styles.module.scss';

const ViewAllLink = ({ className = '', onClick, to = '' }) => (
    <AsideLink
        className={`${className} ${styles.asideLink}`}
        onClick={onClick}
        text="View All"
        to={to}
    />
);

export default ViewAllLink;
