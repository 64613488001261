import InProgressDetailedListItems from 'components/InProgressDetailedListItems';
import InProgressItems from 'components/InProgressItems';
import styles from './styles.module.scss';

const InProgressDetailedItems = ({ className = '', items, onDownloadItem, tenantCount }) =>  (
    <InProgressItems className={className}>
        {items.length > 0 ? (
            <InProgressDetailedListItems
                items={items}
                            onDownloadItem={onDownloadItem}
                tenantCount={tenantCount}
            />
        ) : (
            <p className={styles.noItems}>No items to show</p>
        )}
    </InProgressItems>
);

export default InProgressDetailedItems;
