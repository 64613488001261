const remap = ({ items = [], map = {} }) => {
    const itemKeys = Object.keys(items[0]);

    return items.map(item =>
        itemKeys.reduce((aggregator, itemKey) => {
            aggregator[map[itemKey] || itemKey] = item[itemKey];

            return aggregator;
        }, {})
    );
};

export default remap;
