const httpAuthRefresh = async (url = '', data, options = {}) => {
    const bearer = 'Bearer'.concat(' ', localStorage.getItem('refreshToken'));
    const requestOptions = {
        body: data,
        //cache: 'no-cache',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': bearer
        },
        method: 'GET',
        //mode: 'no-cors',
        redirect: 'follow',
        ...options
    };

    try {
        const response = await fetch(url, requestOptions);
        
        return await response.json();
    } catch (error) {
        console.error(error);

        return error;
    }
};

export default httpAuthRefresh;
