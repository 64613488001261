import Avatar from 'components/Avatar';
import Hamburger from 'components/Hamburger';
import NotificationIcon from 'components/NotificationIcon';
import Logo from 'components/Logo';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';
import LogoutIcon from 'components/icons/LogoutIcon';
import logout from 'utilities/logout';

const Header = ({ className = '', onHamburgerOpen, showHamburguer }) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const handleLogout = () => {
        logout();
    };

    return (
        <header className={`${className} ${styles.header}`}>
            {showHamburguer ? (
                <Hamburger className={styles.hamburger} onClick={onHamburgerOpen} />
            ): (
                <Logo className={styles.logo} />
            )}

            <NotificationIcon className={styles.notificationIcon} />

            <LogoutIcon onClick={handleLogout} />

            <Avatar
                className={styles.avatar}
                user={{
                    id: userId,
                    name: `${localStorage.firstName} ${localStorage.lastName}`
                }}
            />

        </header>
    );
};

export default Header;
