import normalizeListData from 'utilities/normalizeListData';

const schemaData = ({ data, schema }) =>
    data[`${schema}_form`].reduce((accumulator, form) => {
        const {
            id: formId,
            is_active: isActive,
            name,
            form_questions_aggregate: formQuestionsAggregate,
            activity_forms_aggregate: activitiesAggregate,
            activity_form_answers_aggregate: answersAggregate
        } = form;

        accumulator.push({
            activities: activitiesAggregate?.aggregate?.count,
            answers: answersAggregate?.aggregate?.count,
            formId,
            formSchema: schema,
            isActive,
            name,
            questions: formQuestionsAggregate?.aggregate?.count
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, querySchemas, schema, tenants }) =>
    normalizeListData({ data, querySchemas, schema, schemaData, tenants });

export default normalizeData;
