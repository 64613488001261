import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const ListItem = ({ children, className = '', onClick }) => {
    const handleClick = event => {
        if (isFunction(onClick)) {
            onClick(event);
        }
    };

    return (
        <li className={`${className} ${styles.listItem}`} onClick={handleClick}>
            {children}
        </li>
    );
};

export default ListItem;
