const translations = {
    activityTypeDescription: 'Activity Type Description',
    activityTypeName: 'Activity Type Name',
    addExistingForm: '+ Add Existing Form',
    category: 'Category',
    daysToCompletion: 'Days To Completion',
    formName: 'Form Name',
    policy: 'Policy Reference',
    recipient: 'Recipients',
    regulatoryReference: 'Regulatory Reference',
    riskLabel: 'Risk Level',
    visibleBy: 'Visible By'
};

export default translations;
