import Pill from 'components/Pill';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const GroupPill = ({ className = '', label = '' }) => {
    const { GROUP } = useTranslation();

    if (label===''){
        return (
            <Pill className={`${styles.groupPill} ${className}`} label={GROUP} />
        );
    }else{
        return (
            <Pill className={`${styles.groupPill} ${className}`} label={label} />
        );
    }
};

export default GroupPill;
