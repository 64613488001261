import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateGroupMembers } = state;

const { updateGroupMembersAtom } = updateGroupMembers.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateAddGroupMember(
  $groupId: Int!,
  $id: Int
  ) {
    insert_${tenantSchema}_group_user(
      objects: {
        group_id: $groupId
        tenant_user_id: $id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const addGroupMember = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const addedGroupMember = res?.data[`insert_${usedSchema}_group_user`].returning[0]?.id;

            set(updateGroupMembersAtom, addedGroupMember);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateGroupMembersAtom, '');
    });

    return [useRecoilValue(updateGroupMembersAtom), addGroupMember, reset];
};

export default useData;
