import { atom } from 'recoil';

const completedActivityUpcomingSummarizedAtom = atom({
    default: '',
    key: 'completedActivityUpcomingSummarized'
});

const state = {
    atoms: {
        completedActivityUpcomingSummarizedAtom
    }
};

export default state;
