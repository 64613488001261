const translations = {
    assignedTo: 'Assigned To',
    caseType: 'Case Type',
    dueDate: 'End Date',
    entities: 'Entities',
    riskLabel: 'Risk Label',
    search: 'Search',
    startDate: 'Start Date',
    viewAll: 'View All'
};

export default translations;
