import { useCallback } from 'react';

import Tab from 'components/Tab';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const Tabs = props => {
    const { className = '', items, onSelect, selectedTab } = props;

    const handleSelect = useCallback(
        selectedItem => () => {
            if (isFunction(onSelect)) {
                onSelect(selectedItem);
            }
        },
        [onSelect]
    );

    return (
        <div className={`${className} ${styles.tabs}`}>
            {items.map(({ className: tabClassName = '', label, value }) => (
                <Tab
                    className={tabClassName}
                    isActive={value === selectedTab}
                    key={value}
                    onSelect={handleSelect(value)}
                    text={label}
                    value={value}
                />
            ))}
        </div>
    );
};

export default Tabs;
