import { useQuery, gql } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const querySpecGroup = (tenantSchema, activityTypeId) => gql`
    query getData {
        ${tenantSchema}_activity_type (where: {id: {_eq: ${activityTypeId}}}) {
            case_name
            id
            name
            risk_label
        }
    }
`;

const useData = (activityTypeId) => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [tenants] = useTenants();

    return useQuery(querySpecGroup(schema, activityTypeId, tenants), { fetchPolicy: 'no-cache' });
};

export default useData;
