import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('criticalRiskPill');

    return {
        CRITICAL: t('critical')
    };
};

export default useTranslation;
