import useTranslate from 'app/i18n/useTranslate';

const useConstants = () => {
    const t = useTranslate('entityTags');

    return {
        ENTITY_TAGS: t('entityTags')
    };
};

export default useConstants;
