import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceLogTableHeader');

    return {
        COMPANY: t('company'),
        DATE_OF_OCCURENCE: t('dateOfOccurence'),
        DESCRIPTION: t('description'),
        LABEL: t('label'),
        LOG_CATEGORY: t('logCategory')
    };
};

export default useTranslation;
