import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addGroup } = state;

const { addGroupAtom } = addGroup.atoms;

const MUTATE_GROUP = tenantSchema => gql`
    mutation insertGroup (
        $name: String!, 
        $description: String!
    ) {
        insert_${tenantSchema}_group(
            objects: {
                name: $name,
                description: $description
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_GROUP_SUPERVISORS = tenantSchema => gql`
    mutation insertGroupSupervisors (
        $groupId: Int!, 
        $supervisoryGroupId: Int,
        $userId: Int
    ) {
        insert_${tenantSchema}_group_supervisor(
            objects: {
                group_id: $groupId,
                supervisory_group_id: $supervisoryGroupId,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_GROUP_MEMBERS = tenantSchema => gql`
    mutation insertGroupMembers (
        $groupId: Int!, 
        $userId: Int
    ) {
        insert_${tenantSchema}_group_user(
            objects: {
                group_id: $groupId,
                tenant_user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_GROUP, providedSchema);
    const mutationSupervisor = useGraphQL(MUTATE_GROUP_SUPERVISORS, providedSchema);
    const mutationMember = useGraphQL(MUTATE_GROUP_MEMBERS, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveRequest = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const id = res?.data[`insert_${usedSchema}_group`].returning[0]?.id;

            const newSupervisors = JSON.parse(variables?.supervisors);
            for (let supervisor of newSupervisors) {
                await mutationSupervisor({
                    groupId: id,
                    supervisoryGroupId: (supervisor?.type==='group') ? supervisor?.value : null,
                    userId: (supervisor?.type==='user') ? supervisor?.value : null
                });
            }

            const newMembers = JSON.parse(variables?.members);
            for (let member of newMembers) {
                await mutationMember({
                    groupId: id,
                    userId: member.id
                });
            }

            set(addGroupAtom, id);
        }

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addGroupAtom, '');
    });

    return [useRecoilValue(addGroupAtom), saveRequest, reset];
};

export default useData;
