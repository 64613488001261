import httpPostStreaming from 'utilities/httpPostStreaming';

async function downloadFile(directoryName, fileName, apiKey, getFileUrl) {
  const formData = new FormData();
  formData.append('file_name', fileName);
  formData.append('schema', directoryName);
  const accessToken = localStorage.getItem('accessToken')
  formData.append('access_token', accessToken);
  const blob = await httpPostStreaming({data:formData, key: apiKey ? apiKey : '', url:getFileUrl});
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    fileName,
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  return link
}

export default downloadFile;