import BarListItems from 'components/BarListItems';
import TitleListItem from 'components/TitleListItem';

import useTranslation from './hooks/useTranslation';
import { usePermissions } from 'hooks/usePermissions';

import styles from './styles.module.scss';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const DetailedListItems = ({ children, className = '', tenantCount=1 }) => { 
    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const { ACTIONS, ACTIVITY_TYPE, ASSIGNED, COMPANY, DUE_DATE, ENTITIES, REF, RECURRENCE, REVIEWER, START_DATE } = useTranslation();
    
    return (
        <BarListItems className={className}>
            <TitleListItem
                className={tenantCount > 1 ? styles.titleListItemMultiTenant : styles.titleListItem}
                items={tenantCount > 1 ? ['', START_DATE, DUE_DATE, ACTIVITY_TYPE, ASSIGNED, REVIEWER, COMPANY, REF, RECURRENCE, ENTITIES, activitiesActionsPermission ? ACTIONS : ''] : ['', START_DATE, DUE_DATE, ACTIVITY_TYPE, ASSIGNED, REVIEWER, REF, RECURRENCE, ENTITIES, activitiesActionsPermission ? ACTIONS : '']}
            />

            {children}
        </BarListItems>
    );
    
};

export default DetailedListItems;
