import { useCallback } from 'react';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

const AddNewFormButton = ({
    children,
    className = '',
    onClick,
    text
}) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <div
            className={`${className} ${styles.addNewFormButton}`}
            onClick={handleClick}
        >
            {children || text}
        </div>
    );
};

export default AddNewFormButton;
