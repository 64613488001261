import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';

import image from './image.png';

import styles from './styles.module.scss';

const ProgramOverviewPanel = ({ className = '' }) => {

    return (
        <ContentPanel className={`${className} ${styles.programOverviewPanel}`}>
            <PanelBody className={styles.panelBody}>
                <img alt="img" src={image} />
            </PanelBody>
        </ContentPanel>
    );
};

export default ProgramOverviewPanel;
