import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('activityTypeEditForm');

    return {
        ACTIVITY_TYPE: t('activityType'),
        ACTIVITY_TYPE_NAME: t('activityTypeName'),
        RISK_LABEL: t('riskLabel'),
    };
};

export default useTranslation;
