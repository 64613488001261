import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('documentManagementRepositoryPanel');

    return {
        DATE: t('date'),
        NAME: t('name'),
        REFERENCE_NAME: t('referenceName'),
        REFERENCE_TYPE: t('referenceType'),
        SEARCH: t('search'),
        TYPE: t('type'),
        UPLOAD_BY: t('uploadBy'),
        VIEW_ALL: t('viewAll'),
    };
};

export default useTranslation;
