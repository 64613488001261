import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateCaseName } = state;

const { updateCaseNameAtom } = updateCaseName.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateCaseName($text: String!, $caseId: Int!) {
    update_${tenantSchema}_case(where: {id: {_eq: $caseId}}, _set: {case_name: $text}) {
      returning {
        case_name
      }
    }
  }
`;

const MUTATE_CASE_HISTORY = tenantSchema => gql`
    mutation insertCaseHistoryDropdown($caseId: Int!, $color: String = "yellow", $message: String = "changed the case name", $userId: Int!) {
        insert_${tenantSchema}_case_history(
            objects: {
                case_id: $caseId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);
    const mutationCaseHistory = useGraphQL(MUTATE_CASE_HISTORY, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveCaseName = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            // if(variables?.text !== ''){
              const res = await mutation(variables);

              const newName = res?.data[`update_${usedSchema}_case`].returning[0]?.case_name;

              await mutationCaseHistory(variables);

              set(updateCaseNameAtom, newName);
            // }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateCaseNameAtom, '');
    });

    return [useRecoilValue(updateCaseNameAtom), saveCaseName, reset];
};

export default useData;
