import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('marketingRequestMetaPanel');

    return {
        ACTIVE_PARTY: t('activeParty'),
        CREATED_BY: t('createdBy')
    };
};

export default useTranslation;
