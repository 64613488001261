import HeaderText from 'components/HeaderText';
import TableRow from 'components/TableRow';

import styles from './styles.module.scss';

const CaseTableHeader = ({ className = '', dashboard, items = [], styleType, tenantCount=1 }) => {
    const headerStyle = dashboard ? styles.caseTableHeaderDashboard : tenantCount > 1 ? styles.caseTableHeaderMultiTenant : styles.caseTableHeader;
    return(
        <TableRow className={`${className} ${headerStyle}`}>
            {items.map((item, index) => (
                <HeaderText
                    className={`${className} ${index===0?styles.titleText:styles.headerText} ${styleType==='overdue'&&index===0?styles.overdueTitle:styleType==='pending'&&index===0?styles.pendingTitle:styleType==='closed'&&index===0?styles.closedTitle:styles.defaultTitle}`}
                    key={`${item}.${index}`}
                    text={item}
                />
            ))}
        </TableRow>
    );
}

export default CaseTableHeader;
