import { useCallback } from 'react';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import DeleteButton from 'components/DeleteButton';
import SaveButton from 'components/SaveButton';

import isFunction from 'utilities/isFunction';

const CancelDeleteSaveButtons = ({
    className = '',
    onCancel,
    onDelete,
    onSave
}) => {
    const handleCancel = useCallback(
        clickEvent => {
            if (isFunction(onCancel)) {
                onCancel(clickEvent);
            }
        },
        [onCancel]
    );

    const handleDelete = useCallback(
        clickEvent => {
            if (isFunction(onDelete)) {
                onDelete(clickEvent);
            }
        },
        [onDelete]
    );

    const handleSave = useCallback(
        clickEvent => {
            if (isFunction(onSave)) {
                onSave(clickEvent);
            }
        },
        [onSave]
    );

    return (
        <Buttons className={className}>
            <CancelButton onClick={handleCancel} />

            <DeleteButton onClick={handleDelete} />

            <SaveButton onClick={handleSave} />
        </Buttons>
    );
};

export default CancelDeleteSaveButtons;
