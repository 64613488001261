import Container from 'components/Container';
import ContentPanel from 'components/ContentPanel';
import MarketingRequestForm from 'components/MarketingRequestForm';
import PanelBody from 'components/PanelBody';
import RequestedUpdates from 'components/RequestedUpdates';
import Title from 'components/Title';
import VersionTag from 'components/VersionTag';
import VersionTags from 'components/VersionTags';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useVariableForm from 'hooks/useVariableForm';
import { useGetMarketingFormData } from '../../pages/AddMarketingRequest/hooks/useMarketingForm';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

import { useGetAppMarketingSettings } from 'hooks/useAppMarketingSettings';
import { computeQuestionWithAnswers } from 'pages/AddMarketingRequest/data';
import { useEffect, useState } from 'react';

const MarketingRequestDetailsPanel = props => {
    const { useSchema } = useGlobalStateHooks();
    const [schema] = useSchema();
    const {
        attachmentsOnDelete,
        attachmentsOnUpdate,
        className = '',
        dropdownSelections,
        fileUploader,
        form,
        request = {},
        requestSchema,
        version = {}
    } = props;

    const [isPreloadedAnswers, setIsPreloadedAnswers] = useState(false);

    const { feedback } = request;

    const { data: settings } = useGetAppMarketingSettings();

    const formData = useGetMarketingFormData({
        marketingRequestId: request?.id,
        schema
    });
		const { forms, answers } = formData ?? {};
    const questionsWithAnswers = computeQuestionWithAnswers({ answers, forms });

    const variableForm = useVariableForm();
    const { handleForm: handleVariableForm } = variableForm;
    const {
        handleError: handleVariableError,
        handleSubmit: handleVariableSubmit,
        handleValid: handleVariableValid,
        handleValidPreBulk: handleVariableValidPre
    } = variableForm;

    const { INSTRUCTIONS, REQUEST_DETAILS, VERSION } = useTranslation();

    const { status = '', versionNumber = '' } = version;

    useEffect(() => {
        if (questionsWithAnswers && !isPreloadedAnswers) {
            preloadAnswers();
            setIsPreloadedAnswers(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsWithAnswers]);

    function preloadAnswers() {
        const preloadAnswers = {};
        for (const question of questionsWithAnswers) {
            if (question?.answer && typeof question?.answer !== 'undefined') {
                const answerArray =
                    question?.answer && question?.type === 'checkBoxInput'
                        ? question?.answer.split(',').map(x => parseInt(x))
                        : question?.answer;
                if (Array.isArray(answerArray) && answerArray.length > 0) {
                    for (const answer of answerArray) {
                        preloadAnswers[`${question?.id}_${answer}`] = 'true';
                    }
                } else {
                    preloadAnswers[question?.id] = question?.answer;
                }
            }
        }
        handleVariableValidPre(preloadAnswers);
    }

    return (
        <ContentPanel className={className}>
            <PanelBody className={styles.panelBody}>
                <Title className={styles.title} text={REQUEST_DETAILS} />

                <VersionTags className={styles.versionTags}>
                    <VersionTag status={status} text={status} />

                    <VersionTag
                        status={status}
                        text={`${VERSION}: ${versionNumber}`}
                    />
                </VersionTags>

                <Container className={styles.container}>
                    <RequestedUpdates
                        items={feedback}
                        versionNumber={versionNumber}
                    />

                    <MarketingRequestForm
                        dropdownSelections={dropdownSelections}
                        dropdowns={settings?.marketing_dropdowns}
                        dynamicForm={
                            settings?.marketing_form &&
                            settings?.marketing_form.length > 0
                                ? settings?.marketing_form[0]
                                : 0
                        }
                        fileUploader={fileUploader}
                        form={form}
                        forms={forms}
                        handleForm={handleVariableForm}
                        handleVariableError={handleVariableError}
                        handleVariableSubmit={handleVariableSubmit}
                        handleVariableValid={handleVariableValid}
                        handleVariableValidPre={handleVariableValidPre}
                        instructions={INSTRUCTIONS}
                        onDelete={attachmentsOnDelete}
                        onUpdate={attachmentsOnUpdate}
                        providedSchema={requestSchema}
                        questionsWithAnswers={questionsWithAnswers}
                        request={request}
                        version={version}
                    />
                </Container>
            </PanelBody>
        </ContentPanel>
    );
};

export default MarketingRequestDetailsPanel;
