const translations = {
    addDescription: 'Add description',
    categories: 'Categories',
    description: 'Description',
    details: 'Details',
    effectiveDate: 'Effective date',
    policyDocument: 'Document',
    policyName: 'Document name',
    selectDocument: 'Select document to upload',
    upload: 'Upload'
};

export default translations;
