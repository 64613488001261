import { useCallback, useState } from 'react';

import ApproveButton from 'components/ApproveButton';
import ApproveRequestModal from 'components/ApproveRequestModal';

const ApproveRequestButton = ({ createdById, requestId, requestName, requestSchema, versionId }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleModalClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <ApproveButton onClick={handleClick} />

            {isOpen && (
                <ApproveRequestModal
                    createdById={createdById}
                    onCancel={handleModalClose}
                    onSubmit={() => {}}
                    requestId={requestId}
                    requestName={requestName}
                    requestSchema={requestSchema} 
                    versionId={versionId}
                />
            )}
        </>
    );
};

export default ApproveRequestButton;
