import { memo } from 'react';

import DangerBullet from 'components/DangerBullet';
import ImportantText from 'components/ImportantText';
import PositiveBullet from 'components/PositiveBullet';
import SubTitle from 'components/SubTitle';
import VerticalDivider from 'components/VerticalDivider';
import WarningBullet from 'components/WarningBullet';

import styles from './styles.module.scss';
import dateToTimestampString from 'utilities/dateToTimestampString';

const bulletMap = {
    green: <PositiveBullet className={styles.bullet} />,
    red: <DangerBullet className={styles.bullet} />,
    yellow: <WarningBullet className={styles.bullet} />
};

const HistoryEntry = ({ className = '', color = '', createdAt='', text = '' }) => {
    return (
        <div className={`${className} ${styles.historyEntry}`}>
            {bulletMap[color]}

            <VerticalDivider className={styles.verticalDivider} />

            <ImportantText className={styles.importantText} text={text} />

            <SubTitle className={styles.subTitle} text={`${dateToTimestampString(createdAt)}`} />
        </div>
    );
};

export default memo(HistoryEntry);
