import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('complianceEntryCasePanel');

    return {
        CASE_CREATED_DATE: t('caseCreatedDate'),
        CASE_CREATION_REASON: t('caseCreationReason'),
        CASE_DETAILS: t('caseDetails'),
        CASE_TYPE: t('caseType'),
        DATE_CLOSED: t('dateClosed'),
        RISK_LABEL: t('riskLabel')
    };
};

export default useTranslation;
