const normalizeData = ({ baseSchema, data }) =>
    data?.consultancy.reduce(
        (accumulator, consultancy) => {
            const {
                description,
                schema,
                tenants,
                tenantsByTenantId
            } = consultancy;

            const tenantOptions = tenants.reduce((accumulator, tenant) => {
                const {
                    description: tenantDescription,
                    schema: tenantSchema
                } = tenant?.consultancyByTenantId;
                accumulator.push({label: tenantDescription, value: tenantSchema});
                return accumulator;
            }, [])

            const consultantOptions = tenantsByTenantId.reduce((accumulator, tenant) => {
                const {
                    description: tenantDescription,
                    schema: tenantSchema
                } = tenant?.consultancy;
                accumulator.push({label: tenantDescription, value: tenantSchema});
                return accumulator;
            }, [])

            const item = {
                label: description,
                value: schema
            };

            const querySchemas = [item, ...tenantOptions, ...consultantOptions];

            const consolidatedPermissionedTenants = data?.consultancy_users.reduce((accumulator, tenant) => {
                accumulator.push(tenant?.consultancy_users_consultancy?.name);
                accumulator.push(tenant?.consultancy_users_tenant?.name);
                return accumulator;
            }, [baseSchema]) || [baseSchema];
            const permissionedTenants = new Array(...new Set(consolidatedPermissionedTenants));
            const filteredQuerySchemas = querySchemas.filter((item, index) => querySchemas.indexOf(item) === index);
            const consolidatedOptions = [item, ...tenantOptions];

            accumulator.querySchemas = filteredQuerySchemas;

            accumulator.options = consolidatedOptions.filter(x => permissionedTenants.includes(x.value));

            return accumulator;
        },{options: [], querySchemas: []}
    );

export default normalizeData;
