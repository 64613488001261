import BarListItems from 'components/BarListItems';
import TitleListItem from 'components/TitleListItem';

import useTranslation from './hooks/useTranslation';
import { usePermissions } from 'hooks/usePermissions';

import styles from './styles.module.scss';

const ACTIVITIES_ACTIONS_PERMISSIONS = [
    'activities:actions',
];

const SummarizedListItems = ({ children, className = '' }) => { 
    const { hasPermissions } = usePermissions();

    const activitiesActionsPermission = hasPermissions(
        ACTIVITIES_ACTIONS_PERMISSIONS
    );

    const { ACTIONS, ASSIGNED, DUE_DATE, TYPE } = useTranslation();
    
    return (
        <BarListItems className={className}>
            <TitleListItem
                className={styles.titleListItem}
                items={['', DUE_DATE, TYPE, ASSIGNED, activitiesActionsPermission ? ACTIONS : '']}
            />

            {children}
        </BarListItems>
    );
    
};

export default SummarizedListItems;
