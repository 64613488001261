import ActiveIndicator from 'components/ActiveIndicator';
import BodyText from 'components/BodyText';
import Buttonize from 'components/Buttonize';
import PenIcon from 'components/icons/PenIcon';
import PillGroup from 'components/Pills';
import TableRow from 'components/TableRow';

import styles from './styles.module.scss';

const FormSettingsTableRow = props => {
    const { field, onEdit, status, visibility = [] } = props;

    const isActive = status === 'activate';

    return (
        <TableRow className={styles.formSettingsTableRow}>
            <PillGroup items={visibility} />

            <BodyText text={field} />

            <ActiveIndicator
                className={styles.statusIcon}
                isActive={isActive}
            />

            <Buttonize className={styles.buttonize} onClick={onEdit}>
                <PenIcon />
            </Buttonize>
        </TableRow>
    );
};

export default FormSettingsTableRow;
