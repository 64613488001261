const translations = {
    cancel: 'No, cancel',
    youAreAboutToDeleteAttachment: `
        You are about to delete attachment "ATTACHMENT_NAME" from the system permanently. 
        Once deleted, you won't be able to retrieve data in the future. 
        Do you still want to proceed?
    `
};

export default translations;
