import { useNavigate } from 'react-router-dom';

import Bubble from 'components/Bubble';
import StandoutText from 'components/StandoutText';

import styles from './styles.module.scss';

const BubbleWithText = ({ className = '', count = 0, text = '', url=null }) => {
    const navigate = useNavigate();

    return(
        <div className={`${className} ${styles.bubbleWithText} ${url && styles.bubbleWithText_link}`} onClick={url ? () => navigate(url) : null}>
            <Bubble className={`${className} ${styles.bubble}`} text={count} />
    
            <StandoutText className={styles.standoutText} text={text} />
        </div>
    );
}

export default BubbleWithText;
