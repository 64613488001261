import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('administrationAdditionalSettingsSidebar');

    return {
        CASE_MANAGEMENT: t('caseManagement'),
        EMAIL_ADDRESS: t('emailAddress'),
        LOG_CATEGORY_CONFIGURATION: t('logCategoryConfiguration'),
        RISK_BASED_MONITORING: t('riskBasedMonitoring')
    };
};

export default useTranslation;
