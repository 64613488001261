import { useExport } from './useExport';
import { getFileName } from 'utilities/files';
import generateXLSX from 'utilities/generateXLSX';
import parseDataForReport from 'utilities/parseActivitiesDataForReport';
import parseDetailedDataForReport from 'utilities/parseActivitiesDetailedDataForReport';

export function useExportActivities() {
    const { exportToExcel } = useExport();

    async function exportActivities({
        complete,
        inprogress,
        name,
        overdue,
        upcoming
    }) {
        const parsedData = await parseDataForReport(
            complete,
            inprogress,
            overdue,
            upcoming
        );

        const buffer = await generateXLSX(parsedData, name);
        exportToExcel({
            buffer,
            filename: getFileName(name)
        });
    }

    async function exportDetailedActivities({
        data,
        isAll,
        name,
        querySchemas,
        schema,
        tenants,
        userId
    }) {
        const parsedData = await parseDetailedDataForReport(
            data,
            isAll,
            querySchemas,
            schema,
            tenants,
            userId
        );
        const buffer = await generateXLSX(parsedData, name, true);
        exportToExcel({
            buffer,
            filename: getFileName(name)
        });
    }

    return {
        exportActivities,
        exportDetailedActivities
    };
}
