import { useMemo } from 'react';

import TableHeader from 'components/TableHeader';

import styles from './styles.module.scss';

const PeopleTableHeader = ({
    columns = [],
    isAllRowsSelected,
    toggleAllRows,
    onSort
}) => {
    const headerItems = useMemo(
        () => columns.filter(column => column.isSelected),
        [columns]
    );

    return (
        <TableHeader
            className={styles.peopleTableHeader}
            isAllSelected={isAllRowsSelected}
            items={headerItems}
            onSelectAll={toggleAllRows}
            onSort={onSort}
            selectable
        />
    );
};

export default PeopleTableHeader;
