import PanelLoader from 'components/PanelLoader';

import { forwardRef } from 'react';

import styles from './styles.module.scss';

const PanelBody = forwardRef(
    ({ children, className = '', id='', isLoading = false, isLoadingWithText = null }, ref) => {
        const loadingClass = isLoading ? styles.loading : '';

        return (
            <div
                className={`${className} ${loadingClass} ${styles.panelBody}`}
                id={id}
                ref={ref}
            >
                {isLoading ? <PanelLoader /> : isLoadingWithText ? <PanelLoader text={isLoadingWithText}/> : children}
            </div>
        );
    }
);

export default PanelBody;
