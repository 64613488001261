import formatDate from 'utilities/formatDate';

const mapTenantUser = (tenantUser, querySchemas, schema) => {
    const id = tenantUser?.id;
    var user;
    if(querySchemas && querySchemas.length > 0){
        for(const {value:s} of querySchemas){
            if(tenantUser[`user_${s}`]){
                user = tenantUser[`user_${s}`]
            }
        }
        if(!user){
            user = tenantUser[`user_${schema}`];
        }
    }else{
        user = tenantUser[`user_${schema}`];
    }
    const {
        first_name: firstName,
        last_name: lastName
    } = user ?? {}

    return {
        firstName,
        id,
        lastName
    };
};

const resolveHistory = (requestHistory, querySchemas, schema) =>
    requestHistory.reduce((accumulator, history) => {
        const { color, created_at: createdAt, message, tenant_user: tenantUser } = history;

        const user = mapTenantUser(tenantUser, querySchemas, schema)
        const { firstName, lastName } = user;

        const date = new Date(createdAt);
        const text = `${firstName} ${lastName} ${message}`;

        accumulator.push({
            color,
            createdAt: date,
            date: formatDate(date),
            text
        });

        return accumulator;
    }, []);

const resolveVersions = (requestVersion, querySchemas, schema) =>
    requestVersion.reduce((accumulator, version) => {
        const {
            created_date: versionCreatedDate,
            deleted,
            file,
            file_name: fileName,
            id: versionId,
            status,
            uploaded_timestamp,
            version_number: versionNumber,
            tenant_user: tenantUser,
            marketing_request_version_histories: versionHistories
        } = version;

        const user = mapTenantUser(tenantUser, querySchemas, schema);
        const history = resolveHistory(versionHistories, querySchemas, schema);
        const createdDate = new Date(versionCreatedDate);

        accumulator.push({
            createdBy: user,
            createdDate: formatDate(createdDate),
            deleted,
            file,
            fileName,
            history,
            id: versionId,
            label: versionNumber,
            status,
            uploaded_timestamp,
            versionNumber
        });

        return accumulator;
    }, []);

const resolveComments = (requestComments, querySchemas, schema, versions) =>
    requestComments.reduce((accumulator, comments) => {
        const {
            created_at: createdAt,
            deleted,
            id,
            tenant_user: tenantUser,
            text: content,
            version_id: versionId
        } = comments;

        const userId = tenantUser?.id

        var user;
        if(querySchemas && querySchemas.length > 0){
            for(const {value:s} of querySchemas){
                if(tenantUser[`user_${s}`]){
                    user = tenantUser[`user_${s}`]
                }
            }
            if(!user){
                user = tenantUser[`user_${schema}`];
            }
        }else{
            user = tenantUser[`user_${schema}`];
        }

        const { first_name, last_name } = user;

        const authorName = `${first_name} ${last_name}`;

        const commentUser = {id: userId, name: authorName};

        if (versions.filter(version => version.id === versionId)[0]?.label in accumulator){
            accumulator[versions.filter(version => version.id === versionId)[0]?.label].push({
                content,
                createdAt: createdAt,
                deleted,
                id,
                user: commentUser,
                versionId: versions.filter(version => version.id === versionId)[0]?.label
            });
        }else{
            accumulator[versions.filter(version => version.id === versionId)[0]?.label] = [{
                content,
                createdAt: createdAt,
                deleted,
                id,
                user: commentUser,
                versionId: versions.filter(version => version.id === versionId)[0]?.label
            }];
        }

        return accumulator;
    }, {});

const resolveFeedback = (requestFeedback, querySchemas, schema) =>
    requestFeedback.reduce((accumulator, feedback) => {
        const {
            created_at: feedbackCreatedAt,
            id,
            message,
            type,
            tenant_user: tenantUser,
            marketing_request_version: requestVersion
        } = feedback;

        const user = mapTenantUser(tenantUser, querySchemas, schema)
        const version = requestVersion?.version_number
        const createdAt = new Date(feedbackCreatedAt);

        accumulator.push({
            createdAt: formatDate(createdAt),
            id,
            message,
            sortBy: createdAt,
            type,
            user,
            version
        });

        return accumulator;
    }, []);

const resolveAssignedTo = (requestAssignedTo, querySchemas, schema) =>
    requestAssignedTo.reduce((accumulator, assignedTo) => {
        const {
            group,
            status,
            tenant_user: tenantUser,
        } = assignedTo;

        const type = (tenantUser !== null) ? "user" :
                        (group !== null) ? "group" : "none"
        const assigned = (type==='user') ? mapTenantUser(tenantUser, querySchemas, schema) :
                            (type==='group') ? group : {};
        const firstName = (type==='user') ? assigned?.firstName :
                            (type==='group') ? assigned?.name : '';
        const lastName = (type==='user') ? assigned?.lastName :
                            (type==='group') ? 'Group' : '';

        accumulator.push({
            assignedTo: assigned,
            firstName,
            id: assigned?.id,
            lastName,
            status,
            type
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, querySchemas, schema, user }) =>
    data[`${schema}_marketing_request`].reduce(
        (accumulator, request) => {
            const {
                description,
                dropdown_selections: dropdownSelections,
                due_date: requestDueDate,
                expected_use_date: requestExpectedUseDate,
                high_importance: highImportance,
                id,
                recipient,
                request_name: requestName,
                status: requestStatus,
                tenant_user: requestCreatedBy,
                marketing_request_versions: requestVersions,
                marketing_request_version_comments: versionComments,
                marketing_request_feedbacks: requestFeedback,
                marketing_request_assigned_tos: requestAssignedTo
            } = request;

            const createdBy = mapTenantUser(requestCreatedBy, querySchemas, schema);
            const versions = resolveVersions(requestVersions, querySchemas, schema);
            const comments = resolveComments(versionComments, querySchemas, schema, versions);
            const feedback = resolveFeedback(requestFeedback, querySchemas, schema);
            const assignedTo = resolveAssignedTo(requestAssignedTo, querySchemas, schema);

            const dueDate = new Date(requestDueDate);
            const expectedUseDate = new Date(requestExpectedUseDate);

            const activeParty = assignedTo.filter(obj => {
                return obj.status !== 'inactive';
            });

            const userType = (parseInt(user) === createdBy?.id) ? "requestor" :
                                (activeParty.some(e => (e.type === "user") && (e.id === parseInt(user)))) ? "reviewer" : "default";
            const sortedFeedback = feedback.sort((objA, objB) => (objA.sortBy < objB.sortBy ? 1 : -1));
            const pendingRequest = (feedback.length > 0 && feedback[0]?.type === "request")

            accumulator = {
                activeParty,
                assignedTo,
                comments,
                createdBy,
                description,
                dropdownSelections,
                dueDate: formatDate(dueDate),
                expectedUseDate: formatDate(expectedUseDate),
                feedback: sortedFeedback,
                highImportance,
                id,
                name:requestName,
                pendingRequest,
                recipient,
                requestStatus,
                userType,
                versions: versions.sort((objA, objB) => (objA.version_number > objB.version_number ? 1 : -1))
            };
            return accumulator;
        },{}
    );

export default normalizeData;
