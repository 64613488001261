import DropdownMultiSelect from 'components/DropdownMultiSelect';
import Dropdown from 'components/Dropdown';
import TableRow from 'components/TableRow';
import styles from './styles.module.scss';

const dateDropdownOptions = [
    { label: 'All', value: 'all' },
    { label: 'This quarter', value: 'thisQuarter' },
    { label: 'Last quarter', value: 'lastQuarter' },
    { label: 'This year', value: 'thisYear' },
    { label: 'Last year', value: 'lastYear' },
    { label: 'Custom', value: 'custom' }
  ];

const DynamicTableDropdowns = ({ 
    className = '', 
    filters, 
    hasCustomDateDropdown, 
    items = [], 
    refresh, 
    refreshData, 
    selectedDateFilter,
    setFilters,
    setOpenModal,
    setSelectedDateFilter, 
}) => {
    const dateDropdowns = [];

    if (hasCustomDateDropdown) {
        dateDropdowns.push(
            <div className={styles.dateCustomWrap} key="custom-date-dropdown">
                <Dropdown
                    items={dateDropdownOptions}
                    onChange={(value) => {
                        setSelectedDateFilter(value);
                        if(value === 'custom'){
                            setOpenModal(true);
                        }else{
                            setOpenModal(false);
                        }
                    }}
                    returnObject={true}
                    selected={selectedDateFilter}
                />
            </div>
        );
    }

    const dropdowns = items.map((item, index) => {
        function setNewFilters(value){
            const newFilters = {...filters};
            newFilters[index] = value;
            setFilters(newFilters);
            refreshData(!refresh);
        }
        
        if(Array.isArray(item)){
            return(
                <DropdownMultiSelect
                    items={item}
                    key={`DynamicTableDropdown${index}`}
                    onChange={setNewFilters}
                    returnObject={true}
                    selected={filters[index]}
                />
            );
        }else{
            return(<span key={`DynamicTableDropdownPlaceholder${index}`}></span>);
        }
    });

    const allDropdowns = [dateDropdowns, ...dropdowns];

    return(    
        <TableRow className={`${className} ${styles.caseTableHeader}`} key="DynamicTableDropdownRow">
            {allDropdowns}
        </TableRow>
    );
}

export default DynamicTableDropdowns;
